
import DriversEntity from "@/views/TransportModule/Drivers/DriversEntity.vue";
import DriversControl from "@/views/TransportModule/Drivers/DriversControl.vue";
import AreaEntity from "@/views/TransportModule/Areas/AreaEntity.vue";
import AreaControl from "@/views/TransportModule/Areas/AreaControl.vue";
import PortEntity from "@/views/TransportModule/Port/PortEntity.vue";
import PortControl from "@/views/TransportModule/Port/PortControl.vue";
import WarehouseEntity from "@/views/TransportModule/Warehouse/WarehouseEntity.vue";
import WarehouseControl from "@/views/TransportModule/Warehouse/WarehouseControl.vue";
import TripEntity from "@/views/TransportModule/Trip/TripEntity.vue";
import TripControl from "@/views/TransportModule/Trip/TripControl.vue";
import AreaPriceEntity from "@/views/TransportModule/AreaPrice/AreaPriceEntity.vue";
import AreaPriceControl from "@/views/TransportModule/AreaPrice/AreaPriceControl.vue";
import CarsEntity from "@/views/TransportModule/Cars/CarsEntity.vue";
import CarControl from "@/views/TransportModule/Cars/CarsControl.vue";
import WarehouseTransactionEntity from "@/views/TransportModule/WarehouseTransaction/WarehouseTransaction.vue";
import WarehouseTransactionControl from "@/views/TransportModule/WarehouseTransaction/WarehouseTransactionControl.vue";
import ReleaseInvoiceEntity from "@/views/TransportModule/ReleaseInvoice/ReleaseInvoiceEntity.vue";
import ReleaseInvoiceControl from "@/views/TransportModule/ReleaseInvoice/ReleaseInvoiceControl.vue";
import ContainerDamage from "@/views/TransportModule/ContainerDamage/ContainerDamage.vue";
import ContainerDamageControlVue from "@/views/TransportModule/ContainerDamage/ContainerDamageControl.vue";
import TreatmentEntity from "@/views/TransportModule/Treatment/TreatmentEntity.vue";
import WarehouseReportEntity from "../views/TransportModule/WarehouseReport/WarehouseReportEntity.vue";
import TransportReportEntity from "../views/TransportModule/TransportReport/TransportReportEntity.vue";
import MaintenanceReportEntity from "../views/TransportModule/MaintenanceReport/MaintenanceReportEntity.vue";
import CarsMovementsReportEntity from "../views/TransportModule/CarsMovementsReport/CarsMovementsReportEntity.vue";
import CarsIncomeReportEntity from "../views/TransportModule/CarsIncomeReport/CarsIncomeReportEntity.vue";
import DriverIncomeReportEntity from "../views/TransportModule/DriverIncomeReport/DriverIncomeReportEntity.vue";
import DriverReportEntity from "../views/TransportModule/DriverReport/DriverReportEntity.vue";
import ExpensesClearance from "../views/TransportModule/ExpensesClearance/ExpensesClearance.vue";
import TripExpensesReport from "../views/TransportModule/TripExpensesReport/TripExpensesReportEntity.vue";
import AgentsEntity from "../views/TransportModule/Agents/AgentsEntity.vue";
import AgentsControl from "../views/TransportModule/Agents/AgentsControl.vue";
import StorageContainersReportEntity from "../views/TransportModule/StorageContainersReport/StorageContainersReportEntity.vue";

// Transport Module Routes:-
export const TransportModuleRoutes = [
  // Drivers
  {
    path: "/transport/driver",
    name: "DriversEntity",
    component: DriversEntity,
    meta: {
      screen_code: "03-002",
    },
  },
  {
    path: "/transport/driver-control",
    name: "DriversControlADD",
    component: DriversControl,
    meta: {
      screen_code: "03-002",
    },
  },
  {
    path: "/transport/driver-control/:id",
    name: "DriversControlEdit",
    component: DriversControl,
    meta: {
      screen_code: "03-002",
    },
  },
  // Areas
  {
    path: "/transport/area",
    name: "AreaEntity",
    component: AreaEntity,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/transport/area-control",
    name: "AreaControlADD",
    component: AreaControl,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/transport/area-control/:id",
    name: "AreaControlEdit",
    component: AreaControl,
    meta: {
      screen_code: "03-003",
    },
  },
  // Ports
  {
    path: "/transport/port",
    name: "PortEntity",
    component: PortEntity,
    meta: {
      screen_code: "03-004",
    },
  },
  {
    path: "/transport/port-control",
    name: "PortControlADD",
    component: PortControl,
    meta: {
      screen_code: "03-004",
    },
  },
  {
    path: "/transport/port-control/:id",
    name: "PortControlEdit",
    component: PortControl,
    meta: {
      screen_code: "03-004",
    },
  },
  // Warehouses
  {
    path: "/transport/wearhouse",
    name: "WarehouseEntity",
    component: WarehouseEntity,
    meta: {
      screen_code: "03-005",
    },
  },
  {
    path: "/transport/wearhouse-control",
    name: "WarehouseControlADD",
    component: WarehouseControl,
    meta: {
      screen_code: "03-005",
    },
  },
  {
    path: "/transport/wearhouse-control/:id",
    name: "WarehouseControlEdit",
    component: WarehouseControl,
    meta: {
      screen_code: "03-005",
    },
  },
  // Trip
  {
    path: "/transport/trip",
    name: "TripEntity",
    component: TripEntity,
    meta: {
      screen_code: "03-007",
    },
  },
  {
    path: "/transport/trip-control",
    name: "TripControlADD",
    component: TripControl,
    meta: {
      screen_code: "03-007",
    },
  },
  {
    path: "/transport/trip-control/:id",
    name: "TripControlEdit",
    component: TripControl,
    meta: {
      screen_code: "03-007",
    },
  },
  // Area Price
  {
    path: "/transport/trip_price",
    name: "AreaPriceEntity",
    component: AreaPriceEntity,
    meta: {
      screen_code: "03-006",
    },
  },
  {
    path: "/transport/trip_price-control",
    name: "AreaPriceControlADD",
    component: AreaPriceControl,
    meta: {
      screen_code: "03-006",
    },
  },
  {
    path: "/transport/trip_price-control/:id",
    name: "AreaPriceControlEdit",
    component: AreaPriceControl,
    meta: {
      screen_code: "03-006",
    },
  },
  // Warehouse Container
  {
    path: "/transport/wearhouse_transaction",
    name: "WarehouseTransactionEntity",
    component: WarehouseTransactionEntity,
    meta: {
      screen_code: "03-009",
    },
  },
  {
    path: "/transport/wearhouse_transaction-control",
    name: "WarehouseTransactionControl",
    component: WarehouseTransactionControl,
    meta: {
      screen_code: "03-009",
    },
  },
  {
    path: "/transport/wearhouse_transaction-control/:id",
    name: "WarehouseTransactionControlEdit",
    component: WarehouseTransactionControl,
    meta: {
      screen_code: "03-009",
    },
  },
  // Cars
  {
    path: "/transport/car",
    name: "CarsEntity",
    component: CarsEntity,
    meta: {
      screen_code: "03-008",
    },
  },
  {
    path: "/transport/car-control",
    name: "CarControlADD",
    component: CarControl,
    meta: {
      screen_code: "03-008",
    },
  },
  {
    path: "/transport/car-control/:id",
    name: "CarControlEdit",
    component: CarControl,
    meta: {
      screen_code: "03-008",
    },
  },
  // Release Invoice
  {
    path: "/transport/invoice",
    name: "ReleaseInvoiceEntity",
    component: ReleaseInvoiceEntity,
    meta: {
      screen_code: "03-011",
    },
  },
  {
    path: "/transport/invoice-control",
    name: "ReleaseInvoiceControlADD",
    component: ReleaseInvoiceControl,
    meta: {
      screen_code: "03-011",
    },
  },
  {
    path: "/transport/invoice-control/:id",
    name: "ReleaseInvoiceControlEdit",
    component: ReleaseInvoiceControl,
    meta: {
      screen_code: "03-011",
    },
  },
  // Container Damage
  {
    path: "/transport/container_damage",
    name: "ContainerDamage",
    component: ContainerDamage,
    meta: {
      screen_code: "03-010",
    },
  },
  {
    path: "/transport/container_damage-control",
    name: "ContainerDamageControlADD",
    component: ContainerDamageControlVue,
    meta: {
      screen_code: "03-010",
    },
  },
  {
    path: "/transport/container_damage-control/:id",
    name: "ContainerDamageControlEdit",
    component: ContainerDamageControlVue,
    meta: {
      screen_code: "03-010",
    },
  },
  // Treatment 
  {
    path: "/transport/active_treatment",
    name: "TreatmentEntity",
    component: TreatmentEntity,
    meta: {
      screen_code: "03-012",
    },
  },
  // Warehouse Report
  {
    path: "/transport/warehouse_report",
    name: "WarehouseReportEntity",
    component: WarehouseReportEntity,
    meta: {
      screen_code: "03-013",
    },
  },
  // Transport Report
  {
    path: "/transport/transport_report",
    name: "TransportReport",
    component: TransportReportEntity,
    meta: {
      screen_code: "03-014",
    },
  },
  // Maintenance Report 
  {
    path: "/transport/maintenance_report",
    name: "MaintenanceReportEntity",
    component: MaintenanceReportEntity,
    meta: {
      screen_code: "03-015",
    },
  },
  // Car Movements Report 
  {
    path: "/transport/car_movements_report",
    name: "CarsMovementsReportEntity",
    component: CarsMovementsReportEntity,
    meta: {
      screen_code: "03-016",
    },
  },
  // Car Income Report 
  {
    path: "/transport/car_income_report",
    name: "CarsIncomeReportEntity",
    component: CarsIncomeReportEntity,
    meta: {
      screen_code: "03-017",
    },
  },
  // Driver Income Report 
  {
    path: "/transport/driver_income_report",
    name: "DriverIncomeReportEntity",
    component: DriverIncomeReportEntity,
    meta: {
      screen_code: "03-018",
    },
  },
  // Expenses Clearance
  {
    path: "/transport/expenses_clearance",
    name: "ExpensesClearance",
    component: ExpensesClearance,
    meta: {
      screen_code: "03-019",
    },
  },
  // Driver Report 
  {
    path: "/transport/driver_report",
    name: "DriverReportEntity",
    component: DriverReportEntity,
    meta: {
      screen_code: "03-020",
    },
  },
  {
    path: "/transport/expenses_clearance/:id",
    name: "ExpensesClearanceShow",
    component: ExpensesClearance,
    meta: {
      screen_code: "04-021",
    },
  },
  //  trip expenses 
  {
    path: "/transport/trip_expenses_reports",
    name: "TripExpensesReport",
    component: TripExpensesReport,
    meta: {
      screen_code: "03-021",
    },
  },
  // Agents
  {
    path: "/transport/agents",
    name: "AgentsEntity",
    component: AgentsEntity,
    meta: {
      screen_code: "03-022",
    },
  },
  {
    path: "/transport/agents-control",
    name: "AgentsControl",
    component: AgentsControl,
    meta: {
      screen_code: "03-022",
    },
  },
  {
    path: "/transport/agents-control/:id",
    name: "AgentsControlShow",
    component: AgentsControl,
    meta: {
      screen_code: "03-022",
    },
  },
  // Storage Containers Report
  {
    path: "/transport/storage_containers_report",
    name: "StorageContainersReportEntity",
    component: StorageContainersReportEntity,
    meta: {
      screen_code: "03-024",
    },
  },
];
