<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :createBtn="false" :showDeleted="false"
        :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0  justify-center pa-0  overflow-hidden">
              <v-col cols="12" class="pa-0 px-10">
                <v-tabs color="secondary" active-class="blue12">
                  <v-tab class="font-weight-bold" :value="1" @click="tab = 1"><strong>
                      {{ $t('delegates credits') }}
                    </strong>
                  </v-tab>
                  <v-tab class="font-weight-bold" :value="2" @click="tab = 2"><strong>
                      {{ $t('previous qualifiers') }}
                    </strong>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="pa-0 mb-5">
                <v-divider></v-divider>
              </v-col>
              <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="pageData.rows"
                :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" cardClasses="shadow-none">
                <template v-slot:td="{ row, header }">
                  <div v-if="header.key == 'close_petty'">
                    <v-btn @click="edit(row)" small color="success" depressed>
                      {{ $t('close petty cash') }}
                    </v-btn>
                  </div>
                  <div v-if="header.key == 'show_petty'">
                    <v-btn @click="printMethod(row)"
                      :loading="(pageData.printLoading.loading == true && pageData.printLoading.id == row[pageData.printLoading.key])"
                      x-small icon>
                      <img src="@/assets/img/svg_icons/fi_printer.svg" height="18" />
                    </v-btn>
                    <v-btn @click="$router.push(`/accounting/delegate_petty-control/${row.m_id}?show=true`)" x-small icon
                      fab>
                      <img src="@/assets/img/svg_icons/fi_eye.svg" height="20" />
                    </v-btn>
                  </div>
                  <div v-if="header.key == 'source'">
                    <v-chip small :color="row.source == 'PV' ? 'secondary' : 'primary'" depressed>
                      {{ row.source == 'PV' ? $t('payment voucher') : $t('recipe voucher') }}
                    </v-chip>
                  </div>
                </template>
              </DynamicTable>
            </v-card>
          </v-col>

        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
export default {
  name: "DelegatePettyCashEntity",

  data: () => ({
    pageData: {
      screen_code: "02-019",
      url: null,
      controlRoute: "accounting/delegate_petty-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      status: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'm_id'
      },
    },
    tab: 1,
    delegates: [],
    items: [],
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
  },
  computed: {

  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      if (this.tab == 2) {
        this.pageData.rows = this.items.data;
        this.pageData.page = this.items.current_page;
        this.pageData.pagination = {
          page: this.items.current_page,
          totalPages: this.items.last_page,
          per_page: this.items.per_page,
          totalRows: this.items.total,
        };
        this.pageData.tableHeader = [
          { text: "#", key: "m_id", type: 'text', classes: "" },
          { text: "delegate", key: "delegate_name", type: 'text', classes: "" },
          { text: "date", key: "action_date", type: 'date', classes: "" },
          // { text: "remaining", key: "remaining_amount", type: 'float', classes: "" },
          { text: "total", key: "total_amount", type: 'float', classes: "" },
          { text: "", key: "show_petty", type: 'slot', classes: "" },
        ];
      } else {
        this.pageData.rows = this.delegates.data;
        this.pageData.page = this.delegates.current_page;
        this.pageData.pagination = {
          page: this.delegates.current_page,
          totalPages: this.delegates.last_page,
          per_page: this.delegates.per_page,
          totalRows: this.delegates.total,
        };
        this.pageData.tableHeader = [
          { text: "#", key: "id", type: 'text', classes: "" },
          { text: "delegate", key: "name", type: 'text', classes: "" },
          { text: "account", key: "account_tree_name", type: 'text', classes: "" },
          { text: "balance", key: "balance", type: 'float', classes: "" },
          { text: "", key: "close_petty", type: 'slot', classes: "" },
        ];
      }
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "delegate", key: "name", type: 'text', classes: "" },
        { text: "account", key: "account_tree_name", type: 'text', classes: "" },
        { text: "balance", key: "balance", type: 'float', classes: "" },
        { text: "", key: "close_petty", type: 'slot', classes: "" },
        // { text: "print", key: "print", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: true,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `delegate_petty${this.pageData.queryParam}&status=${this.tab}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.delegates = response.data.delegates;
              this.items = response.data.items;
              this.pageData.rows = this.tab == 1 ? this.delegates.data : this.items.data;
              this.pageData.page = this.tab == 1 ? this.delegates.current_page : this.items.current_page;
              this.pageData.pagination = {
                page: this.tab == 1 ? this.delegates.current_page : this.items.current_page,
                totalPages: this.tab == 1 ? this.delegates.last_page : this.items.last_page,
                per_page: this.tab == 1 ? this.delegates.per_page : this.items.per_page,
                totalRows: this.tab == 1 ? this.delegates.total : this.items.total,
              };
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`delegate_petty/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`delegate_petty_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/delegate_petty/${row.m_id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    printMethod(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.m_id;
      this.$api.PrintPOST('export_delegate_petty', row.m_id, 'print').then(() => {
        this.pageData.printLoading.loading = false;
        this.pageData.printLoading.id = null;
      })
    },
  },
};
</script>
