<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <v-col cols="6" class="position-relative pa-0">
                     <GenericInput type="select" :lookups="areas" :disableItem="{ key: 'id', value: form.to_area_id }"
                        :value="form.from_area_id" @input="form.from_area_id = $event" label="from place" :multi="false"
                        :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                     <v-btn icon fab x-small class="lockupsAddButton" @click="openQuickAdd('from_area_id')"
                        color="success">
                        <v-icon>mdi-plus</v-icon>
                     </v-btn>
                  </v-col>

                  <v-col cols="6" class="position-relative pa-0">
                     <GenericInput type="select" :lookups="areas" :disableItem="{ key: 'id', value: form.from_area_id }"
                        :value="form.to_area_id" @input="form.to_area_id = $event" label="to place" :multi="false"
                        :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                     <v-btn icon fab x-small class="lockupsAddButton" @click="openQuickAdd('to_area_id')"
                        color="success">
                        <v-icon>mdi-plus</v-icon>
                     </v-btn>
                  </v-col>



                  <GenericInput type="number" :value="form.driver_price_in" @input="form.driver_price_in = $event"
                     label="diesel expense for Amwaj Mad customers (soil expense)" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                  <GenericInput type="number" :value="form.driver_price_out" @input="form.driver_price_out = $event"
                     label="diesel expense for transport customers (soil expense)" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                  <GenericInput type="number" :value="form.trip_price_in" @input="form.trip_price_in = $event"
                     label="trip price for Amwaj Mad customers (internal)" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                  <GenericInput type="number" :value="form.trip_price_out" @input="form.trip_price_out = $event"
                     label="trip price for transport customers" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"></GenericInput>

               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>

      <AddLockupsDialog entityName="area" endPoint="area" :enablePhone="true" :dialog="addAreaDialog"
         :closeMethod="() => { addAreaDialog = false }" :saveMethod="saveNewArea" name_ar_key="area_name_ar" name_en_key="area_name_en" />
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import AddLockupsDialog from '@/components/AddLockupsDialog.vue';

export default {
   name: "AreaPriceControl",

   data: () => ({
      pageData: {
         screen_code: "03-006",
         url: null,
         controlRoute: "/transport/trip_price-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      put_new_area_to: null,
      addAreaDialog: false,
      areas: [],
      form: {
         from_area_id: null,
         to_area_id: null,
         driver_price_in: null,
         driver_price_out: null,
         trip_price_in: null,
         trip_price_out: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
      AddLockupsDialog
   },
   watch: {

   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      checkIsPlaceDisabled(item) {
         console.log(item);
         return (item.id == this.from_area_id)
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title : this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },

      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`area_price/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.areas = response.data.areas;
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`area_price/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.areas = response.data.areas;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`area_price/${this.form.area_price_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`area_price`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`area_price/${this.form.area_price_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      openQuickAdd(key) {
         this.addAreaDialog = true;
         this.put_new_area_to = key
      },
      saveNewArea(area) {
         console.log(area);
         this.areas.push(area);
         this.form[this.put_new_area_to] = area.id;
         this.addAreaDialog = false;
         this.put_new_area_to = null
      },

   },
};
</script>
