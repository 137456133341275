import { render, staticRenderFns } from "./ChatDialog.vue?vue&type=template&id=61f9394c&scoped=true"
import script from "./ChatDialog.vue?vue&type=script&lang=js"
export * from "./ChatDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f9394c",
  null
  
)

export default component.exports