import { render, staticRenderFns } from "./AddMainCostCenterModal.vue?vue&type=template&id=30c247c0&scoped=true"
import script from "./AddMainCostCenterModal.vue?vue&type=script&lang=js"
export * from "./AddMainCostCenterModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c247c0",
  null
  
)

export default component.exports