<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader
            :pageData="pageData"
            :form="form"
            :save="save"
            :valid="valid"
            :deleteMethod="deleteMethod"
         />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput
                     type="text"
                     :value="form.name_en"
                     @input="form.name_en = $event"
                     label="name_en"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
                  <GenericInput
                     type="text"
                     :value="form.name_ar"
                     @input="form.name_ar = $event"
                     label="name_ar"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
                  <GenericInput
                     type="select"
                     :lookups="account_trees"
                     :value="form.account_tree_id"
                     @input="form.account_tree_id = $event"
                     label="linked account"
                     :multi="false"
                     :required="true"
                     selected_label="name"
                     selected_prop="id"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
                  <GenericInput
                     type="select"
                     :lookups="branchs"
                     :value="form.branch_id"
                     @input="form.branch_id = $event"
                     label="branches"
                     :multi="false"
                     :required="true"
                     selected_label="name"
                     selected_prop="id"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

import ControlHeader from "../../../components/ui/ControlHeader.vue";
import GenericInput from '../../../components/ui/GenericInput.vue';
export default {
   name: "SalesManControl",

   data: () => ({
      pageData: {
         screen_code: "01-013",
         url: null,
         controlRoute: "/main/sales_agent-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      deleteDialog: false,
      valid: false,
      account_trees: [],
      branchs: [],
      form: {
         name_en: null,
         name_ar: null,
         account_tree_id: null,
         branch_id: null,
      },
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('sales agent') : this.$i18n.t('add sales agent');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`sales_agent/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.account_trees = response.data.account_tree;
                     this.branchs = response.data.branchs;
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`sales_agent/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.account_trees = response.data.account_tree;
                     this.branchs = response.data.branchs;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`sales_agent/${this.form.sales_agent_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`sales_agent`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         this.deleteDialog = false
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`sales_agent/${this.form.sales_agent_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
