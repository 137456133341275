<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="450">
         <v-card relative class="backgroundW">
            <v-btn
               absolute
               class="mt-2"
               :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
               small
               fab
               color="grey lighten-1"
               text
               @click="closeDialog"
            >
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-card-title class="text-h6 font-weight-bold">
               <v-icon
                  style="background-color: #2D3E50  ;"
                  small
                  class="rounded-pill pa-1 mx-2"
                  color="white"
               >mdi-sort-numeric-ascending</v-icon>
               <h5>
                  <span>{{ $t('Rearrangement list') }}</span>
                  <span v-if="step == 2" class="mx-2">
                     <!-- <small>({{ mainMenu }})</small> -->
                  </span>
               </h5>
            </v-card-title>
            <v-col v-if="isLoading == true">
               <v-progress-circular
                  :size="70"
                  :width="5"
                  style="margin: auto; "
                  class="d-block"
                  color="#2D3E50"
                  indeterminate
               ></v-progress-circular>
            </v-col>
            <v-col cols="12" sm="12" v-if="step == 1 && isLoading == false">
               <v-col cols="12" sm="12">
                  <label>{{ $t('Choose main menu') }}*</label>
                  <v-autocomplete
                     v-model="mainMenu"
                     :items="mainMenus"
                     hide-details
                     dense
                     :no-data-text="$i18n.t('no selects')"
                     item-text="title"
                     item-value="id"
                     @change="getCats()"
                     outlined
                     hide-no-data
                     class="mt-1"
                     required
                  ></v-autocomplete>
               </v-col>
            </v-col>
            <v-col cols="12" sm="12" v-if="step == 2 && isLoading == false">
               <v-col cols="12" sm="12">
                  <label>{{ $t('Choose category') }}*</label>
                  <v-autocomplete
                     v-model="category"
                     :items="categories"
                     hide-details
                     dense
                     :no-data-text="$i18n.t('no selects')"
                     item-text="title"
                     item-value="id"
                     @change="getSubs()"
                     outlined
                     hide-no-data
                     class="mt-1"
                     required
                  ></v-autocomplete>
               </v-col>
            </v-col>
            <v-col cols="12" v-if="step == 3 && isCatLoading == false">
               <draggable
                  :list="data"
                  :disabled="!enabled"
                  class
                  ghost-class="light"
                  @change="checkMove"
                  @start="dragging = true"
                  @end="dragging = false"
                  v-model="data"
               >
                  <transition-group type="transition">
                     <div
                        style="cursor :move; border-bottom: 1px solid #eee"
                        v-for="(element) in data"
                        :key="element.sub_id"
                     >
                        <div class="d-flex pa-3 align-center">
                           <p class="col-1 text-center pa-0 my-0">{{ element.sub_id }}</p>
                           <v-divider class="mx-3" vertical></v-divider>
                           <p class="my-0">{{ element.sub_title }}</p>
                        </div>
                     </div>
                  </transition-group>
               </draggable>
            </v-col>

            <v-col cols="12" class="py-4">
               <v-row justify="end">
                  <v-col cols="auto">
                     <v-btn
                        outlined
                        @click="closeDialog"
                        class="shadow"
                        color="error"
                        style="width: 100%; text-align: start"
                     >{{ $t('close') }}</v-btn>
                  </v-col>
                  <v-col cols="auto" v-if="step == 1">
                     <v-btn
                        color="primary"
                        :disabled="!mainMenu"
                        @click="step = 2"
                        class="shadow white--text"
                        style="width: 100%; text-align: start"
                     >{{ $t('next') }}</v-btn>
                  </v-col>
                  <v-col cols="auto" v-if="step == 2">
                     <v-btn
                        color="primary"
                        :disabled="!category"
                        @click="step = 3"
                        class="shadow white--text"
                        style="width: 100%; text-align: start"
                     >{{ $t('next') }}</v-btn>
                  </v-col>
                  <v-col cols="auto" v-if="step == 3">
                     <v-btn
                        color="#8F8F8F"
                        @click="returnData"
                        class="shadow white--text"
                        style="width: 100%; text-align: start"
                     >{{ $t('save') }}</v-btn>
                  </v-col>
               </v-row>
            </v-col>
         </v-card>
      </v-dialog>
   </v-row>
</template>



<style scoped lang="scss">
.flip-list-move {
   transition: transform 0.5s;
}
.no-move {
   transition: transform 0s;
}
</style>



<script>
import draggable from "vuedraggable";
export default {
   name: "SubMenusRearrangementDialog",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      isLoading: { default: false },

   },
   computed: {

   },
   components: {
      draggable
   },
   data() {
      return {
         data: [],
         step: 1,
         drag: true,
         dragging: false,
         isCatLoading: false,
         enabled: true,
         mainMenu: null,
         category: null,
         mainMenus: [],
         categories: [],
         subMenus: [],
         newOrder: [],
         oldOrder: [],
      };
   },
   methods: {
      getData() {
         if (this.$global.CheckAction('01-003', 1)) {
            this.isLoading = true
            this.$api.GET_METHOD(`screen_cat/create`).then((response) => {
               this.isLoading = false
               if (response.check) {
                  this.mainMenus = response.data.mains
                  // this.categories = response.data.cats
               }
            })

         }
      },
      getCats() {
         this.isCatLoading = true
         this.$api.GET_METHOD(`screen_cat?main=${this.mainMenu}`).then((response) => {
            this.isCatLoading = false
            if (response.check) {
                this.categories  = response.data.items
            }
         })
      },
      getSubs() {
         this.isCatLoading = true
         this.$api.GET_METHOD(`screen_sub?cat=${this.category}`).then((response) => {
            this.isCatLoading = false
            if (response.check) {
               this.data = response.data.items
            }
         })
      },

      closeDialog() {
         this.dialog = false
         this.backValueMethod(this.oldOrder, false);
         this.step = 2
         this.mainMenu = null
         this.subMenu = null
      },
      returnData() {

         this.backValueMethod(this.newOrder, true);
         // this.step = 2
         this.subMenu = null
         this.mainMenu = null
      },
      checkMove() {
         this.newOrder = []
         this.data.forEach((element) => {
            return this.newOrder.push({ 'id': element.sub_id })
         });
         console.log("newOrder ", this.newOrder);
      }
   },
   mounted() {
      this.getData()
      this.oldOrder = this.data
   }
};
</script>
