<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">

                  <GenericInput type="select" :lookups="warehouses" :value="form.wearhouse_id"
                     @input="form.wearhouse_id = $event" label="terminal" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                  <GenericInput type="radiogroup" :lookups="types" :disabled="form.transaction_type == 1"  selected_label="name" selected_prop="id" label="type"
                     :value="form.is_box" @input="form.is_box = $event" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>


                  <v-col cols="6" class="position-relative pa-0">
                     <GenericInput type="select" :lookups="customers" :disabled="form.transaction_type == 1"
                        :value="form.customer_id" @input="form.customer_id = $event" label="customer" :multi="false"
                        :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[10, 11, 11]"></GenericInput>

                     <v-btn icon fab x-small class="lockupsAddButton" @click="addCustomerDialog = true" color="success">
                        <v-icon>mdi-plus</v-icon>
                     </v-btn>
                  </v-col>

                  <!-- <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                     @input="form.customer_id = $event" label="customer" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" /> -->

                  <GenericInput type="number" v-if="(form.is_box == 0) && pageData.isEdit && form.transaction_type == 1"
                     :value="form.clearance_m_id" @input="form.clearance_m_id = $event"
                     :disabled="form.transaction_type == 1" label="transaction no" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="text" v-if="(form.is_box == 0)" :disabled="form.transaction_type == 1"
                     :value="form.container_number" @input="form.container_number = $event" label="container number"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="radiogroup" v-if="(form.is_box == 0)" :lookups="sizes" selected_label="name"
                     selected_prop="id" label="container size" :disabled="form.transaction_type == 1"
                     :value="form.container_size || form.clearance_d_id" :required="true"
                     @input="form.container_size = $event" :isLoading="false" :cols="[12, 6, 3]"></GenericInput>


                  <GenericInput type="checkbox" v-if="(form.is_box == 0)" :disabled="form.transaction_type == 1" :value="form.is_empty"
                     @input="form.is_empty = $event" label="container is empty" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]" />


                  <GenericInput type="number" :value="form.in_driver_residency_number"
                     @input="form.in_driver_residency_number = $event" label="driver's residence number" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]" :acceptZero="true" />

                  <GenericInput type="text" :value="form.in_driver_name" @input="form.in_driver_name = $event"
                     label="driver name" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]" />

                  <GenericInput type="number" :value="form.in_driver_mobile" @input="form.in_driver_mobile = $event"
                     label="the driver's phone number" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 3]" :acceptZero="true" />

                  <GenericInput type="text" :value="form.in_car_number" @input="form.in_car_number = $event" label="car number"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]" :acceptZero="true" />

                  <GenericInput type="text" :value="form.store_place" @input="form.store_place = $event"
                     label="the storage place in the terminal" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 3]" />

                  <GenericInput type="number" :value="form.cost_per_day" @input="form.cost_per_day = $event"
                     label="storage price" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]" />


                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="notes"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" />
               </v-row>
            </v-card>
         </v-form>


         <AddLockupsDialog entityName="customer" endPoint="customer" :enablePhone="true" :dialog="addCustomerDialog"
            :closeMethod="() => { addCustomerDialog = false }" :saveMethod="saveCustomer" />


         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import AddLockupsDialog from "@/components/modals/AddLockupsDialog.vue";
export default {
   name: "WarehouseTransactionControl",

   data: () => ({
      pageData: {
         screen_code: "03-009",
         url: null,
         controlRoute: "/transport/wearhouse_transaction-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      addCustomerDialog: false,
      customers: [],
      warehouses: [],
      transaction_types: [],
      types: [],
      sizes: [],
      drivers: [],
      cars: [],
      form: {
         wearhouse_id: null,
         is_box: 0,
         customer_id: null,
         container_size: null,
         in_car_number: null,
         in_driver_name: null,
         in_driver_mobile: null,
         in_driver_residency_number: null,
         // in_date: null,
         // in_time: null,
         // in_note: null,
         is_empty: 0,
         clearance_m_id: null,
         clearance_d_id: null,
         store_place: null,
         cost_per_day: null,
         notes: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
      AddLockupsDialog
   },
   watch: {

      'form.is_box'() {
         if (!this.pageData.isEdit) {
            this.form.container_size = null;
            this.form.cost_per_day = null;
            this.form.clearance_m_id = null;
            this.form.clearance_d_id = null;
         }
         if (this.form.is_box) {
            this.form.container_size = 0
         }
      },

      'form.wearhouse_id'() {
         if (!this.pageData.isEdit) {
            switch (this.form.container_size) {
               case 20:
                  this.form.cost_per_day = this.form.wearhouse_id ? this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', this.form.wearhouse_id, 'filled_20') : null;
                  break;
               case 40:
                  this.form.cost_per_day = this.form.wearhouse_id ? this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', this.form.wearhouse_id, 'filled_40') : null;
                  break;

               default:
                  this.form.cost_per_day = null
                  break;
            }

         }
      },
      'form.container_size'() {
         if (!this.pageData.isEdit) {
            switch (this.form.container_size) {
               case 20:
                  this.form.cost_per_day = this.form.wearhouse_id ? this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', this.form.wearhouse_id, 'filled_20') : null;
                  break;
               case 40:
                  this.form.cost_per_day = this.form.wearhouse_id ? this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', this.form.wearhouse_id, 'filled_40') : null;
                  break;

               default:
                  this.form.cost_per_day = null
                  break;
            }

         }
      },
   },
   computed: {

   },
   mounted() {
      this.pageMainData();
      this.form.in_time = this.$global.GetCurrentTime();
      this.form.in_date = this.$global.GetCurrentDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },

      getData() {
         this.transaction_types = [{ name: this.$i18n.t('Amwaj Mad customer'), id: 1 }, { name: this.$i18n.t('an external transport customer'), id: 2 }]
         this.types = [{ name: this.$i18n.t('containers'), id: 0 }, { name: this.$i18n.t('packages'), id: 1 }]
         this.sizes = [{ name: 40, id: 40 }, { name: 20, id: 20 }]
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`wearhouse_transaction/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.item;
                     this.customers = response.data.customers || [];
                     this.warehouses = response.data.wearhouses || [];
                     this.cars = response.data.cars || [];
                     this.drivers = response.data.drivers || [];
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`wearhouse_transaction/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.customers = response.data.customers || [];
                     this.warehouses = response.data.wearhouses || [];
                     this.cars = response.data.cars || [];
                     this.drivers = response.data.drivers || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`wearhouse_transaction/${this.$route.params.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`wearhouse_transaction`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      getWarehousePrices() {
         this.pageData.isLoading = true
         this.$api.GET_METHOD(`wearhouse_prices/${this.form.wearhouse_id}`).then((response) => {
            if (response.check)
               this.warehouse_prices = response.data;
         })
      },
      saveCustomer(customer) {
         this.customers.push(customer);
         this.form.customer_id = customer.id;
         this.addCustomerDialog = false
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`wearhouse_transaction/${this.form.wearhouse_transaction_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
