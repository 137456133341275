<template>
   <v-card relative :key="modalKey" class="shadow-none">

      <v-form ref="detail" v-model="valid" class="pa-2">
         <v-card class="shadow-none">
            <v-row justify="space-between">

               <v-col cols="12" class="">
                  <v-btn class="d-block mi-start-auto" depressed color="success" :min-width="120" :loading="isLoading"
                     @click="save" :disabled="!valid" :height="37">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>

               <v-col cols="12" md="6">
                  <v-row justify="center">
                     <v-col cols="12" class="">
                        <div class="body-2 gray7--text">
                           {{ $t('small containers 20') | capitalize }} :
                        </div>
                     </v-col>
                     <v-col cols="5" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('clearance of the first container') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.cont_s_first" @input="port.cont_s_first = $event"
                        label="clearance of the first container" :required="false" :isLoading="false" :cols="[6, 6, 5]">
                     </GenericInput>

                     <v-col cols="5" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('clearance of the second container') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.cont_s_sec" @input="port.cont_s_sec = $event"
                        label="clearance of the second container" :required="false" :isLoading="false" :cols="[6, 6, 5]">
                     </GenericInput>

                     <v-col cols="5" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('clearance of third containers and above') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.cont_s_other" @input="port.cont_s_other = $event"
                        label="clearance of third containers and above" :required="false" :isLoading="false"
                        :cols="[6, 6, 5]">
                     </GenericInput>


                     <v-col cols="12" class="px-10">
                        <v-divider></v-divider>
                     </v-col>


                     <v-col cols="12" class="">
                        <div class="body-2 gray7--text">
                           {{ $t('large containers 40') | capitalize }} :
                        </div>
                     </v-col>

                     <v-col cols="5" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('clearance of the first container') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.cont_b_first" @input="port.cont_b_first = $event"
                        label="clearance of the first container" :required="false" :isLoading="false" :cols="[6, 6, 5]">
                     </GenericInput>

                     <v-col cols="5" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('clearance of the second container') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.cont_b_sec" @input="port.cont_b_sec = $event"
                        label="clearance of the second container" :required="false" :isLoading="false" :cols="[6, 6, 5]">
                     </GenericInput>

                     <v-col cols="5" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('clearance of third containers and above') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.cont_b_other" @input="port.cont_b_other = $event"
                        label="clearance of third containers and above" :required="false" :isLoading="false"
                        :cols="[6, 6, 5]">
                     </GenericInput>


                  </v-row>
               </v-col>
               <v-col cols="auto">
                  <v-divider vertical></v-divider>
               </v-col>

               <v-col cols="12" md="5">
                  <v-row justify="center">
                     <v-col cols="12" class="">
                        <div class="body-2 gray7--text">
                           {{ $t('translation fees') | capitalize }} :
                        </div>
                     </v-col>
                     <v-col cols="6" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('translation fees amount') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.translate_price" @input="port.translate_price = $event"
                        label="translation fees" :required="false" :isLoading="false" :cols="[6, 6, 6]">
                     </GenericInput>
                     <v-col cols="6" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('translation fees for the entire policy') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="switch" :value="port.translate_type" @input="port.translate_type = $event"
                        label="" :required="false" :isLoading="false" :cols="[6, 6, 6]">
                     </GenericInput>
                     <v-col cols="12" class="px-10">
                        <v-divider></v-divider>
                     </v-col>
                     <v-col cols="12" class="">
                        <div class="body-2 gray7--text">
                           {{ $t('loading and unloading fees') | capitalize }} :
                        </div>
                     </v-col>
                     <v-col cols="6" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('loading and unloading fees amount') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.loading_price" @input="port.loading_price = $event"
                        label="loading and unloading fees" :required="false" :isLoading="false" :cols="[6, 6, 6]">
                     </GenericInput>
                     <v-col cols="6" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('fees for loading and unloading the entire policy') | capitalize
                           }} :
                        </div>
                     </v-col>
                     <GenericInput type="switch" :value="port.loading_type" @input="port.loading_type = $event" label=""
                        :required="false" :isLoading="false" :cols="[6, 6, 6]">
                     </GenericInput>
                     <v-col cols="12" class="px-10">
                        <v-divider></v-divider>
                     </v-col>
                     <v-col cols="12" class="">
                        <div class="body-2 gray7--text">
                           {{ $t('various expenses') | capitalize }} :
                        </div>
                     </v-col>
                     <v-col cols="6" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('various expenses amount') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="float" :value="port.other_price" @input="port.other_price = $event"
                        label="various expenses" :required="false" :isLoading="false" :cols="[6, 6, 6]">
                     </GenericInput>
                     <v-col cols="6" class="">
                        <div class="subtitle-2 gray1--text">
                           {{ $t('miscellaneous charges for the entire policy') | capitalize }} :
                        </div>
                     </v-col>
                     <GenericInput type="switch" :value="port.other_type" @input="port.other_type = $event" label=""
                        :required="false" :isLoading="false" :cols="[6, 6, 6]">
                     </GenericInput>

                  </v-row>
               </v-col>
               <v-col cols="12">
                  <v-col cols="12" class="px-10">
                     <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="">
                     <div class="body-2 gray7--text">
                        {{ $t('transport fees') | capitalize }} :
                     </div>
                  </v-col>

                  <v-col cols="12" class="">
                     <v-btn outlined class="d-block mi-start-auto" depressed color="blue2" :min-width="120"
                        @click="detailDialog = true" :height="37">
                        {{ $t('add line') }}
                     </v-btn>
                  </v-col>

                  <v-col cols="12" class="pa-2">
                     <DynamicTable :isLoading="isLoading" :data="tableRows || []" cardClasses="light" :header="tableHeader"
                        :tableHeight="$store.state.Settings.windowSize.y - 230">
                        <template v-slot:td="{ row, header, index }">
                           <div v-if="header.key == 'actions'">
                              <v-btn @click="editDetail(index, row)" x-small icon fab>
                                 <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                              </v-btn>
                              <!-- <v-btn @click="removeItem(index, row)" x-small icon fab>
                              <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                           </v-btn> -->
                           </div>
                        </template>
                     </DynamicTable>
                  </v-col>
               </v-col>
            </v-row>
         </v-card>
      </v-form>


      <PortPricingDetail :detailAction="detailAction" :detailIndex="detailIndex" :port="port" :areas="areas" :dialog="detailDialog"
         :tableRows="tableRows" :detail="detail" :updateDetail="updateDetail" :isDetailEdit="isDetailEdit" />

   </v-card>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import PortPricingDetail from "./PortPricingDetail.vue";
export default {
   name: "CustomerPortPricing",
   props: {
      backAction: { type: Function },
      port: { default: Object },
      trans_prices: { default: [] },
      prices: { default: [] },
      areas: { default: [] },
      index: { default: null },
   },
   computed: {
      tableRows() {
         return this.port.port_id && this.trans_prices.length > 0 ? this.$global.FilterArrayOfObject(this.trans_prices, 'port_id', this.port.port_id) : [];
      }
   },
   components: {
      GenericInput,
      DynamicTable,
      PortPricingDetail
   },
   watch: {
   },
   data: () => ({
      isLoading: false,
      valid: false,
      modalKey: 0,
      verticalTabs: 0,
      form: {
         customer_id: 0,
         port_id: 0,
         cont_s_first: 0,
         cont_s_sec: 0,
         cont_s_other: 0,
         cont_b_first: 0,
         cont_b_sec: 0,
         cont_b_other: 0,
         loading_type: 0,
         loading_price: 0,
         translate_type: 0,
         translate_price: 0,
         other_type: 0,
         other_price: 0
      },
      tableHeader: [],
      tableOption: {},
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      detail: {
         from_area_id: null,
         to_area_id: null,
         price: 0,
         container_40: 0,
         container_20: 0,
      },
   }),
   methods: {

      save() {
         this.isLoading = true;
         this.port.customer_id = +this.$route.params.id;
         this.port.prices = this.tableRows || [];
         this.$api.POST_METHOD(`toggle_customer_prices`, this.port).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.backAction();
               this.$router.push(`/sales/customer`)
            }
         })

      },
      detailAction(detail, isEdit, cancel) {
         console.log(detail, isEdit, cancel);
         //  cancel detail
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.detail = {
               from_area_id: null,
               to_area_id: null,
               price: 0,
               container_40: 0,
               container_20: 0,
            }
         }
         //  Edit detail
         if (isEdit && !cancel) {
            // this.backAction();
            this.trans_prices[this.detailIndex] = {...detail}
            this.trans_prices = [...this.trans_prices]
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.detail = {
               from_area_id: null,
               to_area_id: null,
               price: 0,
               container_40: 0,
               container_20: 0,
            }
         }
         //  Add detail
         if (!isEdit && !cancel) {
            console.log('ssssssssssss');
            // this.backAction();
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.trans_prices.push({...detail});
            this.detail = {
               from_area_id: null,
               to_area_id: null,
               price: 0,
               container_40: 0,
               container_20: 0,
            }
         }

      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.detail = {
            from_area_id: null,
            to_area_id: null,
            price: 0,
            container_40: 0,
            container_20: 0,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
   },
   mounted() {
      this.tableHeader = [
         { text: "from city", key: "from_area_name", type: 'text', classes: "" },
         { text: "to city", key: "to_area_name", type: 'text', classes: "" },
         { text: "package price", key: "price", type: 'float', classes: "" },
         { text: "container size 20", key: "container_20", type: 'float', classes: "" },
         { text: "container size 40", key: "container_40", type: 'float', classes: "" },
         { text: "actions", key: "actions", type: 'slot', classes: "" },
      ];
   },
};
</script>
