<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="12" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object" :printTitle="' '">
               <template v-slot:pdf_content>

                  <table width="100%" id="trip_date_table">
                     <tr>
                        <td>تاريخ: <span>{{ printStaticData.trip_date | dateAr }}</span> </td>
                        <td>Date : <span>{{ printStaticData.trip_date | dateEn }}</span> </td>
                     </tr>
                  </table>
                  <div id="trip_title">
                     سند استلام بضاعه
                  </div>
                  <table width="100%" id="trip_date_table">
                     <tr>
                        <td>{{ $t('clearance number') }} : <span>{{ printStaticData.clearance_m_id }}</span> </td>
                        <td dir="rtl">{{ $t('declaration number') }} :
                           <span>
                              {{ printStaticData.customs_declaration_number || printStaticData.declaration_number || ' - '
                              }}
                           </span>
                        </td>
                     </tr>
                  </table>
                  <table width="100%" id="trip_master_table">
                     <tr>
                        <td width="60%">
                           <div class="trip_td">
                              <div>اسم العميل :</div>
                              <div>{{ printStaticData.customer_name }}</div>
                              <div dir="ltr">Customer name :</div>
                           </div>

                           <div class="trip_td">
                              <div> رقم البوليصه :</div>
                              <div>{{ printStaticData.shipping_policy_no }}</div>
                              <div dir="ltr">Bil of landing no:</div>
                           </div>

                           <div class="trip_td">
                              <div> رقم الحاويه :</div>
                              <div>{{ printStaticData.container_number }}</div>
                              <div dir="ltr">Container No: </div>
                           </div>

                           <div class="trip_td">
                              <div> نوع الحاويه :</div>
                              <div>{{ printStaticData.container_size }}</div>
                              <div dir="ltr">Container Kind: </div>
                           </div>

                           <div class="trip_td">
                              <div> اسم السائق :</div>
                              <div>{{ printStaticData.driver_name }}</div>
                              <div dir="ltr">Driver Name: </div>
                           </div>

                           <div class="trip_td">
                              <div> توقيع السائق  :</div>
                              <div>{{ printStaticData.finger_print }}</div>
                              <div dir="ltr">Driver Signature : </div>
                           </div>

                        </td>
                        <td width="40%" style="text-center">
                           <div>توقيع المستلم</div>
                           <div>the recipient's signature</div>
                           <hr>
                           <div>استلمنا اصل البضاعه المكونه من :</div>
                           <hr>
                           <div class="td_padding">
                           </div>
                           <hr>
                           <div class="td_padding">
                           </div>
                           <hr>
                           <div>و عليه توقيع باستلام...وتحت مسؤليتنا</div>
                           <hr>
                           <div class="trip_td">
                              <div> الاسم :</div>
                              <div></div>
                              <div dir="ltr">Name : </div>
                           </div>
                           <div class="trip_td">
                              <div> التوقيع :</div>
                              <div></div>
                              <div dir="ltr">Sign : </div>
                           </div>
                           <div class="trip_td">
                              <div> التاريخ :</div>
                              <div></div>
                              <div dir="ltr">Date : </div>
                           </div>
                           <div class="trip_td">
                              <div> الوقت :</div>
                              <div></div>
                              <div dir="ltr">Time : </div>
                           </div>
                        </td>

                     </tr>
                  </table>
                  <p id="trip_paragraph" dir="ltr">
                     <span>Driver's Endorsement:</span>
                  <ol>
                     <li>1- the one, who undersigned, acknowledge that, Ireceived the parcels described above and admit to
                        deliver them to the consignee as they're describe above in this form. I'll be responsible for those
                        parcels in case there is any loss are damage to all or any of them.</li>
                     <li>
                       2- If you have any remarks / Complaint, Please Contact this Number: 0539393697
                     </li>
                  </ol>
                  </p>
                  <hr>
                  <p id="trip_paragraph" dir="rtl">
                     <span> ملاحظات العميل</span>
                  <ol>
                     <li>1- نظافه الحاويه</li>
                     <li>2- اي تلفيات بالسطحه او فقدانها عند المستودع تكون مسؤليه العميل و يسدد ما هو مطلوب منه.</li>
                  </ol>


                  </p>


               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>


   </div>
</template>

<script>
import PrintPDF from "@/components/PrintPDF.vue";
import VueHtml2pdf from 'vue-html2pdf'
export default {
   name: "TripPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
   },
   computed: {
      computedTotals() {
         if (this.printData.length) {
            const debit = this.printData.reduce((oldValue, newValue) => +oldValue + +newValue.debit, 0);
            const credit = this.printData.reduce((oldValue, newValue) => +oldValue + +newValue.credit, 0);
            return {
               debit: debit,
               credit: credit,
            }
         } else {
            return {
               debit: 0,
               credit: 0,
            }
         }
      }
   },
   components: {
      PrintPDF,
      VueHtml2pdf,
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
#trip_date_table {
   td {
      font-size: 14px !important;
      text-align: start !important;

      &:last-of-type {
         text-align: end !important;
      }
   }
}

#trip_master_table {
   border: 1px solid #000;
   margin: 10px 0 0 0;
   -webkit-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;

   tr {
      background: #fff !important;

      &:last-of-type {}
   }

   td {

      text-align: start !important;
      padding: 5px !important;
      font-weight: 800;
      background: #fff !important;

      span {
         color: blue !important;
      }

      &:first-of-type {
         .trip_td {
            margin: 20px 0;
            font-size: 12px !important;
         }
      }
   }

   td:last-of-type {
      text-align: center !important;
      border-right: 1px solid #000 !important;
   }

   hr {
      opacity: 0.3;
      margin: 10px 0;
   }

   .trip_td {
      display: flex;
      justify-content: space-between;

      div {
         margin: 5px 0;

         &:nth-of-type(1),
         &:nth-of-type(3) {
            width: 25%;
            text-align: start !important;
         }

         &:nth-of-type(2) {
            width: 50%;
            text-align: center !important;
         }
      }

   }

   .td_padding {
      height: 10px;
      position: relative;
   }
}


#trip_paragraph {
   font-size: 14px !important;
   text-align: start !important;
   margin: 10px 0;
   ol {
      padding: 10px;
      list-style-type: none!important;
      li {
         list-style: none !important;
         list-style-type: none!important;
      }
      span{
         font-size: 16px !important;
         font-weight: bold;
      }
   }
}

#trip_title {
   text-align: center;
   font-weight: bold;
}
</style>