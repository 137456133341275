<template>
  <v-card elevation="0" class="ma-0 pa-0 px-5 rounded-0  sticky_control_header">
    <v-row justify="space-between" align="center">
      <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
        <div class="subtitle-2 secondary--text  font-weight-medium">
          {{ pageData.main }} <v-icon size="5" color="gray6" class="mx-1">mdi-circle
          </v-icon> {{ pageData.category }} <v-icon size="5" color="gray6" class="mx-1">mdi-circle
          </v-icon>
        </div>
        <div class="text-h6 secondary--text mx-2 text-capitalize">
          <strong>{{ pageData.entityName }}</strong>
        </div>
        <slot name="rightSide"></slot>
      </v-col>
      <slot name="centerSide"></slot>
      <v-col cols="12" sm="auto" class="pa-0">
        <v-row justify="end" align="center">
          <v-col cols="12" sm="auto">
            <slot name="leftSide"></slot>

            <v-btn depressed @click="viewForm" v-if="editForm" :loading="pageData.isLoading"
              class="my-2 mx-2 " color="blue4" outlined :height="37">{{ $t("edit") }}
            </v-btn>

            <v-btn depressed @click="deleteDialog = true"
              v-if="pageData.isEdit == true && $global.CheckAction(pageData.screen_code, 4) && form.active != 0 && showDelete"
              :loading="pageData.isLoading" class="my-2 mx-2 " color="error" :height="37">{{ $t("delete") }}
            </v-btn>

            <v-btn depressed @click="restoreMethod"
              v-if="pageData.isEdit == true && $global.CheckAction(pageData.screen_code, 4) && form.active == 0 && showDelete"
              :loading="pageData.isLoading" class="my-2 mx-2  accent--text" color="white" :height="37">
              {{ $t("restore") }}</v-btn>
            <slot name="left"></slot>
            <v-btn depressed @click="actionBtnClick" v-if="actionBtn == true" :loading="pageData.isLoading"
              :disabled="!actionBtnValid" class="my-2 mx-2 white--text " :color="actionBtnColor" :height="37">
              <span>{{ $t(actionBtnText) }}</span>
            </v-btn>
            <v-btn depressed @click="cancelControl" class="my-2 mx-2  error--text" :height="37" color="backgroundW">
              {{ $t('cancel') }} <v-icon right>
                mdi-close</v-icon>
            </v-btn>

            <v-btn depressed @click="save" :min-width="90" v-if="showCreate" :loading="pageData.isLoading" :disabled="!valid"
              class="my-2 mx-2  white--text" :height="37" color="success">{{ pageData.isEdit ?
                  $t('save') : $t(createBtnText)
              }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog" :item="form.name ? form.name : null"
      :backValueMethod="returnedDelete"></DeleteConfirmation>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
import DeleteConfirmation from "../modals/DeleteConfirmation.vue";
export default {
  name: "ControlHeader",
  data: () => ({
    deleteDialog: false,
  }),
  computed: {
    ...mapState(["favourites"]),
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  props: {
    pageData: { default: {} },
    valid: { default: false },
    form: { default: {} },
    save: { type: Function },
    deleteMethod: { type: Function },
    showDelete: { default: true },
    restoreMethod: { type: Function },
    showCreate: { default: true },
    createBtnText: { default: "create" },
    actionBtn: { default: false },
    actionBtnValid: { default: false },
    actionBtnColor: { default: 'accent' },
    actionBtnClick: { type: Function },
    actionBtnText: { default: "action btn" },
    editForm: { default: false },
    viewForm: { type: Function },
  },
  methods: {
    ...mapActions(["addFavourites"]),

    addToFav() {
      const fav = {
        screen_code: this.pageData.screen_code,
        name: this.pageData.entityName,
        url: this.$route.params.id ? this.pageData.controlRoute + this.$route.params.id : this.pageData.controlRoute,
      };
      // const fav = {
      //   screen_code: this.pageData.screen_code,
      //   name: this.pageData.entityName,
      //   url: this.pageData.controlRoute,
      // };
      this.addFavourites(fav).finally(() => { });
    },
    returnedDelete(status) {
      this.deleteDialog = false;
      this.deleteMethod(status);
    },
    cancelControl() {
      if (this.pageData.isEdit) {
        this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
      } else {
        this.$router.push(this.$store.state.activeScreen.url)
      }
    },
  },
  components: {
    DeleteConfirmation
  }
};
</script>

<style scoped lang="scss">
</style>
