<template>
   <section>
      <v-container fluid class="classifications_control">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" createBtnText="save"
            :showCreate="viewForm" :deleteMethod="deleteMethod" :actionBtnValid="true"
            :actionBtnText="form.is_custom ? 'edit' : 'classification'" :actionBtnColor="form.is_custom ? 'info' : 'black'"
            :actionBtn="pageData.isEdit && !viewForm" :actionBtnClick="() => viewForm = true">
         </ControlHeader>
         <!-- End Of Header -->

         <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
            <AnimatedLoading :height="100" :hideText="false" :isLoading="pageData.isLoading" />
         </v-col>

         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 mt-3 d-flex flex-wrap" v-if="!pageData.isLoading">
               <!-- date -->
               <GenericInput type="date" v-if="!pageData.isEdit || viewForm" :value="form.action_date"
                  :disabled="pageData.isEdit" @input="form.action_date = $event" label="date" :required="true"
                  :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
               </GenericInput>

               <!-- date in summary -->
               <v-col cols="6" md="4" v-if="(!viewForm && pageData.isEdit) && !pageData.isLoading">
                  <div class="subtitle-1 font-weight-bold">
                     {{ $t('date') }} :
                     <span class="gray6--text" v-if="form.action_date">{{ form.action_date }}</span>
                  </div>
               </v-col>

               <!-- transaction no -->
               <GenericInput type="autocomplete" v-if="!pageData.isEdit || viewForm" :lookups="clearances"
                  :value="clearancesValue" :keyupValue="searchClearance" :disabled="pageData.isEdit" :required="true"
                  @input="clearancesValue = $event" noDataText="Enter the transaction number" label="transaction no"
                  selected_label="clearance_label" selected_prop="id" :isLoading="clearancesIsLoading" :cols="[6, 4, 4]">
               </GenericInput>

               <!-- transaction no in summary -->
               <v-col cols="6" md="4" v-if="(!viewForm && pageData.isEdit) && !pageData.isLoading">
                  <div class="subtitle-1 font-weight-bold">
                     {{ $t('transaction no') }} :
                     <span class="gray6--text" v-if="form.clearances_m_id">{{ form.clearances_m_id }}</span>
                  </div>
               </v-col>

               <!-- customer name -->
               <v-col cols="6" md="4">
                  <div class="subtitle-2 font-weight-bold">
                     {{ $t('customer name') }} :
                     <span class="gray6--text">{{ form.customer_name | capitalize }}</span>
                  </div>
               </v-col>
            </v-card>

            <v-card class="backgroundW shadow pa-5 mt-3" v-if="!pageData.isLoading && form.clearances_m_id">
               <table id="summary_table">
                  <tbody>
                     <tr>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('policy number') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.shipping_policy_no">
                              {{ form.shipping_policy_no }}</div>
                        </td>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('currency') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.currency_name">
                              {{ form.currency_name }}</div>
                        </td>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('shipping currency') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.shipping_currency_name">
                              {{ form.shipping_currency_name }}</div>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('invoice type') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.invoice_type">{{ form.invoice_type }}</div>
                        </td>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('invoice value') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.invoice_amount">
                              {{ form.invoice_amount | float }}</div>
                        </td>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('containers QTY') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.container_count">
                              {{ form.container_count }}</div>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('the weight') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.weight">
                              {{ form.weight }}</div>
                        </td>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('country of origin') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.country_name">
                              {{ form.country_name }}</div>
                        </td>
                        <td>
                           <div class="subtitle-1">
                              {{ $t('package count') }} :
                           </div>
                        </td>
                        <td>
                           <div class="gray6--text" v-if="form.lcl_count">{{ form.lcl_count }}</div>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </v-card>

            <v-card class="backgroundW shadow pa-5 mt-3 d-flex flex-wrap"
               v-if="!pageData.isLoading && pageData.rows.length">
               <v-row>
                  <v-tabs v-model="tab">
                     <v-tab>{{ $t('details of the goods') }}</v-tab>
                     <v-tab :disabled="!files.length">{{ $t('attachments') }}</v-tab>
                  </v-tabs>

                  <v-col cols="12" id="classification_table" v-show="tab === 0">
                     <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="computedTableHeader()"
                        :tableHeight="$store.state.Settings.windowSize.y - 230">
                        <template v-slot:td="{ row, header, index }">
                           <div>

                              <div v-if="header.key == 'index'" style="width: 100%;">
                                 {{ index + 1 }}
                              </div>
                              <div v-if="header.key == 'customs_item_number'" style="width: 100%;">
                                 <TextInput type="text" :value="row.customs_item_number" :validation_type="requiredRule"
                                    @input="row.customs_item_number = $event" :required="true" :isLoading="false"
                                    :cols="[12, 12, 12]">
                                 </TextInput>
                              </div>
                              <div v-if="header.key == 'slug'" style="width: 100%;">
                                 <TextInput v-if="!pageData.isEdit" type="text" :value="row.slug"
                                    @input="row.slug = $event" :required="false" :isLoading="false" :cols="[12, 12, 12]">
                                 </TextInput>

                                 <span v-else>{{ row.slug || "-" }}</span>
                              </div>

                              <div v-if="header.key == 'item_name'" style="width: 100%;">
                                 <v-col cols="12" md="12" lg="12" class="py-1">
                                    <v-combobox :items="itemNameArr" :loading="isLoadingItemName" v-model="row.item_name"
                                       color="primary" :rules="$store.state.Settings.requiredRule"
                                       @keydown="computedItemName(row, $event)" @input="getItemNameObject(row)" outlined
                                       dense item-text="item_name" item-value="item_name">
                                    </v-combobox>
                                 </v-col>

                                 <!-- <GenericInput type="autocomplete" :lookups="itemNameArr" :value="row.item_name"
                                    :required="true" :keyupValue="computedItemName"
                                    @input="row.item_name = $event" selected_label="item_name" selected_prop="item_name"
                                    :cols="[12, 12, 12]">
                                 </GenericInput> -->

                              </div>
                              <div v-if="header.key == 'item_type'" style="width: 100%;">
                                 <FloatInput type="float" :value="Math.abs(row.item_type)" :validation_type="requiredRule"
                                    @input="row.item_type = $event" :required="false" :isLoading="false"
                                    :cols="[12, 12, 12]">
                                 </FloatInput>
                              </div>

                              <div v-if="header.key == 'unit_id'" style="width: 100%;">
                                 <GenericInput type="select" v-if="viewForm" :lookups="units" :value="row.unit_id"
                                    paddingX="px-0" @input="row.unit_id = $event" :multi="false" :required="true"
                                    selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                                 </GenericInput>

                                 <!-- units in summary  -->
                                 <v-col cols="12" v-if="!viewForm && row.unit_id">
                                    <span>{{ units.find(unit => unit.id == row.unit_id).name }}</span>
                                 </v-col>
                              </div>

                              <div v-if="header.key == 'qry'" style="width: 100%;">
                                 <TextInput type="number" :value="row.qry" :validation_type="requiredRule"
                                    @input="row.qry = $event" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                                 </TextInput>
                              </div>

                              <div v-if="header.key == 'weight'" style="width: 100%;">
                                 <FloatInput v-if="!form?.weight_factor" :value="calcRowWeight(row)"
                                    @input="row.weight = $event" :required="false" :isLoading="false" :cols="[12, 12, 12]">
                                 </FloatInput>

                                 <span v-if="form?.weight_factor"> {{ calcRowWeight(row) | float }}</span>
                              </div>

                              <div v-if="header.key == 'unit_price'" style="width: 100%;">
                                 {{ row.qry && (row.amount / row.qry) || 0 | float }}
                              </div>

                              <div v-if="header.key == 'amount'" style="width: 100%;">
                                 <FloatInput :value="row.amount" @input="row.amount = $event" :required="true"
                                    :validation_type="requiredRule" :isLoading="false" :cols="[12, 12, 12]">
                                 </FloatInput>
                              </div>

                              <div v-if="header.key == 'containers'" style="width: 100%;"
                                 @keydown="addNewRowByTab($event, index)">
                                 <GenericInput type="select" v-if="viewForm" :lookups="containers" :value="row.containers"
                                    paddingX="px-0" @input="row.containers = $event" :multi="true" :required="false"
                                    selected_label="container_number" selected_prop="clearances_d_id" :isLoading="false"
                                    :cols="[12, 12, 12]">
                                 </GenericInput>

                                 <!-- containers in summary  -->
                                 <v-col cols="12" v-if="!viewForm">
                                    {{ row.containers.map(row_container => row_container).join('-') || '-' }}
                                 </v-col>
                              </div>

                              <div v-if="header.key == 'customs_fees'" style="width: 100%;">
                                 {{ calcCustomsFees(row) | float }}
                              </div>

                              <div v-if="header.key == 'delete'">
                                 <span>
                                    <v-tooltip bottom color="error">
                                       <template v-slot:activator="{ on, attrs }">
                                          <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                                             @click="deleteDetails(index)" src="@/assets/img/svg_icons/delete_icon.svg" />
                                       </template>
                                       <span>{{ $t('delete') }}</span>
                                    </v-tooltip>
                                 </span>
                              </div>

                           </div>
                        </template>
                        <template v-slot:afterTable>
                           <v-row justify="space-between" align="center"
                              class="font-weight-bold text-center text-body-2 mt-1 px-3">
                              <v-col cols="12" class="pt-0 px-0"> <v-divider></v-divider> </v-col>
                              <v-col cols="3" class="background_style footer_totals">{{ $t('Totals') }}</v-col>
                              <v-col cols="3" class="pa-0">
                                 <div class="background_style">{{ $t('quantity') }}</div>
                                 <div>{{ calcTotals('qry') | float }}</div>
                              </v-col>
                              <v-col cols="3" class="pa-0">
                                 <div class="background_style">{{ $t('the weight') }}</div>
                                 <div>{{ calcTotals('weight') | float }}</div>
                              </v-col>
                              <v-col cols="3" class="pa-0">
                                 <div class="background_style">{{ $t('value') }}</div>
                                 <div>{{ calcTotals('amount') | float }}</div>
                              </v-col>
                           </v-row>
                        </template>
                     </DynamicTable>
                  </v-col>

                  <v-col cols="12" v-show="tab === 1">
                     <v-card class="backgroundW  pa-5 text-end" v-if="!pageData.isLoading && files.length" elevation="0">
                        <v-btn color="primary" outlined class="blue12" :disabled="!files.length"
                           @click="downloadAllAttachment" depressed :loading="attachDownloadLoading">
                           {{ $t('download all attachment') }}
                           <v-icon size="20" right>mdi-download</v-icon>
                        </v-btn>

                        <GalleryFiles :attachments="files" width="250" height="250" :showDelete="false"
                           downloadEndPoint="clearance_download_file" />
                     </v-card>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>
      </v-container>

      <WarningDialog :openDialog="warningDialog" :closeDialog="() => warningDialog = false"
         :selectedData="+calcTotals('amount') - +form.invoice_amount" :confirmAction="confirmAction" />
   </section>
</template>

<script>

import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import GenericInput from "@/components/ui/GenericInput.vue";
import GalleryFiles from "@/components/GalleryFiles.vue";
import WarningDialog from "./WarningDialog.vue";
export default {
   name: "ClassificationsControl",
   data: () => ({
      pageData: {
         screen_code: "04-007",
         url: null,
         controlRoute: "/sales/classification/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         rows: [],
      },
      formCollapse: false,
      getContainersLoading: false,
      viewForm: false,
      valid: false,
      tab: 0,
      detailTableHeader: [],
      detailDialog: false,
      warningDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      delegates: [],
      customers: [],
      ports: [],
      customs_items: [],
      policy_type: [],
      clearances: [],
      containers: [],
      invoice_types: [],
      units: [],
      countries: [],
      currencies: [],
      itemNameArr: [],
      files: [],
      clearancesValue: null,
      is_moreThanInvoice: null,
      isLoading: false,
      clearancesIsLoading: false,
      isLoadingItemName: false,
      attachDownloadLoading: false,
      confirmStatus: false,
      rules: {
         required: value => !!value || this.$i18n.t('required'),
      },
      form: {
         action_date: null,
         invoice_type: 0,
         shipping_amount: null,
         invoice_amount: null,
         supplier_invoice_code: null,
         customer_name: null,
         port_name: null,
         customer_id: null,
         clearances_m_id: null,
         shipping_currency_id: null,
         currency_id: null,
         customs_declaration_number: null,
         notes: null,
         lcl_count: null,
         items: [],
      },
   }),
   components: {
      ControlHeader,
      DynamicTable,
      AnimatedLoading,
      GenericInput,
      GalleryFiles,
      WarningDialog
   },
   computed: {
      calcTotals() {
         return (KEY) => this.pageData.rows.length && this.pageData.rows.reduce((prev, current) => prev + +current[KEY], 0) || 0
      },
      requiredRule() {
         return [(v) => !!v || '']
      },
      pushNewDetailsRow() {
         return (index) => {
            let defaultRow = {
               classifications_d_id: 0,
               customs_item_number: 0,
               item_name: '',
               item_type: '',
               slug: null,
               qry: 0,
               unit_id: null,
               containers: [],
               net_weight: 0,
               total_weight: 0,
               amount: 0,
               custom_fees: 0,
               weight: 0,
            }
            if (typeof index === 'number') {
               this.pageData.rows.splice(index + 1, 0, defaultRow)
            } else {
               this.pageData.rows.push(defaultRow)
            }
         }
      },
      computedTableHeader() {
         return () => {
            let tableHeader = []
            console.log('this.viewForm ', this.viewForm);
            tableHeader = [
               { text: "#", key: "index", type: 'slot', classes: "" },
               { text: "slug", key: "slug", type: 'slot', classes: "" },
               { text: "Description of the goods", key: "item_name", type: this.viewForm ? 'slot' : 'text', classes: "" },
               { text: "quantity", key: "qry", type: this.viewForm ? 'slot' : 'text', classes: "" },
               { text: "value", key: "amount", type: this.viewForm ? 'slot' : 'float', classes: "" },
               { text: "unit price", key: "unit_price", type: 'slot', classes: "" },
               { text: "the weight", key: "weight", type: this.viewForm ? 'slot' : 'float', classes: "" },
               { text: "Container", key: "containers", type: 'slot', classes: "" },
               { text: "delete", key: "delete", type: 'slot', classes: "" },
            ];

            if (this.$route.params.id) {
               tableHeader.splice(2, 0, { text: "Item category", key: "item_type", type: this.viewForm ? 'slot' : 'text', classes: "" })
               tableHeader.splice(3, 0, { text: "Item No", key: "customs_item_number", type: this.viewForm ? 'slot' : 'text', classes: "" })
               tableHeader.splice(6, 0, { text: "unit", key: "unit_id", type: this.viewForm ? 'slot' : 'float', classes: "" })
               tableHeader.splice(9, 0, { text: "Customs duties (estimated)", key: "customs_fees", type: 'slot', classes: "" })
            }

            if (this.pageData.rows.length == 1) {
               tableHeader.pop()
            }

            return tableHeader
         }
      },
      calcRowWeight() {
         return (row) => {
            if (row.amount && this.form.weight_factor) {
               return (+row.amount * +this.form.weight_factor).toFixed(2)
            } else return (row.weight || 0)
         }
      },
      calcCustomsFees() {
         return (row) => {
            if (this.form?.equivalent_value) {
               row.custom_fees = +((this.form.equivalent_value * row.amount * row.item_type) / 100).toFixed(2)
               return row.custom_fees
            } else return 0
         }
      },
      computedItemName() {
         let timeOutSearchItemNames = null;

         return (row, event) => {
            let value = event.target.value.trim()

            clearTimeout(timeOutSearchItemNames)
            if (value && this.pageData.isEdit && event.key !== "Tab") {
               timeOutSearchItemNames = setTimeout(() => {
                  this.isLoadingItemName = true
                  this.$api.GET_METHOD(`customs_item?word=${value}`)
                     .then((response) => {
                        this.itemNameArr.push(...response.data.customs_items)
                     })
                     .finally(() => (this.isLoadingItemName = false))
               }, 650)
            }
         }
      },
      getItemNameObject() {
         return (row) => {
            console.log({ row });
            if (typeof row.item_name == "object") {
               row.customs_item_number = row.item_name.name || ''
               row.item_type = row.item_name.item_type || 0
               row.item_name = row.item_name.item_name.trim()
            } else row.item_name = row.item_name.trim()

         }
      },
      searchClearance() {
         let asyncSearch = null;

         return (val) => {
            clearTimeout(asyncSearch)

            if (val && !this.clearancesValue) {
               asyncSearch = setTimeout(() => {
                  this.clearancesIsLoading = true
                  this.$api.GET_METHOD(`get_aval_clearances_short?is_classification=${0}&word=${val}`)
                     .then((response) => {
                        response.data.forEach(clearance => {
                           clearance.clearance_label = `${clearance.clearance_id} - ${clearance.customer_name}`
                        });
                        this.clearances.push(...response.data)
                     })
                     .finally(() => (this.clearancesIsLoading = false))
               }, 600)
            }
         }
      },
      confirmAction() {
         return (status) => {
            if (status) {
               this.confirmStatus = status;
               console.log({ status });
               this.save()
            }
         }

      }
   },
   watch: {
      'form.clearances_m_id'() {
         if (!this.pageData.isEdit) {
            this.containers = [];
            this.getContainers()
         }
      },
      clearancesValue(val, curr) {
         console.log({ val, curr });
         if (!this.pageData.isEdit && val) {
            this.form.clearances_m_id = val;
         } else {
            this.clearances.push(val);
         }
      },
      '$store.state.Settings.key_clicked'() {
         switch (this.$store.state.Settings.key_clicked) {
            case 'F3':
               !this.pageData.isEdit && this.pushNewDetailsRow();
               break;
            default:
               break;
         }
      },
      // is_moreThanInvoice(){

      // }
   },
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
         this.pushNewDetailsRow();
      },
      addNewRowByTab(event, index) {
         if (event.key == "Tab" && !this.pageData.isEdit) {
            this.pushNewDetailsRow(index);
         }
      },
      deleteDetails(index) {
         this.pageData.rows.splice(index, 1);
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`classification/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.containers = response.data.clearances_det || [];
                     this.units = response.data.units || [];
                     this.form = response.data.master;
                     this.clearancesValue = this.form.clearances_m_id
                     this.pageData.rows = response.data.details || [];
                     if (response.data.master?.is_lcl) {
                        this.pageData.rows.forEach(row => row.containers = [])
                        this.containers = []
                     }
                     this.pageData.rows.forEach(row => row.unit_id = 7)
                     this.files = response.data.files;
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = false;
               this.viewForm = true;
            } else {
               this.$router.push('/')
            }

         }
      },
      save() {
         // this.form.items = this.tableRows;
         this.form.items = this.pageData.rows;
         this.is_moreThanInvoice = +this.calcTotals('amount') !== +this.form.invoice_amount
         if (this.is_moreThanInvoice) {
            this.warningDialog = true;
         }

         if (this.$refs.form.validate() && (!this.is_moreThanInvoice || this.confirmStatus)) {
            this.confirmStatus = false;
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               // this.form.items.forEach(item => item.item_name = item.item_name.item_name)
               this.$api.POST_METHOD(`classification/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`classification`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            // delegate_petty
            this.$api.POST_METHOD(`classification/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
      getContainers() {
         this.isLoading = true;
         this.$api.GET_METHOD(`get_clearance_data/${this.form.clearances_m_id}`).then((response) => {
            this.isLoading = false;
            if (response.check) {
               console.log("get_clearance_data", response.data);
               if (response.data.details?.is_lcl) {
                  this.pageData.rows.forEach(row => row.containers = [])
                  this.containers = []
               } else {
                  this.containers = response.data.details;
               }
               this.containers = !response.data.details?.is_lcl ? response.data.details : [];
               this.form = { ...response.data.master };
               this.form.clearances_m_id = this.form.id;
               this.files = response.data.files;
               this.form = { ...this.form };
            }
         });
      },
      downloadAttachment(attach) {
         console.log(attach);
         this.attachDownloadLoading = true
         var src = `clearance_download_file/${attach.id}`;
         var download_file_name = `${attach.file_name}.${attach.file_type}`
         this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            this.attachDownloadLoading = false;
         })
      },
      downloadAllAttachment() {
         this.files.forEach(attachment => {
            this.downloadAttachment(attachment, 0)
         });
      },
   },
};

</script>

<style lang="scss" >
.v-application .classifications_control {
   .background_style {
      background-color: #e8e8ee;
   }

   .footer_totals {
      padding: 10px 0;
   }

   #classification_table {
      tr:nth-of-type(even) {
         background-color: unset !important;
      }
   }

   tr td span.d-flex.justify-center {
      display: block !important;
   }

   #summary_table {
      width: 100%;
      border-collapse: collapse !important;

      tr:nth-of-type(even) {
         background-color: unset !important;
      }

      tr td {
         border: 1px solid rgba(0, 0, 0, 0.13) !important;
      }

      td:nth-of-type(odd) {
         border-left-color: transparent !important;
      }

      td div {
         text-align: start;
         padding: 0 7px;
         font-weight: bold;
      }
   }
}
</style>