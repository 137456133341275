<template>
  <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="800">
    <v-card>
      <v-card relative class="pa-7 py-10 shadow-none overflow-x-auto"
        :height="$store.state.Settings.windowSize.y - 260" id="chatCard">
        <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="closeDialog">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-row justify="center" class="mt-2">
          <v-col cols="12" v-if="chat.length > 0">
            <v-timeline dense>
              <v-timeline-item v-for="(message, i) in chat" :key="i" color="primary" small>
                <v-card outlined class="shadow-none light  justify-space-between  mt-2">
                  <v-card-title class="subtitle-2  font-weight-bold">
                    <div>
                      {{ message[userKey] }}
                      <span class="mx-1 font-weight-medium gray8--text"><small>
                          {{ message[infoKey] }}
                        </small></span>
                    </div>
                    <v-card-text class="subtitle-2 gray6--text pa-0">{{ message[messageKey] }}
                    </v-card-text>
                  </v-card-title>
                  <div class="caption px-5 mt-n5 text-end">{{ message[messageDateKey] }}</div>
                </v-card>

              </v-timeline-item>
            </v-timeline>

          </v-col>
          <v-col v-if="chat.length == 0" cols="12">
            <v-alert type="primary" outlined class="w-100">
              {{ $t(noChatMessage) | capitalize }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card>
      <GenericInput type="textarea" :value="message" @input="message = $event" :disabled="isLoading" :label="textAreaLabel" :required="false"
        :isLoading="false" :cols="[12, 12, 12]">
      </GenericInput>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn class="mx-2" v-if="showCancel" text depressed color="red4" @click="closeDialog" :height="37" :width="120">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn class="mx-2 white--text" :disabled="!(message)" :loading="isLoading" @click="addMessage" depressed
          color="success" :height="37" :width="120">
          {{ $t(submitButtonText) }}
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
</template>


<script>
import GenericInput from "./GenericInput.vue";
export default {
  name: "ChatDialog",
  props: {
    saveMessage: { type: Function },
    closeDialog: { type: Function },
    dialog: { default: false },
    isLoading: { default: false },
    chat: { default: [] },
    messageKey : {default: 'note'},
    infoKey : {default: 'status_name'},
    userKey : {default: 'add_user'},
    messageDateKey : {default: 'add_date'},
    submitButtonText : {default: 'add comment'},
    textAreaLabel : {default: 'comment'},
    showCancel: { default: true },
    noChatMessage: { default: 'no comments found to this clearance' },
  },
  data: () => ({
    message: null
  }),
  components: {
    GenericInput
  },
  computed: {

  },
  watch: {
    dialog() {
      if (this.dialog) {
        setTimeout(() => {
          const element = document.getElementById('chatCard');
          element.scrollTop  = element.scrollHeight;
        }, 10);
      }
    },
    isLoading() {
      if (!this.isLoading) {
        this.message = null
      }
    },
    chat() {
      if (this.dialog) {
        setTimeout(() => {
          const element = document.getElementById('chatCard');
          element.scrollTop  = element.scrollHeight;
        }, 10);
      }
    }
  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    addMessage() {
      this.saveMessage(this.message)

    }
  },
};
</script>



<style scoped lang="scss">

</style>
