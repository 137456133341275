<template>
  <div :class="classes" v-if="isLoading">
    <img src="@/assets/img/png/files/loading.gif" :style="'height :' + height  + 'px'" class="ma-auto d-block" alt="Qawaem">
    <p v-if="!hideText" class="text-center my-3">{{ $t("loading data") }}</p>
  </div>
</template>


<script>
export default {
  name: "AnimatedLoading",
  props: {
    height: { default: 100 },
    isLoading: { default: false},
    hideText: { default: false},
    classes: { default: "d-block" },
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">

</style>
