<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800">
         <v-card relative :key="modalKey">
            <v-btn
               absolute
               class="mt-2"
               :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
               small
               fab
               color="grey lighten-1"
               text
               @click="closeDialog"
            >
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-card-title class="text-h6 font-weight-bold">
               <v-icon color="primary" left>mdi-plus-circle</v-icon>
               <h5>
                  <span>{{ $t('add cost center code') }}</span>
               </h5>
            </v-card-title>
            {{ edit_Cost_centers }}
            <v-col cols="12" class="py-4">
               <v-row justify="end" align="center">
                  <v-col cols="12" sm="12">
                     <v-card class="backgroundW shadow pa-5 pt-7">
                        <v-simple-table v-if="edit == false">
                           <template v-slot:default>
                              <thead>
                                 <tr>
                                    <th>{{ $t('#') }}</th>
                                    <th>{{ $t('account') }}</th>
                                    <th class="text-center">{{ $t('percentage') }}</th>
                                    <th class="text-center">{{ $t('Actions') }}</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr v-for="(row, index) in selected_Cost_centers" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                       <GenericInput
                                          type="autocomplete"
                                          :lookups="cost_centers"
                                          selected_label="name"
                                          :value="row.centers"
                                          @input="row.centers = $event"
                                          label=" "
                                          :required="false"
                                          :isLoading="false"
                                          :hideDetails="true"
                                          :multi="true"
                                          :cols="[12, 12, 12]"
                                       ></GenericInput>
                                    </td>
                                    <td>
                                       <v-text-field
                                          v-model="row.percentage"
                                          class="d-block ma-auto"
                                          outlined
                                          required
                                          color="primary"
                                          :rules="$store.state.Settings.requiredRule"
                                          dense
                                          style="width: 100px;"
                                          hide-details
                                          full-width
                                          rounded-md
                                          :append-icon="'mdi-percent-outline'"
                                          type="number"
                                       ></v-text-field>
                                    </td>
                                    <td>
                                       <div class="d-inline-flex align-center">
                                          <v-btn
                                             elevation="0"
                                             @click="removeLine(index)"
                                             fab
                                             class="mx-1"
                                             x-small
                                             color="transparent"
                                             :disabled="selected_Cost_centers.length == 1"
                                          >
                                             <v-icon color="error">mdi-minus-box</v-icon>
                                          </v-btn>

                                          <v-btn
                                             elevation="0"
                                             @click="addNew()"
                                             :disabled="row.centers.length == 0"
                                             fab
                                             class="mx-1"
                                             x-small
                                             color="transparent"
                                             v-if="selected_Cost_centers.length == (index + 1)"
                                          >
                                             <v-icon color="success">mdi-plus-box</v-icon>
                                          </v-btn>
                                       </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </template>
                        </v-simple-table>
                     </v-card>
                  </v-col>
                  <v-col cols="6">
                     <strong>{{ $t('total') }} : {{ total }}%</strong>
                     <br />
                     <span v-if="!(allIsSet == true && total == 100)">
                        <small>{{ $t('All fields must be filled with a total of 100%.') }}</small>
                     </span>
                  </v-col>
                  <v-col cols="3">
                     <!-- <v-btn
                           outlined
                           @click="closeDialog"
                           class="shadow"
                           color="error"
                           style="width: 100%; text-align: start"
                     >{{ $t('close') }}</v-btn>-->
                  </v-col>

                  <v-col cols="3">
                     <v-btn
                        color="success"
                        :disabled="!(allIsSet && total == 100)"
                        @click="save"
                        class="shadow"
                        :loading="isLoading == true"
                        style="width: 100%; text-align: start"
                     >{{ $t('save') }}</v-btn>
                  </v-col>
               </v-row>
            </v-col>
         </v-card>
      </v-dialog>
   </v-row>
</template>



<style scoped lang="scss">
</style>



<script>
import GenericInput from "../../../components/ui/GenericInput.vue";
export default {
   name: "CostCenterDialog",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      edit: { default: false },
      cost_centers: { default: [] },
      defaultValues: { default: [] },
   },
   computed: {
      edit_Cost_centers() {
         return this.defaultValues.cost_centers
      },
      total() {
         return this.selected_Cost_centers.reduce((oldValue, newValue) => {
            var total = Number(+oldValue) + Number(+(newValue.percentage ? newValue.percentage : 0));
            return total;
         }, 0)
      },

      allIsSet() {
         return this.selected_Cost_centers.every(element => {
            return element.percentage != 0 && element.percentage != null ? true : false
         });
      }
   },
   components: {
      GenericInput
   },
   data() {
      return {
         isLoading: false,
         valid: false,
         modalKey: 1,
         selected_Cost_centers: [
            {
               percentage: 0,
               centers: [],
            },
         ],
      };
   },
   methods: {
      closeDialog() {
         this.dialog = false
         this.backValueMethod(this.selected_Cost_centers, false)
         this.modalKey = this.modalKey + 1
      },

      save() {
         this.backValueMethod(this.selected_Cost_centers, true);
         this.modalKey = this.modalKey + 1
      },
      removeLine(index) {
         if (index >= 0)
            this.selected_Cost_centers.splice(index, 1);
      },
      addNew() {
         this.selected_Cost_centers.push({
            percentage: 0,
            centers: [],
         });
      },
   },
   mounted() {
      console.log(this.defaultValues);
      if (this.defaultValues.length > 0) {
         this.selected_Cost_centers = this.defaultValues
      }
   },
};
</script>
