<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="500">
      <v-card relative :key="selectedRow.id">
         <v-card class="backgroundW shadow pa-5 pt-7">
            <v-row>
               <v-col cols="12" class="text-h6 font-weight-bold">
                  {{ $t('are you sure that the transaction number has arrived') | capitalize }}
                  {{ selectedRow.id || '' }} {{ $t('?') }}
               </v-col>

               <GenericInput type="text" :value="mainfest_no" @input="mainfest_no = $event" label="manifest no"
                  :multi="false" :required="false" :isLoading="false" :cols="[6, 6, 6]">
               </GenericInput> 
               <GenericInput type="date" :value="mainfest_date" @input="mainfest_date = $event" label="manifest date"
                  :multi="false" :required="false" :isLoading="false" :cols="[6, 6, 6]">
               </GenericInput> 
               <GenericInput type="textarea" :value="arrival_notes" @input="arrival_notes = $event" label="notes"
                  :multi="false" :required="false" :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>

               <v-col cols="12" class="d-flex justify-end pt-0">
                  <v-btn text depressed color="error" :min-width="120" @click="closeDialog(false)" :height="37">
                     <v-icon left>mdi-close</v-icon>
                     {{ $t('close') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="changeStatus" depressed color="success" :loading="isLoading"
                     :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-card>
   </v-dialog>
</template>

<script>

import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "ArrivalConfirmationDialog",
   props: {
      closeDialog: { type: Function },
      dialog: { default: false },
      id: { default: 0 },
      selectedRow: { default: Object },
   },
   computed: {

   },
   components: {
      GenericInput
   },
   watch: {
      dialog() {
         if (this.dialog) {
            this.arrival_notes = this.selectedRow.id || null
            this.mainfest_date = this.selectedRow.mainfest_date || this.$global.GetCurrentDate()
            this.today = this.$global.GetCurrentDate();
         } else {
            this.isLoading = false;
            this.arrival_notes = null;
            this.mainfest_no = null;
            this.mainfest_date =  this.$global.GetCurrentDate();
         }

      }
   },
   data: () => ({
      isLoading: false,
      today: null,
      arrival_notes: null,
      mainfest_no: null,
      mainfest_date: null,
   }),
   methods: {
      changeStatus() {
         this.isLoading = true;
         const requestBody = {
            status_id: this.selectedRow.clearance_status_id,
            arrival_notes: this.arrival_notes,
            mainfest_no: this.mainfest_no,
            mainfest_date: this.mainfest_date,
         }
         this.$api.POST_METHOD(`update_clearance_arrival/${this.id}`, requestBody).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.selectedRow.arrival_notes = this.arrival_notes
               this.arrival_notes = null;
               this.mainfest_no = null;
               this.mainfest_date = null;
               this.closeDialog(true);
            }
         })

      },

   },
   mounted() {

   },
};
</script>
