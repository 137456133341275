<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :hideDeleted="true" :hideCreate="false"
        actionBtnText="Search" :createBtnText="$i18n.t('add transaction')" :actionBtn="!showFilter" :actionBtnValid="true"
        actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
        actionBtnClass="blue4--text" />
      <!-- End Of Header -->


      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" class="">
              <GenericInput type="text" :value="clearance_id" :keydownEnter="getData" :clearable="true"
                @input="clearance_id = $event" label="transaction no" :required="false" :hide-details="true"
                :cols="[12, 6, 3]" />

              <GenericInput type="text" :value="container_number" :keydownEnter="getData" :clearable="true"
                @input="container_number = $event" label="container number" :required="false" :hide-details="true"
                :cols="[12, 6, 3]" />

              <GenericInput type="text" :value="customer" :keydownEnter="getData" :clearable="true"
                @input="customer = $event" label="customer" :required="false" :hide-details="true" :cols="[12, 6, 3]" />

              <v-col cols="auto" md="6" lg="3" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="primary" outlined :loading="(pageData.isLoading)" @click="getData"
                  class="mx-1 white--text">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden shadow">
              <v-col cols="12" class="pa-0  position-relative" style="z-index:22">
                <v-tabs color="blue4" active-class="blue11" v-model="tab">
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold"><strong>{{ $t('all') }}</strong></v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold"><strong>{{ $t('pending')
                  }}</strong></v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold"><strong>{{ $t('stored')
                  }}</strong></v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold"><strong>{{ $t('exit waiting')
                  }}</strong></v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold"><strong>{{ $t('exited')
                  }}</strong></v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold"><strong>{{ $t('switched')
                  }}</strong></v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="pa-0 position-relative" style="z-index:99">
                <v-divider></v-divider>
              </v-col>
              <DynamicTable :isLoading="pageData.isLoading" :maxPDFRows="23"
                :printValueMethod="(row) => { printDialog = true; printRow = row }" :buttonValueMethod="statusMethod"
                :data="pageData.rows" :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit"
                :pageData="pageData" :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod"
                cardClasses="shadow-none">

                <template v-slot:td="{ row, header }">
                  <div v-if="header.key == 'size'">
                    <v-chip outlined small>{{ row.container_size }}</v-chip>
                  </div>
                  <div v-if="header.key == 'type'">
                    <v-chip v-if="row.is_box" text :color="'success'" small>{{ $t('package') }}</v-chip>
                    <div v-else>{{ row.clearance_d_id }}</div>
                  </div>
                  <div v-if="header.key == 'status_title'">
                    <v-btn @click="statusMethod(row)" depressed small :class="row.button_color">
                      {{ $t(row.status_title) }}
                    </v-btn>
                    <v-btn @click="switchMethod(row)" depressed small v-if="row.status == 1"
                      :loading="switchForm.isLoading && switchForm.warehouse_transaction_id == row.id" :color="'gray5'"
                      class="white--text ms-2">
                      {{ $t('switch') }}
                    </v-btn>
                  </div>
                </template>

              </DynamicTable>
            </v-card>
          </v-col>

        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>

      <v-dialog v-model="switchDialog" v-if="switchDialog" persistent width="400">
        <v-card class="pb-5">
          <v-card-title v-if="activeDialogRow.status == 1" class="subtitle-1  light">
            {{ $t('switch') }}
            <span class="primary--text">({{ $t('clearance number') }} {{ switchForm.clearance_id }})</span>
          </v-card-title>

          <v-divider></v-divider>
          <div class="pa-5">
            <GenericInput type="select" :lookups="areas" :value="switchForm.area_id" @input="switchForm.area_id = $event"
              label="area" :multi="false" :required="true" selected_label="name" selected_prop="id"
              :isLoading="updateStatusLoading" :cols="[12, 12, 12]" />

            <GenericInput type="select" :lookups="drivers" :value="switchForm.driver_id"
              @input="switchForm.driver_id = $event" label="driver" :multi="false" :required="true" selected_label="name"
              selected_prop="id" :isLoading="updateStatusLoading" :cols="[12, 12, 12]" />

            <GenericInput type="select" :lookups="cars" :value="switchForm.car_id" @input="switchForm.car_id = $event"
              label="car" :multi="false" :required="true" selected_label="name" selected_prop="id"
              :isLoading="updateStatusLoading" :cols="[12, 12, 12]" />
          </div>
          <v-card-actions class="justify-center">
            <v-btn color="error" text depressed @click="switchDialog = false">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="success" :disabled="!(switchForm.area_id && switchForm.driver_id && switchForm.car_id)"
              :loading="switchForm.isLoading" @click="saveSwitching" depressed>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="dialog" v-if="dialog" persistent width="400">
        <v-card class="pb-5">
          <v-card-title v-if="activeDialogRow.status == 1" class="subtitle-1  light">
            {{ $t('shipment has arrived') }} {{ $t('?') }}
          </v-card-title>
          <v-card-title v-if="activeDialogRow.status == 2" class="subtitle-1  light">
            {{ $t('shipment leave permission') }} {{ $t('?') }}
          </v-card-title>
          <v-divider></v-divider>
          <div class="pa-5">
            <GenericInput type="text" v-if="activeDialogRow.status == 2" :value="activeDialogRow.out_driver_name"
              @input="activeDialogRow.out_driver_name = $event" label="driver name" :required="true"
              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" />

            <GenericInput type="number" v-if="activeDialogRow.status == 2"
              :value="activeDialogRow.out_driver_residency_number"
              @input="activeDialogRow.out_driver_residency_number = $event" label="driver's residence number"
              :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" :acceptZero="true" />

            <GenericInput type="number" v-if="activeDialogRow.status == 2" :value="activeDialogRow.out_driver_mobile"
              @input="activeDialogRow.out_driver_mobile = $event" label="the driver's phone number" :required="true"
              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" :acceptZero="true" />

            <GenericInput type="text" v-if="activeDialogRow.status == 2" :value="activeDialogRow.out_car_number"
              @input="activeDialogRow.out_car_number = $event" label="car number" :required="true"
              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" :acceptZero="true" />

            <GenericInput type="textarea" :value="activeDialogRow.notes" @input="activeDialogRow.notes = $event"
              label="container details" :required="false" :isLoading="false" :cols="[12, 12, 12]">
            </GenericInput>
          </div>
          <v-card-actions class="justify-center">
            <v-btn color="error" text depressed @click="dialog = false">
              {{ $t('no') }}
            </v-btn>
            <v-btn color="success" :loading="updateStatusLoading" @click="updateStatus" depressed>
              {{ $t('yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="printDialog" v-if="printDialog" width="400">
        <v-card class="pb-0">
          <v-card-title class="subtitle-1  light">
            {{ $t('print transaction number') }} <span class="primary--text mx-2">{{ printRow.code_number }}</span> {{
              $t('?')
            }}
          </v-card-title>
          <!-- <v-card-title v-if="printRow.status == 3" class="subtitle-1   light">
           <v-icon class="mx-2" color="primary">mdi-information-outline</v-icon> {{ $t('transaction exited') }} <span class="mx-2">{{ printRow.code_number }}</span> 
          </v-card-title> -->
          <v-divider></v-divider>

          <v-card-actions class="justify-center pa-0">
            <v-col cols="6" class="pa-0">
              <v-btn color="blue10" class="rounded-0" block large :loading="pageData.printLoading.loading" depressed
                @click="printMethod(printRow, 1)">
                {{ $t('print receipt') }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-btn color="green9" class="rounded-0" :disabled="printRow.status == 1" block large
                :loading="pageData.printLoading.loading" @click="printMethod(printRow, 2)" depressed>
                {{ $t('print delivery') }}
              </v-btn>
            </v-col>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <WarehouseTransactionPrint :print="print" :printAction="() => { print = false; printData = [] }"
        :printStaticData="printStaticData" :printData="printData" />
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import WarehouseTransactionPrint from "./WarehouseTransactionPrint.vue";
export default {
  name: "WarehouseTransactionEntity",

  data: () => ({
    pageData: {
      screen_code: "03-009",
      url: null,
      controlRoute: "transport/wearhouse_transaction-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      tab: 0,
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
      queryParam: '?'
    },
    printData: [],
    printStaticData: Object,
    print: false,
    dialog: false,
    switchDialog: false,
    showFilter: false,
    printDialog: false,
    printLoading: false,
    printRow: null,
    updateStatusLoading: false,
    activeDialogRow: null,
    tab: 0,
    customers: [],
    customer: null,
    container_number: null,
    clearance_id: null,
    cars: [],
    drivers: [],
    areas: [],
    switchForm: {
      warehouse_transaction_id: null,
      trip_id: null,
      isLoading: false,
      area_id: null,
      car_id: null,
      driver_id: null,
    }
  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput,
    Pagination,
    WarehouseTransactionPrint,
  },
  computed: {},
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      this.getData()
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "blue4--text" },
        { text: "clearance number", key: "clearance_m_id", type: 'text', },
        { text: "in date", key: "in_date", type: 'date', classes: "" },
        { text: "out date", key: "out_date", type: 'date', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "container number", key: "container_number", type: 'text', classes: "" },
        { text: "size", key: "container_size", type: 'text', classes: "" },
        { text: "container status", key: "container_status", type: 'text', classes: "" },
        { text: "total price", key: "cost_per_day", type: 'price', classes: "" },
        { text: "in driver", key: "in_driver_name", type: 'text', classes: "" },
        { text: "out driver", key: "out_driver_name", type: 'text', classes: "" },
        { text: "status", key: "status_title", hideInPrint: true, type: 'slot', classes: "", buttonClass: "" },
        { text: "status", key: "status_title_translated", hideInList: true, type: 'text', classes: "", buttonClass: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        let status = this.tab ? `&status=${this.tab == 5 ? 10 : this.tab}` : ``
        this.$api
          .GET_METHOD(`wearhouse_transaction${this.pageData.queryParam}${status}&container_number=${this.container_number || ''}&clearance_id=${this.clearance_id || ''}&customer=${this.customer || ''}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.customers = response.data.customers || []
              response.data.items.data.forEach(row => {
                row.container_status = row.is_empty ? this.$i18n.t('empty') : this.$i18n.t('packed');
                switch (Number(row.status)) {
                  case 1:
                    row.status_title = "pending";
                    row.notes = null;
                    row.button_color = "yellow8";
                    break;
                  case 2:
                    row.status_title = "stored";
                    row.notes = null;
                    row.button_color = "green8";
                    break;
                  case 3:
                    row.status_title = "exit waiting";
                    row.notes = null;
                    row.button_color = "red5 white--text disable-button";
                    break;
                  case 4:
                    row.status_title = "exited";
                    row.notes = null;
                    row.button_color = "error disable-button";
                    break;
                  case 10:
                    row.status_title = "switched";
                    row.notes = null;
                    row.button_color = "gray2 white--text disable-button";
                    break;

                  default:
                    break;
                }
                row.status_title_translated = this.$t(row.status_title)
              });
              this.areas = response.data.areas || [];
              this.cars = response.data.cars || [];
              this.drivers = response.data.drivers || [];
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    updateStatus() {
      this.updateStatusLoading = true;
      let newStatus = 0
      switch (this.activeDialogRow.status) {
        case 2:
          newStatus = 4;
          break;
        default:
          newStatus = this.activeDialogRow.status + 1;
          break;
      }
      let requestBody = {
        status: newStatus,
        notes: this.activeDialogRow.notes,
        _method: "PUT"
      }
      if (this.activeDialogRow.status == 2) {
        requestBody.out_car_number = this.activeDialogRow.out_car_number
        requestBody.out_driver_mobile = this.activeDialogRow.out_driver_mobile
        requestBody.out_driver_name = this.activeDialogRow.out_driver_name
        requestBody.out_driver_residency_number = this.activeDialogRow.out_driver_residency_number
      }
      console.log("this.activeDialogRow", this.activeDialogRow);
      this.$api.POST_METHOD(`wearhouse_transaction/${this.activeDialogRow.id}`, requestBody).then((response) => {
        this.updateStatusLoading = false;
        if (response.check) {
          this.dialog = false;
          this.getData();
          this.printMethod(this.activeDialogRow, this.activeDialogRow.status == 1 ? 1 : 2)
        }
      });

    },
    statusMethod(row) {
      console.log("row", row);
      if (row.status != 10 && row.status != 4) {
        this.dialog = true;
        this.activeDialogRow = row;
      }
    },
    switchMethod(row) {
      console.log("row", row);
      this.switchForm.clearance_id = row.clearance_m_id;
      this.switchForm.warehouse_transaction_id = row.id;
      this.switchForm.trip_id = row.trip_id;
      this.switchDialog = true;
      this.activeDialogRow = row;
    },
    saveSwitching() {
      this.switchForm.isLoading = true;
      this.$api.POST_METHOD(`trip_change`, this.switchForm).then((response) => {
        this.switchForm.isLoading = false;
        if (response.check) {
          this.switchForm = {
            warehouse_transaction_id: null,
            trip_id: null,
            isLoading: false,
            area_id: null,
            car_id: null,
            driver_id: null,
          }
          this.switchDialog = false;
          this.activeDialogRow = {}
          this.getData();
        }
      });


    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`wearhouse_transaction/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`wearhouse_transaction_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/car/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    printMethod(row, print = null) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api.GET_METHOD(`${this.$store.state.endpointURL}api/v1/en/wearhouse_transaction/${row.id}`).then((response) => {
        this.pageData.printLoading.loading = false;
        if (response.check) {
          this.printData = [response.data.item];
          this.printStaticData = response.data.item;
          this.printStaticData.sub_title = print == 1 ? this.$i18n.t('gate-in') : this.$i18n.t('gate-out pass');
          console.log('print', print);
          this.printStaticData.print_type = print;
          this.print = true;
          setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.printDialog = false;
            this.pageData.printLoading.loading = false;
          }, 1000);
        }
      });
    },
  },
};
</script>
