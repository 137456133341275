<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :showSearch="false" :getData="getData" actionBtnText="Search"
        :showDeleted="false" :createBtn="false" :actionBtn="!showFilter" :actionBtnValid="true"
        actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
        actionBtnClass="blue4--text" :getSearch="getSearch">
        <!-- <template v-slot:rightSide>
          <v-col cols="12" sm="auto">
            <PrintBtn classes="my-2 gray4 white--text" :backValueMethod="print" :width="120" :height="37"
              :loading="pageData.printLoading || pageData.printLoading" :image="false"
              :disabled="!(transport_report.length > 0)" />
          </v-col>
        </template> -->
      </EntityHeader>
      <!-- End Of Header -->

      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" class="">



              <v-col cols="4">
                <v-menu v-model="datePicker" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" outlined dense hide-details :label="$i18n.t('select month')"
                      append-icon="mdi-calendar" readonly v-bind="attrs"  v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'" v-model="date"
                    @input="datePicker = false" type="month"></v-date-picker>
                </v-menu>
              </v-col>


              <GenericInput type="select" solo :clearable="true" :lookups="customers" :value="customer_id"
                @input="customer_id = $event" label="customer" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.reportIsLoading)"
                :cols="[12, 12, 4]">
              </GenericInput>
              <v-col cols="12" md="6" lg="4" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="primary" outlined :disabled="!(customer_id)"
                  :loading="(pageData.isLoading || pageData.reportIsLoading)" @click="getReport"
                  class="mx-1 white--text">{{
                      $t("Search")
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--  -->
      <v-row v-if="transport_report.length > 0">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="transport_report" :header="pageData.tableHeader"
            :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 260 : $store.state.Settings.windowSize.y - 150"
            :option="pageData.options" :pageData="pageData" :showGroupHeader="false" :groupHeader="groupHeader"  :customize_header_footer="true"  :printStaticData="printStaticData" />
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
// import PrintBtn from "@/components/ui/PrintBtn.vue";
export default {
  name: "TransportReportEntity",

  data: () => ({
    pageData: {
      screen_code: "03-014",
      url: null,
      controlRoute: "transport/transport_report-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      reportIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    groupHeader: [],
    printStaticData: Object,
    showFilter: true,
    datePicker: false,
    from_date: null,
    to_date: null,
    customer_id: null,
    date: null,
    today: null,
    transport_report: [],
    customers: [],
  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput,
    // PrintBtn
},
  watch: {
    customer_id() {
      this.transport_report = [];
      this.getReport();
    }
  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.to_date = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.getData();

  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "" },
        { text: "date", key: "trip_date", type: 'date', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "clearance", key: "clearance_m_id", type: 'text', classes: "" },
        { text: "container number", key: "container_number", type: 'text', classes: "" },
        { text: "container type", key: "container_type", type: 'text', classes: "" },
        { text: "container size", key: "container_size", type: 'text', classes: "" },
        { text: "driver name", key: "driver_name", type: 'text', classes: "" },
        { text: "direction", key: "direction", type: 'text', classes: "" },
      ];

      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`transport_report`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.customers = response.data.customers || [];
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getReport() {
      this.pageData.reportIsLoading = true;
      const body = {
        customer_id: this.customer_id,
        month_date: this.date,
        type: 'json'
      }
      this.$api.POST_METHOD(`transport_report`, body , false).then((response) => {
        this.pageData.reportIsLoading = false;
        if (response.check) {
          response.data.report.forEach(row => {
            row.container_type = row.is_empty ? this.$i18n.t('empty'): this.$i18n.t('packed');
            let from_place = row.from_area_name || row.from_port_name || row.from_wearhouse_name // it will be one of them
            let to_place = row.to_area_name || row.to_port_name || row.to_wearhouse_name // it will be one of them
            row.direction = from_place + ' --> ' + to_place
          });
          this.transport_report = response.data.report || [];
          this.printStaticData = response.data.print;
        }
      });
    },
    print(type) {
      this.pageData.printLoading = true;
      const body = {
        customer_id: this.customer_id,
        month_date: this.date,
        type: type
      }
      this.$api.DOWNLOAD_METHOD_BODY(`transport_report`, body, `transport_report.${type}`)
        .then((response) => {
          this.pageData.printLoading = false;
          if (response.check) {
            // this.pageData.rows = response.data.report;
          }
        });
    },

  },
};
</script>
