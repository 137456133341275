<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="2"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="40" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="printStaticData"
               :printTitle="$store.getters.activeScreen.sub_title">
               <template v-slot:pdf_content="{ page }">

                  <table width="100%" v-if="page == 1" id="master_table_multi_voucher">
                     <tr>
                        <td width="50%">{{ $t('voucher number') }} : <span>{{ printStaticData.master.id }}</span> </td>
                        <td width="50%">{{ $t('date') }} : <span>{{ printStaticData.master.add_date }}</span> </td>
                     </tr>
                     <tr>
                        <td width="50%">{{ $t('currency') }} : <span>{{ printStaticData.master.currency_name }}</span>
                        </td>
                        <td width="50%">{{ $t('description') }} : <span>{{ printStaticData.master.description }}</span>
                        </td>
                     </tr>
                  </table>
               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>
   </div>
</template>

<script>
import PrintPDF from "@/components/PrintPDF.vue";
import VueHtml2pdf from 'vue-html2pdf'
export default {
   name: "MultiVoucherPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
   },
   computed: {
      computedTotals() {
         if (this.printData.length) {
            const credit = this.printData.reduce((oldValue, newValue) => {
               return +oldValue + +newValue.credit;
            }, 0);
            const debit = this.printData.reduce((oldValue, newValue) => {
               return +oldValue + +newValue.debit;
            }, 0);
            const first = 0;
            const vat = this.printData.reduce((oldValue, newValue) => {
               return +oldValue + +newValue.vat;
            }, 0);
            const without_vat = this.printData.reduce((oldValue, newValue) => {
               return +oldValue + +newValue.without_vat;
            }, 0);
            const balance = 0;
            const total = vat + without_vat
            return {
               voucher_type_title: this.$i18n.t('total'),
               first: first,
               credit: credit,
               debit: debit,
               balance: balance,
               vat: vat,
               without_vat: without_vat,
               total: total,
            }
         } else {
            return {
               voucher_type_title: this.$i18n.t('total'),
               credit: 0,
               debit: 0,
               vat: 0,
               without_vat: 0,
               total: 0,
            }
         }
      }
   },
   components: {
      PrintPDF,
      VueHtml2pdf,
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
            // setTimeout(() => {
            //    this.printAction()
            // }, 2000);
         }
      },
      'printData.length'() {
         this.printData.forEach(row => {
            row.voucher_type_title = row.voucher_type ? this.$global.FilterArrayOfObjectElement(this.voucher_types, 'id', +row.voucher_type, 'name') : null;
            row.dis_type_title = row.dis_type ? this.$global.FilterArrayOfObjectElement(this.dis_types, 'id', +row.dis_type, 'name') : null;
            row.tax_title = row.tax_id ? this.$global.FilterArrayOfObjectElement(this.printStaticData.taxes, 'id', +row.tax_id, 'name') : null;
            row.vat_percentage = row.tax_id ? this.$global.FilterArrayOfObjectElement(this.printStaticData.taxes, 'id', +row.tax_id, 'tax_percentage') : null;
            row.debit = +row.voucher_type == 1 ? +row.amount : 0;
            row.credit = +row.voucher_type == 2 ? +row.amount : 0;
            row.vat = +row.amount - (+row.amount / ((+row.vat_percentage / 100) + 1));
            row.without_vat = +row.amount - row.vat;
            if (typeof row.cost_centers == 'string' && row.cost_centers) {
               row.cost_centers = row.cost_centers.split(',').map(i => Number(i))
            }
            row.selected_cost_centers = null;
            if (row.cost_centers.length) {
               row.cost_centers.forEach(cost_center => {
                  if (cost_center) {
                     row.selected_cost_centers = `${row.selected_cost_centers ? row.selected_cost_centers + '-' : ''}  ${this.$global.FilterArrayOfObjectElement(this.printStaticData.cost_centers, 'id', +cost_center, 'name')}`;
                  }
               });
            }
            switch (+row.dis_type) {
               case 1:
                  row.dis_type_name = row.dis_treasury_id ? this.$global.FilterArrayOfObjectElement(this.printStaticData.treasurys, 'id', +row.dis_treasury_id, 'name') : null;
                  break;
               case 2:
                  row.dis_type_name = row.dis_bank_id ? this.$global.FilterArrayOfObjectElement(this.printStaticData.bank, 'id', +row.dis_bank_id, 'name') : null;
                  break;
               case 3:
                  row.dis_type_name = row.dis_customer_id ? this.$global.FilterArrayOfObjectElement(this.printStaticData.customers, 'id', +row.dis_customer_id, 'name') : null;
                  break;
               case 4:
                  row.dis_type_name = row.dis_supplier_id ? this.$global.FilterArrayOfObjectElement(this.printStaticData.suppliers, 'id', +row.dis_supplier_id, 'name') : null;
                  break;
               case 5:
                  row.dis_type_name = row.dis_account_tree_id ? this.$global.FilterArrayOfObjectElement(this.printStaticData.account_trees, 'id', +row.dis_account_tree_id, 'name') : null;
                  break;
               default:
                  break;
            }

         });
      },
   },
   data: () => ({
      dis_types: [],
      tableHeader: [],
      voucher_types: [],
   }),
   methods: {

   },
   mounted() {
      this.tableHeader = [
         { text: "voucher type", key: "voucher_type_title", type: 'text', classes: "" },
         { text: "entity type", key: "dis_type_title", type: 'text', classes: "" },
         { text: "the entity", key: "dis_type_name", type: 'text', classes: "" },
         { text: "cost center", key: "selected_cost_centers", type: 'text', classes: "" },
         { text: "clearance number", key: "clearance_id", type: 'text', classes: "" },
         { text: "description", key: "description", type: 'text', classes: "" },
         { text: "debit", key: "debit", type: 'float', classes: "" },
         { text: "credit", key: "credit", type: 'float', classes: "" },
         { text: "vat type", key: "tax_title", type: 'text', classes: "" },
         { text: "vat", key: "vat", type: 'float', classes: "" },
         { text: "without vat", key: "without_vat", type: 'float', classes: "" },
      ];
      this.dis_types = [
         { id: 1, name: this.$i18n.t('treasury') },
         { id: 2, name: this.$i18n.t('bank') },
         { id: 3, name: this.$i18n.t('customer') },
         { id: 4, name: this.$i18n.t('supplier') },
         { id: 5, name: this.$i18n.t('account') },
      ];
      this.voucher_types = [
         { id: 1, name: this.$i18n.t('debit') },
         { id: 2, name: this.$i18n.t('credit') }
      ];
   },
};
</script>

<style lang="scss" scoped>
.summary_row {
   padding: 10px 0.2rem;
   border-bottom: 2px solid #eee;
   font-size: 12px !important;
   display: flex !important;
   justify-content: space-between !important;
}

#master_table_multi_voucher {
   border: 1px solid #000;
  margin-bottom: 10px;
   -webkit-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;

   tr {
      background: #fff !important;

      &:last-of-type {
         td {
            border-top: 1px solid #000 !important;
         }
      }
   }

   td {

      text-align: start !important;
      padding: 5px !important;
      font-weight: 800;
      background: #fff !important;

      span {
         color: blue !important;
      }

   }
}

#print_title {
   width: fit-content;
   text-align: center;
   font-size: 12px !important;
   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 1.2rem;
   margin: auto;
   margin-bottom: 20px;
}</style>