<template>
  <section>
    <v-container fluid>

      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" actionBtnText="view checks"
        :actionBtnClick="getData" :actionBtn="true" :createBtn="false" :showSearch="true" :showDeleted="false"
        :actionBtnValid="searchValid" actionBtnColor="success" />
      <!-- End Of Header -->


      <v-row justify="center">
        <v-col cols="12" class="">
          <v-card class="shadow pa-7">
            <v-row align="center">
              <v-col cols="6" class="py-0">
                <div class="caption gray6--text">{{ $t('view') | capitalize }}</div>
              </v-col>
              <v-col cols="6" class="py-0">
                <div class="caption gray6--text">{{ $t('date') | capitalize }}</div>
              </v-col>
            </v-row>
            <v-row align="center" class="">
              <GenericInput type="select" :lookups="[
                { id: 0, name: $i18n.t('view all') },
                { id: 3, name: $i18n.t('receiving') },
                { id: 1, name: $i18n.t('collected') },
                { id: 2, name: $i18n.t('rejected') },
              ]" :value="pageData.status" @input="pageData.status = $event; getData()" label="" :multi="false"
                :required="false" selected_label="name" selected_prop="id" :hide-details="true"
                :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

              <GenericInput type="date" :value="pageData.from" @input="pageData.from = $event" label="from"
                :required="false" :hide-details="true" :isLoading="pageData.editIsLoading" :cols="[12,3,3]">
              </GenericInput>
              <GenericInput type="date" :value="pageData.to" @input="pageData.to = $event" label="to" :required="false"
                :hide-details="true" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]"></GenericInput>
            </v-row>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" sm="12">
          <ChequesDataTable :isLoading="pageData.isLoading" :options="pageData.options" :data="pageData.rows"
            :header="pageData.tableHeader" :backValueMethod="setClickRow" :editValueMethod="edit"
            :changeStatus="changeStatus" :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod"
            :pageData="pageData">
          </ChequesDataTable>
          <!-- <SkeletonTableLoader v-if="pageData.isLoading"></SkeletonTableLoader> -->
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
import ChequesDataTable from "./ChequesDataTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import EntityHeader from "../../../components/ui/EntityHeader.vue";
export default {
  name: "FollowIncomingChecksEntity",

  data: () => ({
    pageData: {
      screen_code: "02-010",
      url: null,
      controlRoute: "accounting/cheque_incoming-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      saveStatusLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      requestURL: null,
      options: {},
      page: 1,
      pagination: {},
      from: null,
      to: null,
      status: 0,
    },
  }),
  components: {
    ChequesDataTable,
    Pagination,
    GenericInput,
    EntityHeader
  },
  computed: {
    ...mapState(["favourites"]),
    searchValid() {
      if (this.pageData.from && this.pageData.to) {
        return true
      }
      if (!this.pageData.from && !this.pageData.to && this.pageData.status != null) {
        return true
      }
      if (!this.pageData.from && !this.pageData.to && this.pageData.search) {
        return true
      }
      if (!this.pageData.from && !this.pageData.to && this.pageData.status != null) {
        return true
      }
      else {
        return false
      }
    }
  },
  watch: {
    searchValid() {
      if (this.pageData.from && this.pageData.to) {
        this.pageData.requestURL = `cheque_incoming?from_date=${this.pageData.from}&to_date=${this.pageData.to}`
      }
      if (!this.pageData.from && !this.pageData.to && this.pageData.status != null) {
        this.pageData.requestURL = `cheque_incoming?status=${this.pageData.status}`
      }
      if (!this.pageData.from && !this.pageData.to && this.pageData.search) {
        this.pageData.requestURL = `cheque_incoming?word=${this.pageData.search}`
      }
      else {
        this.pageData.requestURL = `cheque_incoming`
      }
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    ...mapActions(["addFavourites"]),

    addToFav() {
      const fav = {
        screen_code: this.pageData.screen_code,
        name: this.pageData.entityName,
        url: this.pageData.url,
      };
      this.addFavourites(fav).finally(() => { });
    },

    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        {
          text: this.$i18n.t("movement code"),
          sortable: true,
          value: "voucher_id",
        },
        {
          text: this.$i18n.t("customer"),
          sortable: true,
          value: "customer_name",
        },
        {
          text: this.$i18n.t("the bond number"),
          sortable: true,
          value: "cheque_number",
        },
        {
          text: this.$i18n.t("due date"),
          sortable: true,
          value: "due_date",
        },
        {
          text: this.$i18n.t("bank"),
          sortable: true,
          value: "bank_name",
        },
        {
          text: this.$i18n.t("deposit date"),
          sortable: true,
          value: "deposit_date",
        },
        {
          text: this.$i18n.t("value"),
          sortable: true,
          value: "amount",
        },

        { text: this.$i18n.t("Check Status"), value: "cheque_status_id", sortable: false },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    changeStatus(item, status) {
      this.pageData.saveStatusLoading = true
      console.log(item, status);
      this.$api
        .POST_METHOD(`cheque_incoming_status/${item.id}`, { status: status })
        .then((response) => {
          this.pageData.saveStatusLoading = false
          if (response.check) {
            this.getData();
          }
        });
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        console.log(this.pageData.requestURL);
        const status = this.pageData.status != 0 ? `&status=${this.pageData.status}` : ``;
        const from_date = this.pageData.from && this.pageData.to ? `&from_date=${this.pageData.from}` : ``;
        const to_date = this.pageData.from && this.pageData.to ? `&to_date=${this.pageData.to}` : ``;
        // `cheque_incoming?from_date=${this.pageData.from}&to_date=${this.pageData.to}${status}&word=${this.pageData.search}`
        this.$api
          .GET_METHOD(`cheque_incoming?rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}${status}${from_date}${to_date}`)
          .then((response) => {
            this.pageData.isLoading = false;
            console.log("response", response);
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted financial periods")
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      console.log("page");
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api
        .POST_METHOD(`accounting/cheque_incoming/${id}`, { _method: "delete" })
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    restoreMethod(row) {
      this.$api
        .POST_METHOD(`accounting/cheque_incoming_toggle_active/${row.id}`, null)
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    setClickRow() {
      // this.$router.push(`/main/cheque_incoming/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>
