<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid"
            :actionBtnClick="() => { $router.push(pageData.controlRoute + $route.params.id + '?customer_name=' + form.name_ar) }"
            actionBtnText="pricing" :actionBtnValid="pageData.isEdit" actionBtnColor="success" :actionBtn="pageData.isEdit"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-col cols="12" class="pa-0 px-10">
                  <v-tabs active-class="blue12" color="primary">
                     <v-tab class="font-weight-bold" :value="1" @click="tab = 1"><strong>
                           {{ $t('customer data') }}
                        </strong></v-tab>
                     <v-tab class="font-weight-bold" :value="3" @click="tab = 3"><strong>
                           {{ $t('attachments') }}
                        </strong></v-tab>
                  </v-tabs>
               </v-col>

               <v-col cols="12" class="pa-0">
                  <v-divider></v-divider>
               </v-col>

               <v-sheet class="pa-5">
                  <v-tabs-items v-model="tab">
                     <v-tab-item eager :value="1" class="pb-4">
                        <v-row align="center">
                           <v-col cols="12" class="pb-0 d-flex justify-space-between" v-if="pageData.isEdit">
                              <div class="body-2 gray7--text">{{ $t('customer code') | capitalize }}: {{ form.id }}</div>
                           </v-col>

                           <v-col cols="12" class="pb-0 d-flex justify-space-between">
                              <div class="body-2 gray7--text">{{ $t('customer details') | capitalize }}</div>
                           </v-col>
                           <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                              :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                           </GenericInput>

                           <GenericInput type="select" :lookups="account_trees" :value="form.account_tree_id"
                              @input="form.account_tree_id = $event" label="linked account" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 3]">
                           </GenericInput>

                           <GenericInput type="select" :lookups="delegates" :value="form.delegate_id"
                              @input="form.delegate_id = $event" label="delegate" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 3]">
                           </GenericInput>

                           <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                              :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                           </GenericInput>

                           <GenericInput type="number" :value="form.vat_register_number"
                              @input="form.vat_register_number = $event" label="vat register number" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                           </GenericInput>

                           <GenericInput type="number" :value="form.credit_period" @input="form.credit_period = $event"
                              label="credit period" :required="false" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 3]">
                           </GenericInput>

                           <GenericInput type="text" v-if="$store.state.userData.client_id_status"
                              :value="form.alireda_code" @input="form.alireda_code = $event" label="ali reda code"
                              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                           </GenericInput>

                           <v-col cols="12" class="pa-0 d-flex">
                              <GenericInput type="textarea" :value="form.address" @input="form.address = $event"
                                 label="address" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>

                              <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="notes"
                                 :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>
                           </v-col>

                           <GenericInput type="checkbox" :disabled="!$store.state.userData.invoice_issuance_status"
                              :value="form.issuing_invoice" @input="form.issuing_invoice = $event" label="release invoice"
                              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 'auto']">
                           </GenericInput>

                           <v-col cols="auto"
                              v-if="pageData.isEdit && !form.commercial_register_file && !form.other_file && !form.iban_file && !form.prices_list_file && !prices.length">
                              <div class="body-2 info--text">
                                 <v-icon color="info" size="16">mdi-information</v-icon>
                                 {{ $t('customer should be have an attachments and prices') }}
                              </div>
                           </v-col>

                        </v-row>
                     </v-tab-item>

                     <v-tab-item eager :value="3">
                        <div class="py-4">
                           <v-row align="start" justify="center">
                              <GenericInput type="file" :value="form.commercial_register_file"
                                 @input="form.commercial_register_file = $event" label="commercial register"
                                 :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                              </GenericInput>
                              <GenericInput type="file" :value="form.iban_file" @input="form.iban_file = $event"
                                 label="IBAN" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                              </GenericInput>
                              <GenericInput type="file" :value="form.prices_list_file"
                                 @input="form.prices_list_file = $event" label="quotation" :required="false"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                              </GenericInput>
                              <GenericInput type="file" :value="form.other_file" @input="form.other_file = $event"
                                 label="other file" :required="false" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 12, 6]">
                              </GenericInput>
                           </v-row>
                        </div>

                        <v-divider></v-divider>
                        <AttachmentList :returnAttachOnly="true" :attachments="client_attachments"
                           cardTitle="Additional attachments" deleteEndPoint="delete_file_customer"
                           downloadEndPoint="download_file_customer" :multi="true" />
                     </v-tab-item>
                  </v-tabs-items>
               </v-sheet>
            </v-card>

            <v-card class="backgroundW shadow overflow-hidden pa-3 mt-3" v-if="tab == 1">
               <div :key="contactKey">
                  <v-row align="center" class="mt-0" v-for="(contact, index) in form.customer_contacts" :key="index">
                     <GenericInput type="text" :value="contact.customers_info_name"
                        @input="contact.customers_info_name = $event" label="name" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[4, 3, 3]">
                     </GenericInput>
                     <GenericInput type="mobile" :value="contact.customers_info_phone"
                        @input="contact.customers_info_phone = $event" label="phone" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[4, 3, 3]">
                     </GenericInput>
                     <v-col cols="4" md="3" lg="3" class="pa-0" @keydown="addNewRowByTab($event, index)">
                        <GenericInput type="email" :value="contact.customers_info_email"
                           @input="contact.customers_info_email = $event" label="email" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                        </GenericInput>
                     </v-col>
                     
                     <div>
                        <v-col cols="3" class="d-flex">
                           <v-btn depressed text color="error" small class="mx-1" icon fab
                              v-if="form.customer_contacts.length !== 1" @click="removeContact(index); contactKey++">
                              <v-icon size="25">mdi-trash-can-outline</v-icon>
                           </v-btn>
                           <v-btn color="success" small class="mx-1" icon fab
                              v-if="form.customer_contacts.length == index + 1" @click="addNewRowByTab()">
                              <v-icon size="25">mdi-plus-circle</v-icon>
                           </v-btn>
                        </v-col>
                     </div>

                  </v-row>
                  <v-row v-if="form.customer_contacts.length == 0">
                     <v-col cols="12">
                        <v-btn color="success" large class="d-block mi-start-auto shadow"
                           @click="form.customer_contacts.push({ phone: null, name: null }); contactKey++">
                           {{ $t('add another contact') }}
                           <v-icon size="25" right>mdi-plus</v-icon>
                        </v-btn>
                     </v-col>
                  </v-row>
               </div>
            </v-card>
         </v-form>
         <!-- End of Form -->

         <AttachmentDialog :dialog="attachmentDialog" :entityName="form.name || '-'" :dataKey="'id'" :data="form"
            :uploadKey="'customer_attachments'" :multi="false" :uploadResource="'upload_customer_attachment'"
            :downloadResource="'download_customer_attachment'" :returnAttachOnly="!pageData.isEdit"
            :returnAttachMethod="(attach) => { attachmentDialog = false; this.form.customer_attachments.push(attach) }"
            :returnMethod="(reload) => { attachmentDialog = false; reload ? getData() : null }" />
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import AttachmentDialog from "@/components/modals/AttachmentDialog.vue";
import AttachmentList from "@/components/modals/AttachmentList.vue";

export default {
   name: "CustomersControl",

   data: () => ({
      pageData: {
         screen_code: "04-003",
         url: null,
         controlRoute: "/sales/customer_ports-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: true,
      attachmentDialog: false,
      summery: [],
      delegates: [],
      valid: false,
      attachDownloadLoading: false,
      attachmentListDialog: false,
      tab: 1,
      toggle_ports: 0,
      verticalTabs: 0,
      client_attachments: [],
      areas: [],
      prices: [],
      trans_prices: [],
      account_trees: [],
      currencies: [],
      customer_types: [],
      contactKey: 1,
      form: {
         name_en: null,
         name_ar: null,
         address: null,
         notes: null,
         account_tree_id: null,
         delegate_id: null,
         issuing_invoice: 0,
         credit_period: 0,
         other_file: null,
         iban_file: null,
         commercial_register_file: null,
         prices_list_file: null,
         customer_attachments: [],
         customer_contacts: [],
      },
   }),
   watch: {
      '$store.state.Settings.key_clicked'() {
         switch (this.$store.state.Settings.key_clicked) {
            case 'F3':
               this.pushNewDetailsRow();
               break;
            default:
               break;
         }
      },
   },
   components: {
      GenericInput,
      ControlHeader,
      AttachmentDialog,
      AttachmentList
   },
   computed: {
      pushNewDetailsRow() {
         return (index) => {
            let defaultRow = {
               customers_info_name: null,
               customers_info_phone: null,
               customers_info_email: null,
            }
            if (typeof index === 'number') {
               this.form.customer_contacts.splice(index + 1, 0, defaultRow)
            } else {
               this.form.customer_contacts.push(defaultRow)
            }
         }
      },
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`customer/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.areas = response.data.areas || [];
                     this.delegates = response.data.delegates || [];
                     this.prices = response.data.prices || [];
                     this.trans_prices = response.data.trans_prices || [];
                     this.account_trees = response.data.account_tree || [];
                     this.form.customer_attachments = response.data.customer_attachments || [];
                     this.form.customer_contacts = response.data.customer_contacts || [];
                     this.client_attachments = response.data.client_attachments || [];
                     this.viewForm = true;
                     this.tab = 1;

                     // this.form.iban_file = 'https://trans-api.arabdivs.com/upload/customers/796465fd-4586-4199-8f2a-0305bad080ef.pdf'
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.viewForm = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`customer/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.areas = response.data.areas || [];
                     this.prices = response.data.prices || [];
                     this.delegates = response.data.delegates || [];
                     this.trans_prices = response.data.trans_prices || [];
                     this.account_trees = response.data.account_tree || [];
                     this.form.account_tree_id = response.data.default_account
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      async saveAsyncAttachments(id) {
         // To send image one by one
         for (let i = 0; i < this.client_attachments.length; i++) {
            // send only new attachments
            if (typeof this.client_attachments[i].file_path === 'object') {
               const response = await this.$api.POST_METHOD_MULTIPART(`upload_file_customer/${id}`, { 'file_path': this.client_attachments[i].file_path, 'file_name': this.client_attachments[i].file_name });
               if (response.check) {
                  console.log("response saveAsyncAttachments", response);
               }
            }
         }
      },
      save() {
         if (this.$refs.form.validate()) {
            // this.form.phone = this.form.customer_contacts[0].phone
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               delete this.form.customer_attachments
               this.$api.POST_METHOD(`customer/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.saveAsyncAttachments(this.form.id)
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`customer`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.saveAsyncAttachments(response.data.id)
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`customer/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      downloadAttachment(attach) {
         console.log(attach);
         this.attachDownloadLoading = true
         var src = `download_customer_attachment/${attach.customer_attachment_id}`;
         var download_file_name = attach.file_name
         this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            this.attachDownloadLoading = false;
         })
      },
      deleteAttachment(attach, index) {
         if (this.pageData.isEdit) {
            console.log(attach);
            this.attachDownloadLoading = true
            this.$api.POST_METHOD(`delete_customer_attachment/${attach.customer_attachment_id}`).then((res) => {
               this.attachDownloadLoading = false;
               if (res.check) {
                  this.getData()
               }
            })
         } else {
            this.form.customer_attachments.splice(index, 1);
         }

      },
      downloadAllAttachment() {
         this.form.customer_attachments.forEach(attachment => {
            this.downloadAttachment(attachment, 0)
         });
      },
      removeContact(index) {
         this.form.customer_contacts.splice(index, 1);
      },
      addNewRowByTab(event, index) {
         if (event.key == "Tab") {
            this.pushNewDetailsRow(index);
            this.contactKey++
         }
      },

   },

};
</script>
