<template>
  <!-- max-width="650" -->
  <div overlay-opacity="0.75" persistent :width="!expandDialog ? '50%' : '95%'">

    <v-card relative elevation="0">

      <v-card-title class="title_attachment text-h6 pa-3 d-flex font-weight-bold justify-space-between">
        <div class="text-h6 font-weight-bold text-capitalize">
          {{ $t(cardTitle) }}</div>
      </v-card-title>

      <v-card-text>
        <v-row>

          <v-col cols="12">
            <v-container class="d-flex flex-wrap flex-row-reverse align-center text-start">

              <!-- upload card in signal file-->
              <div v-if="!multi" class="upload ma-5 order-1 flex-shrink-0" @click="attachmentDialog = true">
                <div class="d-flex flex-column align-center">
                  <v-icon color="info">mdi-tray-arrow-up</v-icon>
                  <span>{{ $t('upload') }}</span>
                </div>
              </div>
              <!-- End upload card in signal file- -->

              <!-- upload card in multi files-->
              <DropZone v-if="multi" :multi="multi" :width="364" :height="142" :backDataMethod="sendArray"
                :deleteIndex="deleteIndex" :dropZoneFilesLength="(length) => dropZoneFilesLength = length"
                :previewInnerImgs="false" />
              <!-- End upload card in multi files-->

              <!-- Start Preview Cards -->
              <div class="card order-2 ma-2" v-for="(arr, i) in attachments" :key="i">
                <div class="layer">
                  <div class="icons">
                    <v-icon color="white" @click.prevent="deleteMethod(arr, i)">mdi-trash-can-outline</v-icon>
                    <v-icon color="white" @click.prevent="openInNewWindow(arr)">mdi mdi-open-in-new</v-icon>
                    <v-icon color="white" v-if="!arr?.not_valid"
                      @click.prevent="downloadItem(arr)">mdi-download-outline</v-icon>
                    <v-icon color="white" @click.prevent="magnifyItem(i)">mdi-magnify-plus-outline</v-icon>
                  </div>
                </div>

                <!-- iframe -->
                <iframe v-if="FILE_TYPE(arr.file_url || arr.file_path || arr) === 'pdf'"
                  :src="resolveFileDisplay(arr.file_url || arr.file_path || arr)" :width="pdfWidth" :height="pdfHight"
                  frameborder="0"></iframe>
                <!-- iframe -->

                <!-- image -->
                <v-img v-if="!isNotImg(arr.file_url || arr.file_path || arr)" :key="refresher"
                  @error="handelErrorImg(arr, i)"
                  :src="!arr?.not_valid ? resolveFileDisplay(arr.file_url || arr.file_path || arr) : errorImg(arr.file_url || arr.file_path || arr)"
                  :width="imgWidth" :height="imgHight" class="ma-3" contain />
                <!-- image -->

                <!-- video -->
                <video v-if="FILE_TYPE(arr.file_url || arr.file_path || arr) === 'mp4'" controls :width="imgWidth"
                  :height="imgHight" :src="resolveFileDisplay(arr.file_url || arr.file_path || arr)">
                </video>
                <!-- video -->

                <!-- Title -->
                <div class="img_title" v-if="showTitle">{{ arr.file_name || arr }}</div>
                <!-- Title -->

              </div>
              <!-- End Preview Cards -->

              <!-- Start dialog -->
              <v-dialog v-model="carouselDialog" min-width="600">
                <v-card-actions class="d-flex justify-end white">
                  <v-btn small fab color="grey lighten-1" text @click="closeCarouselDialog">
                    <v-icon color="grey lighten-1">mdi-close</v-icon>
                  </v-btn>
                </v-card-actions>

                <template>
                  <!-- carousel -->
                  <v-carousel height="80dvh" hide-delimiters show-arrows-on-hover v-model="updateIndex">
                    <v-carousel-item v-for="(arr, i) in attachments" :key="i">
                      <v-sheet height="100%" class="d-flex align-center justify-center">
                        <div class="dialog_container ma-0 pa-2" align="center" justify="center">

                          <!-- iframe -->
                          <iframe id="iframe_slider" v-if="FILE_TYPE(arr.file_url || arr.file_path || arr) === 'pdf'"
                            :src="resolveFileDisplay(arr.file_url || arr.file_path || arr)" height="100%" width="800"
                            frameborder="0"></iframe>
                          <!-- iframe -->


                          <!-- image -->
                          <v-img v-if="!isNotImg(arr.file_url || arr.file_path || arr)" @error="handelErrorImg(arr, i)"
                            :src="!arr?.not_valid ? resolveFileDisplay(arr.file_url || arr.file_path || arr) : errorImg(arr.file_url || arr.file_path || arr)"
                            id="img_slider" class="ma-3" contain />
                          <!-- image -->


                          <!-- video -->
                          <video id="video_slider" v-if="FILE_TYPE(arr.file_url || arr.file_path || arr) === 'mp4'"
                            controls width="90%" :src="resolveFileDisplay(arr.file_url || arr.file_path || arr)">
                          </video>
                          <!-- video -->

                          <!-- Title -->
                          <div class="img_title_slide" v-if="showTitle">{{ arr.file_name || arr }}</div>

                        </div>
                      </v-sheet>
                    </v-carousel-item>
                  </v-carousel>
                  <!-- End carousel -->
                </template>
              </v-dialog>
              <!-- End dialog -->
            </v-container>
          </v-col>

        </v-row>
      </v-card-text>


    </v-card>

    <AttachmentDialog :multiPart="true" :dialog="attachmentDialog" :returnMethod="() => attachmentDialog = false"
      :returnAttachMethod="returnAttachMethod" :returnAttachOnly="returnAttachOnly" />

    <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog"
      :item="selectedItem?.file_name || selectedItem?.name ? selectedItem?.file_name || selectedItem?.name : null"
      :backValueMethod="deleteValue">
    </DeleteConfirmation>
  </div>
</template>

<script>
import DropZone from '../DropZone.vue';
import AttachmentDialog from './AttachmentDialog.vue';
import DeleteConfirmation from './DeleteConfirmation.vue';

export default {
  name: "AttachmentList",
  components: { DropZone, AttachmentDialog, DeleteConfirmation },
  props: {
    returnMethod: { type: Function },
    returnAttachOnly: { default: false },
    attachments: { type: Array },
    deleteEndPoint: { default: String },
    downloadEndPoint: { default: String },
    multi: { default: false },
    imgWidth: { default: 145 },
    imgHight: { default: 145 },
    pdfWidth: { default: 145 },
    pdfHight: { default: 145 },
    arrFiles: { default: Array },
    showTitle: { default: true },
    cardTitle: { default: 'attachments' },
  },
  data: () => ({
    printLoading: false,
    attachment: null,
    isLoading: false,
    expandDialog: false,
    deleteDialog: false,
    attachDownloadLoading: false,
    attachmentDialog: false,
    carouselDialog: false,
    updateIndex: 0,
    refresher: 0,
    deleteIndex: 0,
    selectedItem: {},
    dropZoneFilesLength: 0,
  }),
  watch: {
  },
  computed: {
    isNotImg() {
      return (data) => {
        if (
          this.FILE_TYPE(data) === 'mp4' ||
          this.FILE_TYPE(data) === 'pdf'
        ) {
          return true
        }
      }
    },
    errorImg() {
      return (data) => {
        if (this.FILE_TYPE(data) === 'xlsx') {
          // excel 
          return require('@/assets/img/svg/microsoft-excel.svg')
        } else if (this.FILE_TYPE(data) === 'zip') {
          // zip
          return require('@/assets/img/svg/zip.svg')
        } else if (this.FILE_TYPE(data) === 'rar') {
          // rar
          return require('@/assets/img/svg/RAR.svg')
        } else {
          // error
          return require('@/assets/img/svg/Broken-Document.svg')
        }
      }
    },
    isPdf() {
      return (data) => data.substring(5, 20) === 'application/pdf';
    },
    resolveFileDisplay() {
      return (file) => {
        // excel
        if (this.FILE_TYPE(file) === 'excel') {
          return require('@/assets/img/svg/microsoft-excel.svg')
        }

        //  zip
        if (this.FILE_TYPE(file) === 'zip') {
          return require('@/assets/img/svg/zip.svg')
        }

        //  rar
        if (this.FILE_TYPE(file) === 'rar') {
          return require('@/assets/img/svg/RAR.svg')
        }

        // handel new files  
        else if (typeof file === 'object') {
          let url = URL.createObjectURL(file);
          return url;
        }

        // handel files in edit
        else if (typeof file === 'string') {
          return this.$api.serverUrl + file
        }
      };
    },
    FILE_TYPE() {
      return (FILE) => {
        // Base64
        // console.log(FILE);
        if (String(FILE).substring(0, 5) == "data:") {
          console.log(
            "FILE TYPE =>",
            FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          );
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              return "notFound";
          }
        }
        // Normal
        else {
          switch (FILE.type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              if (FILE.name) {
                var n = FILE.name.lastIndexOf(".");
                var result = FILE.name.substring(n + 1);
                return result;
              } else {
                var N = FILE.lastIndexOf(".");
                var RESULT = FILE.substring(N + 1);
                return RESULT;
              }
          }

        }
      }
    }
  },
  methods: {
    closeDialog() {
      this.returnMethod();
    },
    closeCarouselDialog() {
      const videoElement = document.getElementById('video_slider');
      if (videoElement && !videoElement.paused) {
        console.log('videoElement', videoElement);
        videoElement.pause();
      }
      this.carouselDialog = false;
    },
    // work if multi = true
    sendArray(data) {

      // case if we upload multiple file
      if (this.dropZoneFilesLength > 1) {
        data.forEach(sendArray => {
          let getDotIndex = sendArray.name.lastIndexOf('.');
          let nameFile = sendArray.name.substring(0, getDotIndex)
          const isAttachmentsExists = this.attachments && this.attachments.find((element) => element.file_name === nameFile);
          if (!isAttachmentsExists) {
            const attach = {
              'file_url': sendArray,
              'file_name': nameFile,
              'file': sendArray,
              'file_path': sendArray,
              'attachment_name': nameFile,
            }
            this.attachments.push(attach)
          }
        });
      }


      // case if we upload one file
      else if (this.dropZoneFilesLength === 1) {
        let getDotIndex = data[data.length - 1].name.lastIndexOf('.');
        let nameFile = data[data.length - 1].name.substring(0, getDotIndex)
        const one_attach = {
          'file_url': data[data.length - 1],
          'file_name': nameFile,
          'file': data[data.length - 1],
          'file_path': data[data.length - 1],
          'attachment_name': nameFile,
        }

        this.attachments.push(one_attach)
      }

      // rest to start with right length
      this.dropZoneFilesLength = 0
    },
    // work if multi = false
    returnAttachMethod(Attach) {
      console.log('Attach', Attach);
      const attach = {
        'file_url': Attach.file_path,
        'file_name': Attach.file_name,
        'file': Attach.file_path,
        'file_path': Attach.file_path,
        'attachment_name': Attach.file_name,
      }
      this.attachments.push(attach)
    },
    handelErrorImg(arr, index) {
      arr.not_valid = true;
      this.refresher++
      console.log('arr,index', arr, index);
    },
    downloadItem(file) {
      if (typeof file.file_url === 'object' || typeof file.file_path === 'object') {
        let url = URL.createObjectURL(file.file_url || file.file_path);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = file.file_name;
        anchor.click();
      } else {
        if (this.downloadEndPoint) {
          this.downloadRequest(file)
        } else {
          this.downloadLiveAttachment(file)
        }
      }
    },
    magnifyItem(index) {
      this.updateIndex = index;
      this.carouselDialog = true;
    },
    deleteValue(status) {
      if (status) {
        if (this.selectedItem.id || this.selectedItem.attachment_id) {
          this.$api.DELETE_METHOD(`${this.deleteEndPoint}`, this.selectedItem.id || this.selectedItem.attachment_id).then((response) => {
            if (response.check) {
              this.attachments.splice(this.deleteIndex, 1);
              this.deleteRefresher++;
              this.deleteDialog = false;
            }
          });
        }
      } else {
        this.deleteDialog = false;
      }
    },
    deleteMethod(arr, index) {
      this.deleteIndex = index;
      this.selectedItem = arr
      if (!arr?.id) {
        this.attachments.splice(this.deleteIndex, 1);
      } else {
        this.deleteDialog = true;
      }
    },
    downloadRequest(file) {
      this.attachDownloadLoading = true
      var src = `${this.downloadEndPoint}/${file.id || file.attachment_id}`;
      let dotIndex = file.file_path.lastIndexOf('.')
      let getExt = file.file_path.substring(dotIndex)
      let download_file_name = file.file_name + getExt
      this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
        this.attachDownloadLoading = false;
      })
    },
    downloadLiveAttachment(file) {
      let imagUrl = this.$api.serverUrl + (file.file_url || file.file_path)
      console.log("imagUrl", imagUrl);
      // Create an image element to load the image
      const img = new Image();
      img.crossOrigin = "anonymous"; // Enable cross-origin requests if necessary
      img.onload = function () {
        // Create a canvas to convert the image to base64
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        // Convert the image to base64
        const fileExtinction = imagUrl.lastIndexOf(".");
        const base64Data = canvas.toDataURL(`image/${imagUrl.slice(fileExtinction + 1)}`); // You can change the format if needed
        // Create a download link for the base64 image
        const anchor = document.createElement("a");
        anchor.href = base64Data;
        var fileName = `${imagUrl.slice(fileExtinction)}`;
        anchor.download = fileName; // Set the desired filename and extension
        // Trigger the download
        anchor.click();
      };
      img.src = imagUrl;
    },
    openInNewWindow(arr) {
      let url;
      if (typeof arr.file === 'object') {
        url = URL.createObjectURL(arr.file);
      } else {
        url = this.$api.serverUrl + arr.file_path;
      }
      window.open(url);
    }

  },
  mounted() {
  },

};
</script>


<style scoped lang="scss">
.upload {
  width: 145px;
  height: 145px;
  border: 2px dotted var(--info);
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.card {
  position: relative;
  border-radius: 8px;

}

.card .layer {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s;
  border-radius: 8px;
}

.card:hover .layer {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 1;
  font-size: 20px;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s;
  border-radius: 8px;
  z-index: 9999999;
}

.card .layer div:nth-child(1) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .v-icon {
    cursor: pointer;
  }
}

.dialog_container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  img {
    // width: 100%;
    // height: auto;
    width: 100%;
    height: 69dvh;
    object-fit: contain;
  }
}

.img_title {
  width: 154px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.img_title_slide {
  width: 100%;
  padding: 10px;
}

.title_attachment {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: white;
}

#iframe_slider {
  height: 69vh;
}

#video_slider {
  height: 70vh;
}

#img_slider {
  max-height: 412px;
}
</style>
