<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="656">
         <v-card relative class="pa-5">
            <v-form ref="form" v-model="valid">
               <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 20px' : 'right: 20px'" small fab
                  color="grey lighten-1" text @click="closeDialog">
                  <v-icon color="grey lighten-1">mdi-close</v-icon>
               </v-btn>
               <v-card-title class="text-h6 text-center font-weight-bold">
                  <h5 class="d-block font-weight-medium ma-auto text--disabled">
                     <span>{{ $t('add a main account') | capitalize }}</span>
                  </h5>
               </v-card-title>

               <v-col cols="12" class="py-4">
                  <v-row align="center" justify="center">
                     <v-col cols="auto">
                        <v-btn depressed @click="closeDialog" text :height="52" :width="120"
                           class="rounded-lg font-weight-bold white--text" color="error">{{ $t('cancel') }} <v-icon
                              class="mx-1" small left>mdi-close</v-icon>
                        </v-btn>
                     </v-col>

                     <v-col cols="auto">
                        <v-btn color="success" depressed :height="52" :width="120" :disabled="!valid" @click="save"
                           class="rounded-lg font-weight-bold white--text" :loading="isLoading == true">{{ $t('create')
                           }}</v-btn>
                     </v-col>
                  </v-row>
                  <v-row justify="end" align="center">
                     <GenericInput type="text" :value="name_en" @input="name_en = $event" label="name_en"
                        :required="true" :isLoading="false" :cols="[12, 6, 6]"></GenericInput>
                     <GenericInput type="text" :value="name_ar" @input="name_ar = $event" label="name_ar"
                        :required="true" :isLoading="false" :cols="[12, 6, 6]"></GenericInput>
                     <GenericInput type="select"
                        :lookups="[{ name: $i18n.t('1-9'), id: 1 }, { name: $i18n.t('1-99'), id: 2 }, { name: $i18n.t('1-999'), id: 3 }]"
                        selected_label="name" selected_prop="id" :value="level_chars" @input="level_chars = $event"
                        label="number of sub accounts" :isLoading="false" :cols="[12, 6, 6]"></GenericInput>
                     <v-col cols="12" sm="6">
                        <v-row>
                           <v-col cols="12" sm="auto">{{ $t("account nature") }} :</v-col>
                           <GenericInput type="radiogroup"
                              :lookups="[{ name: $i18n.t('creditor'), id: 1 }, { name: $i18n.t('debtor'), id: 2 }]"
                              selected_label="name" selected_prop="id" :value="account_nature"
                              @input="account_nature = $event" label=" " :isLoading="false"
                              :cols="[12, 'auto', 'auto']" />
                        </v-row>
                     </v-col>
                  </v-row>
               </v-col>
            </v-form>
         </v-card>
      </v-dialog>
   </v-row>
</template>



<style scoped lang="scss">
</style>



<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "AddMainAccountModal",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
   },
   computed: {

   },
   components: {
      GenericInput
   },
   data() {
      return {
         isLoading: false,
         valid: false,
         name_en: null,
         name_ar: null,
         account_type: 1,
         protection: 1,
         account_nature: 1,
         parent_id: 0,
         level_chars: 1,
      };
   },
   methods: {
      closeDialog() {
         this.dialog = false
         this.backValueMethod(false);
         this.name_en = null
         this.name_ar = null
         this.level_chars = 1
      },
      save() {
         var requestBody = {
            name_en: this.name_en,
            name_ar: this.name_ar,
            account_type: this.account_type,
            protection: this.protection,
            account_nature: this.account_nature,
            parent_id: this.parent_id,
            level_chars: this.level_chars,
         }
         this.isLoading = true;
         this.$api.POST_METHOD(`account_tree`, requestBody).then((response) => {
            this.isLoading = false;
            if (response.check == true) {
               this.backValueMethod(false);
            }
         })


      },

   },
   mounted() {

   },
};
</script>
