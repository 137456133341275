<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && tableRows.length > 0"
            :deleteMethod="deleteMethod" :editForm="false">
            <template v-slot:centerSide>
               <div class="font-weight-bold subtitle-1 gray6--text" v-if="totalsCalculations.amount">{{$t('total voucher')}} : {{totalsCalculations.amount | float}}</div>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
                  <v-row align="center" v-show="!formCollapse">
                     <v-col cols="6" md="4">
                        <v-row>
                           <GenericInput type="date" :value="form.action_date" @input="form.action_date = $event"
                              label="date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                           </GenericInput>

                           <GenericInput type="select" :lookups="daily_types" :value="form.daily_type_id"
                              @input="form.daily_type_id = $event" label="daily" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 12, 12]">
                           </GenericInput>
                        </v-row>
                     </v-col>
                     <GenericInput type="textarea" solo :value="form.arabic_description" :rows="3"
                        @input="form.arabic_description = $event" label="description" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 7]"></GenericInput>
                  </v-row>
               </v-expand-transition>
            </v-card>
            <!-- Detail Form -->
         </v-form>
         <v-expand-transition>
            <v-form ref="newItem" v-model="validItem" class="pa-2" v-show="!formCollapse && !pageData.isEdit">
               <v-card class="backgroundW shadow pa-5 pt-7">
                  <v-row>
                     <GenericInput type="float" solo :value="newItem.amount" :rows="5" @input="newItem.amount = $event"
                        label="amount" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="select" :lookups="covenant_types" :value="newItem.pay_for"
                        @input="newItem.pay_for = $event" label="pay for" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="filter_delegates"
                        v-if="newItem.pay_for == 1 || newItem.pay_for == 2" :value="newItem.delegate_id"
                        @input="newItem.delegate_id = $event" label="delegate" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>


                     <GenericInput type="select" :lookups="account_trees" v-if="newItem.pay_for == 3"
                        :value="newItem.account_tree_id" @input="newItem.account_tree_id = $event" label="account"
                        :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="cost_centers" v-if="newItem.pay_for == 3"
                        :value="newItem.cost_center_id" @input="newItem.cost_center_id = $event" label="cost center"
                        :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="clearances" v-if="newItem.pay_for !== 1" :value="newItem.clearance_id"
                        @input="newItem.clearance_id = $event" label="clearance" :multi="false" :required="false" :clearable="true"
                        selected_label="id" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="containers" v-if="newItem.pay_for == 3"
                        :disabled="!(newItem.clearance_id)" :value="newItem.container_id"
                        @input="newItem.container_id = $event" label="container number" :multi="false" :required="false"
                        selected_label="container_number" selected_prop="id" :isLoading="getContainerLoading"
                        :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="textarea" solo :value="newItem.arabic_description" :rows="5"
                        @input="newItem.arabic_description = $event" label="description" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                     <v-col cols="12">
                        <v-btn outlined style="background-color:  #e6ecff ;" class="d-block mi-start-auto"
                           :disabled="!(validItem && newItem.amount)" depressed color="blue2" :min-width="120"
                           @click="addItem" :height="37">
                           {{ $t('add line') }}
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-card>
            </v-form>
         </v-expand-transition>
         <!-- Details Table -->
         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="tableRows || []" :footerData="[totalsCalculations]"
               :header="tableHeader" :deleteValueMethod="removeItem"
               :tableHeight="$store.state.Settings.windowSize.y - 230" />
         </div>

         <!-- End of Form -->
      </v-container>


   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
export default {
   name: "PaymentVoucherControl",
   data: () => ({
      pageData: {
         screen_code: "02-008",
         url: null,
         controlRoute: "/accounting/payment_voucher-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      formCollapse: false,
      viewForm: false,
      valid: false,
      validItem: false,
      getContainerLoading: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      daily_types: [],
      account_trees: [],
      cost_centers: [],
      delegates: [],
      clearances: [],
      containers: [],
      covenant_types: [],
      form: {
         action_date: null,
         daily_type_id: null,
         arabic_description: null,
         items: [],
      },
      newItem: {
         pay_for: 1,
         delegate_id: null,
         cost_center_id: null,
         account_tree_id: null,
         clearance_id: null,
         container_id: null,
         arabic_description: null,
         amount: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
      DynamicTable,
   },
   computed: {
      totalsCalculations() {
         if (this.tableRows) {
            const totalAmount = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.amount);
            }, 0);
            return {
               pay_for_title: this.$i18n.t('total'),
               amount: totalAmount,
            }
         } else {
            return {
               pay_for_title: this.$i18n.t('total'),
               amount: 0,
            }
         }
      },
      filter_delegates() {
         switch (this.newItem.pay_for) {
            case 1:
               return this.$global.FilterArrayOfObject(this.delegates, 'covenant_type_id', this.newItem.pay_for);
            case 2:
               return this.$global.FilterArrayOfObject(this.delegates, 'covenant_type_id', this.newItem.pay_for);
            default:
               return []
         }
      }
   },
   watch: {
      "pageData.isEdit"() {
         if (this.pageData.isEdit) {
            this.tableHeader = [
            { text: "pay for", key: "pay_for_title", type: 'text', classes: "" },
            { text: "delegate", key: "delegate_name", type: 'text', classes: "" },
            { text: "account number", key: "account_number", type: 'text', classes: "" },
            { text: "account", key: "account_name", type: 'text', classes: "" },
            { text: "cost center", key: "cost_center_name", type: 'text', classes: "" },
            { text: "clearance", key: "clearance_id", type: 'text', classes: "" },
            { text: "container number", key: "container_number", type: 'text', classes: "" },
            { text: "amount", key: "amount", type: 'float', classes: "" },
            { text: "description", key: "arabic_description", type: 'text', classes: "" },
         ];
         }

      },
      "newItem.pay_for"() {
         if (!this.pageData.isEdit) {
            this.newItem.container_id = null;
            this.newItem.clearance_id = null;
            this.newItem.account_tree_id = null;
            this.newItem.delegate_id = null;
            this.newItem.cost_center_id = null;
         }
      },
      "newItem.clearance_id"() {
         this.newItem.container_id = null;
         if (this.newItem.clearance_id && this.newItem.pay_for == 3) {
            this.getClearanceContainers();
         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            row.pay_for_title = row.pay_for ? this.$global.FilterArrayOfObjectElement(this.covenant_types, 'id', row.pay_for, 'name') : null;
            row.delegate_name = row.delegate_id ? this.$global.FilterArrayOfObjectElement(this.delegates, 'id', row.delegate_id, 'name') : null;
            row.account_name = row.account_tree_id ? this.$global.FilterArrayOfObjectElement(this.account_trees, 'id', row.account_tree_id, 'name') : null;
            row.account_number = row.account_tree_id ? this.$global.FilterArrayOfObjectElement(this.account_trees, 'id', row.account_tree_id, 'name') : null;
            row.cost_center_name = row.cost_center_id ? this.$global.FilterArrayOfObjectElement(this.cost_centers, 'id', row.cost_center_id, 'name') : null;
            row.container_number = row.container_id ? this.$global.FilterArrayOfObjectElement(this.containers, 'id', row.container_id, 'container_no') : null;
         });
      }
   },
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "pay for", key: "pay_for_title", type: 'text', classes: "" },
            { text: "delegate", key: "delegate_name", type: 'text', classes: "" },
            { text: "account number", key: "account_number", type: 'text', classes: "" },
            { text: "account", key: "account_name", type: 'text', classes: "" },
            { text: "cost center", key: "cost_center_name", type: 'text', classes: "" },
            { text: "clearance", key: "clearance_id", type: 'text', classes: "" },
            { text: "container number", key: "container_number", type: 'text', classes: "" },
            { text: "amount", key: "amount", type: 'float', classes: "" },
            { text: "description", key: "arabic_description", type: 'text', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },
         ];


      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`payment_voucher/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.account_trees = response.data.account_trees || [];
                     this.cost_centers = response.data.cost_centers || [];
                     this.daily_types = response.data.daily_types || [];
                     this.delegates = response.data.delegates || [];
                     this.clearances = response.data.clearances || [];
                     this.containers = response.data.containers || [];
                     this.form = response.data.master;
                     this.tableRows = response.data.details || [];
                     this.covenant_types = [{ id: 1, name: this.$i18n.t('delegate covenant') },
                     { id: 2, name: this.$i18n.t('maintenance covenant - workshop') },
                     { id: 3, name: this.$i18n.t('account') }]
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`payment_voucher/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.account_trees = response.data.account_trees || [];
                     this.cost_centers = response.data.cost_centers || [];
                     this.daily_types = response.data.daily_types || [];
                     this.delegates = response.data.delegates || [];
                     this.clearances = response.data.clearances || [];
                     this.containers = response.data.containers || [];
                     this.covenant_types = [{ id: 1, name: this.$i18n.t('delegate covenant') },
                     { id: 2, name: this.$i18n.t('maintenance covenant - workshop') },
                     { id: 3, name: this.$i18n.t('account') }]
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`payment_voucher/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.printReport(response.data)
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`payment_voucher`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.printReport(response.data)
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      addItem() {
         this.tableRows.push(this.newItem);
         this.newItem = {
            pay_for: 1,
            delegate_id: null,
            cost_center_id: null,
            account_tree_id: null,
            clearance_id: null,
            container_id: null,
            arabic_description: null,
            amount: null,
         }
      },
      removeItem(item, index) {
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`payment_voucher/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
      getClearanceContainers() {
         this.getContainerLoading = true;
         this.$api.GET_METHOD(`get_clearance_containers/${this.newItem.clearance_id}`).then((response) => {
            this.getContainerLoading = false;
            if (response.check) {
               this.containers = response.data || [];
            }
         })
      },
      showVoucher() {
         this.pageData.printLoading = true;
         const src = `export_journal_data/${this.form.daily_journal_id}?type=pdf`;
         this.$api.DOWNLOAD_METHOD(src, `${this.$i18n.t('payment_voucher')}_${this.form.daily_journal_id}.pdf`).then(() => {
            this.pageData.printLoading = false;
         })
      },
      downloadAttachment() {
         this.printLoading = true;
         this.printID = this.form.m_id;
         const src = `get_daily_journal_attach/${this.form.m_id}`;
         const download_file_name = `${this.$i18n.t('attach')}_${this.$i18n.t('daily journal')}_${this.form.m_id}.${this.form.attach_ext}`
         this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            this.printLoading = false;
         })
      },
      printReport(id) {
         this.$api.PrintPOST('export_payment_voucher', id, 'print').then(() => {})
      }
   },
};
</script>
