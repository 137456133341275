<template>
  <section>
     <v-container fluid>
        <!-- Header -->
        <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
        <!-- End Of Header -->
        <!-- Form -->
        <v-form ref="form" v-model="valid" class="pa-3">
           <v-card class="backgroundW shadow pa-5 pt-7">
              <v-row align="center">

                 <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                    :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                 <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                    :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                 <GenericInput type="select" :lookups="account_trees" :value="form.account_tree_id"
                    @input="form.account_tree_id = $event" label="linked account" :multi="false" :required="true"
                    selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                 </GenericInput>

                 <GenericInput type="select" :lookups="cost_centers" :value="form.cost_center_id"
                    @input="form.cost_center_id = $event" label="cost center" :multi="false" :required="false"
                    selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                 </GenericInput>


                 <!-- <GenericInput type="textarea" :value="form.description" @input="form.description = $event" label="description"
                    :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput> -->
              </v-row>
           </v-card>
        </v-form>
        <!-- End of Form -->
     </v-container>
  </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
  name: "ExpensesCodeControl",
  data: () => ({
     pageData: {
        screen_code: "02-014",
        url: null,
        controlRoute: "/accounting/expense_code-control",
        entityName: null,
        main: null,
        category: null,
        isLoading: true,
        isEdit: false,
        editIsLoading: false,
     },
     valid: false,
     account_trees: [],
     cost_centers: [],
     form: {
        name_en: null,
        name_ar: null,
        // description: null,
        account_tree_id: null,
        cost_center_id: null,
     },
  }),
  components: {
     ControlHeader,
     GenericInput,
  },
  computed: {
  },
  mounted() {
     this.pageMainData()
     this.getData()
  },
  methods: {
     pageMainData() {
        this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
        this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
        this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
        this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
     },
     getData() {
        if (this.$route.params.id) {
           if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
              this.pageData.isEdit = true;
              this.pageData.editIsLoading = true;
              this.pageData.isLoading = true;
              this.$api.GET_METHOD(`expense_code/${this.$route.params.id}`).then((response) => {
                 this.pageData.editIsLoading = false;
                 this.pageData.isLoading = false;
                 if (response.check) {
                    console.log("response", response);
                    this.form = response.data.item;
                    this.account_trees = response.data.account_trees;
                    this.cost_centers = response.data.cost_centers;
                    this.$refs.form.validate();
                 }
              })
           } else {
              this.$router.push('/')
           }
        }
        else {
           if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
              this.pageData.isLoading = true;
              this.$api.GET_METHOD(`expense_code/create`).then((response) => {
                 this.pageData.isLoading = false;
                 if (response.check) {
                    this.account_trees = response.data.account_trees;
                    this.cost_centers = response.data.cost_centers;
                 }
              })
           } else {
              this.$router.push('/')
           }
        }
     },
     save() {
        if (this.$refs.form.validate()) {
           this.pageData.isLoading = true;
           if (this.pageData.isEdit == true) {
              this.form._method = 'PUT';
              this.$api.POST_METHOD(`expense_code/${this.form.id}`, this.form).then((response) => {
                 if (response.check) {
                    this.pageData.isLoading = false;
                    this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                 }
              })
           }
           else {
              this.$api.POST_METHOD(`expense_code`, this.form).then((response) => {
                 if (response.check) {
                    this.pageData.isLoading = false;
                    this.$router.push(this.pageData.url)
                 }
              })
           }
        }
     },
     deleteMethod(status) {
        if (status == true) {
           this.pageData.isLoading = true
           this.$api.POST_METHOD(`expense_code/${this.form.id}`, { _method: 'delete' }).then(() => {
              this.$router.push(this.pageData.url);
              this.pageData.isLoading = false
           })
        }
     },
  },
};
</script>