import Vue from "vue";
import vuetify from "vuetify/lib/framework";
import '@mdi/font/css/materialdesignicons.css'; 
Vue.use(vuetify);

export default new vuetify({
  rtl: localStorage.getItem("language") == "en" ? false : true,
  lang: {
    // current: localStorage.getItem("language") == "en" ? en : ar,
    //  current: "ar",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: localStorage.getItem("darkTheme") == "true" ? true : false,
    customProperties: true,
    themes: {
      light: {
        primary: "#37b0ef",
        secondary: "#717377",
        accent: "#334d6e",
        error: "#f65354",
        info: "#3363ff",
        success: "#1bd2a4",
        warning: "#e6c84c",
        backgroundD: "#1e1e1e",
        backgroundW: "#FFFFFF",
        light: "#F5F6F8",

        // prototype colors:
        rowUp: "#daf3ea",
        rowDown: "#f8d0ce",

        gray1:  "#0f0d15",
        gray2:  "#1d1929",
        gray3:  "#34303e",
        gray4:  "#4a4754",
        gray5:  "#615e69",
        gray6:  "#77757f",
        gray7:  "#8e8c94",
        gray8:  "#a5a3a9",
        gray9:  "#bbbabf",
        gray10: "#d2d1d4",
        gray11: "#e8e8ea",
        gray12: "#f9f9f9",

        blue1:  "#0030cc",
        blue2:  "#0036e6",
        blue3:  "#003cff",
        blue4:  "#1a50ff",
        blue5:  "#3363ff",
        blue6:  "#4d77ff",
        blue7:  "#668aff",
        blue8:  "#809eff",
        blue9:  "#99b1ff",
        blue10: "#b3c5ff",
        blue11: "#ccd8ff",
        blue12: "#e6ecff",

        green1:  "#18ba92",
        green2:  "#1bd2a4",
        green3:  "#1ee9b6",
        green4:  "#35ebbd",
        green5:  "#4bedc5",
        green6:  "#62f0cc",
        green7:  "#78f2d3",
        green8:  "#8ff4db",
        green9:  "#a5f6e2",
        green10: "#bcf8e9",
        green11: "#d2fbf0",
        green12: "#e9fdf8",

        yellow1:  "#ccb243",
        yellow2:  "#e6c84c",
        yellow3:  "#ffde54",
        yellow4:  "#ffe165",
        yellow5:  "#ffe576",
        yellow6:  "#ffe887",
        yellow7:  "#ffeb98",
        yellow8:  "#ffefaa",
        yellow9:  "#fff2bb",
        yellow10: "#fff5cc",
        yellow11: "#fff8dd",
        yellow12: "#fffcee",

        red1:  "#c32021",
        red2:  "#dc2425",
        red3:  "#f42829",
        red4:  "#f53e3e",
        red5:  "#f65354",
        red6:  "#f76969",
        red7:  "#f87e7f",
        red8:  "#f87e7f",
        red9:  "#fba9a9",
        red10: "#FCBFBF",
        red11: "#FDD4D4",
        red12: "#feeaea",

        orange5: "#ff8b34",
      },
      dark: {
        primary: "#45ade2",
        secondary: "#336cfb",
        accent: "#334d6e",
        error: "#f65354",
        info: "#3363ff",
        success: "#1bd2a4",
        warning: "#e6c84c",
        backgroundD: "#fff",
        backgroundW: "#1e1e1e",
        light: "#181818",
        // prototype colors:
        rowUp: "#daf3ea",
        rowDown: "#f8d0ce",

        gray1: "#0f0d15",
        gray2: "#1d1929",
        gray3: "#34303e",
        gray4: "#4a4754",
        gray5: "#615e69",
        gray6: "#77757f",
        gray7: "#8e8c94",
        gray8: "#a5a3a9",
        gray9: "#bbbabf",
        gray10: "#d2d1d4",
        gray11: "#e8e8ea",
        gray12: "#f9f9f9",

        blue1: "#0030cc",
        blue2: "#0036e6",
        blue3: "#003cff",
        blue4: "#1a50ff",
        blue5: "#3363ff",
        blue6: "#4d77ff",
        blue7: "#668aff",
        blue8: "#809eff",
        blue9: "#99b1ff",
        blue10: "#b3c5ff",
        blue11: "#ccd8ff",
        blue12: "#e6ecff",

        green1: "#18ba92",
        green2: "#1bd2a4",
        green3: "#1ee9b6",
        green4: "#35ebbd",
        green5: "#4bedc5",
        green6: "#62f0cc",
        green7: "#78f2d3",
        green8: "#8ff4db",
        green9: "#a5f6e2",
        green10: "#bcf8e9",
        green11: "#d2fbf0",
        green12: "#e9fdf8",

        yellow1: "#ccb243",
        yellow2: "#e6c84c",
        yellow3: "#ffde54",
        yellow4: "#ffe165",
        yellow5: "#ffe576",
        yellow6: "#ffe887",
        yellow7: "#ffeb98",
        yellow8: "#ffefaa",
        yellow9: "#fff2bb",
        yellow10: "#fff5cc",
        yellow11: "#fff8dd",
        yellow12: "#fffcee",

        red1: "#c32021",
        red2: "#dc2425",
        red3: "#f42829",
        red4: "#f53e3e",
        red5: "#f65354",
        red6: "#f76969",
        red7: "#f87e7f",
        red8: "#f87e7f",
        red9: "#fba9a9",
        red10: "#FCBFBF",
        red11: "#FDD4D4",
        red12: "#feeaea",

        orange5: "#ff8b34",
      },
    },
  },
});
