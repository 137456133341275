<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="1200">
      <v-card relative :key="selectedRow.id">
         <v-card class="backgroundW shadow pa-5 pt-7">
            <v-row>
               <v-col cols="6" class="pt-0">
                  <div class="subtitle-1 font-weight-bold d-flex align-center">
                     <span class="mx-2">
                        {{ $t('Clearance') }}
                        <span class="gray5--text" v-if="selectedRow.id"> ( {{ selectedRow.id }} )</span>
                        <v-chip v-if="selectedRow.id && selectedRow.status_name" small color="primary" class="mx-2">
                           {{ selectedRow.status_name }}
                        </v-chip>
                        <v-chip v-if="selectedRow.id && selectedRow.has_problem" small color="error" class="mx-2">
                           {{ $t('has problem') }}
                        </v-chip>
                     </span>
                  </div>
               </v-col>
               <v-col cols="6" class="d-flex justify-end pt-0">
                  <v-btn text depressed color="error" :min-width="120" @click="closeDialog" :height="37">
                     <v-icon left>mdi-close</v-icon>
                     {{ $t('close') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="viewComments" depressed color="gray3" :loading="commentsLoading"
                     :height="37" :width="120">
                     {{ $t('comments') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text"
                     v-if="$global.CheckAction('04-004', 3) && selectedRow.clearance_status_id !== 6" @click="edit"
                     depressed color="primary" :loading="isLoading" :height="37" :width="120">
                     {{ $t('edit') }}
                  </v-btn>
               </v-col>
               <v-col cols="12" class="px-9 py-0">
                  <v-tabs v-model="tab">
                     <v-tab>
                        {{ $t('Clearance') }}
                     </v-tab>
                     <v-tab :disabled="!(master.final_note_file || master.customs_declaration_file || attachments.length)">
                        {{ $t('attachments') }}
                     </v-tab>
                  </v-tabs>
               </v-col>
               <v-col cols="12" class="py-0">
                  <v-tabs-items v-model="tab">
                     <v-tab-item>
                        <ControlSummery :summery="summery" :loading="isLoading">
                           <template v-slot:value="{ item }">
                              <span v-if="item.key == 'invoices'">
                                 <span v-for="(invoice, index) in item.value"
                                    @click="$router.push(`/sales/clearance_invoice-control?clearance_invoice_id=${invoice}`)"
                                    class="mx-1 cursor_pointer text-decoration-underline primary--text" :key="index">
                                    {{ invoice }}
                                 </span>
                              </span>
                           </template>
                        </ControlSummery>

                        <v-col cols="12">
                           <v-card class="shadow-none pa-0 overflow-y-auto"
                              :max-height="$store.state.Settings.windowSize.y - 450">
                              <DynamicTable :isLoading="isLoading" v-if="tableRows.length" :data="tableRows || []"
                                 :header="tableHeader" :tableHeight="$store.state.Settings.windowSize.y - 450">
                              </DynamicTable>
                           </v-card>
                        </v-col>
                     </v-tab-item>
                     <!-- Attachments -->
                     <v-tab-item>
                        <v-col cols="12"
                           v-if="master.final_note_file || master.customs_declaration_file || attachments.length">
                           <v-card class="shadow-none pa-0 overflow-x-hidden overflow-y-auto"
                              :max-height="$store.state.Settings.windowSize.y - 280">
                              <v-row align="center">


                                 <GalleryFiles :attachments="attachments" width="280" height="280"
                                    downloadEndPoint="clearance_download_file" deleteEndPoint="clearance_delete_file">
                                    <template v-slot:top>
                                       <v-col cols="12" md="6" lg="4" v-if="master.customs_declaration_file" class="pa-0">
                                          <!-- <v-card height="280" class="overflow-hidden rounded-lg shadow pa-2">
                                             <div class=" my-2">
                                                <iframe width='100%' height='200'
                                                   :src="$api.serverUrl + master.customs_declaration_file" />
                                             </div>
                                             <div class="text-center subtitle-1">{{ $t('customs declaration file') }}</div>
                                          </v-card> -->

                                          <GalleryFiles
                                             :attachments="[{ file_path: master.customs_declaration_file, file_name: $t('customs declaration file') }]"
                                             :showDelete="false" :hideDownload="true" width="280" height="280">
                                          </GalleryFiles>
                                       </v-col>
                                       <v-col cols="12" md="6" lg="4" v-if="master.final_note_file" class="pa-0">
                                          <!-- <v-card height="280" class="overflow-hidden rounded-lg shadow pa-2">
                                             <div class=" my-2">
                                                <iframe width='100%' height='200'
                                                   :src="$api.serverUrl + master.final_note_file" />
                                             </div>
                                             <div class="text-center subtitle-1">{{ $t('final statement') }}</div>
                                          </v-card> -->

                                          <GalleryFiles
                                             :attachments="[{ file_path: master.final_note_file, file_name: $t('final statement') }]"
                                             :showDelete="false" :hideDownload="true" width="280" height="280">
                                          </GalleryFiles>
                                       </v-col>
                                    </template>
                                 </GalleryFiles>



                                 <v-col cols="12" md="6" lg="4" v-show="false" v-for="(attach, index) in attachments"
                                    :key="index">
                                    <v-hover v-slot="{ hover }">
                                       <v-card height="280" class="overflow-hidden rounded-lg shadow pa-2">
                                          <div class=" my-2" :class="{ 'opacity-015': hover }">
                                             <div v-lazy-container="{ selector: 'img' }"
                                                v-if="attach.file_type == 'jpeg' || attach.file_type == 'jpg' || attach.file_type == 'png' || attach.file_type == 'gif' || attach.file_type == 'svg'">
                                                <img class="d-block ma-auto" style="height: 200px;" :key="index"
                                                   :data-src="String(attach.file_path).substring(0, 10) == 'data:image' ? attach.file_path : $api.serverUrl + attach.file_path"
                                                   alt="qarat" />
                                             </div>

                                             <div v-else>
                                                <img v-if="attach.file_type !== 'pdf'"
                                                   :src="require(`@/assets/img/png/files/${attach.file_type}.png`)"
                                                   height="200" class="d-block ma-auto" alt="qarat" />
                                                <div v-if="attach.file_type == 'pdf'">
                                                   <iframe width='100%' height='200'
                                                      :src="String(attach.file_path).substring(0, 20) == 'data:application/pdf' ? attach.file_path : $api.serverUrl + attach.file_path" />
                                                </div>

                                             </div>
                                          </div>
                                          <div class="text-center subtitle-1" :class="{ 'd-none': hover }">
                                             {{ attach.file_name }}</div>

                                          <div class="absolute-center justify-end px-5" :class="{ 'd-none': !hover }">
                                             <v-btn icon color="primary" class="mx-3" large
                                                @click="downloadAttachment(attach)" :loading="attachDownloadLoading">
                                                <v-icon size="40">mdi-download</v-icon>
                                             </v-btn>
                                          </div>
                                       </v-card>
                                    </v-hover>
                                 </v-col>
                              </v-row>
                           </v-card>
                        </v-col>
                     </v-tab-item>
                  </v-tabs-items>
               </v-col>




            </v-row>
         </v-card>
      </v-card>

      <ChatDialog :dialog="commentsDialog" :chat="comments" :isLoading="commentsLoading" :saveMessage="addComment"
         :closeDialog="() => commentsDialog = false" />
   </v-dialog>
</template>

<script>
import ControlSummery from '@/components/ui/ControlSummery.vue';
import DynamicTable from '@/components/DynamicTable.vue';
import ChatDialog from '@/components/ui/ChatDialog.vue';
import GalleryFiles from '@/components/GalleryFiles.vue';
export default {
   name: "ClearanceSummeryDialog",
   props: {
      closeDialog: { type: Function },
      dialog: { default: false },
      id: { default: 0 },
      selectedRow: { default: Object },
   },
   computed: {

   },
   components: {
      ControlSummery,
      DynamicTable,
      ChatDialog,
      GalleryFiles
   },
   watch: {
      dialog() {
         this.tab = 0;
         this.attachDownloadLoading = false;
         this.isLoading = false;
         this.master = Object;
         this.summery = [];
         this.tableRows = [];
         this.attachments = [];
         this.getClearance()
      },
   },
   data: () => ({
      tab: 0,
      isLoading: false,
      attachDownloadLoading: false,
      master: Object,
      summery: [],
      attachments: [],
      tableRows: [],
      tableHeader: [],
      commentsDialog: false,
      commentsLoading: false,
      comments: [],
   }),
   methods: {
      edit() {
         this.$router.push(`/sales/clearance-control/${this.id}`);
      },
      getClearance() {
         if (this.dialog && this.id) {
            this.isLoading = true;
            this.$api.GET_METHOD(`clearance/${this.id}`).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  const types = [{ id: 1, name: this.$i18n.t('international') },
                  { id: 2, name: this.$i18n.t('regional') },]
                  this.sizes = [{ id: 40, name: 40 },
                  { id: 20, name: 20 },]
                  response.data.master.invoices = response.data.master.invoices ? response.data.master.invoices.split(",").map((i) => +i) : [];
                  this.master = response.data.master;
                  this.summery = [
                     {
                        label: 'action date',
                        value: response.data.master.action_date,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'add date',
                        value: response.data.master.add_date,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'old transaction number',
                        value: response.data.master.old_sys_id,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'customer',
                        value: response.data.master.customer_name,
                        class: '',
                        cols: null, sm: null, md: null, lg: 6, xl: null,
                     },
                     {
                        label: 'delegate',
                        value: response.data.master.delegate_name,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'shipping policy number',
                        value: response.data.master.shipping_policy_no,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'shipping policy type',
                        value: types.find(type => type.id == response.data.master.clearance_type).name,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'port',
                        value: response.data.master.port_name,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'LCL',
                        value: response.data.master.is_lcl ? this.$i18n.t('yes') : this.$i18n.t('no'),
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'count container size 20',
                        value: response.data.master.container_small || 0,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'count container size 40',
                        value: response.data.master.container_big || 0,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'manifest date',
                        value: response.data.master.mainfest_date,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'manifest no',
                        value: response.data.master.mainfest_no,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'declaration date',
                        value: response.data.master.customs_declaration_date,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'customs declaration number',
                        value: response.data.master.customs_declaration_number,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'unloading date',
                        value: response.data.master.unloading_date,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'port numbers',
                        value: response.data.master.port_withdraw,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'inspection no',
                        value: response.data.master.inspection_number,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'withdrawal number',
                        value: response.data.master.withdrawal_note,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'clearance invoice',
                        value: response.data.master.invoices,
                        key: 'invoices',
                        type: 'slot',
                        class: response.data.master.invoices.length ? '' : 'd-none',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },
                     {
                        label: 'floor date',
                        value: response.data.master.customs_floor_date,
                        class: '',
                        cols: null, sm: null, md: null, lg: 3, xl: null,
                     },

                  ]
                  this.tableRows = response.data.details || [];
                  this.attachments = response.data.files || [];
               }
            })
         }
      },
      downloadAttachment(attach) {
         this.attachDownloadLoading = true
         var src = `clearance_download_file/${attach.id}`;
         var download_file_name = `${attach.file_name}.${attach.file_type}`
         this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            this.attachDownloadLoading = false;
         })
      },
      viewComments() {
         this.commentsLoading = true;
         this.$api.GET_METHOD(`clearance_get_comment/${this.id}`).then((response) => {
            this.commentsLoading = false;
            if (response.check) {
               this.comments = response.data.items;
               this.commentsDialog = true;
            }
         })
      },
      addComment(comment) {
         this.commentsLoading = true;
         this.$api.POST_METHOD(`clearance_add_comment/${this.id}`, { comment: comment }).then((response) => {
            this.commentsLoading = false;
            if (response.check) {
               this.viewComments(this.id);
            }
         });
      },
   },
   mounted() {
      this.getClearance();
      this.tableHeader = [
         { text: "#", key: "index", type: 'index', classes: "" },
         { text: "size", key: "container_size", type: 'text', classes: "" },
         { text: "container number", key: "container_number", type: 'text', classes: "" },
      ]
   },
};
</script>
