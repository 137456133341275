<template>
  <label for="dropzoneFile" class="dropzone" :class="{ 'active-dropzone': active }"
    :style="`height:${height};width:${width}`" @dragenter.prevent="toggleActive" @dragleave.prevent="toggleActive"
    @dragover.prevent @drop.prevent="dropFile" @change="dropFile">
    <div class="d-flex flex-column justify-center align-center" v-if="(!previewInnerImgs || !filesArray.length)">
      <div class="text-body-2">
        {{ $t('drag the file and place it here') | capitalize }}
      </div>
      <v-btn outlined color="primary" class="upload_btn d-block mt-3">
        <label id="removeStyle" for="dropzoneFile">{{ $t('upload from your device') }}</label>
      </v-btn>
    </div>
    <input ref="input" type="file" id="dropzoneFile" class="dropzoneFile" name="files" :multiple="multi">

    <div v-show="previewInnerImgs && filesArray.length">
        <GalleryFiles @click.prevent :attachments="filesArray" :deleteMethod="deleteItem" />
    </div>

  </label>
</template>



<script>
// import GalleryFiles from '@/components/GalleryFiles.vue';
// import GalleryFiles from '../../src/components/GalleryFiles.vue';
import GalleryFiles from '../../src/components/GalleryFiles.vue';


export default {
  components: { GalleryFiles },
  name: "DropZone",
  data: () => ({
    active: false,
    files: null,
    previewArray: [],
    filesArray: [],
  }),
  props: {
    multi: { default: false },
    height: { default: 100 },
    width: { default: 100 },
    imgWidth: { default: 145 },
    imgHight: { default: 145 },
    pdfWidth: { default: 145 },
    pdfHight: { default: 145 },
    previewInnerImgs: { default: true },
    backDataType: { default: 'normal' },
    backDataMethod: { default: Function },
    previewMethod: { default: Function },
    dropZoneFilesLength: { default: Function },
    deleteIndex: { default: Number },
  },
  methods: {
    toggleActive() {
      this.active = !this.active
    },

    dropFile($event) {
      if ($event.type === 'drop') {
        this.active = !this.active;
        this.files = $event.dataTransfer.files;
      } else if ($event.type === 'change') {
        this.files = document.querySelector(".dropzoneFile").files;
      }
      this.dropZoneFilesLength(this.files.length)
      for (const property in this.files) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.files.hasOwnProperty(property) && typeof this.files[property] === 'object') {
          this.filesArray.push(this.files[property]);
          console.log("this.filesArray", this.filesArray);
          // Create a FileReader instance for each file
          const reader = new FileReader();
          reader.onload = () => {
            // Assign the Data URL to a variable for previewing
            this.previewArray.push(reader.result);
            this.previewMethod(this.previewArray)

            if (this.files.length - 1 == property) {
              if (this.backDataType === 'normal' && this.multi) {
                this.backDataMethod(this.filesArray);
              } else if (this.backDataType === 'normal' && !this.multi) {
                this.backDataMethod(this.filesArray.at(-1));
              } else if (this.backDataType === 'base64' && this.multi) {
                this.backDataMethod(this.previewArray);
              } else if (this.backDataType === 'base64' && !this.multi) {
                this.backDataMethod(this.previewArray.at(-1));
              }
            }
          };

          // Read the file as a Data URL
          reader.readAsDataURL(this.files[property]);
        }
      }
    },
    deleteItem(index) {
      console.log('index', index);
      this.previewArray.splice(index, 1)
      this.filesArray.splice(index, 1)
    },
  },
  watch: {
    deleteIndex() {
      this.deleteItem(this.deleteIndex)
    }
  },
  computed: {
    isPdf() {
      return (data) => data.substring(5, 20) === 'application/pdf'
    },
    lastArrItem() {
      return this.previewArray[this.previewArray.length - 1]
    }
  },
  mounted() {

  },
};
</script>


<style scoped lang="scss">
.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dashed var(--primary);
  transition: 0.3s ease-in-out all;
  border-radius: 9px;
  cursor: pointer;

  label {
    padding: 8px 16px;
    color: #fff;
    background: var(--primary);
  }

  #dropzoneFile {
    display: none;
  }
}

.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: var(--primary);

  label {
    padding: 8px 16px;
    color: var(--primary);
    background: #fff;
  }

  .upload_btn{
    color: #fff !important; 
  }
}

.card {
  position: relative;
  border-radius: 8px;

}

.card .layer {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s;
  border-radius: 8px;
}

.card:hover .layer {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 1;
  font-size: 20px;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s;
  border-radius: 8px;
}

.card .layer div:nth-child(1) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .v-icon {
    cursor: pointer;
  }
}

#removeStyle {
  cursor: pointer;
  padding: unset;
  color: unset;
  background: unset;
}
</style>