<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row v-if="viewAnalysis">
        <v-col cols="12" sm="12">
          <v-card class="pa-7 shadow">
            <v-row align="center">
              <v-col cols="12" md="6">
                <div class="font-weight-bold gray5--text">
                  {{ $t('current year') | capitalize }}
                </div>
                <v-row class="px-10 py-5" align="center">
                  <v-col cols="12" class="py-1">
                    <div class="gray5--text">
                      {{ $t('financial year') | capitalize }}
                    </div>
                  </v-col>
                  <v-col cols="4" class="py-1">
                    <div class="gray8--text body-2">
                      {{ $t('from') | capitalize }}
                    </div>
                    <h4 class="text-h5 font-weight-medium">
                      01-01-2022
                    </h4>
                  </v-col>
                  <v-col cols="4" class="py-1">
                    <div class="gray8--text body-2">
                      {{ $t('to') | capitalize }}
                    </div>
                    <h4 class="text-h5 font-weight-medium">
                      25-05-2022
                    </h4>
                  </v-col>
                  <v-col cols="4" class="py-1">
                    <v-btn depressed outlined @click="viewAnalysis = !viewAnalysis" color="blue2">
                      {{ $t("year details") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <div class="gray5--text body-2">
                      256 {{ $t('days left to be closed automatic') | capitalize }}
                    </div>
                  </v-col>
                  <v-col cols="6" class="py-1 mt-2 d-flex">
                    <span class="success--text">30%</span>
                    <div class="font-weight-bold body-1 mx-1">
                      {{ $t('revenues') | capitalize }}
                    </div>
                  </v-col>
                  <v-col cols="6" class="py-1 mt-2 d-flex">
                    <span class="success--text">20%</span>
                    <div class="font-weight-bold body-1 mx-1">
                      {{ $t('expenses') | capitalize }}
                    </div>

                  </v-col>
                  <v-col cols="12" class="py-0">
                    <div class="gray5--text body-2">
                      <small> {{ $t('compare from last year') | capitalize }}</small>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" class="d-flex align-center">
                <v-col cols="6">
                  <v-card color="#ecfdf9" class="pa-7 elevation-0">
                    <div class="text-center text-h6 green1--text">
                      {{ $t('profet') | capitalize }}
                    </div>
                    <div class="text-center font-weight-bold body-1 green1--text">
                      {{ 156468 | float }}
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="6">
                   <v-card color="blue12" class="pa-7 elevation-0">
                    <div class="text-center text-h6 blue2--text">
                      {{ $t('revenues') | capitalize }}
                    </div>
                    <div class="text-center font-weight-bold body-1 blue2--text">
                      {{ 156468 | float }}
                    </div>
                  </v-card>
                   <v-card color="red12" class="pa-7 mt-3 elevation-0">
                    <div class="text-center text-h6 red3--text">
                      {{ $t('expenses') | capitalize }}
                    </div>
                    <div class="text-center font-weight-bold body-1 red3--text">
                      {{ 156468 | float }}
                    </div>
                  </v-card>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!viewAnalysis">
        <v-col cols="12" sm="12">
          <DataTableVue :isLoading="pageData.isLoading" :options="pageData.options" :data="pageData.rows"
            :header="pageData.tableHeader" :backValueMethod="setClickRow" :editValueMethod="edit"
            :deleteValueMethod="deleteMethod" deleteProperty="fp_id" :restoreValueMethod="restoreMethod"
            :pageData="pageData"></DataTableVue>

        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && !viewAnalysis">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
import DataTableVue from "../../../components/DataTable.vue";
import Pagination from "../../../components/ui/Pagination.vue";
import EntityHeader from "../../../components/ui/EntityHeader.vue";
// import SkeletonTableLoader from "../../../components/ui/SkeletonTableLoader.vue";
export default {
  name: "FinancialPeriods",

  data: () => ({
    pageData: {
      screen_code: "02-009",
      url: null,
      controlRoute: "accounting/financial_period-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    viewAnalysis: false
  }),
  components: {
    DataTableVue,
    Pagination,
    EntityHeader
  },
  computed: {
    ...mapState(["favourites"]),
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    ...mapActions(["addFavourites"]),

    addToFav() {
      const fav = {
        screen_code: this.pageData.screen_code,
        name: this.pageData.entityName,
        url: this.pageData.url,
      };
      this.addFavourites(fav).finally(() => { });
    },

    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        {
          text: "#",
          sortable: true,
          value: "fp_id",
        },
        {
          text: this.$i18n.t("date of the period"),
          sortable: true,
          value: "finance_year_name",
        },
        {
          text: this.$i18n.t("created_at"),
          sortable: true,
          value: "add_date",
        },
        {
          text: this.$i18n.t("closing of the fiscal year"),
          sortable: true,
          value: "finance_year_name",
        },
        {
          text: this.$i18n.t("opening restriction"),
          sortable: true,
          value: "add_date",
        },

        { text: this.$i18n.t("actions"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `financial_period?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted financial periods")
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      console.log("page");
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api
        .POST_METHOD(`financial_period/${id}`, { _method: "delete" })
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    restoreMethod(row) {
      this.$api
        .POST_METHOD(`financial_period_toggle_active/${row.fp_id}`, null)
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    setClickRow() {
      // this.$router.push(`/main/financial_period/${row.fp_id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.fp_id);
    },
  },
};
</script>
