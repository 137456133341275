<template>
    <v-container class="d-flex flex-wrap flex-row-reverse align-center text-start" :key="deleteRefresher"
        :class="{ 'justify-space-between': attachments?.length >= 3 }">
        <slot name="top"></slot>

        <!-- Start Preview Cards -->
        <div class="card order-2 ma-2" v-for="(arr, i) in attachments" :key="i">
            <div class="layer">
                <div class="icons">
                    <v-icon color="white" v-if="showDelete"
                        @click.prevent="deleteMethod(arr, i)">mdi-trash-can-outline</v-icon>
                    <v-icon color="white" @click.prevent="openInNewWindow(arr)">mdi mdi-open-in-new</v-icon>
                    <v-icon color="white" v-if="!arr?.not_valid && !hideDownload"
                        @click.prevent="downloadItem(arr)">mdi-download-outline</v-icon>
                    <v-icon color="white" @click.prevent="magnifyItem(i)">mdi-magnify-plus-outline</v-icon>
                </div>
            </div>

            <!-- iframe -->
            <iframe v-if="FILE_TYPE(arr?.file_url || arr?.file_path || arr) === 'pdf'"
                :src="resolveFileDisplay(arr?.file_url || arr?.file_path || arr)" :width="pdfWidth || width"
                :height="pdfHight || height" frameborder="0"></iframe>
            <!-- iframe -->

            <!-- image -->
            <v-img v-if="!isNotImg(arr?.file_url || arr?.file_path || arr)" :key="refresher" @error="handelErrorImg(arr, i)"
                :src="!arr?.not_valid ? resolveFileDisplay(arr?.file_url || arr?.file_path || arr) : errorImg(arr?.file_url || arr?.file_path || arr)"
                :width="imgWidth || width" :height="imgHight || height" class="ma-3" contain />
            <!-- image -->

            <!-- video -->
            <video v-if="FILE_TYPE(arr?.file_url || arr?.file_path || arr) === 'mp4'" controls :width="imgWidth || width"
                :height="imgHight || height" :src="resolveFileDisplay(arr?.file_url || arr?.file_path || arr)">
            </video>
            <!-- video -->

            <!-- Title -->
            <div class="img_title" :style="`width:${width}px;`" v-if="showTitle">{{ arr?.file_name || arr?.name }}</div>
            <!-- Title -->

        </div>
        <!-- End Preview Cards -->
        <slot name="bottom"></slot>
        <!-- Start dialog -->
        <v-dialog v-model="carouselDialog" min-width="600">
            <v-card-actions class="d-flex justify-end white">
                <v-btn small fab color="grey lighten-1" text @click="closeCarouselDialog">
                    <v-icon color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <template>
                <!-- carousel -->
                <v-carousel height="80dvh" hide-delimiters show-arrows-on-hover v-model="updateIndex">
                    <v-carousel-item v-for="(arr, i) in attachments" :key="i">
                        <v-sheet height="100%" class="d-flex align-center justify-center">
                            <div class="dialog_container ma-0 pa-2" align="center" justify="center">

                                <!-- iframe -->
                                <iframe id="iframe_slider"
                                    v-if="FILE_TYPE(arr?.file_url || arr?.file_path || arr) === 'pdf'"
                                    :src="resolveFileDisplay(arr?.file_url || arr?.file_path || arr)" height="100%"
                                    width="800" frameborder="0"></iframe>
                                <!-- iframe -->

                                <!-- image -->
                                <v-img v-if="!isNotImg(arr?.file_url || arr?.file_path || arr)"
                                    @error="handelErrorImg(arr, i)"
                                    :src="!arr?.not_valid ? resolveFileDisplay(arr?.file_url || arr?.file_path || arr) : errorImg(arr?.file_url || arr?.file_path || arr)"
                                    id="img_slider" class="ma-3" contain />
                                <!-- image -->

                                <!-- video -->
                                <video id="video_slider" v-if="FILE_TYPE(arr?.file_url || arr?.file_path || arr) === 'mp4'"
                                    controls width="58%" :src="resolveFileDisplay(arr?.file_url || arr?.file_path || arr)">
                                </video>
                                <!-- video -->

                                <!-- Title -->
                                <div class="img_title_slide" v-if="showTitle">{{ arr?.file_name || arr?.name }}</div>

                            </div>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
                <!-- End carousel -->
            </template>
        </v-dialog>
        <!-- End dialog -->

        <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog"
            :item="selectedItem?.file_name || selectedItem?.name ? selectedItem?.file_name || selectedItem?.name : null"
            :backValueMethod="deleteValue">
        </DeleteConfirmation>
    </v-container>
</template>

<script>
import DeleteConfirmation from './modals/DeleteConfirmation.vue'


export default {
    name: 'GalleryFiles',
    data: () => ({
        carouselDialog: false,
        deleteDialog: false,
        selectedItem: {},
        deleteIndex: 0,
        updateIndex: 0,
        refresher: 0,
        deleteRefresher: 0,
    }),
    props: {
        imgWidth: { default: null },
        imgHight: { default: null },
        pdfWidth: { default: null },
        pdfHight: { default: null },
        width: { default: 145 },
        height: { default: 145 },
        attachments: { default: Array },
        showTitle: { default: true },
        showDelete: { default: true },
        hideDownload: { default: false },
        deleteEndPoint: { default: String },
        downloadEndPoint: { default: String },
    },
    computed: {
        isNotImg() {
            return (data) => {
                if (this.FILE_TYPE(data) === 'mp4' ||
                    this.FILE_TYPE(data) === 'pdf') {
                    return true;
                }
            };
        },
        errorImg() {
            return (data) => {
                if (this.FILE_TYPE(data) === 'xlsx') {
                    // excel 
                    return require('@/assets/img/svg/microsoft-excel.svg');
                }
                else if (this.FILE_TYPE(data) === 'zip') {
                    // zip
                    return require('@/assets/img/svg/zip.svg');
                }
                else if (this.FILE_TYPE(data) === 'rar') {
                    // rar
                    return require('@/assets/img/svg/RAR.svg');
                }
                else {
                    // error
                    return require('@/assets/img/svg/Broken-Document.svg');
                }
            };
        },
        isPdf() {
            return (data) => data.substring(5, 20) === 'application/pdf';
        },
        resolveFileDisplay() {
            return (file) => {
                // excel
                if (this.FILE_TYPE(file) === 'excel') {
                    return require('@/assets/img/svg/microsoft-excel.svg');
                }
                //  zip
                if (this.FILE_TYPE(file) === 'zip') {
                    return require('@/assets/img/svg/zip.svg');
                }
                //  rar
                if (this.FILE_TYPE(file) === 'rar') {
                    return require('@/assets/img/svg/RAR.svg');
                }
                // handel new files  
                else if (typeof file === 'object') {
                    let url = URL.createObjectURL(file);
                    return url;
                }
                // handel files in edit
                else if (typeof file === 'string') {
                    return this.$api.serverUrl + file;
                }
            };
        },
        FILE_TYPE() {
            return (FILE) => {
                // Base64
                // console.log(FILE);
                if (String(FILE).substring(0, 5) == "data:") {
                    console.log("FILE TYPE =>", FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]);
                    switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        case "application/vnd.ms-excel":
                            return "excel";
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            return "word";
                        case "application/octet-stream":
                            return "rar";
                        case "application/x-zip-compressed":
                            return "zip";
                        case "application/json":
                            return "json";
                        case "application/pdf":
                            return "pdf";
                        case "image/png":
                            return "png";
                        case "image/jpg":
                            return "jpg";
                        case "image/jpeg":
                            return "jpeg";
                        case "text/plain":
                            return "txt";
                        case "image/svg+xml":
                            return "svg";
                        case "image/gif":
                            return "gif";
                        case "video/mp4":
                            return "mp4";
                        case "video/ogg":
                            return "mp4";
                        case "video/quicktime":
                            return "quicktime";
                        case "video/mov":
                            return "mov";
                        default:
                            return "notFound";
                    }
                }
                // Normal
                else {
                    switch (FILE.type) {
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        case "application/vnd.ms-excel":
                            return "excel";
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            return "word";
                        case "application/octet-stream":
                            return "rar";
                        case "application/x-zip-compressed":
                            return "zip";
                        case "application/json":
                            return "json";
                        case "application/pdf":
                            return "pdf";
                        case "image/png":
                            return "png";
                        case "image/jpg":
                            return "jpg";
                        case "image/jpeg":
                            return "jpeg";
                        case "text/plain":
                            return "txt";
                        case "image/svg+xml":
                            return "svg";
                        case "image/gif":
                            return "gif";
                        case "video/mp4":
                            return "mp4";
                        case "video/ogg":
                            return "mp4";
                        case "video/quicktime":
                            return "quicktime";
                        case "video/mov":
                            return "mov";
                        default:
                            if (FILE.name) {
                                var n = FILE.name.lastIndexOf(".");
                                var result = FILE.name.substring(n + 1);
                                return result;
                            }
                            else {
                                var N = FILE.lastIndexOf(".");
                                var RESULT = FILE.substring(N + 1);
                                return RESULT;
                            }
                    }
                }
            };
        }
    },
    methods: {
        closeCarouselDialog() {
            const videoElement = document.getElementById('video_slider');
            if (videoElement && !videoElement.paused) {
                console.log('videoElement', videoElement);
                videoElement.pause();
            }
            this.carouselDialog = false;
        },
        // work if multi = true
        sendArray(data) {
            // case if we upload multiple file
            if (this.dropZoneFilesLength > 1) {
                data.forEach(sendArray => {
                    let getDotIndex = sendArray.name.lastIndexOf('.');
                    let nameFile = sendArray.name.substring(0, getDotIndex);
                    const isAttachmentsExists = this.attachments && this.attachments.find((element) => element.file_name === nameFile);
                    if (!isAttachmentsExists) {
                        const attach = {
                            'file_url': sendArray,
                            'file_name': nameFile,
                        };
                        this.attachments.push(attach);
                    }
                });
            }
            // case if we upload one file
            else if (this.dropZoneFilesLength === 1) {
                let getDotIndex = data[data.length - 1].name.lastIndexOf('.');
                let nameFile = data[data.length - 1].name.substring(0, getDotIndex);
                const one_attach = {
                    'file_url': data[data.length - 1],
                    'file_name': nameFile,
                };
                this.attachments.push(one_attach);
            }
            // rest to start with right length
            this.dropZoneFilesLength = 0;
        },
        // work if multi = false
        returnAttachMethod(Attach) {
            console.log('Attach', Attach);
            const attach = {
                'file_url': Attach.file_path,
                'file_name': Attach.file_name,
            };
            this.attachments.push(attach);
        },
        handelErrorImg(arr, index) {
            arr.not_valid = true;
            this.refresher++;
            console.log('arr,index', arr, index);
        },
        downloadItem(file) {
            if (typeof file.file_url === 'object' || typeof file.file_path === 'object') {
                let url = URL.createObjectURL(file.file_url || file.file_path);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = file.file_name;
                anchor.click();
            }
            else {
                if (this.downloadEndPoint) {
                    this.downloadRequest(file);
                }
                else {
                    this.downloadLiveAttachment(file);
                }
            }
        },
        magnifyItem(index) {
            this.updateIndex = index;
            this.carouselDialog = true;
        },
        deleteValue(status) {
            if (status) {
                if (this.selectedItem.id || this.selectedItem.attachment_id) {
                    this.$api.DELETE_METHOD(`${this.deleteEndPoint}`, this.selectedItem.id || this.selectedItem.attachment_id).then((response) => {
                        if (response.check) {
                            this.attachments.splice(this.deleteIndex, 1);
                            this.deleteRefresher++;
                            this.deleteDialog = false;
                        }
                    });
                }
            } else {
                this.deleteDialog = false;
            }
        },
        deleteMethod(arr, index) {
            this.deleteIndex = index;
            this.selectedItem = arr
            if (!arr?.id) {
                this.attachments.splice(this.deleteIndex, 1);
            } else {
                this.deleteDialog = true;
            }
        },
        downloadRequest(file) {
            this.attachDownloadLoading = true;
            var src = `${this.downloadEndPoint}/${file.id || file.attachment_id}`;
            let dotIndex = file.file_path.lastIndexOf('.');
            let getExt = file.file_path.substring(dotIndex);
            let download_file_name = file.file_name + getExt;
            this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
                this.attachDownloadLoading = false;
            });
        },
        downloadLiveAttachment(file) {
            let imagUrl = this.$api.serverUrl + (file.file_url || file.file_path);
            console.log("imagUrl", imagUrl);
            // Create an image element to load the image
            const img = new Image();
            img.crossOrigin = "anonymous"; // Enable cross-origin requests if necessary
            img.onload = function () {
                // Create a canvas to convert the image to base64
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                // Convert the image to base64
                const fileExtinction = imagUrl.lastIndexOf(".");
                const base64Data = canvas.toDataURL(`image/${imagUrl.slice(fileExtinction + 1)}`); // You can change the format if needed
                // Create a download link for the base64 image
                const anchor = document.createElement("a");
                anchor.href = base64Data;
                var fileName = `${imagUrl.slice(fileExtinction)}`;
                anchor.download = fileName; // Set the desired filename and extension
                // Trigger the download
                anchor.click();
            };
            img.src = imagUrl;
        },
        openInNewWindow(arr) {
            let url;
            if (typeof arr.file === 'object') {
                url = URL.createObjectURL(arr.file);
            }
            else {
                url = this.$api.serverUrl + arr.file_path;
            }
            window.open(url);
        }
    },
    components: { DeleteConfirmation }
}
</script>

<style lang="scss" scoped>
.card {
    position: relative;
    border-radius: 8px;

}

.card .layer {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s;
    border-radius: 8px;
}

.card:hover .layer {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 1;
    font-size: 20px;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s;
    border-radius: 8px;
    z-index: 10;
}

.card .layer div:nth-child(1) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .v-icon {
        cursor: pointer;
    }
}

.dialog_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: auto;
    }
}

.img_title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.upload {
    width: 145px;
    height: 145px;
    border: 2px dotted var(--info);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#img_slider {
    max-height: 412px;
}
</style>