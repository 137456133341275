<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <v-card elevation="0" class="ma-0 pa-0 px-5 transparent rounded-lg">
            <v-row justify="space-between" align="center">
               <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
                  <div class="subtitle-2 text--disabled">
                     <strong>{{ pageData.main }} . {{ pageData.category }} .</strong>
                  </div>
                  <div class="text-h6 mx-2 text-capitalize">
                     <strong>{{ pageData.entityName }}</strong>
                  </div>
               </v-col>
               <v-col cols="12" sm="auto" class="pa-0">
                  <v-row justify="end" align="center">
                     <v-col cols="auto" class="pa-0">
                        <v-tooltip right>
                           <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                 v-bind="attrs"
                                 v-on="on"
                                 @click="addToFav"
                                 class="mx-2"
                                 :color="favourites.some((fav) => fav.url === pageData.controlRoute) ? 'accent' : ''"
                              >mdi-star</v-icon>
                           </template>
                           <span>{{ favourites.some((fav) => fav.url === pageData.controlRoute) ? $t("remove from favourites") : $t("add to favourites") }}</span>
                        </v-tooltip>
                     </v-col>
                     <v-col cols="12" sm="auto">
                        <v-btn
                           @click="deleteDialog = true"
                           v-if="pageData.isEdit == true && $global.CheckAction(pageData.screen_code, 4) && form.active != 0"
                           :loading="pageData.isLoading"
                           class="my-2 mx-2 shadow"
                           color="error"
                        >{{ $t('delete') }}</v-btn>
                        <v-btn
                           @click="save"
                           :loading="pageData.isLoading"
                           :disabled="!valid"
                           class="my-2 mx-2 shadow"
                           color="success"
                        >{{ $t('save') }}</v-btn>
                     </v-col>
                  </v-row>
               </v-col>
            </v-row>
         </v-card>
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput
                     type="text"
                     :value="form.screen_main_title_en"
                     @input="form.screen_main_title_en = $event"
                     label="name_en"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
                  <GenericInput
                     type="text"
                     :value="form.screen_main_title_ar"
                     @input="form.screen_main_title_ar = $event"
                     label="name_ar"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
                  <GenericInput
                     type="dropzone"
                     v-if="pageData.isEdit == true"
                     :value="form.screen_main_image || form.screen_main_image_edit"
                     @input="form.screen_main_image_edit = $event"
                     label="icon"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 12, 12]"
                  ></GenericInput>
                  <GenericInput
                     type="dropzone"
                     v-if="pageData.isEdit == false"
                     :value="form.screen_main_image"
                     @input="form.screen_main_image = $event"
                     label="icon"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 12, 12]"
                  ></GenericInput>
                  <!-- <v-col cols="12" lg="6" v-if="pageData.isEdit == true && form.screen_main_image">
                     <img :src="$api.serverUrl + form.screen_main_image" class="d-block ma-auto" alt="image">
                  </v-col> -->
               </v-row>
            </v-card>
         </v-form>
         <DeleteConfirmation
            v-if="deleteDialog == true"
            :dialog="deleteDialog"
            :item="form.name ? form.name : null"
            :backValueMethod="deleteMethod"
         ></DeleteConfirmation>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
import GenericInput from '../../../components/ui/GenericInput.vue';
import DeleteConfirmation from "../../../components/modals/DeleteConfirmation.vue";
export default {
   name: "MainMenuControlVue",

   data: () => ({
      pageData: {
         screen_code: "01-001",
         url: null,
         controlRoute: "/main/screen_main-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      deleteDialog: false,
      valid: false,
      form: {
         screen_main_title_en: null,
         screen_main_title_ar: null,
         screen_main_image: null,
      },
   }),
   components: {
      GenericInput,
      DeleteConfirmation
   },
   computed: {
      ...mapState(["favourites"]),
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      ...mapActions(["addFavourites"]),
      addToFav() {
         const fav = {
            screen_code: this.pageData.screen_code,
            name: this.$i18n.t('add category'),
            url: this.pageData.controlRoute,
         };
         this.addFavourites(fav).finally(() => { });
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('category') : this.$i18n.t('add category');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_main/${this.$route.params.id}`).then((response) => {
                  if (response.check) {
                     this.pageData.editIsLoading = false;
                     this.pageData.isLoading = false;
                     console.log("response", response);
                     this.form = response.data.data;
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_main/create`).then((response) => {
                  if (response.check) {
                     this.pageData.isLoading = false;
                     this.account_trees = response.data.account_tree;
                     this.branchs = response.data.branchs;
                     this.banks = response.data.banks;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               if (this.form.screen_main_image_edit ) {
                  this.form.screen_main_image = this.form.screen_main_image_edit ;
                  delete this.form.screen_main_image_edit;
               }else{
                    delete this.form.screen_main_image;
                    delete this.form.screen_main_image_edit;
               }
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`screen_main/${this.form.screen_main_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push('/main/screen_main')
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`screen_main`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push('/main/screen_main')
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         this.deleteDialog = false
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`screen_main/${this.form.screen_main_id}`, { _method: 'delete' }).then(() => {
               this.$router.push('/main/screen_main');
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
