<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="12" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object" :printTitle="' '">
               <template v-slot:pdf_content>
                  <div id="trip_title" v-if="printStaticData.print_type == 1">
                     <div><span>{{ $t('equipment interchange') }}</span></div>
                     <div>{{ printStaticData.sub_title }}</div>
                  </div>
                  <div id="trip_title" v-if="printStaticData.print_type == 2">
                     <div><span>{{ $t('equipment') }}</span></div>
                     <div>{{ printStaticData.sub_title }}</div>
                  </div>
                  <table dir="ltr" width="100%" class="w_tans_master_table">
                     <tr>
                        <td>
                           EIR NO
                        </td>
                        <td>
                           {{ printStaticData.code_number }}
                           <span>
                              {{ printStaticData.print_type == 1 ? 'in' : 'out' }}
                           </span>
                        </td>
                        <td>
                           EIR NO
                        </td>
                     </tr>
                     <tr>
                        <td>
                           CONTAINER #
                        </td>
                        <td>
                           {{ printStaticData.container_number }}
                        </td>
                        <td>
                           الحاوية
                        </td>
                     </tr>
                     <tr>
                        <td>
                           SIZE
                        </td>
                        <td>
                           {{ printStaticData.container_size }}
                        </td>
                        <td>
                           مقاس الحاوية
                        </td>
                     </tr>
                     <tr>
                        <td>
                           BL/REF
                        </td>
                        <td>

                        </td>
                        <td>
                           رقم البوليصة
                        </td>
                     </tr>
                     <tr>
                        <td>
                           CLIENT NAME
                        </td>
                        <td>
                           {{ printStaticData.customer_name }}
                        </td>
                        <td>
                           اسم العميل
                        </td>
                     </tr>
                     <tr>
                        <td>
                           {{ printStaticData.print_type == 1 ? 'DATE IN' : 'DATE OUT' }}
                        </td>
                        <td>
                           {{ printStaticData.print_type == 1 ? printStaticData.in_date : printStaticData.out_date }}
                        </td>
                        <td>تاريخ و وقت الخروج</td>
                     </tr>
                     <tr>
                        <td>
                           TRUCK NO
                        </td>
                        <td>
                           {{ printStaticData.print_type == 1 ? printStaticData.in_car_number :
                              printStaticData.out_car_number }}
                        </td>
                        <td>رقم السيارة</td>
                     </tr>
                     <tr>
                        <td>
                           DRIVER NAME
                        </td>
                        <td>
                           {{ printStaticData.print_type == 1 ? printStaticData.in_driver_name :
                              printStaticData.out_driver_name }}
                        </td>
                        <td>اسم السائق</td>
                     </tr>
                     <tr>
                        <td>
                           ID NO
                        </td>
                        <td>
                           {{ printStaticData.print_type == 1 ? printStaticData.in_driver_residency_number :
                              printStaticData.out_driver_residency_number }}
                        </td>
                        <td>رقم الهوية</td>
                     </tr>
                     <tr>
                        <td>
                           MOBILE NO
                        </td>
                        <td>
                           {{ printStaticData.print_type == 1 ? printStaticData.in_driver_mobile :
                              printStaticData.out_driver_mobile }}
                        </td>
                        <td>رقم الجوال</td>
                     </tr>
                     <tr height="200px">
                        <td>
                           DRIVER SIGN
                        </td>
                        <td>
                           <div>
                              {{ printStaticData.print_type == 1 ? printStaticData.in_date : printStaticData.out_date }}
                           </div>
                           <div>
                              {{ printStaticData.customer_name }}
                           </div>
                           <div v-if="printStaticData.print_type == 2">
                              (received the equipment in good condition)
                           </div>
                        </td>
                        <td>توقيع السائق</td>
                     </tr>
                  </table>

                  <div id="note">
                     <span>ملاحظات</span>
                     <span>Notes</span>
                  </div>
                  <!-- <table dir="ltr" width="100%" class="w_tans_master_table" v-if="printStaticData.print_type == 1">
                     <tr>
                        <td>remarks</td>
                        <td colspan="3"></td>
                     </tr>
                  </table>
                  <div class="w_tans_master_text" v-if="printStaticData.print_type == 1">
                     accepted the containers in as-is condition. excluding any risks /liabilities
                  </div>
                  <div class="w_tans_master_text" style="margin-top:90px" v-if="printStaticData.print_type == 1">
                     for AMWAJ AL-MAD LOGISTICS CI., LTD
                     <br />
                     No.
                  </div>
                  <div class="w_tans_master_text" style="margin-top:90px" v-if="printStaticData.print_type == 2">
                     Del No.
                     <br />
                     for AMWAJ AL-MAD LOGISTICS CI., LTD
                  </div> -->
               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>


   </div>
</template>

<script>
import PrintPDF from "@/components/PrintPDF.vue";
import VueHtml2pdf from 'vue-html2pdf'
export default {
   name: "WarehouseTransactionPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
   },
   computed: {

   },
   components: {
      PrintPDF,
      VueHtml2pdf,
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
.w_tans_master_table {
   border: 2px solid #a5a3a9;
   // background: #000 !important;
   border-collapse: collapse;
   // width: 600px;
   // margin: 10px auto auto auto;
   margin-top: 10px;

   tr {
      // background: #000 !important;
      border: 1px solid #a5a3a9;

      &:last-of-type {}
   }

   td {
      text-align: center !important;
      padding: 5px !important;
      font-weight: 800;
      background: #fff !important;
      text-transform: uppercase;
      font-size: 14px !important;
      border: 1px solid #a5a3a9;

      &:nth-of-type(1) {
         width: 25%;
         background-color: #ccd8ff !important;
      }

      &:nth-of-type(2) {
         width: 50%;
      }

      &:nth-of-type(3) {
         width: 25%;
         background-color: #ccd8ff !important;
      }

      &:nth-of-type(4) {
         width: 15%;
      }
   }

}

.w_tans_master_text {
   font-size: 13px;
   // width: 600px;
   margin: 10px auto auto auto;
   text-transform: capitalize;
   direction: ltr;
   font-weight: 700;
}

#trip_title {
   font-weight: bold;
   font-weight: 700;
   font-size: 23px;
   text-transform: uppercase;
   //  width: 500px;
   // margin: 0 9%;
   display: flex;
   justify-content: space-between;
   background: #e8e8ea;
   padding: 5px 10px;
   border-bottom: 4px solid #c0c0c0;
   border-right: 4px solid #c0c0c0;

   span {
      font-weight: 700;
      font-size: 14px;
   }

}

#note {
   background-color: #ccd8ff !important;
   display: flex;
   justify-content: space-between;
   height: 200px;
   font-weight: 500;
   padding: 10px;
   font-size: 10px;
   margin-top: 20px;
}
</style>