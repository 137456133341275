<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="500">
      <v-card relative :key="selectedRow.id">
         <v-card class="backgroundW shadow pa-5 pt-7">
            <v-row>
               <v-col cols="12" class="pt-0">
                  <div class="subtitle-1 font-weight-bold d-flex align-center">
                     <span class="mx-2">
                        {{ $t('expected date of arrival') }}
                        <span class="gray5--text" v-if="selectedRow.id">( {{ selectedRow.id }} )</span>
                     </span>
                  </div>
               </v-col>
               <GenericInput type="date" :minDate="$global.GetCurrentDate()"  :disabled="(selectedRow.clearance_status_id > 1)" :value="arrival_date" @input="arrival_date = $event"
                  label="expected date of arrival" :multi="false"
                  :required="true" :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>
               <v-col cols="12" class="d-flex justify-end pt-0">
                  <v-btn text depressed color="error" :min-width="120" @click="closeDialog" :height="37">
                     <v-icon left>mdi-close</v-icon>
                     {{ $t('close') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="updateClearance" depressed color="success" :disabled="!(arrival_date)" :loading="isLoading"
                     :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-card>
   </v-dialog>
</template>

<script>

import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "ArrivalExpectedDateDialog",
   props: {
      closeDialog: { type: Function },
      dialog: { default: false },
      id: { default: 0 },
      selectedRow: { default: Object },
   },
   computed: {

   },
   components: {
      GenericInput
   },
   watch: {
      dialog() {
         if (this.dialog) {
            this.arrival_date = this.selectedRow.arrival_date
         } else {
            this.isLoading = false;
            this.arrival_date = null;
         }

      }
   },
   data: () => ({
      isLoading: false,
      arrival_date: null,
   }),
   methods: {
      updateClearance() {
         this.isLoading = true;
         const requestBody = {
            items_update: 0,
            arrival_date: this.arrival_date
         }
         this.$api.POST_METHOD(`update_clearance_item/${this.id}`, requestBody).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.selectedRow.arrival_date = this.arrival_date
               this.arrival_date = null;
               this.closeDialog();
            }
         })

      },

   },
   mounted() {

   },
};
</script>
