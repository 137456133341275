<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="900">
      <v-card relative :key="modalKey">

         <v-form ref="detail" v-model="valid" class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <v-col cols="12" class="pt-0">
                     <div class="subtitle-1 font-weight-bold d-flex align-center">
                        <v-icon right v-if="!isDetailEdit">mdi-plus</v-icon>
                        <img v-else src="@/assets/img/svg_icons/pen.svg" height="18" />
                        <span class="mx-2">
                           {{ isDetailEdit ? $t('edit line') : $t('add line') }}
                        </span>
                     </div>
                  </v-col>
                  <GenericInput type="float" :value="detail.amount" :rows="5" @input="detail.amount = $event"
                     label="amount" :required="true" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>


                  <GenericInput type="number" :value="detail.receipt_number" :rows="5"
                     @input="detail.receipt_number = $event" label="receipt number" :required="true" :isLoading="false"
                     :cols="[12, 6, 6]">
                  </GenericInput>


                  <GenericInput type="select" :lookups="expense_codes" :value="detail.expense_code_id"
                     @input="detail.expense_code_id = $event" label="expense code" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>

                  <!-- transaction no -->
                  <GenericInput type="autocomplete" :lookups="clearances" :value="detail.clearance_id"
                     :keyupValue="searchClearance" :required="true" @input="detail.clearance_id = $event"
                     noDataText="Enter the transaction number" label="clearance" selected_label="clearance_label"
                     selected_prop="id" :isLoading="clearancesIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="checkbox" :value="detail.taxable" paddingY="py-1" @input="detail.taxable = $event"
                     label="taxable" :multi="false" :required="false" :disabled="false" :isLoading="false"
                     :cols="[6, 6, 6]">
                  </GenericInput>

                  <GenericInput type="select" v-if="detail.taxable" :lookups="taxes" :value="detail.tax_id"
                     @input="detail.tax_id = $event" label="vat account" :multi="false" :required="true"
                     selected_label="name" :disabled="false" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="treasuries" :value="detail.treasury_id"
                     @input="detail.treasury_id = $event" label="finance" :multi="false" :required="false"
                     selected_label="name" :disabled="false" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>

                  <GenericInput type="textarea" :value="detail.description" :rows="2" @input="detail.description = $event"
                     label="description" :required="false" :isLoading="false" :cols="[12, 12, 12]"></GenericInput>

                  <v-col cols="4" v-if="detail.taxable">
                     <div class="text--disabled  font-weight-bold subtitle-2">{{ $t('vat') | capitalize }} :
                        <span class="primary--text">{{ amountCalculation.total_vat | float }}</span>
                     </div>
                  </v-col>

                  <!-- <v-col cols="4" v-if="detail.taxable">
                        <div class="text--disabled text-center font-weight-bold subtitle-2">{{ $t('total without vat') | capitalize }} :
                           <span class="primary--text">{{ amountCalculation.total_without_vat | float }}</span>
                        </div>
                     </v-col>
                     <v-col cols="4" v-if="detail.taxable">
                        <div class="text--disabled text-center font-weight-bold subtitle-2">{{ $t('total with vat') | capitalize }} :
                           <span class="primary--text">{{ amountCalculation.total_with_vat | float }}</span>
                        </div>
                     </v-col> -->

                  <v-col cols="12" class="d-flex justify-end">
                     <v-btn text depressed color="error" class="mx-1" :min-width="120" @click="closeDialog" :height="37">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn outlined style="background-color:  #e6ecff ;" class="mx-1" :disabled="!(valid)" depressed
                        color="blue2" :min-width="120" @click="save" :height="37">
                        <span v-if="isDetailEdit">
                           <v-icon left>mdi-pencil</v-icon>
                           {{ $t('edit line') }}
                        </span>
                        <span v-else>
                           <v-icon left>mdi-plus</v-icon>
                           {{ $t('add line') }}
                        </span>
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>

      </v-card>
   </v-dialog>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "DPettyCashDetail",
   props: {
      detailAction: { type: Function },
      dialog: { default: false },
      updateDetail: { type: Function },
      detail: { default: Object },
      isDetailEdit: { default: false },
      expense_codes: { default: [] },
      tableRows: { default: [] },
      taxes: { default: [] },
      treasuries: { default: [] },
   },
   computed: {
      amountCalculation() {
         let calculation = {}
         let tax = this.taxes.find(tax => tax.id == this.detail.tax_id) || {};
         calculation.tax_title = tax.name;
         calculation.tax_percentage = tax.tax_percentage || 0;
         calculation.total_with_vat = +this.detail.amount
         calculation.total_without_vat = +calculation.total_with_vat - (+calculation.total_with_vat - +calculation.total_with_vat / (calculation.tax_percentage / 100 + 1));
         calculation.total_vat = calculation.total_with_vat - calculation.total_without_vat;
         return calculation
      },
      searchClearance() {
         let asyncSearch = null;

         return (val) => {
            clearTimeout(asyncSearch)

            if (val && !this.detail.clearance_id) {
               asyncSearch = setTimeout(() => {
                  this.clearancesIsLoading = true
                  this.$api.GET_METHOD(`get_aval_clearances_short?word=${val}`)
                     .then((response) => {
                        response.data.forEach(clearance => {
                           clearance.clearance_label = `${clearance.clearance_id} - ${clearance.customer_name}`
                        });
                        this.clearances.push(...response.data)
                     })
                     .finally(() => (this.clearancesIsLoading = false))
               }, 600)
            }
         }
      },
   },
   components: {
      GenericInput
   },
   watch: {
      detail(){
         if (Object.keys(this.detail).length) {
           let clearancesFullName = `${this.detail.clearance_id} - ${this.detail.customer_name}`
            this.clearances.push({'clearance_label': clearancesFullName})
            this.detail.clearance_id = clearancesFullName
         }
      }
   },
   data: () => ({
      isLoading: false,
      valid: false,
      clearancesIsLoading: false,
      modalKey: 0,
      clearances: [],
   }),
   methods: {
      closeDialog() {
         this.detailAction(null, false, true)
      },
      save() {
         console.log(this.isDetailEdit);
         this.detailAction(this.detail, this.isDetailEdit, false);
      },

   },
   mounted() {

   },
};
</script>
