<template>
   <v-dialog v-model="dialog" v-if="hasAccess" fullscreen overlay-opacity="0.75" content-class="overflow-hidden"
      transition="dialog-bottom-transition">
      <template v-slot:activator="{ on }">
         <v-row align="center" justify="center">
            <GenericInput type="text" :value="filterData.clearance_id" :isLoading="isLoading" :keydownEnter="getSearch"
               :clearable="true" @input="filterData.clearance_id = $event" label="transaction no" :required="false"
               :hide-details="true" :cols="[10, 10, 10]" />
            <v-col cols="2" class="pa-0">
               <v-btn v-on="on" color="primary" icon :loading="isLoading" @click="getSearch">
                  <v-icon>mdi-filter</v-icon>
               </v-btn>
            </v-col>
         </v-row>
      </template>
      <v-card class="overflow-hidden  rounded-0">
         <v-toolbar dark color="primary" class="shadow px-5">
            <v-toolbar-title>{{ $t('search in clearances') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
               <v-btn width="120" color="white" x-large class="shadow primary--text" v-if="!showFilter"
                  @click="showFilter = !showFilter">
                  {{ $t('Filter') }} <v-icon right>mdi-filter</v-icon>
               </v-btn>
               <v-btn icon color="white" @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </v-toolbar-items>
         </v-toolbar>


         <v-col cols="12" class="py-0 pt-3" v-if="showFilter && !isLoading">
            <v-row justify="center">
               <v-card class="shadow rounded-0 pa-7">
                  <v-row align="center" justify="space-between" class="">

                     <GenericInput type="date" :isPickerOpened="() => filterData.to_date = null"
                        :value="filterData.from_date" :clearable="true" :maxDate="filterData.to_date || today"
                        @input="filterData.from_date = $event" label="from date" :required="false" :hide-details="true"
                        :cols="[12, 4, 4]" />

                     <GenericInput type="date" :value="filterData.to_date" :clearable="true"
                        :minDate="filterData.from_date" :maxDate="today" @input="filterData.to_date = $event"
                        label="to date" :required="false" :hide-details="true" :cols="[12, 4, 4]" />

                     <GenericInput type="select" :lookups="customers" :value="filterData.customer_id" :clearable="true"
                        @input="filterData.customer_id = $event" label="customer name" :multi="false" :required="false"
                        selected_label="name" selected_prop="id" :hide-details="true" :cols="[12, 4, 4]" />

                     <GenericInput type="select" :lookups="ports" :value="filterData.port_id"
                        @input="filterData.port_id = $event" :clearable="true" label="port" :multi="false"
                        :required="false" selected_label="name" selected_prop="id" :hide-details="true"
                        :cols="[12, 4, 4]" />

                     <GenericInput type="text" :value="filterData.clearance_id" :keydownEnter="getSearch" :clearable="true"
                        @input="filterData.clearance_id = $event" label="transaction no" :required="false"
                        :hide-details="true" :cols="[12, 4, 4]" />

                     <GenericInput type="text" :value="filterData.container_number" :keydownEnter="getSearch"
                        :clearable="true" @input="filterData.container_number = $event" label="container number"
                        :required="false" :hide-details="true" :cols="[12, 4, 4]" />

                     <GenericInput type="text" :value="filterData.shipping_policy_no" :keydownEnter="getSearch"
                        :clearable="true" @input="filterData.shipping_policy_no = $event" label="shipping policy no"
                        :required="false" :hide-details="true" :cols="[12, 4, 4]" />

                     <GenericInput type="select" :lookups="status" :value="filterData.status"
                        @input="filterData.status = $event" :clearable="true" label="status" :multi="false"
                        :required="false" selected_label="name" selected_prop="id" :hide-details="true"
                        :cols="[12, 4, 4]" />
                     <v-col cols="4" class="d-flex justify-end">
                        <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                           <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                        </v-btn>
                        <v-btn depressed width="120" color="blue4" outlined :loading="isLoading" @click="getSearch"
                           class="mx-1 white--text">
                           {{ $t("Search") }} 
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-card>
            </v-row>
         </v-col>
         <v-col cols="12" class="pa-0" v-if="tableRows.length || isLoading">
            <DynamicTable :isLoading="isLoading" :data="tableRows" cardClasses="shadow-none" :header="tableHeader"
               :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 530 : $store.state.Settings.windowSize.y - 380"
               :tableParams="true">
               <template v-slot:td="{ row, header }">
                  <span class="d-flex justify-center" v-if="header.key == 'id'">
                     <v-btn @click="showClearance(row)" readonly class="text-decoration-underline font-weight-bold"
                        depressed color="primary" text small>
                        {{ row.id || 0 }}
                     </v-btn>
                  </span>
               </template>
            </DynamicTable>
            <Pagination :pagination="filterData" v-if="tableRows.length" :limit="pagination.rows"
               :backValueMethod="changePage" :isLoading="isLoading" />
         </v-col>
      </v-card>
   </v-dialog>
</template>

<script>

import DynamicTable from "@/components/DynamicTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "ClearanceQuickFilter",
   props: {
      closeDialog: { type: Function },
   },
   computed: {
      tableHeader() {
         return [
            { text: "transaction no", key: "id", type: 'slot', classes: "", },
            { text: "delivery date", key: "action_date", type: 'date', width: 95, classes: "" },
            { text: "customer", key: "customer_name", type: 'text', width: 120, classes: "" },
            { text: "delegate", key: "delegate_name", type: 'text', classes: "" },
            { text: "containers QTY", key: "container_count", type: 'text', classes: "" },
            { text: "shipping policy no", key: "shipping_policy_no", type: 'text', classes: "" },
            { text: "port", key: "port_name", type: 'text', classes: "" },
            { text: "first container number", key: "first_container_number", type: 'text', classes: "" },
            { text: "status", key: "status_name", type: 'text', classes: "" },
            { text: "expected arrival", key: "arrival_date", type: 'text', classes: "" },
            { text: "unloading date", key: "unloading_date", type: 'text', classes: "" },
            { text: "floor date", key: "customs_floor_date", type: 'date', classes: "", width: 85 },
            { text: "customs declaration number", key: "customs_declaration_number", type: 'text', classes: "" },
            { text: "port numbers", key: "port_withdraw", type: 'text', classes: "" },
            { text: "delivery number", key: "withdrawal_note", type: 'text', classes: "" },
            { text: "inspection no", key: "inspection_number", type: 'text', classes: "" },
            { text: "clearance invoice", key: "invoices", type: 'text', classes: "" },
         ]
      },
      hasAccess() {
         if (
            this.$global.CheckAction('04-004', 1) ||
            this.$global.CheckAction('04-009', 1) ||
            this.$global.CheckAction('04-010', 1) ||
            this.$global.CheckAction('04-019', 1) ||
            this.$global.CheckAction('04-011', 1) ||
            this.$global.CheckAction('04-017', 1) ||
            this.$global.CheckAction('04-020', 1) ||
            this.$global.CheckAction('04-018', 1)
         ) {
            return true
         } else {
            return false
         }
      }
   },
   components: {
      GenericInput,
      Pagination,
      DynamicTable,
   },
   watch: {
      dialog() {
         this.queryParam.set("page", this.filterData.page || 1);
         this.queryParam.set("rows", this.filterData.rows || 30);
         if (this.dialog) {
            // if (!this.filterData.clearance_id) {
            //    this.getSearch();
            // }
         } else {
            this.tableRows = []
            this.filterData = {
               word: null,
               page: 1,
               rows: 30,
               status: null,
               from_date: null,
               to_date: null,
               customer_id: null,
               port_id: null,
               clearance_id: null,
               shipping_policy_no: null,
               container_number: null,
            }
         }
      },
      filterData: {
         handler(newValue, oldValue) {
            console.log("newValue", newValue);
            console.log("oldValue", oldValue);
            this.queryParam.set("word", this.filterData.word || '');
            this.queryParam.set("status", this.filterData.status || '');
            this.queryParam.set("from_date", this.filterData.from_date || '');
            this.queryParam.set("to_date", this.filterData.to_date || '');
            this.queryParam.set("customer_id", this.filterData.customer_id || '');
            this.queryParam.set("port_id", this.filterData.port_id || '');
            this.queryParam.set("clearance_id", this.filterData.clearance_id || '');
            this.queryParam.set("shipping_policy_no", this.filterData.shipping_policy_no || '');
            this.queryParam.set("container_number", this.filterData.container_number || '');
         },
         deep: true
      },
   },
   data: () => ({
      today: null,
      isLoading: false,
      showFilter: true,
      dialog: false,
      customers: [],
      status: [],
      ports: [],
      tableRows: [],
      queryParam: new URLSearchParams(),
      pagination: {},
      filterData: {
         word: null,
         page: 1,
         rows: 30,
         status: null,
         from_date: null,
         to_date: null,
         customer_id: null,
         port_id: null,
         clearance_id: null,
         shipping_policy_no: null,
         container_number: null,
      }
   }),
   methods: {
      getSearch() {
         this.isLoading = true;
         this.$api.GET_METHOD(`clearance?${this.queryParam.toString()}`)
            .then((response) => {
               this.isLoading = false;
               if (response.check) {

                  response.data.items.data.forEach(row => {
                     row.rowClass = row.has_problem ? 'red12' : '';
                     row.invoices = row.invoices ? row.invoices.split(",").map((i) => +i) : null;
                  });
                  this.tableRows = response.data.items.data;
                  this.filterData.rows = 30;
                  this.filterData.page = response.data.items.current_page;
                  this.filterData.totalPages = response.data.items.last_page;
                  this.filterData.per_page = response.data.items.per_page;
                  this.filterData.totalRows = response.data.items.total;
                  this.showFilter = false;
                  this.customers = response.data.customers || [];
                  this.ports = response.data.ports || []
                  this.status = response.data.status || [];
                  if (!response.data.items.data.length) {
                     this.$store.state.snackbarMessages = [];
                     this.$store.state.snackbarTitle = 'no search results found';
                     this.$store.state.snackbarType = 'white';
                     this.$store.state.showSnackbar = true;
                  } else {
                     this.dialog = true;
                  }

               }
            });
      },
      changePage(page, limit) {
         console.log(page, limit);
         this.queryParam.set("page", page || 1);
         this.queryParam.set("rows", limit || 30);
         this.getSearch();
      },
      showClearance(row) {
         this.dialog = false;
         this.$router.push(`/sales/clearance-control/` + row.id);
      }
   },
   mounted() {
      this.today = this.$global.GetCurrentDate();
      this.queryParam.set("page", 1);
      this.queryParam.set("rows", 30);
   },
};
</script>
