<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :showSearch="false" :getData="getData" actionBtnText="Search"
        :showDeleted="false" :createBtn="false" :actionBtn="!showFilter" :actionBtnValid="true"
        actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
        actionBtnClass="blue4--text" :getSearch="getSearch">
        <template v-slot:rightSide>
          <v-col cols="12" sm="auto">
            <ExportBtn :exportData="car_income_report" v-if="car_income_report.length" :header="pageData.tableHeader"
              :footerData="footerData" :showGroupHeader="true" :groupHeader="groupHeader"
              :attachmentFileName="pageData.entityName" />
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->

      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" class="">
              <GenericInput type="date" :isPickerOpened="() => to_date = null" :value="from_date"
                :maxDate="to_date || today" @input="from_date = $event" label="from" :required="false"
                :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="date" :value="to_date" :minDate="from_date" :maxDate="today" @input="to_date = $event"
                label="to" :required="false" :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="select" solo :clearable="true" :lookups="customers" :value="customer_id"
                @input="customer_id = $event" label="customer" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.reportIsLoading)"
                :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="select" solo :clearable="true" :lookups="drivers" :value="driver_id"
                @input="driver_id = $event" label="driver" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.reportIsLoading)"
                :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="select" solo :clearable="true" :lookups="cars" :value="car_id" @input="car_id = $event"
                label="car" :multi="false" :required="false" selected_label="name" selected_prop="id" :hide-details="true"
                :isLoading="(pageData.isLoading || pageData.reportIsLoading)" :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="select" solo :clearable="true" :lookups="branches" :value="branch_id"
                @input="branch_id = $event" label="branch" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.reportIsLoading)"
                :cols="[12, 6, 3]">
              </GenericInput>
              <v-col cols="6" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="primary" outlined
                  :disabled="!(driver_id || car_id || customer_id || from_date || to_date)"
                  :loading="(pageData.isLoading || pageData.reportIsLoading)" @click="getReport"
                  class="mx-1 white--text">{{
                    $t("Search")
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--  -->
      <v-row v-if="car_income_report.length > 0">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="car_income_report"
            :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 260 : $store.state.Settings.windowSize.y - 150"
            :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData" :showGroupHeader="true"
            :groupHeader="groupHeader" :customize_header_footer="true" :printStaticData="printStaticData" />
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
// import PrintBtn from "@/components/ui/PrintBtn.vue";
export default {
  name: "CarsIncomeReportEntity",

  data: () => ({
    pageData: {
      screen_code: "03-017",
      url: null,
      controlRoute: "transport/cars_income_report-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      reportIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    groupHeader: [],
    printStaticData: Object,
    showFilter: true,
    from_date: null,
    to_date: null,
    today: null,
    car_income_report: [],
    drivers: [],
    cars: [],
    driver_id: null,
    car_id: null,
    customer_id: null,
    branches: [],
    branch_id: null,
    customers: [],
  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput,
    // PrintBtn
  },
  watch: {
    driver_id() {
      this.car_income_report = [];
      this.getReport();
    },
    customer_id() {
      this.car_income_report = [];
      this.getReport();
    },
    car_id() {
      this.car_income_report = [];
      this.getReport();
    },
  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.to_date = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.getData();

  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "trip number", key: "id", type: 'text', classes: "" },
        { text: "driver", key: "driver_name", type: 'text', classes: "" },
        { text: "car number", key: "car_name", type: 'text', classes: "" },
        { text: "name", key: "customer_name", type: 'text', classes: "" },
        { text: "phone", key: "customer_mobile", type: 'text', classes: "" },
        { text: "number", key: "container_number", type: 'text', classes: "" },
        { text: "size", key: "container_size", type: 'text', classes: "" },
        { text: "from", key: "from_place", type: 'text', classes: "" },
        { text: "to", key: "to_place", type: 'text', classes: "" },
        { text: "amount", key: "trip_price", type: 'float', classes: "" },
        // { text: "notes", key: "note", type: 'text', classes: "" },
      ];
      this.groupHeader = [
        { text: " ", colspan: 1, classes: "" },
        { text: "car", colspan: 2, classes: "" },
        { text: "customer", colspan: 2, classes: "" },
        { text: "container", colspan: 2, classes: "" },
        { text: "direction", colspan: 2, classes: "" },
        { text: "", colspan: 1, classes: "" },
        // { text: "", colspan: 1, classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`car_income_report`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.customers = response.data.customers || [];
              this.drivers = response.data.drivers || [];
              this.cars = response.data.cars || [];
              this.branches = response.data.branchs || [];
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getReport() {
      this.pageData.reportIsLoading = true;
      const body = {
        driver_id: this.driver_id,
        customer_id: this.customer_id,
        car_id: this.car_id,
        from_date: this.from_date,
        to_date: this.to_date,
        branch_id: this.branch_id,
        type: 'json'
      }
      this.$api.POST_METHOD(`car_income_report`, body, false).then((response) => {
        this.pageData.reportIsLoading = false;
        if (response.check) {
          response.data.report.forEach(row => {
            if (row.from_area_name) {
              row.from_place = row.from_area_name
            }
            if (row.from_port_name) {
              row.from_place = row.from_port_name
            }
            if (row.from_wearhouse_name) {
              row.from_place = row.from_wearhouse_name
            }
            if (row.to_area_name) {
              row.to_place = row.to_area_name
            }
            if (row.to_port_name) {
              row.to_place = row.to_port_name
            }
            if (row.to_wearhouse_name) {
              row.to_place = row.to_wearhouse_name
            }
          });
          this.car_income_report = response.data.report || [];
          this.printStaticData = response.data.print;
        }
      });
    },
    print(type) {
      this.pageData.printLoading = true;
      const body = {
        driver_id: this.driver_id,
        customer_id: this.customer_id,
        car_id: this.car_id,
        from_date: this.from_date,
        to_date: this.to_date,
        branch_id: this.branch_id,
        type: type
      }
      this.$api.DOWNLOAD_METHOD_BODY(`car_income_report`, body, `car_income_report.${type}`)
        .then((response) => {
          this.pageData.printLoading = false;
          if (response.check) {
            // this.pageData.rows = response.data.report;
          }
        });
    },


  },
};
</script>
