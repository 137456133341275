<template>
  <v-container fluid class="bgAuth white d-flex align-center justify-center pa-0" style="height: 100vh">

    <div class="wave"><svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
        <path d="M0.23,-0.49 C38.60,163.31 188.14,-22.20 218.62,179.10 L0.00,150.00 L-0.90,-1.48 Z"
          style="stroke: none; fill: #08f;"></path>
      </svg></div>


    <v-col cols="11" lg="5" md="6" sm="7" class="LoginCol">
      <v-card class="login-card backgroundW pa-5">
        <img src="@/assets/logo.png" class="mx-auto d-block mb-5" height="60" alt="clearance" />
        <h5 class="text-h5 text-center my-3">{{ $t("Welcome Back") }}</h5>
        <!-- <div
          class="subtitle-2 text-center text--disabled"
        >{{ $t('Enter your credentials to access your account') }}</div>-->
        <v-form ref="form" @submit="login()" v-model="valid" class="mt-3 pa-3" lazy-validation>
          <v-row justify="center">
            <v-col cols="12">

              <v-item-group v-model="client_id" mandatory>
                <v-row>
                  <v-col cols="4">
                    <v-item v-slot="{ active, toggle }">
                      <v-card :class="active ? 'linear' : 'light'" class="d-flex  align-center elevation-0" dark
                        height="50" @click="toggle">
                        <div v-if="active" class="subtitle-1 font-weight-bold flex-grow-1 text-center">
                          {{ $t('logistic') | capitalize }}
                          <!-- <v-icon size="18">mdi-check</v-icon> -->
                        </div>

                        <div v-if="!active" class="subtitle-1 font-weight-bold primary--text  flex-grow-1 text-center">
                          {{ $t('logistic') | capitalize }}
                        </div>

                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col cols="4">
                    <v-item v-slot="{ active, toggle }">
                      <v-card :color="active ? 'linear' : 'light'" class="d-flex  align-center elevation-0" dark
                        height="50" @click="toggle">
                        <div v-if="active" class="subtitle-1 font-weight-bold flex-grow-1 text-center">
                          {{ $t('Transportation') | capitalize }}
                          <!-- <v-icon size="18">mdi-check</v-icon> -->
                        </div>

                        <div v-if="!active" class="subtitle-1 font-weight-bold primary--text  flex-grow-1 text-center">
                          {{ $t('Transportation') | capitalize }}
                        </div>

                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col cols="4">
                    <v-item v-slot="{ active, toggle }">
                      <v-card :color="active ? 'linear' : 'light'" class="d-flex  align-center elevation-0" dark
                        height="50" @click="toggle">
                        <div v-if="active" class="subtitle-1 font-weight-bold flex-grow-1 text-center">
                          {{ $t('Sandbox') | capitalize }}
                          <!-- <v-icon size="18">mdi-check</v-icon> -->
                        </div>

                        <div v-if="!active" class="subtitle-1 font-weight-bold primary--text  flex-grow-1 text-center">
                          {{ $t('Sandbox') | capitalize }}
                        </div>

                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-col>
            <v-col cols="12">
              <h5 class="mb-3">{{ $t('username') | capitalize }}</h5>
              <v-text-field hide-details="auto" class="my-2 rounded-lg" filled :rules="$global.requiredRule"
                @keydown.enter="login()" v-model="username" rounded color="primary"
                prepend-inner-icon="mdi-account-circle-outline" type="text"></v-text-field>
            </v-col>
            <v-col cols="12">
              <h5 class="mb-3">{{ $t('password') | capitalize }}</h5>
              <v-text-field hide-details="auto" class="my-2 rounded-lg" filled required color="primary"
                :rules="passwordRule" v-model="password" rounded @keydown.enter="login()"
                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
                prepend-inner-icon="mdi-key-outline" @click:append="show = !show"></v-text-field>
            </v-col>
            <v-col cols="12" sm="7">
              <v-btn @click="login" color="accent" :loading="loading" height="50" :disabled="!valid" block large
                class="d-block mi-start-auto subtitle-1 rounded-xl shadow">
                {{ $t("login") }}
                <!-- <v-icon right>mdi-login</v-icon> -->
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white" class="mdi-spin">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <div class="subtitle-2 my-5 text-center gray8--text position-relative" style="z-index: 2;">
        {{ $t('Forget your password?') }}
        <a class="blueLink--text">
          <strong>{{ $t('Reset Password') }}</strong>
        </a>
      </div>
    </v-col>
  </v-container>
</template>


<script>
export default {
  name: "LoginPage",
  computed: {},
  watch: {
    client_id() {
      if (this.client_id == 0) { this.$store.commit('UPDATE_CLIENT', "amwaj") }
      if (this.client_id == 1) { this.$store.commit('UPDATE_CLIENT', "trans") }
      else { this.$store.commit('UPDATE_CLIENT', "am_test") }
    }
  },
  mounted() {
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version);
    this.$store.commit('UPDATE_CLIENT', "amwaj")
  },
  components: {
    //  AnimatedLogo
  },
  data() {
    return {
      show: false,
      valid: false,
      remember: true,
      loading: false,
      connectionStatus: '',

      client_id: 0,
      username: null,
      password: null,
      usernameRule: [
        v => !!v || this.$t('Username is required')
      ],
      passwordRule: [
        v => !!v || this.$t('Password is required'),
        v => (v && v.length >= 5) || this.$t('Password must be greater than 5 characters'),
      ],
      companyIDRule: [
        v => !!v || this.$i18n.t('Company number is required'),
        v => (v && v.length >= 3) || this.$i18n.t('Company ID not valid'),
      ],
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true) {
        const requestBody = {
          username: this.username,
          password: this.password,
        }
        this.$api.LOGIN_AUTH(requestBody).then((response) => {
          this.loading = false;
          if (response.check == true) {
            this.$router.push('/');
            localStorage.setItem("clientID", this.$store.state.clientID)
          }
        })
      }
      else {
        this.loading = false;
      }

    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>


<style scoped lang="scss">
.bgAuth {
  position: relative;
  max-height: 100vh;
  overflow: hidden;

  .wave {
    position: absolute !important;
    bottom: 0px;
    height: 50vh;
    left: 0;

    path {
      opacity: 0.09;
    }
  }

  //background-image: url("../../assets/img/png/bgCement.png");
  //background-attachment: inherit;
  //background-repeat: no-repeat;
  //background-position: 50%;
  //background-size: cover;
  .LoginCol {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      width: 560px;
      height: 560px;
      border-radius: 2000px;
      bottom: -80px;
      right: -130px;
      background: linear-gradient(225deg, #E9F4FF 0%, rgba(26, 145, 205, 0) 70%);
    }
  }

  .login-card {
    //background: #fff;
    padding-bottom: 1.5rem;
    border-radius: 15px;
    // overflow: hidden;
    z-index: 55;
    box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%), 0 12px 90px 0 rgb(103 151 255 / 11%) !important;
    position: relative;

    //  button.v-btn {
    //    background: linear-gradient(120deg, rgba(103, 151, 255, 0.5) 0%, #4d86ff 100%) !important;
    //    color: #fff;
    //  }
    .linear {
      background: linear-gradient(120deg, rgba(103, 151, 255, 0.5) 0%, #4d86ff 100%) !important;
    }
  }
}
</style>