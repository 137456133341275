<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="900">
         <v-card relative :key="modalKey">
            <v-btn absolute class="ma-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="closeDialog">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>

            <v-form ref="newItem" v-model="valid" class="py-10 pb-5 pa-14">
               <v-row align="center" v-for="(row) in containers" :key="row.clearances_d_id">
                  <v-col cols="3" class="subtitle-2">
                     <div> {{ $t('container') }} : <span class="gray7--text">{{ row.container_number }}</span></div>
                     <div> {{ $t('size') }} : <span class="gray7--text">{{ row.container_size }}</span></div>
                  </v-col>

                  <GenericInput type="select" :lookups="areas" :value="+row.from_area" @input="row.from_area = $event"
                     :disableItem="{ key: 'id', value: +row.to_area }" label="from place" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[3, 3, 3]">
                  </GenericInput>


                  <GenericInput type="select" :lookups="areas" :value="+row.to_area" @input="row.to_area = $event"
                     :disableItem="{ key: 'id', value: +row.from_area }" label="to place" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[3, 3, 3]">
                  </GenericInput>
                  <v-col cols="3" class="subtitle-2 d-flex">
                     {{ $t('price') | capitalize }} :
                     <div v-if="computedPrice(row)" class="gray7--text mx-2">
                        {{ computedPrice(row) | float }}
                     </div>
                     <div v-else class="error--text  mx-2">
                        {{ $t('not found') }}
                     </div>
                  </v-col>


               </v-row>


               <v-col cols="12" class="d-flex mt-5 justify-center">
                  <v-btn class="mx-2" text depressed color="red4" @click="closeDialog" :height="37" :width="120">
                     {{ $t('close') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="save" :loading="isLoading" depressed color="blue4"
                     :disabled="!saveValid" :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-form>

         </v-card>
      </v-dialog>
   </v-row>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "ApplyTransportFeesDialog",
   props: {
      backValueMethod: { default: Function },
      setContainersPrice: { default: Function },
      dialog: { default: false },
      master: { default: Object },
      clearance_d: { default: [] },
      areas: { default: [] },
      prices: { default: [] },
   },
   computed: {
      containers() {
         return this.clearance_d
      },
      saveValid() {
         let pricesValid = this.containers.every(container => this.prices.some((obj) => container.from_area == obj.from_area_id && container.to_area == obj.to_area_id))
         return this.valid && pricesValid
      },
      computedPrice() {
         return (row) => {
            if (row.from_area && row.to_area) {
               let getRoadPrice = this.prices.find((obj) => row.from_area == obj.from_area_id && row.to_area == obj.to_area_id) || {}
               console.log(row,getRoadPrice);
               if (this.master.is_lcl) {
                  return getRoadPrice.price || 0
               } else {
                  return row.container_size == 20 ? getRoadPrice.container_20 : getRoadPrice.container_40
               }
            } else {
               return 0
            }
         }
      }
   },
   components: {
      GenericInput
   },
   watch: {

   },
   data: () => ({
      isLoading: false,
      valid: false,
      modalKey: 0,
      newItem: {
         item_title: null,
         unit_id: null,
         item_id: null,
         quantity: null,
         previous_received_quantity: null,
         receiving_location_id: null,
         received_quantity: null,
         warehouse_id: null,
         tax_percent_id: null,
         price: null,
      },
      rules: {

      },
   }),
   methods: {
      closeDialog() {
         this.backValueMethod(false)
      },

      save() {
         this.containers.forEach(container => {
            const getRoadPrice = this.prices.find((obj) => container.from_area == obj.from_area_id && container.to_area == obj.to_area_id) || {}
            if (this.master.is_lcl) {
               container.price = getRoadPrice.price || 0
            } else {
               container.price = container.container_size == 20 ? getRoadPrice.container_20 : getRoadPrice.container_40
            }
         });
         this.setContainersPrice(this.clearance_d)
         // this.isLoading = true;
         // this.$api.POST_METHOD(`clearance_transport_fees`, { items: this.clearance_d }).then((response) => {
         //    this.isLoading = false;
         //    if (response.check) {
         //       this.backValueMethod(false)
         //    }
         // })
      },

   },
   mounted() { },
};
</script>
