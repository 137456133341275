<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <EntityHeader :pageData="pageData" :showSearch="false" :getData="getData" :getSearch="getSearch" />
         <!-- End Of Header -->
         <v-row>
            <v-col cols="12" sm="12">


                  <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="pageData.rows"
                  :tableHeight="$store.state.Settings.windowSize.y - 150" :header="pageData.tableHeader" :fixedHeader="true"
                  :option="pageData.options" :pageData="pageData" :showGroupHeader="false" :editValueMethod="edit" :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod"  :printValueMethod="printMethod">
                  <template v-slot:td="{ row, header }">
                    <div class="d-flex justify-center" v-if="header.key == 'journal_code'">
                      <v-hover v-slot="{ hover }" close-delay="150" open-delay="150">
                        <v-btn color="primary" @click="$router.push('/accounting/daily_journal-control/' + row.m_id )" min-width="90" :text="!hover" depressed
                          v-if="row[header.key]"> {{hover ? $t('show') :
                          row[header.key] || '-'}}</v-btn>
                      </v-hover>
                    </div>
                  </template>
                </DynamicTable>

            </v-col>
         </v-row>
         <v-row v-if="!pageData.isLoading">
            <v-col cols="12" sm="12">
               <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                  :isLoading="pageData.isLoading"></Pagination>
            </v-col>
         </v-row>
         <DailyJournalPrint :printAction="() => print = false" :print="print" :printData="printData"
            :printStaticData="printStaticData" />
      </v-container>
   </section>
</template>



<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
// import DataTableVue from "@/components/DataTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import DailyJournalPrint from "./DailyJournalPrint.vue";
export default {
   name: "DailyJournalEntity",

   data: () => ({
      pageData: {
         screen_code: "02-003",
         url: null,
         controlRoute: "accounting/daily_journal-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         printLoading: {
            loading: false,
            id: null,
            key: 'm_id'
         },
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
      },
      printData: [],
      printStaticData: Object,
      print: false,
   }),
   components: {
    //  DataTableVue,
    Pagination,
    EntityHeader,
    DynamicTable,
    DailyJournalPrint
},
   computed: {
      ...mapState(["favourites"]),
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      ...mapActions(["addFavourites"]),

      addToFav() {
         const fav = {
            screen_code: this.pageData.screen_code,
            name: this.pageData.entityName,
            url: this.pageData.url,
         };
         this.addFavourites(fav).finally(() => { });
      },

      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.entityName = this.$store.state.activeScreen.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         // this.pageData.tableHeader = [
         //    {
         //       text: this.$i18n.t('restriction number'),
         //       sortable: true,
         //       value: 'journal_code',
         //    },
         //    {
         //       text: this.$i18n.t('restriction description'),
         //       sortable: true,
         //       value: 'description',
         //    },
         //    {
         //       text: this.$i18n.t('year'),
         //       sortable: true,
         //       value: 'finance_year_name',
         //    },
         //    {
         //       text: this.$i18n.t('restriction date'),
         //       sortable: true,
         //       value: 'journal_date',
         //    },
         //    {
         //       text: this.$i18n.t('daily'),
         //       sortable: true,
         //       value: 'daily_type',
         //    },
         //    {
         //       text: this.$i18n.t('created_at'),
         //       sortable: true,
         //       value: "add_date",
         //    },
         //    { text: this.$i18n.t('actions'), value: 'actions', sortable: false },
         // ]
         this.pageData.tableHeader = [
        { text: "journal code", key: "journal_code", type: 'slot', classes: "" },
        { text: "restriction description", key: "description", type: 'text', classes: "" },
        { text: "year", key: "finance_year_name", type: 'text', classes: "" },
        { text: "restriction date", key: "journal_date", type: 'date', classes: "" },
        { text: "daily", key: "daily_type", type: 'text', classes: "" },
        { text: "actions", key: "m_id", type: 'actions', classes: "" },
      ];
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: 'id',
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         }
      },
      getSearch(word) {
         this.pageData.search = word ? word : '';
         this.getData();
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`daily_journal?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.pageData.entityName = this.pageData.isTrashed == 0 ? this.$i18n.t('deleted daily journal') : this.$store.state.activeScreen.sub_title;
                  this.pageData.rows = response.data.items.data;
                  this.pageData.page = response.data.items.current_page;
                  this.pageData.pagination = {
                     page: response.data.items.current_page,
                     totalPages: response.data.items.last_page,
                     per_page: response.data.items.per_page,
                     totalRows: response.data.items.total,
                  }

               }
            })

         }
         else {
            this.$router.push('/')
         }

      },
      changePage(page, limit) {
         this.pageData.page = page;
         this.pageData.rowsNumber = limit;
         this.getData()
      },
      deleteMethod(id) {
         this.$api.POST_METHOD(`daily_journal/${id}`, { _method: 'delete' }).then((response) => {
            if (response.check) {
               this.getData()
            }
         })
      },
      restoreMethod(row) {
         this.$api.POST_METHOD(`daily_journal_toggle_active/${row.m_id}`, null).then((response) => {
            if (response.check) {
               this.getData()
            }
         })
      },
      setClickRow() {
         // this.$router.push(`/main/daily_journal/${row.id}`)
      },
      edit(row) {
         console.log('edit', row);
         this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id)
      },
      printMethod(row) {
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = row.m_id;
         const src = `export_journal_data/${row.m_id}?type=pdf`;
         this.$api.DOWNLOAD_METHOD(src, `${this.pageData.entityName}_${row.m_id}.pdf`).then(() => {
            this.pageData.printLoading.loading = false;
         })
      },
      // printMethod(row) {
      //    this.pageData.printLoading.loading = true;
      //    this.pageData.printLoading.id = row.m_id;
      //    this.$api
      //       .GET_METHOD(`daily_journal/${row.m_id}`).then((response) => {
      //          this.pageData.printLoading.loading = false;
      //          if (response.check) {
      //             this.printData = response.data.details;
      //             this.printStaticData = response.data.master;
      //             this.print = true;
      //             setTimeout(() => {
      //                this.printData = [];
      //                this.printStaticData = Object;
      //                this.print = false;
      //                this.pageData.printLoading.loading = false;
      //             }, 1000);
      //          }
      //       });
      // },
   },
};
</script>
