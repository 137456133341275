<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600">
         <v-card relative :key="modalKey">
            <v-card-title class="text-h6 font-weight-bold">
               <v-icon color="primary" left>mdi-square-edit-outline</v-icon>
               <h5>
                  <span>{{ $t('edit') }}</span>
               </h5>
            </v-card-title>
            <form class="pa-5">
               <v-row v-if="dialog == true">
                  <GenericInput v-if="isDebit == true" type="number" :value="defaultValues.debit"
                     @input="defaultValues.debit = $event" label="debit" :required="true" :isLoading="false"
                     :cols="[12, 12, 6]"></GenericInput>
                  <GenericInput type="number" v-if="isDebit == false" :value="defaultValues.credit"
                     @input="defaultValues.credit = $event" label="credit" :required="true" :isLoading="false"
                     :cols="[12, 12, 6]"></GenericInput>
                  <GenericInput v-if="isDebit == true" type="autocomplete" :lookups="cost_centers" selected_label="name"
                     :value="defaultValues.cost_centers" @input="defaultValues.cost_centers = $event"
                     label="cost centers" :required="false" :isLoading="false" :hideDetails="true" :multi="true"
                     :cols="[12, 12, 6]"></GenericInput>
                  <GenericInput  type="select" selected_prop="id" :lookups="account_trees" selected_label="account_name_code"
                     :value="defaultValues.account_tree_id" @input="defaultValues.account_tree_id = $event"
                     label="account code" :required="true" :isLoading="false" :hideDetails="true" :multi="false"
                     :cols="[12, 12, 6]"></GenericInput>
                  <GenericInput type="textarea" :value="defaultValues.description"
                     @input="defaultValues.description = $event" label="jornal description" :required="false"
                     :isLoading="false" :cols="[12, 12, 12]"></GenericInput>
               </v-row>
               <v-row justify="end">
                  <v-col cols="auto">
                     <v-btn @click="closeDialog" class="shadow" outlined color="error"
                        style="width: 100%; text-align: start">{{ $t('close') }}</v-btn>
                  </v-col>
                  <v-col cols="auto" v-if="isDebit == true">
                     <v-btn :disabled="!(defaultValues.debit > 0)" @click="closeDialog" class="shadow" color="primary"
                        style="width: 100%; text-align: start">{{ $t('done') }}</v-btn>
                  </v-col>
                  <v-col cols="auto" v-if="isDebit == false">
                     <v-btn :disabled="!(defaultValues.credit > 0)" @click="closeDialog" class="shadow" color="primary"
                        style="width: 100%; text-align: start">{{ $t('done') }}</v-btn>
                  </v-col>
               </v-row>
            </form>
         </v-card>
      </v-dialog>
   </v-row>
</template>


<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "JornalEditDialog",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      cost_centers: { default: [] },
      account_trees: { default: [] },
      defaultValues: { default: [] },

   },
   computed: {
      isDebit() {
         console.log("defaultValues", this.defaultValues);
         return this.defaultValues.d_type == 2 ? true : false;
      }
   },
   components: {
      GenericInput
   },
   data() {
      return {
         valid: false,
         modalKey: 1,
      };
   },
   methods: {
      closeDialog() {
         this.dialog = false
         this.backValueMethod(this.selected_Cost_centers, false)
         this.modalKey = this.modalKey + 1
      },
      save() {
         this.backValueMethod(this.selected_Cost_centers, true);
         this.modalKey = this.modalKey + 1
      },
   },
   mounted() {

   },
};
</script>

<style scoped lang="scss">

</style>