<template>
  <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="500">
    <v-card relative class="backgroundW">
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab color="grey lighten-1"
        text @click="closeDialog">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h6 font-weight-bold">
        <v-icon size="25" class="mx-2">mdi-plus</v-icon>
        <div class="text-h6 font-weight-bold">
          {{ entityName ? $t('add') + ' ' + $t(entityName) : $t('add') | capitalize }}
        </div>
      </v-card-title>

      <v-col cols="12" sm="12" class="px-7" :key="dialogKey">

        <v-row align="center">
          <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar" :required="true"
            :isLoading="false" :cols="[6, 6, 6]" />
          <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en" :required="false"
            :isLoading="false" :cols="[6, 6, 6]" />
        </v-row>

      </v-col>


      <v-col cols="12" class="pa-7">
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn depressed color="error" text plain @click="closeDialog">
              {{ $t('close') }}</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn depressed color="success" :loading="isLoading" :disabled="!(form.name_ar && form.name_en)"
              min-width="110" @click="save">
              {{ $t('add') }}</v-btn>
          </v-col>

        </v-row>
      </v-col>


    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>
import GenericInput from './ui/GenericInput.vue';



export default {
  name: "AddLockupsDialog",
  props: {
    closeMethod: { type: Function },
    saveMethod: { type: Function },
    dialog: { default: false },
    postKey: { default: null },
    entityName: { default: null },
    endPoint: { default: null },
    dataKey: { default: 'id' },
    name_ar_key: { default: 'name_ar' },
    name_en_key: { default: 'name_en' },
  },
  computed: {},
  watch: {
    dialog() {
      this.dialogKey = this.dialogKey + 1
    }
  },
  data: () => ({
    isLoading: false,
    dialogKey: 1,
    form: {
      name_ar: null,
      name_en: null,
    }
  }),
  methods: {
    closeDialog() {
      this.dialogKey = this.dialogKey + 1
      this.closeMethod();
    },
    save() {
      this.isLoading = true;
      var requestBody = null
      if (this.postKey) {
        requestBody = {
          [this.postKey]: [{
            [this.name_ar_key]: this.form.name_ar,
            [this.name_en_key]: this.form.name_en,
          }]
        }
      } else {
        requestBody = {
          [this.name_ar_key]: this.form.name_ar,
          [this.name_en_key]: this.form.name_en,
        }
      }
      this.$api.POST_METHOD(`${this.endPoint}`, requestBody).then((res) => {
        this.isLoading = false;
        if (res.check) {
          res.data.name = this.$vuetify.rtl ?  this.form.name_ar : this.form.name_en
          this.saveMethod(res.data);
          this.form = {
            name_ar: null,
            name_en: null,
          };
          this.dialogKey = this.dialogKey + 1
        }
      })
    },
  },
  mounted() {
  },
  components: { GenericInput }
};
</script>
