<template>
   <section id="salesModule_clearance_control">
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :editForm="false" actionBtnText="comments" :actionBtn="pageData.isEdit" :actionBtnValid="true"
            actionBtnColor="gray5" :actionBtnClick="() => { timeLineDialog = true }" :actionBtnOutline="true"
            actionBtnClass="primary--text">
            <template v-slot:leftSide>
               <v-btn depressed v-if="pageData.isEdit" @click="openClearanceLevelsData = true" color="primary">
                  {{ $t('levels data') }}
               </v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->
         <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
            <AnimatedLoading :height="100" :hideText="false" :isLoading="pageData.isLoading" />
         </v-col>

         <div v-if="!pageData.isLoading">
            <!-- content -->
            <!-- Form -->
            <v-form ref="form" v-model="valid" class="pa-3">
               <v-card class="backgroundW shadow pa-5 pt-7">
                  <v-fade-transition>
                     <div class="collapseForm" v-if="tableRows.length > 0 || form.delegate_petty_status == 2">
                        <div class="d-flex">
                           <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                              <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                              <v-icon v-else>mdi-chevron-down</v-icon>
                           </v-btn>
                        </div>
                     </div>
                  </v-fade-transition>

                  <v-expand-transition>
                     <v-row align="center" v-show="!formCollapse">
                        <v-col cols="12" class="pt-0 position-relative" style="z-index: 10;" v-if="pageData.isEdit">
                           <v-tabs :slider-size="0" v-model="activeTab" active-class="blue12">
                              <v-tab class="font-weight-bold rounded-lg caption">
                                 <strong>{{ $t('basic data') }}</strong>
                              </v-tab>
                              <!-- <v-tab class="font-weight-bold rounded-lg caption">
                                 <strong>{{ $t('levels data') }}</strong>
                              </v-tab> -->
                              <v-tab :disabled="!(reimburses_expenses.length)" class="font-weight-bold rounded-lg caption">
                                 <strong>{{ $t('reimburses expenses') }}</strong>
                              </v-tab>
                           </v-tabs>
                        </v-col>
                        <v-col cols="12">
                           <v-tabs-items v-model="activeTab">
                              <!-- basic data -->
                              <v-tab-item>
                                 <v-col cols="12" md="12">
                                    <v-row align="center">
                                       <GenericInput type="date" :value="form.action_date"
                                          @input="form.action_date = $event" label="date" :required="true"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="number" v-if="$store.state.userData.client_id_status"
                                          :value="form.old_sys_id" @input="form.old_sys_id = $event"
                                          label="old transaction number" :required="false"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="select" v-if="pageData.isEdit" :lookups="allStatus"
                                          :value="form.clearance_status_id" @input="changeStatus($event)"
                                          label="clearance status" :multi="false" :required="true" selected_label="name"
                                          selected_prop="id" :isLoading="pageData.editIsLoading || statusLoading"
                                          :cols="[12, 4, 3]" />

                                       <GenericInput type="autocomplete" :lookups="customers" :value="form.customer_id"
                                          :clearable="true" @input="form.customer_id = $event" :required="true"
                                          :isLoading="customersLoading" :keyupValue="search_customer" label="customer"
                                          :multi="false" selected_label="name" selected_prop="id" :hide-details="true"
                                          :cols="[12, 4, 3]" />

                                       <GenericInput type="select" :lookups="delegates" :value="form.delegate_id"
                                          @input="form.delegate_id = $event" label="delegate" :multi="false"
                                          :required="true" selected_label="name" selected_prop="id"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>
                                       <!-- .................................................moved from ClassificationsControl.. -->
                                       <GenericInput type="select" :lookups="invoice_types" :value="form.invoice_type"
                                          @input="form.invoice_type = $event" label="invoice type" :multi="false"
                                          :required="false" selected_label="name" selected_prop="id"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="float" :value="form.weight" @input="form.weight = $event"
                                          label="the weight" :required="false" :isLoading="pageData.editIsLoading"
                                          :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="text" :value="form.supplier_invoice_code"
                                          @input="form.supplier_invoice_code = $event" label="supplier invoice number"
                                          :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>
                                       <GenericInput type="date" :value="form.invoice_date"
                                          @input="form.invoice_date = $event" label="invoice date" :required="true"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>
                                       <GenericInput type="float" :value="form.invoice_amount"
                                          @input="form.invoice_amount = $event" label="invoice value" :required="false"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="select" :lookups="currencies" :value="form.currency_id"
                                          @input="form.currency_id = $event" label="currency" :multi="false"
                                          :required="false" selected_label="name" selected_prop="id" :isLoading="false"
                                          :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="float" :value="form.shipping_amount"
                                          @input="form.shipping_amount = $event" label="shipping fee in Dollars"
                                          :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="select" :lookups="currencies" :value="form.shipping_currency_id"
                                          @input="form.shipping_currency_id = $event" label="shipping currency"
                                          :multi="false" :required="false" selected_label="name" selected_prop="id"
                                          :isLoading="false" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <!-- .................................................moved from ClassificationsControl.. -->
                                       <GenericInput type="text" :value="form.shipping_policy_no" :acceptZero="true"
                                          @input="form.shipping_policy_no = $event" label="shipping policy number"
                                          :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>


                                       <GenericInput type="select" :lookups="clearance_types" :value="form.clearance_type"
                                          @input="form.clearance_type = $event" label="shipping policy type" :multi="false"
                                          :required="false" selected_label="name" selected_prop="id"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="select" :lookups="ports" :value="form.port_id"
                                          @input="form.port_id = $event" label="port" :multi="false" :required="true"
                                          selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                                          :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">
                                          {{ $t('containers QTY') }} : <span class="gray6--text">{{ containersQTY }}</span>
                                       </v-col>

                                       <GenericInput type="mobile" :value="form.agent_number"
                                          @input="form.agent_number = $event" label="shipping agent number"
                                          :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="date" :value="form.arrival_date"
                                          :disabled="(form.clearance_status_id > 1)" @input="form.arrival_date = $event"
                                          label="expected date of arrival" :required="false"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>



                                       <GenericInput type="select" :lookups="countries" :value="form.country_id"
                                          @input="form.country_id = $event" label="country of origin" :multi="false"
                                          :required="false" selected_label="name" selected_prop="id"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <!-- <GenericInput type="autocomplete" :lookups="countries" :value="form.country_id"
                                          :clearable="true" @input="form.country_id = $event" :required="false"
                                          :isLoading="customersLoading" :keyupValue="search_customer" label="country of origin"
                                          :multi="false" selected_label="name" selected_prop="id" :hide-details="true"
                                          :cols="[12, 4, 3]" /> -->

                                       <GenericInput type="text" :value="form.invoice_2" @input="form.invoice_2 = $event"
                                          label="invoice 2" :required="false" :isLoading="pageData.editIsLoading"
                                          :cols="[12, 4, 3]">
                                       </GenericInput>
                                       <GenericInput type="text" :value="form.invoice_3" @input="form.invoice_3 = $event"
                                          label="invoice 3" :required="false" :isLoading="pageData.editIsLoading"
                                          :cols="[12, 4, 3]">
                                       </GenericInput>
                                       <GenericInput type="text" :value="form.invoice_4" @input="form.invoice_4 = $event"
                                          label="invoice 4" :required="false" :isLoading="pageData.editIsLoading"
                                          :cols="[12, 4, 3]">
                                       </GenericInput>

                                       <GenericInput type="text" :value="form.exporting_company"
                                          @input="form.exporting_company = $event" label="Exporting company"
                                          :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                                       </GenericInput>


                                       <v-row cols="12" class="d-flex flex-wrap pa-3" style="width: 100%;">

                                          <v-col cols="12" md="auto" v-for="policy in policies_types" :key="policy.id">
                                             <v-checkbox class="mt-0" v-model="form.policy_type_id"
                                                :value="policy.policy_type_id" hide-details
                                                :label="policy.policy_type_name"></v-checkbox>
                                          </v-col>

                                          <v-spacer></v-spacer>

                                          <v-col cols="12" md="6" :class="{ 'py-3': !policies_types.length }"
                                             class="flex-grow-1 pa-0 d-flex flex-wrap align-center justify-end">
                                             <GenericInput type="checkbox" :value="form.is_lcl"
                                                @input="form.is_lcl = $event" label="LCL" :required="false"
                                                :isLoading="pageData.editIsLoading" :cols="[12, 4, 'auto']">
                                             </GenericInput>

                                             <GenericInput type="number" v-if="form.is_lcl" :value="form.lcl_count"
                                                :acceptZero="true" @input="form.lcl_count = $event" label="package count"
                                                :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 6]">
                                             </GenericInput>
                                          </v-col>

                                       </v-row>



                                       <v-col cols="12" v-if="pageData.isEdit && form.has_problem">
                                          <v-alert dense :icon="false" class="font-weight-bold text-center" outlined
                                             type="error">
                                             {{ $t('Clearance') | capitalize }} {{ $t('has a problem') }}
                                          </v-alert>
                                       </v-col>

                                       <!-- Attachments -->
                                       <v-col cols="12" v-if="pageData.isEdit">
                                          <v-col cols="12" class="d-flex justify-end">
                                             <v-btn color="secondary" depressed @click="attachmentsDialog = true"
                                                :loading="attachDownloadLoading">
                                                {{ $t('add attachment') }}
                                             </v-btn>
                                             <v-btn color="primary" outlined class="blue12 mx-2"
                                                :disabled="(form.attachments.length == 0)" @click="downloadAllAttachment"
                                                depressed :loading="attachDownloadLoading">
                                                {{ $t('download all attachment') }}
                                                <v-icon size="20" right>mdi-download</v-icon>
                                             </v-btn>
                                          </v-col>
                                          <div>
                                             <GalleryFiles :attachments="form.attachments" width="250" height="250"
                                                downloadEndPoint="clearance_download_file"
                                                deleteEndPoint="clearance_delete_file" />
                                          </div>
                                          <div class="py-4" v-if="false">
                                             <v-row align="center">
                                                <v-col cols="12" md="6" lg="4" v-for="(attach, index) in form.attachments"
                                                   :key="index">
                                                   <v-hover v-slot="{ hover }">
                                                      <v-card height="280" class="overflow-hidden rounded-lg shadow pa-2">
                                                         <div class=" my-2" :class="{ 'opacity-015': hover }">
                                                            <div v-lazy-container="{ selector: 'img' }"
                                                               v-if="attach.file_type == 'jpeg' || attach.file_type == 'jpg' || attach.file_type == 'png' || attach.file_type == 'gif' || attach.file_type == 'svg'">
                                                               <img class="d-block ma-auto attachment_img"
                                                                  style="height: 200px;" :key="index"
                                                                  :data-src="String(attach.file_path).substring(0, 10) == 'data:image' ? attach.file_path : $api.serverUrl + attach.file_path"
                                                                  alt="qarat" />
                                                            </div>

                                                            <div v-else>
                                                               <img v-if="attach.file_type !== 'pdf'"
                                                                  :src="require(`@/assets/img/png/files/${attach.file_type}.png`)"
                                                                  height="200" class="d-block ma-auto" alt="qarat" />
                                                               <div v-if="attach.file_type == 'pdf'">
                                                                  <iframe width='100%' height='200'
                                                                     :src="String(attach.file_path).substring(0, 20) == 'data:application/pdf' ? attach.file_path : $api.serverUrl + attach.file_path" />
                                                               </div>

                                                            </div>
                                                         </div>
                                                         <div class="text-center subtitle-1" :class="{ 'd-none': hover }">
                                                            {{ attach.file_name }}</div>

                                                         <div class="absolute-center justify-end px-5"
                                                            :class="{ 'd-none': !hover }">
                                                            <v-btn icon color="primary" class="mx-3" large
                                                               v-if="pageData.isEdit" @click="downloadAttachment(attach)"
                                                               :loading="attachDownloadLoading">
                                                               <v-icon size="40">mdi-download</v-icon>
                                                            </v-btn>
                                                         </div>
                                                      </v-card>
                                                   </v-hover>
                                                </v-col>
                                             </v-row>
                                             <v-col v-if="form.attachments.length == 0" cols="12">
                                                <v-alert type="primary" outlined dense :value="true">
                                                   {{ $t('no attachment') }}
                                                </v-alert>
                                             </v-col>
                                          </div>
                                       </v-col>


                                    </v-row>
                                 </v-col>
                              </v-tab-item>

                              <!-- reimburses expenses -->
                              <v-tab-item>
                                 <v-row>
                                    <v-col cols="12">
                                       <DynamicTable :isLoading="pageData.isLoading" :data="reimburses_expenses || []"
                                          :header="tableRExpensesHeader"
                                          :tableHeight="$store.state.Settings.windowSize.y - 400" />
                                    </v-col>
                                 </v-row>
                              </v-tab-item>
                           </v-tabs-items>
                        </v-col>


                     </v-row>
                  </v-expand-transition>
               </v-card>
            </v-form>

            <!-- Details Table -->
            <div class="pa-2" id="container_table" v-show="activeTab == 0">

               <div cols="12" v-if="tableRows?.length === 0 && form.is_lcl == 0">
                  <v-btn outlined class="pa-10 " depressed color="blue6" width="100%" @click="addNewDetailsLine">
                     <v-icon>mdi mdi-file-plus</v-icon>
                     {{ $t('add container details') }}
                  </v-btn>
               </div>

               <DynamicTable v-show="tableRows?.length" :isLoading="pageData.isLoading" :data="tableRows || []"
                  :header="tableHeader" :tableHeight="$store.state.Settings.windowSize.y - 230">
                  <template v-slot:td="{ row, header, index }">

                     <div v-if="header.key == 'container_number'" style="width: 100%;">
                        <!-- :inputBlur="checkContainer" -->
                        <GenericInput type="text" solo :value="row.container_number" @input="row.container_number = $event"
                           :isLoading="false" :required="true" :cols="[12, 12, 12]">
                        </GenericInput>
                     </div>

                     <div v-if="header.key == 'container_size'" style="width: 100%;"
                        @keydown="addNewRowByTab($event, index)">
                        <GenericInput type="select" :lookups="sizes" :value="row.container_size"
                           @input="row.container_size = $event" :multi="false" :required="true" selected_label="name"
                           selected_prop="name" :isLoading="false" :cols="[12, 12, 12]">
                        </GenericInput>
                     </div>

                     <div v-if="header.key == 'delete'" class="d-flex align-center">

                        <span @click="addNewRowByTab($event, index)">
                           <v-tooltip bottom color="success">
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon color="success" class="cursor_pointer mb-1" size="26" v-bind="attrs" v-on="on">mdi
                                    mdi-plus</v-icon>
                              </template>
                              <span>{{ $t('add line') }}</span>
                           </v-tooltip>
                        </span>

                        <span>
                           <v-tooltip bottom color="error">
                              <template v-slot:activator="{ on, attrs }">
                                 <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                                    @click="removeItem(index, row)" src="@/assets/img/svg_icons/delete_icon.svg" />
                              </template>
                              <span>{{ $t('delete') }}</span>
                           </v-tooltip>
                        </span>

                     </div>
                  </template>
               </DynamicTable>
            </div>
            <!-- Detail Form -->
            <ClearanceDetail :sizes=sizes :detailAction="detailAction" :dialog="detailDialog" :tableRows="tableRows"
               :detail="detail" :updateDetail="updateDetail" :isDetailEdit="isDetailEdit" :detailIndex="detailIndex" />
            <!-- Detail of Form -->
         </div>
      </v-container>

      <v-dialog v-model="attachmentsDialog" overlay-opacity="0.75" persistent :max-width="950">
         <v-card relative class="pa-7 py-10">
            <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="attachmentsDialog = false">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-row justify="center" class="mt-2" v-if="pageData.isEdit">


               <GenericInput type="file" v-for="(attachment) in file_names" :key="attachment.id" :value="attachment.value"
                  @input="attachment.value = $event" :label="attachment.name" :multi="false" :required="false"
                  selected_label="name" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
               </GenericInput>


               <v-col cols="12" class="d-flex justify-end">
                  <v-btn class="mx-2" text depressed color="red4" @click="attachmentsDialog = false" :height="37"
                     :width="120">
                     {{ $t('cancel') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="saveAttachment" :loading="pageData.rowIsLoading.loading"
                     depressed color="success" :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-dialog>

      <v-dialog v-model="openClearanceLevelsData" overlay-opacity="0.75" persistent
         :max-width="$store.state.Settings.windowSize.x - 300">
         <v-card relative class="pa-7 py-10">
            <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="openClearanceLevelsData = false">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-row justify="center" class="mt-2">
               <v-col cols="12" class="pa-0">
                  <v-card style="overflow-y: scroll; overflow-x: hidden;" class="pa-5 shadow-none"
                     :max-height="$store.state.Settings.windowSize.y - 250">
                     <v-form ref="levelsForm" v-model="levelsFormValid" lazy-validation>
                        <!-- levels data -->
                        <v-row>
                           <v-col cols="12" v-if="levelsForm.clearance_status_id >= 2" class="subtitle-2 ">
                              {{ $t('level') }} {{ $t('required clearance') }}
                           </v-col>
                           <GenericInput type="date" v-if="levelsForm.clearance_status_id >= 2"
                              :value="levelsForm.mainfest_date" @input="levelsForm.mainfest_date = $event"
                              label="manifest date" :required="false" :isLoading="pageData.editIsLoading"
                              :cols="[12, 4, 4]">
                           </GenericInput>
                           <GenericInput type="text" v-if="levelsForm.clearance_status_id >= 2"
                              :value="levelsForm.mainfest_no" @input="levelsForm.mainfest_no = $event" label="manifest no"
                              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           </GenericInput>
                           <v-col cols="12" v-if="levelsForm.clearance_status_id >= 3">
                              <v-divider />
                           </v-col>

                           <v-col cols="12" v-if="levelsForm.clearance_status_id >= 2" class="subtitle-2 ">
                              {{ $t('level') }} {{ $t('preview clearance') }}
                           </v-col>
                           <GenericInput type="date" v-if="levelsForm.clearance_status_id >= 3"
                              :value="levelsForm.customs_declaration_date"
                              @input="levelsForm.customs_declaration_date = $event" label="declaration date"
                              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           </GenericInput>

                           <GenericInput type="text" v-if="levelsForm.clearance_status_id >= 3"
                              :value="levelsForm.customs_declaration_number"
                              @input="levelsForm.customs_declaration_number = $event" label="customs declaration number"
                              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           </GenericInput>

                           <GenericInput type="date" v-if="levelsForm.clearance_status_id >= 3"
                              :value="levelsForm.unloading_date" @input="levelsForm.unloading_date = $event"
                              label="unloading date" :required="false" :isLoading="pageData.editIsLoading"
                              :cols="[12, 4, 4]">
                           </GenericInput>
                           <GenericInput type="file" v-if="levelsForm.clearance_status_id >= 3"
                              :value="levelsForm.customs_declaration_file"
                              @input="levelsForm.customs_declaration_file = $event" label="customs declaration file"
                              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           </GenericInput>


                           <v-col cols="12" v-if="levelsForm.clearance_status_id > 1">
                              <v-divider />
                           </v-col>
                           <GenericInput type="date" :value="levelsForm.customs_floor_date"
                              @input="levelsForm.customs_floor_date = $event" label="floor date" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           </GenericInput>

                           <GenericInput type="text" :value="levelsForm.port_withdraw"
                              @input="levelsForm.port_withdraw = $event" label="port numbers" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           </GenericInput>

                           <GenericInput type="text" :value="levelsForm.inspection_number"
                              @input="levelsForm.inspection_number = $event" label="inspection no" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           </GenericInput>

                           <GenericInput type="text" :value="levelsForm.withdrawal_note"
                              @input="levelsForm.withdrawal_note = $event" label="withdrawal number" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           </GenericInput>
                        </v-row>
                     </v-form>
                  </v-card>
               </v-col>

               <v-col cols="12" class="d-flex justify-end">
                  <v-btn class="mx-2" text depressed color="red4" @click="openClearanceLevelsData = false" :height="37"
                     :width="120">
                     {{ $t('cancel') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="updateLevelData" :loading="isLevelLoading" depressed
                     color="success" :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-dialog>

      <ChatDialog :dialog="timeLineDialog" :chat="timeline" :isLoading="pageData.isLoading" :saveMessage="addComment"
         :closeDialog="() => timeLineDialog = false" />

   </section>
</template>



<script>

import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import ClearanceDetail from "./ClearanceDetail.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import ChatDialog from "@/components/ui/ChatDialog.vue";
import GalleryFiles from "@/components/GalleryFiles.vue";
export default {
   name: "ClearanceControl",
   data: () => ({
      pageData: {
         screen_code: "04-004",
         url: null,
         controlRoute: "/sales/clearance/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         rowIsLoading: {
            loading: false,
            id: null,
            index: null,
            key: 'id'
         },
      },
      activeTab: 0,
      formCollapse: false,
      viewForm: false,
      valid: false,
      levelsFormValid: false,
      openClearanceLevelsData: false,
      isLevelLoading: false,
      levelsForm: Object,
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      tableHeader: [],
      allStatus: [],
      tableRows: [],
      tableOption: {},
      delegates: [],
      customers: [],
      ports: [],
      sizes: [],
      policies_types: [],
      clearance_types: [],
      covenant_types: [],
      file_names: [],
      attachmentsDialog: false,
      attachDownloadLoading: false,
      comment: null,
      addCommentDialog: false,
      timeline: [],
      reimburses_expenses: [],
      tableRExpensesHeader: [],
      currencies: [],
      invoice_types: [],
      countries: [],
      timeLineDialog: false,
      statusLoading: false,
      customersLoading: false,
      asyncSearchCustomer: null,
      form: {
         action_date: null,
         is_lcl: 0,
         container_count: null,
         port_id: null,
         delegate_id: null,
         delegate_name: null,
         shipping_policy_no: 0,
         clearance_type: 0,
         customer_id: 0,
         arrival_date: null,
         agent_number: null,
         has_problem: 0,
         clearance_status_id: 1,
         invoice_type: null,
         supplier_invoice_code: null,
         invoice_amount: null,
         currency_id: null,
         shipping_amount: null,
         shipping_currency_id: null,
         lcl_count: null,
         exporting_company: null,
         weight: null,
         invoice_date: null,
         country_id: null,
         invoice_2: null,
         invoice_3: null,
         invoice_4: null,
         policy_type_id: null,
         items: [],
         attachments: [],
      },
      detail: {
         container_number: null,
         container_size: null,
         id: 0,
      },

   }),
   components: {
      ControlHeader,
      DynamicTable,
      AnimatedLoading,
      ClearanceDetail,
      GenericInput,
      ChatDialog,
      GalleryFiles
   },
   computed: {
      containersQTY() {
         return this.tableRows.length
      },
      search_customer() {
         let asyncSearch = null;
         return (val) => {
            console.log({ val });
            clearTimeout(asyncSearch)

            if (val) {
               asyncSearch = setTimeout(() => {
                  this.customersLoading = true
                  this.$api.GET_METHOD(`customer?word=${val}`)
                     .then((response) => {
                        this.customers.push(...response.data.items)
                     })
                     .finally(() => (this.customersLoading = false))
               }, 750)
            }
         }
      },
   },
   watch: {
      'form.is_lcl'() {
         if (this.form.is_lcl == 1) {
            this.tableRows = [];
         } else {
            this.form.lcl_count = null;
            this.addNewDetailsLine();
         }
      },
      $route() {
         this.pageMainData();
         this.detailDialog = false;
         this.getData();
      },
      tableRows() {
         // this.tableRows.forEach(row => {
         //    row.size_name = row.container_size ? this.$global.FilterArrayOfObjectElement(this.sizes, 'id', row.container_size, 'name') : null;
         // });
      },
      '$store.state.Settings.key_clicked'() {
         switch (this.$store.state.Settings.key_clicked) {
            case 'F3':
               this.form.is_lcl === 0 && this.tableRows.push({ ...this.detail });
               break;
            default:
               break;
         }
      },
      // 'form.customer_id'() {
      //    console.log('sss', this.form.customer_id = 10031434);
      //    this.search_customer('سيد ادهم')
      // }
   },
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate();
      this.detailDialog = false;
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
         this.tableHeader = [
            { text: "container number", key: "container_number", type: 'slot', classes: "" },
            { text: "size", key: "container_size", type: 'slot', classes: "" },
            { text: "actions", key: "delete", type: 'slot', classes: "" },
         ];
         this.tableRExpensesHeader = [
            { text: "invoice", key: "invoice_id", type: 'text', classes: "" },
            { text: "expense code", key: "expense_code_name", type: 'text', classes: "" },
            { text: "amount", key: "amount", type: 'float', classes: "" },
            { text: "description", key: "description", type: 'text', classes: "" },
            { text: "date", key: "add_date", type: 'date', classes: "" },
         ];
      },
      getData(isLoading = true) {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = isLoading;
               this.pageData.isLoading = isLoading;
               this.$api.GET_METHOD(`clearance/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.comment = null;
                     this.addCommentDialog = false;
                     // this.timeLineDialog = false;
                     // this.customers = response.data.customers || [];
                     this.delegates = response.data.delegates || [];
                     this.allStatus = response.data.status || [];
                     this.invoice_types = response.data.types || [];
                     this.currencies = response.data.currencys || [];
                     this.countries = response.data.countrys || [];
                     this.reimburses_expenses = response.data.reimburses_expenses || [];

                     this.ports = response.data.ports || [];
                     this.form = response.data.master;
                     this.customers.push({ id: this.form.customer_id, name: this.form.customer_name })
                     this.levelsForm = response.data.master;
                     this.timeline = response.data.comments || [];
                     this.form.attachments = response.data.files || [];
                     this.policies_types = response.data.policies_types || [];
                     response.data.file_names.forEach((file) => {
                        file.value = null;
                     });
                     this.file_names = response.data.file_names || [];
                     this.tableRows = response.data.details || [];
                     this.clearance_types = [{ id: 1, name: this.$i18n.t('international') },
                     { id: 2, name: this.$i18n.t('regional') },]
                     this.sizes = [{ id: 40, name: 40 },
                     { id: 20, name: 20 },]

                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`clearance/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.customers = response.data.customers || [];
                     this.delegates = response.data.delegates || [];
                     this.ports = response.data.ports || [];
                     this.file_names = response.data.file_names || [];
                     this.invoice_types = response.data.types || [];
                     this.currencies = response.data.currencys || [];
                     this.countries = response.data.countrys || [];
                     this.policies_types = response.data.policies_types || [];
                     this.addNewDetailsLine()
                     this.clearance_types = [{ id: 1, name: this.$i18n.t('international') },
                     { id: 2, name: this.$i18n.t('regional') },]
                     this.sizes = [{ id: 1, name: 40 },
                     { id: 2, name: 20 },]
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`clearance/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`clearance`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      updateLevelData() {
         this.isLevelLoading = true;
         // Collect level keys
         let requestBody = this.$global.SelectFromObject(this.levelsForm, 'mainfest_date', 'mainfest_no', 'customs_declaration_date', 'customs_declaration_number', 'unloading_date', 'customs_declaration_file', 'customs_floor_date', 'port_withdraw', 'inspection_number', 'withdrawal_note')
         // tells backend that no update in details (items_update = 0)
         requestBody.items_update = 0;
         this.$api.POST_METHOD(`update_clearance_item/${this.$route.params.id}`, requestBody).then((response) => {
            this.isLevelLoading = false;
            if (response.check) {
               // update form by collected level keys
               this.form = { ...this.form, ...requestBody }
               this.openClearanceLevelsData = false;
            }
         })
      },
      detailAction(detail, isEdit, cancel) {
         console.log(detail, isEdit, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.detail = {
               expense_code_id: null,
               treatment_id: null,
               receipt_number: null,
               arabic_description: null,
               amount: null,
            }
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.tableRows.push(detail);
            this.detail = {
               container_number: null,
               container_size: null,
            }
         }

      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.detail = {
            container_number: null,
            container_size: null,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`delegate_petty/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
      addComment(comment) {
         this.pageData.isLoading = true;
         this.$api.POST_METHOD(`clearance_add_comment/${this.form.id}`, { comment: comment }).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
               this.getData();
            }
         });
      },
      downloadAttachment(attach) {
         console.log(attach);
         this.attachDownloadLoading = true
         var src = `clearance_download_file/${attach.id}`;
         var download_file_name = `${attach.file_name}.${attach.file_type}`
         this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            this.attachDownloadLoading = false;
         })
      },
      downloadAllAttachment() {
         this.form.attachments.forEach(attachment => {
            this.downloadAttachment(attachment, 0)
         });
      },
      saveAttachment() {
         // this.pageData.isLoading = true;
         this.pageData.rowIsLoading.loading = true;
         this.file_names.forEach((attachment, index) => {
            if (attachment.value && attachment.id) {
               this.pageData.rowIsLoading.loading = true;
               this.pageData.rowIsLoading.id = this.form.id;
               const requestBody = {
                  clearance_file_name_id: attachment.id,
                  file: attachment.value,
               }
               this.$api.POST_METHOD(`clearance_add_file/${this.form.id}`, requestBody).then((response) => {
                  if (response.check) {
                     this.pageData.rowIsLoading.loading = false;
                     this.pageData.rowIsLoading.id = null;
                     this.pageData.rowIsLoading.index = null;
                     this.getData(false)
                     attachment.value = null;
                     if (this.file_names.length == index + 1) {
                        this.attachmentsDialog = false
                     }
                  }
               })
            }
         });
         this.attachmentsDialog = false
      },
      changeStatus(status) {
         this.statusLoading = true;
         this.$api.POST_METHOD(`update_status/${this.form.id}`, { status_id: status }).then((response) => {
            this.statusLoading = false;
            if (response.check) {
               this.form.clearance_status_id = status
            }
         })
      },
      addNewDetailsLine() {
         this.tableRows.push({ ...this.detail });
      },
      addNewRowByTab(event, index) {
         console.log('event', event);
         if (event.key == "Tab" || event.type == "click") {
            if (typeof index === 'number') {
               this.tableRows.splice((index + 1), 0, { ...this.detail })
            } else {
               this.tableRows.push({ ...this.detail });
            }
         }
      },
   },
};
</script>

<style lang="scss" >
#salesModule_clearance_control {

   .attachment_img {
      object-fit: contain;
      width: 100%;
   }

   #container_table table {
      tr:nth-of-type(even) {
         background-color: unset !important;
      }
   }
}
</style>