import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// Views :-
import DashboardERP from "@/views/ERP/DashboardERP";
import HomeModule from "@/views/HomeModule/HomeModule";
import SettingsModule from "@/views/SettingsModule/SettingsModule";
import AccountingModule from "@/views/AccountingModule/AccountingModule";
import TransportModule from "@/views/TransportModule/TransportModule.vue";
import SalesModule from "../views/SalesModule/SalesModule.vue";
import Auth from "@/views/Auth/Auth";
import LoginPage from "@/views/Auth/LoginPage";
import ForgetPassword from "@/views/Auth/ForgetPassword";
import Error500 from "@/views/Errors/Error500";
import SystemUpdate from "@/views/Errors/SystemUpdate";
import Error404 from "@/views/Errors/Error404";

// Modules Children Routes :-

import { HomeModuleChildrens } from "@/router/HomeModuleRoutes";
import { SettingsModuleChildrens } from "@/router/SettingsModuleRoutes";
import { AccountingModuleChildrens } from "@/router/AccountingModuleRoutes";
import { SalesModuleChildrens } from "@/router/SalesModuleRoutes";
import { TransportModuleRoutes } from "@/router/TransportModuleRoutes";


Vue.use(VueRouter);

// Routes :-
const routes = [
  // Authorized :-
  {
    path: "/",
    component: DashboardERP,
    beforeEnter: (to, from, next) => {
      store.dispatch("checkAppVersion").then();
      store.dispatch("checkAuth").then();
      console.log("beforeEnter and checkAuth", store.state.isAuth);
      if (store.state.isAuth === true) {
        store.dispatch("getUserData").then();
        next();
      } else {
        router.push("/login").then();
      }
    },
    children: [
      {
        path: "",
        component: HomeModule,
        children: [...HomeModuleChildrens],
      },
      {
        path: "/settings",
        name: "SettingsModule",
        component: SettingsModule,
        children: [...SettingsModuleChildrens],
      },
      {
        path: "/accounting",
        name: "AccountingModule",
        component: AccountingModule,
        children: [...AccountingModuleChildrens],
      },
      {
        path: "/sales",
        name: "SalesModule",
        component: SalesModule,
        children: [...SalesModuleChildrens],
      },
      {
        path: "/accounting",
        name: "TransportModule",
        component: TransportModule,
        children: [...TransportModuleRoutes],
      },
    ],
  },
  // Not Authorized :-
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "/",
        name: "LoginPageAuth",
        component: LoginPage,
        meta: {
          title: "login",
        },
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
        meta: {
          title: "Forget Password",
        },
      },
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
        meta: {
          title: "login",
        },
      },
    ],
  },
  // Error Pages  :-
  {
    path: "/error500",
    name: "Error500",
    component: Error500,
  },
  {
    path: "/system_update",
    name: "SystemUpdate",
    component: SystemUpdate,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to);
  next();
});

export default router;
