<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :showSearch="false" :getData="getData" actionBtnText="Search"
        :showDeleted="false" :createBtn="false" :actionBtn="!showFilter" :actionBtnValid="true"
        actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
        actionBtnClass="blue4--text" :getSearch="getSearch">
        <template v-slot:rightSide>
          <v-col cols="12" sm="auto">
            <ExportBtn :exportData="warehouse_report" v-if="warehouse_report.length"
              :header="pageData.tableHeader" :footerData="footerData" :showGroupHeader="true" :groupHeader="groupHeader"
              :attachmentFileName="pageData.entityName" :printSubTitle="printTitle" />
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->

      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" class="">
              <GenericInput type="date" :isPickerOpened="() => to_date = null" :value="from_date"
                :maxDate="to_date || today" @input="from_date = $event" label="from" :required="false"
                :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="date" :value="to_date" :minDate="from_date" :maxDate="today" @input="to_date = $event"
                label="to" :required="false" :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="select" solo :clearable="true" :lookups="warehouses" :value="warehouse_id"
                @input="warehouse_id = $event" label="terminal" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.reportIsLoading)"
                :cols="[12, 6, 3]">
              </GenericInput>
              <v-col cols="auto" md="6" lg="3" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="primary" outlined :disabled="!(warehouse_id)"
                  :loading="(pageData.isLoading || pageData.reportIsLoading)" @click="getReport"
                  class="mx-1 white--text">
                  {{$t("Search")}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--  -->
      <v-row v-if="warehouse_report.length > 0">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="warehouse_report" :printSubTitle="printTitle"
            :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 260 : $store.state.Settings.windowSize.y - 150"
            :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData" :showGroupHeader="true"
            :groupHeader="groupHeader" :customize_header_footer="true"  :printStaticData="printStaticData" />
 
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import ExportBtn from "@/components/ui/ExportBtn.vue";
export default {
  name: "WarehouseReportEntity",

  data: () => ({
    pageData: {
      screen_code: "03-013",
      url: null,
      controlRoute: "transport/warehouse_report-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      reportIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    groupHeader: [],
    printStaticData: Object,
    showFilter: true,
    from_date: null,
    to_date: null,
    today: null,
    warehouse_report: [],
    warehouses: [],
    warehouse_id: null,
  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput,
    ExportBtn
  },
  watch: {
    warehouse_id() {
      this.warehouse_report = [];
      this.getReport();
    }
  },
  computed: {
    printTitle() {
      let title = '';
      if (this.from_date) {
        title = this.from_date
      }
      if (this.to_date) {
        title = title + (this.from_date ? ' - ' : '') + this.to_date
      }
      if (this.warehouse_id) {
        const warehouse = this.warehouses.find((warehouse) => warehouse.id == +this.warehouse_id)
        title = title + ' / ' + this.$i18n.t('terminal') + ' : ' + warehouse.name
      }
      return title
    }
  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.to_date = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.getData();

  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "" },
        { text: "in date", key: "in_date", type: 'date', classes: "" },
        { text: "out date", key: "out_date", type: 'date', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "size", key: "container_size", type: 'text', classes: "" },
        { text: "status", key: "container_status", type: 'text', classes: "" },
        { text: "driver", key: "driver_name", type: 'text', classes: "" },
        { text: "car", key: "car_number", type: 'text', classes: "" },
        { text: "clearance number", key: "clearance_m_id", type: 'text', classes: "" },
        { text: "container number", key: "container_number", type: 'text', classes: "" },
        { text: "status", key: "status_title", type: 'text', classes: "" },
      ];
      this.groupHeader = [
        { text: " ", colspan: 1, classes: "" },
        { text: "date", colspan: 2, classes: "" },
        { text: "", colspan: 1, classes: "" },
        { text: "container", colspan: 2, classes: "" },
        { text: "driver", colspan: 4, classes: "" },
        { text: "", colspan: 1, classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      this.pageData.isLoading = true;
      this.pageData.reportIsLoading = true;
      this.$api.GET_METHOD(`warehouse_report`).then((response) => {
        this.pageData.reportIsLoading = false;
        this.pageData.isLoading = false;
        if (response.check) {
          this.warehouses = response.data.wearhouses || [];
        }
      });
    },
    getReport() {
      this.pageData.reportIsLoading = true;
      const body = {
        warehouse_id: this.warehouse_id,
        from_date: this.from_date,
        to_date: this.to_date,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`warehouse_report`, body, false)
        .then((response) => {
          this.pageData.reportIsLoading = false;
          if (response.check) {
            response.data.report.forEach(row => {
              row.container_status = row.is_empty ? this.$i18n.t('empty'): this.$i18n.t('packed');
              switch (Number(row.status)) {
                  case 1:
                    row.status_title = this.$i18n.t("pending");
                    break;
                  case 2:
                    row.status_title = this.$i18n.t("stored");
                    break;
                  case 3:
                    row.status_title = this.$i18n.t("exited");
                    break;

                  default:
                    break;
                }
            });
            this.warehouse_report = response.data.report || [];
            this.printStaticData = response.data.print;
          }
        });
    },
    print(type) {
      this.pageData.printLoading = true;
      const body = {
        warehouse_id: this.warehouse_id,
        from_date: this.from_date,
        to_date: this.to_date,
        type: type
      }
      this.$api.DOWNLOAD_METHOD_BODY(`warehouse_report`, body, `warehouse_report.${type}`).then((response) => {
        this.pageData.printLoading = false;
        if (response.check) {
          // this.pageData.rows = response.data.report;
        }
      });
    },
  },
};
</script>
