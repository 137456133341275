<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">

                  <GenericInput type="select" :lookups="status" :value="form.status" @input="form.status = $event"
                     label="status" :multi="false" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <!-- <GenericInput type="checkbox" :value="form.has_damage" @input="form.has_damage = $event"
                     label="shipment has damage?" :required="false" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput> -->
                  <GenericInput type="checkbox" :value="form.customer_pay" @input="form.customer_pay = $event"
                     label="damage value on customer" :required="false" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.damage_cost" @input="form.damage_cost = $event"
                     label="damage value" :required="false" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>
                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event"
                     label="container details" :required="false" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
   name: "ContainerDamageControl",

   data: () => ({
      pageData: {
         screen_code: "03-010",
         url: null,
         controlRoute: "/transport/container-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      status: [],
      form: {
         _method: "PUT",
         status: null,
         has_damage: 0,
         notes: null,
         customer_pay: 1,
         damage_cost: null
      },
   }),
   components: {
      GenericInput,
      ControlHeader
   },
   watch: {

   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`container/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.form.status = response.data.data.container_status_id;
                     this.status = response.data.status;
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`container/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.status = response.data.status;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`container/${this.form.container_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`container`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`container/${this.form.container_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
