<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && tableRows.length > 0"
            :deleteMethod="deleteMethod" :editForm="false" :showDelete="false" createBtnText="save">
            <template v-slot:centerSide>
               <div class="font-weight-bold subtitle-1 primary--text"
                  v-if="form.total_amount && form.delegate_petty_status == 2 && formCollapse">
                  {{ $t('total petty cash') }} : {{ form.total_amount | float }}</div>
            </template>
         </ControlHeader>
         <!-- End Of Header -->
         <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
            <AnimatedLoading :height="100" :hideText="false" :isLoading="pageData.isLoading" />
         </v-col>

         <div v-if="!pageData.isLoading">
            <!-- content -->
            <!-- Form -->
            <v-form ref="form" v-model="valid" class="pa-3">
               <v-card class="backgroundW shadow pa-5 pt-7">
                  <v-fade-transition>
                     <div class="collapseForm" v-if="tableRows.length > 0 || form.delegate_petty_status == 2">
                        <div class="d-flex">
                           <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                              <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                              <v-icon v-else>mdi-chevron-down</v-icon>
                           </v-btn>
                        </div>
                     </div>
                  </v-fade-transition>
                  <v-expand-transition>
                     <v-row align="center" v-show="!formCollapse">
                        <v-col cols="12" md="12">
                           <v-row align="center" v-if="pageData.isEdit">
                              <GenericInput type="date" solo :value="form.action_date" @input="form.action_date = $event"
                                 label="action date" :required="true" :isLoading="false" :cols="[12, 6, 4]" />
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('delegate') }} :
                                    <span class="gray6--text"> {{ form.delegate_name || '-' | capitalize }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('daily type') }} :
                                    <span class="gray6--text"> {{ form.daily_type_name || '-' | capitalize }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('type') }} :
                                    <span class="gray6--text">
                                       {{ form.source == 'PV' ? $t('payment voucher') : $t('receipt voucher') | capitalize
                                       }}</span>
                                 </div>
                              </v-col>
                              <!-- <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold" >
                                    {{ $t('remaining') }} :
                                    <span class="gray6--text" dir="ltr"> {{ form.remaining_amount || 0 | float }}</span>
                                 </div>
                              </v-col> -->

                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('total') }} :
                                    <span class="gray6--text"> {{ form.total_amount || '-' | capitalize }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="12">
                                 <v-btn outlined class="d-block mi-start-auto" depressed color="blue2" :min-width="120"
                                    @click="detailDialog = true" :height="37">
                                    {{ $t('add line') }}
                                 </v-btn>
                              </v-col>
                           </v-row>
                           <v-row align="center" v-else>
                              <GenericInput type="date" solo :value="form.action_date" @input="form.action_date = $event"
                                 label="action date" :required="true" :isLoading="false" :cols="[12, 6, 4]" />
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('delegate') }} :
                                    <span class="gray6--text"> {{ form.name || '-' | capitalize }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('account') }} :
                                    <span class="gray6--text"> {{ form.account_tree_name || '-' | capitalize }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('balance') }} :
                                    <span class="gray6--text"> {{ form.balance || 0 | float }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="12">
                                 <v-btn outlined class="d-block mi-start-auto" depressed color="blue2" :min-width="120"
                                    @click="detailDialog = true" :height="37">
                                    {{ $t('add line') }}
                                 </v-btn>
                              </v-col>
                           </v-row>
                        </v-col>
                     </v-row>
                  </v-expand-transition>
               </v-card>
            </v-form>

            <!-- Details Table -->
            <div class="pa-2">
               <DynamicTable :isLoading="pageData.isLoading" :data="tableRows || []" :footerData="[totalsCalculations]"
                  :header="tableHeader" :tableHeight="$store.state.Settings.windowSize.y - 230">
                  <template v-slot:td="{ row, header, index }">
                     <div v-if="header.key == 'actions'">
                        <v-btn @click="editDetail(index, row)" v-if="!row.invoice_id" x-small icon fab>
                           <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                        </v-btn>
                        <v-btn @click="removeItem(index, row)" v-if="!row.invoice_id" x-small icon fab>
                           <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                        </v-btn>
                     </div>
                  </template>
               </DynamicTable>
            </div>
            <!-- Detail Form -->
            <DPettyCashDetail :detailAction="detailAction" :expense_codes="expense_codes" :taxes="taxes" :treasuries="treasuries"
                :dialog="detailDialog" :tableRows="tableRows" :detail="detail"
               :updateDetail="updateDetail" :isDetailEdit="isDetailEdit" />
            <!-- Detail of Form -->
         </div>
      </v-container>


   </section>
</template>



<script>

import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import DPettyCashDetail from "./DPettyCashDetail.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "DelegatePettyCashControl",
   data: () => ({
      pageData: {
         screen_code: "02-019",
         url: null,
         controlRoute: "/accounting/delegate_petty-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      formCollapse: false,
      viewForm: false,
      valid: false,
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      getContainerLoading: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      daily_types: [],
      expense_codes: [],
      cost_centers: [],
      delegates: [],
      containers: [],
      taxes: [],
      treasuries: [],
      covenant_types: [],
      form: {
         delegate_id: null,
         description: null,
         action_date: null,
         items: [],
      },
      detail: {
         expense_code_id: null,
         clearance_id: null,
         receipt_number: null,
         description: null,
         amount: null,
         tax_id: null,
         treasury_id: null,
         taxable: 0,
         tax_percentage: 0,
         total_vat: 0,
         total_with_vat: 0,
      }
   }),
   components: {
      ControlHeader,
      DynamicTable,
      AnimatedLoading,
      DPettyCashDetail,
      GenericInput
   },
   computed: {
      totalsCalculations() {
         if (this.tableRows.length > 0) {
            const totalWithoutVat = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_without_vat);
            }, 0);
            const totalVat = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_vat);
            }, 0);
            const totalWithVat = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_with_vat);
            }, 0);
            return {
               clearance_id: this.$i18n.t('total'),
               total_without_vat: totalWithoutVat,
               total_vat: totalVat,
               total_with_vat: totalWithVat,
            }
         } else {
            return {
               clearance_id: this.$i18n.t('total'),
               total_without_vat: 0,
               total_vat: 0,
               total_with_vat: 0,
            }
         }
      },
   },
   watch: {
      'form.delegate_petty_status'() {
         if (this.form.delegate_petty_status == 2) {
            this.tableHeader = [
               { text: "clearance", key: "clearance_id", type: 'text', classes: "" },
               { text: "expense code", key: "expense_code_name", type: 'text', classes: "" },
               { text: "receipt number", key: "receipt_number", type: 'text', classes: "" },
               { text: "total without vat", key: "total_without_vat", type: 'float', classes: "" },
               { text: "total vat", key: "total_vat", type: 'float', classes: "" },
               { text: "total after vat", key: "total_with_vat", type: 'float', classes: "" },
               { text: "description", key: "description", type: 'text', classes: "" },
            ];
         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            let tax = this.taxes.find(tax => tax.id == row.tax_id) || {};
            row.tax_title = tax.name;
            row.tax_percentage = tax.tax_percentage || 0;
            row.total_with_vat = +row.amount
            row.total_without_vat = +row.total_with_vat - (+row.total_with_vat - +row.total_with_vat / (row.tax_percentage / 100 + 1));
            row.total_vat = row.total_with_vat - row.total_without_vat;
            row.expense_code_name = row.expense_code_id ? this.$global.FilterArrayOfObjectElement(this.expense_codes, 'id', row.expense_code_id, 'name') : null;
            console.log('row', row);
         });
      }
   },
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "clearance", key: "clearance_id", type: 'text', classes: "" },
            { text: "expense code", key: "expense_code_name", type: 'text', classes: "" },
            { text: "receipt number", key: "receipt_number", type: 'text', classes: "" },
            { text: "vat type", key: "tax_title", type: 'text', classes: "" },
            { text: "total without vat", key: "total_without_vat", type: 'float', classes: "" },
            { text: "total vat", key: "total_vat", type: 'float', classes: "" },
            { text: "total after vat", key: "total_with_vat", type: 'float', classes: "" },
            { text: "description", key: "description", type: 'text', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
         ];

      },

      getData() {
         if (this.$route.params.delegate_id && this.$route.query.show) {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.pageData.isEdit = true;
               this.$api.GET_METHOD(`delegate_petty/${this.$route.params.delegate_id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.master
                     this.tableRows = response.data.details || [];
                     this.expense_codes = response.data.expense_codes || [];
                     this.delegates = response.data.delegates || [];
                     this.taxes = response.data.taxes || [];
                     this.treasuries = response.data.treasuries || [];
                     this.tableHeader = [
                        { text: "clearance", key: "clearance_id", type: 'text', classes: "" },
                        { text: "expense code", key: "expense_code_name", type: 'text', classes: "" },
                        { text: "receipt number", key: "receipt_number", type: 'text', classes: "" },
                        { text: "vat type", key: "tax_title", type: 'text', classes: "" },
                        { text: "total without vat", key: "total_without_vat", type: 'float', classes: "" },
                        { text: "total vat", key: "total_vat", type: 'float', classes: "" },
                        { text: "total after vat", key: "total_with_vat", type: 'float', classes: "" },
                        { text: "description", key: "description", type: 'text', classes: "" },
                        { text: "actions", key: "actions", type: 'slot', classes: "" },
                     ];
                  }
               })
            } else {
               this.$router.push(this.pageData.url)
            }
         } else if (this.$route.params.delegate_id && !this.$route.query.show) {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`delegate_petty/create?delegate_id=${this.$route.params.delegate_id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.expense_codes = response.data.expense_codes || [];
                     this.taxes = response.data.taxes || [];
                     this.delegates = response.data.delegates || [];
                     this.treasuries = response.data.treasuries || [];
                     this.form = response.data.delegate
                     this.form.action_date = this.$global.GetCurrentDate()
                     this.form = { ...this.form }
                     // this.tableRows = response.data.details;
                  }
               })
            } else {
               this.$router.push(this.pageData.url)
            }
         }
         else {
            this.$router.push(this.pageData.url)
         }
      },
      save() {
         this.pageData.isLoading = true;
         this.form.items = this.tableRows;
         if (this.$route.params.delegate_id && this.$route.query.show) {
            this.form._method = 'PUT';
            this.$api.POST_METHOD(`delegate_petty/${this.form.delegate_petty_m_id}`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.printReport(response.data)
                  this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               }
            })
         } else {
            if (this.$refs.form.validate()) {
               this.form.delegate_id = this.$route.params.delegate_id;
               this.$api.POST_METHOD(`delegate_petty`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.printReport(response.data)
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })

            }
         }


      },
      detailAction(detail, isEdit, cancel) {
         console.log(detail, isEdit, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.detail = {
               expense_code_id: null,
               clearance_id: null,
               receipt_number: null,
               description: null,
               amount: null,
               tax_id: null,
               taxable: 0,
            }
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.tableRows.push(detail);
            this.detail = {
               expense_code_id: null,
               clearance_id: null,
               receipt_number: null,
               description: null,
               amount: null,
               tax_id: null,
               taxable: 0,
            }
         }

      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.detail = {
            expense_code_id: null,
            clearance_id: null,
            receipt_number: null,
            description: null,
            amount: null,
            tax_id: null,
            taxable: 0,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`delegate_petty/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
      printReport(id) {
         this.$api.PrintPOST('export_delegate_petty', id, 'print').then(() => { })
      }
   },
};
</script>
