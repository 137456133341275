<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :actionBtnText="'create invoice'"
            :actionBtnClick="save" :actionBtn="true" :createBtn="false" :showSearch="false" :showDeleted="false"
            :actionBtnValid="!(selectedRowTrips.length + selectedRowTransactions.length + selectedRowContainers.length == 0)"
            :hideCreate="true" :hideDeleted="true" actionBtnColor="success" />
         <!-- End Of Header -->


         <v-row justify="center">
            <v-col cols="12" class="">
               <v-card class="shadow pa-7 rounded-lg">
                  <v-row align="center" justify="space-between" class="">
                     <GenericInput type="select" :lookups="customers" :value="customer_id" @input="customer_id = $event"
                        :label="'please choose customer'" :multi="false" :required="true" selected_label="name"
                        selected_prop="id" :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 4]">
                     </GenericInput>



                     <v-col cols='12' md="6" lg="4" class="d-flex align-center">
                        <v-btn depressed @click="getReports()" :loading="pageData.isSearchLoading"
                           :disabled="!(customer_id)" class="shadow d-block mi-start-auto" color="primary">{{
                                 $t('Search')
                           }} <v-icon right>
                              mdi-magnify</v-icon>
                        </v-btn>


                     </v-col>
                  </v-row>
               </v-card>
            </v-col>
         </v-row>
         <v-row>
            <v-col cols="12" sm="12" v-if="trips.length > 0 && !pageData.isSearchLoading">
               <DynamicTable tableTitle="un paid trips" :viewTitle="true" :isLoading="pageData.isSearchLoading"
                  :printValueMethod="print" :data="trips" :header="tripTableHeader" :option="pageData.options"
                  :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
                  :restoreValueMethod="restoreMethod" cardClasses="" :selectRow="true"
                  :checkboxMethod="checkboxMethod" />
            </v-col>
            <v-col cols="12" class="px-16" v-if="trips.length > 0 && !pageData.isSearchLoading">
               <v-divider style="width : 80%;" class="ma-auto d-block"></v-divider>
            </v-col>
            <v-col cols="12" sm="12" v-if="transactions.length > 0 && !pageData.isSearchLoading">
               <DynamicTable tableTitle="un paid transaction" :viewTitle="true" :isLoading="pageData.isSearchLoading"
                  :printValueMethod="print" :data="transactions" :header="transactionTableHeader"
                  :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                  :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" cardClasses="" :selectRow="true"
                  :checkboxMethod="checkboxTransactions" />
            </v-col>
            <v-col cols="12" class="px-16" v-if="containers.length > 0 && !pageData.isSearchLoading">
               <v-divider style="width : 80%;" class="ma-auto d-block"></v-divider>
            </v-col>
            <v-col cols="12" sm="12" v-if="containers.length > 0 && !pageData.isSearchLoading">
               <DynamicTable tableTitle="damage" :viewTitle="true" :isLoading="pageData.isSearchLoading"
                  :printValueMethod="print" :data="containers" :header="containerTableHeader" :option="pageData.options"
                  :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
                  :restoreValueMethod="restoreMethod" cardClasses="" :selectRow="true"
                  :checkboxMethod="checkboxContainers" />
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import DynamicTable from "@/components/DynamicTable.vue";
// import PrintBtn from "@/components/ui/PrintBtn.vue";
export default {
   name: "ReleaseInvoiceEntity",

   data: () => ({
      pageData: {
         screen_code: "03-011",
         url: null,
         controlRoute: "transport/invoice-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: false,
         isSearchLoading: false,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: "",
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         printLoading: false
      },
      selectedRowTrips: [],
      selectedRowTransactions: [],
      selectedRowContainers: [],
      customers: [],
      transactions: [],
      trips: [],
      containers: [],
      tripTableHeader: [],
      transactionTableHeader: [],
      customer_id: null,
      type: null,
   }),
   components: {
      EntityHeader,
      // PrintBtn,
      GenericInput,
      DynamicTable
   },
   computed: {},
   watch: {
      customer_id() {
         this.getReports()
      }
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).main_title;
         this.pageData.category = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).cat_title;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.tripTableHeader = [
            { text: "#", key: "id", type: 'text', classes: "" },
            { text: "customer name", key: "customer_name", type: 'text', classes: "" },
            { text: "transaction no", key: "transaction_number", type: 'text', classes: "" },
            { text: "driver name", key: "driver_name", type: 'text', classes: "" },
            { text: "car name", key: "car_name", type: 'text', classes: "" },
            { text: "trip date", key: "trip_date", type: 'date', classes: "" },
            { text: "trip price", key: "trip_price", type: 'price', classes: "" },
            // { text: "actions", key: "id", type: 'actions', classes: "" },
         ];
         this.transactionTableHeader = [
            { text: "#", key: "code_number", type: 'text', classes: "" },
            { text: "customer name", key: "customer_name", type: 'text', classes: "" },
            { text: "transaction no", key: "transaction_number", type: 'text', classes: "" },
            { text: "terminal", key: "wearhouse_name", type: 'text', classes: "" },
            { text: "in date", key: "in_date", type: 'date', classes: "" },
            { text: "out date", key: "in_date", type: 'date', classes: "" },
            { text: "total days", key: "total_days", type: 'text', classes: "" },
            { text: "total price", key: "total_cost", type: 'price', classes: "" },
            // { text: "actions", key: "id", type: 'actions', classes: "" },
         ];
         this.containerTableHeader = [
            { text: "#", key: "code_number", type: 'text', classes: "" },
            { text: "date", key: "add_date", type: 'date', classes: "" },
            { text: "container number", key: "container_number", type: 'text', classes: "" },
            { text: "status", key: "status_text", type: 'text', classes: "" },
            { text: "damage cost", key: "damage_cost", type: 'price', classes: "" },
            { text: "customer name", key: "customer_name", type: 'text', classes: "" },
            { text: "transaction no", key: "transaction_number", type: 'text', classes: "" },
            // { text: "actions", key: "id", type: 'actions', classes: "" },
         ];
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: "id",
            tableHeader: false,
            searchInput: false,
            printButton: true,
            handleClickRow: false,
         };
      },
      getSearch(word) {
         this.pageData.search = word ? word : "";
         this.getData();
      },
      getReports() {
         this.pageData.isSearchLoading = true;
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {

            this.$api
               .GET_METHOD(
                  `invoice/create?customer_id=${this.customer_id}`
               )
               .then((response) => {
                  this.pageData.isSearchLoading = false;
                  if (response.check) {
                     this.trips = response.data.trips;
                     this.transactions = response.data.transactions;
                     response.data.containers.forEach(row => {
                        switch (row.container_status_id) {
                           case 1:
                              row.status_text = this.$i18n.t('Received from port');
                              break;
                           case 2:
                              row.status_text = this.$i18n.t('Received into warehouse');
                              break;
                           case 3:
                              row.status_text = this.$i18n.t('Delivered to customer');
                              break;
                           case 4:
                              row.status_text = this.$i18n.t('Received from customer');
                              break;
                           case 5:
                              row.status_text = this.$i18n.t('Delivered to port');
                              break;

                           default:
                              break;
                        }
                     });
                     this.containers = response.data.containers;
                  }
               });
         } else {
            this.$router.push("/");
         }
      },

      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.pageData.isLoading = true;
            this.$api
               .GET_METHOD(
                  `invoice/create`
               )
               .then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.customers = response.data.customers;
                     //   this.trips = response.data.trips;
                     //   this.transactions = response.data.transactions;

                  }
               });
         } else {
            this.$router.push("/");
         }
      },
      changePage(page, limit) {
         console.log("page");
         this.pageData.page = page;
         this.pageData.rowsNumber = limit;
         this.getData();
      },
      save() {

         const tripIDS = this.selectedRowTrips.map(object => object.id);
         const transactionsIDS = this.selectedRowTransactions.map(object => object.id);
         const containersIDS = this.selectedRowContainers.map(object => object.container_id);
         this.pageData.isLoading = true;

         let requestBody = {
            customer_id: this.customer_id,
            trips: tripIDS,
            transactions: transactionsIDS,
            containers: containersIDS,
         }
         this.$api.POST_METHOD(`invoice`, requestBody).then((response) => {
            if (response.check) {

               this.pageData.isLoading = false;
               // print
               const body = {
                  id: response.data.id,
                  type: 'pdf'
               }

               this.$api
                  .DOWNLOAD_METHOD_BODY(`export_invoice/${response.data.id}`, body, `invoice_${response.data.id}.pdf`)
                  .then(() => { });
               this.$router.push("/transport/invoice");

            }
         })

      },
      deleteMethod(id) {
         console.log(id);
         this.$api.POST_METHOD(`customer/${id}`, { _method: "delete" }).then((response) => {
            if (response.check) {
               this.getData();
            }
         });
      },
      restoreMethod(row) {
         this.$api.POST_METHOD(`customer_toggle_active/${row.id}`, null).then((response) => {
            if (response.check) {
               this.getData();
            }
         });
      },
      setClickRow() {
         // this.$router.push(`/main/customer/${row.id}`)
      },
      edit(row) {
         console.log("edit", row);
         this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
      },
      checkboxMethod(rows) {
         this.selectedRowTrips = rows;
         // this.selectedRowID ;
         console.log("selectedRowTrips", this.selectedRowTrips)

      },
      checkboxTransactions(rows) {
         this.selectedRowTransactions = rows;
         // this.selectedRowID ;
         console.log("selectedRowTransactions", this.selectedRowTransactions)

      },
      checkboxContainers(rows) {
         this.selectedRowContainers = rows;
         // this.selectedRowID ;
         console.log("selectedRowContainers", this.selectedRowContainers)

      },

   },
};
</script>
