import AccountingTree from "@/views/AccountingModule/AccountingTree/AccountingTree";
import CostCenter from "@/views/AccountingModule/CostCenter/CostCenter";
import DailyJournalEntity from "@/views/AccountingModule/DailyJournal/DailyJournalEntity";
import DailyJournalControl from "@/views/AccountingModule/DailyJournal/DailyJournalControl";
import ReceiptVoucherEntity from "@/views/AccountingModule/ReceiptVoucher/ReceiptVoucherEntity";
import ReceiptVoucherControl from "@/views/AccountingModule/ReceiptVoucher/ReceiptVoucherControl";
import PaymentVoucherEntity from "@/views/AccountingModule/PaymentVoucher/PaymentVoucherEntity";
import PaymentVoucherControl from "@/views/AccountingModule/PaymentVoucher/PaymentVoucherControl";
import TreasuryEntity from "@/views/AccountingModule/Treasury/TreasuryEntity";
import TreasuryControl from "@/views/AccountingModule/Treasury/TreasuryControl";
import AccountStatmentAnalReportEntity from "@/views/AccountingModule/AccountStatmentAnalReport/AccountStatmentAnalReportEntity";
import TrialBalanceEntity from "@/views/AccountingModule/TrialBalance/TrialBalanceEntity";
import FollowIncomingChecksEntity from "@/views/AccountingModule/ChequesIncomingOutgoing/FollowIncomingChecksEntity";
import FollowOutgoingChecksEntity from "@/views/AccountingModule/ChequesIncomingOutgoing/FollowOutgoingChecksEntity";
import FinancialPeriodsEntity from "@/views/AccountingModule/FinancialPeriods/FinancialPeriodsEntity";
import FinancialPeriodsControl from "@/views/AccountingModule/FinancialPeriods/FinancialPeriodsControl";
import TaxEntity from "@/views/AccountingModule/Tax/TaxEntity";
import TaxControl from "@/views/AccountingModule/Tax/TaxControl";
import MultiVoucherEntity from "../views/AccountingModule/MultiVoucher/MultiVoucherEntity.vue";
import MultiVoucherControl from "../views/AccountingModule/MultiVoucher/MultiVoucherControl.vue";
import ExpensesCodeEntity from "../views/AccountingModule/ExpensesCode/ExpensesCodeEntity.vue";
import ExpensesCodeControl from "../views/AccountingModule/ExpensesCode/ExpensesCodeControl.vue";
import CurrencyEntity from "../views/AccountingModule/Currency/CurrencyEntity.vue";
import RelatedPartieEntity from "../views/AccountingModule/RelatedPartie/RelatedPartieEntity.vue";
import BankTransfersEntity from "../views/BankTransfers/BankTransfersEntity.vue";
import BankTransfersControl from "../views/BankTransfers/BankTransfersControl.vue";
import MaintenancePettyCashEntity from "../views/AccountingModule/MaintenancePettyCash/MaintenancePettyCashEntity.vue";
import DelegatePettyCashEntity from "../views/AccountingModule/DelegatePettyCash/DelegatePettyCashEntity.vue";
import DelegatePettyCashControl from "../views/AccountingModule/DelegatePettyCash/DelegatePettyCashControl.vue";
import MaintenancePettyCashControl from "../views/AccountingModule/MaintenancePettyCash/MaintenancePettyCashControl.vue";

// Accounting Module Routes:-

export const AccountingModuleChildrens = [
  // Accounting Tree
  {
    path: "/accounting/account_tree",
    name: "AccountingTree",
    component: AccountingTree,
    meta: {
      screen_code: "02-001",
    },
  },
  // Cost Center Tree
  {
    path: "/accounting/cost_center",
    name: "CostCenter",
    component: CostCenter,
    meta: {
      screen_code: "02-002",
    },
  },
  // Daily Journal
  {
    path: "/accounting/daily_journal",
    name: "DailyJournalEntityVue",
    component: DailyJournalEntity,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/accounting/daily_journal-control",
    name: "DailyRestrictionsControl",
    component: DailyJournalControl,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/accounting/daily_journal-control/:id",
    name: "DailyJournalControlVueEdit",
    component: DailyJournalControl,
    meta: {
      screen_code: "02-003",
    },
  },
  // Receipt Voucher
  {
    path: "/accounting/receipt_voucher",
    name: "ReceiptVoucherEntity",
    component: ReceiptVoucherEntity,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/accounting/receipt_voucher-control",
    name: "ReceiptVoucherControl",
    component: ReceiptVoucherControl,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/accounting/receipt_voucher-control/:id",
    name: "ReceiptVoucherControlEdit",
    component: ReceiptVoucherControl,
    meta: {
      screen_code: "02-007",
    },
  },
  // Payment Voucher
  {
    path: "/accounting/payment_voucher",
    name: "PaymentVoucherEntity",
    component: PaymentVoucherEntity,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/accounting/payment_voucher-control",
    name: "PaymentVoucherControl",
    component: PaymentVoucherControl,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/accounting/payment_voucher-control/:id",
    name: "PaymentVoucherControlEdit",
    component: PaymentVoucherControl,
    meta: {
      screen_code: "02-008",
    },
  },
  // Treasury
  {
    path: "/accounting/treasury",
    name: "TreasuryEntity",
    component: TreasuryEntity,
    meta: {
      screen_code: "02-006",
    },
  },
  {
    path: "/accounting/treasury-control",
    name: "TreasuryControl",
    component: TreasuryControl,
    meta: {
      screen_code: "02-006",
    },
  },
  {
    path: "/accounting/treasury-control/:id",
    name: "TreasuryControlVueEdit",
    component: TreasuryControl,
    meta: {
      screen_code: "02-006",
    },
  },
  // account statement anal report
  {
    path: "/accounting/account_statment_anal_report",
    name: "AccountStatmentAnalReport",
    component: AccountStatmentAnalReportEntity,
    meta: {
      screen_code: "02-004",
    },
  },
  //trial balance report
  {
    path: "/accounting/trial_balance_report",
    name: "TrialBalanceEntity",
    component: TrialBalanceEntity,
    meta: {
      screen_code: "02-005",
    },
  },
  // Follow incoming checks
  {
    path: "/accounting/cheque_incoming",
    name: "FollowIncomingChecksEntityVue",
    component: FollowIncomingChecksEntity,
    meta: {
      screen_code: "02-010",
    },
  },
  // Follow incoming checks
  {
    path: "/accounting/cheque_outgoing",
    name: "FollowOutgoingChecksEntity",
    component: FollowOutgoingChecksEntity,
    meta: {
      screen_code: "02-011",
    },
  },
  // Financial Periods
  {
    path: "/accounting/financial_periods",
    name: "FinancialPeriodsEntityVue",
    component: FinancialPeriodsEntity,
    meta: {
      screen_code: "02-009",
    },
  },
  {
    path: "/accounting/financial_period-control",
    name: "FinancialPeriodsControlVue",
    component: FinancialPeriodsControl,
    meta: {
      screen_code: "02-009",
    },
  },
  {
    path: "/accounting/financial_period-control/:id",
    name: "FinancialPeriodsControlVueEdit",
    component: FinancialPeriodsControl,
    meta: {
      screen_code: "02-009",
    },
  },

  // Financial Periods
  {
    path: "/accounting/tax",
    name: "TaxEntity",
    component: TaxEntity,
    meta: {
      screen_code: "02-012",
    },
  },
  {
    path: "/accounting/tax-control",
    name: "TaxControl",
    component: TaxControl,
    meta: {
      screen_code: "02-012",
    },
  },
  {
    path: "/accounting/tax-control/:id",
    name: "TaxControlEdit",
    component: TaxControl,
    meta: {
      screen_code: "02-012",
    },
  },
  {
    path: "/accounting/multi_voucher",
    name: "MultiVoucherEntity",
    component: MultiVoucherEntity,
    meta: {
      screen_code: "02-013",
    },
  },
  {
    path: "/accounting/multi_voucher-control",
    name: "MultiVoucherControl",
    component: MultiVoucherControl,
    meta: {
      screen_code: "02-013",
    },
  },
  {
    path: "/accounting/multi_voucher-control/:id",
    name: "MultiVoucherControlEdit",
    component: MultiVoucherControl,
    meta: {
      screen_code: "02-013",
    },
  },
  // Expenses Code
  {
    path: "/accounting/expense_code",
    name: "ExpensesCodeEntity",
    component: ExpensesCodeEntity,
    meta: {
      screen_code: "02-014",
    },
  },
  {
    path: "/accounting/expense_code-control",
    name: "ExpensesCodeControl",
    component: ExpensesCodeControl,
    meta: {
      screen_code: "02-014",
    },
  },
  {
    path: "/accounting/expense_code-control/:id",
    name: "ExpensesCodeControlVueEdit",
    component: ExpensesCodeControl,
    meta: {
      screen_code: "02-014",
    },
  },
  // Currency
  {
    path: "/accounting/currency",
    name: "CurrencyEntity",
    component: CurrencyEntity,
    meta: {
      screen_code: "02-015",
    },
  },
  // Related Parties
  {
    path: "/accounting/related_partie",
    name: "RelatedPartieEntity",
    component: RelatedPartieEntity,
    meta: {
      screen_code: "02-016",
    },
  },
  // Bank Transfers
  {
    path: "/accounting/bank_transfer",
    name: "BankTransfersEntity",
    component: BankTransfersEntity,
    meta: {
      screen_code: "02-017",
    },
  },
  {
    path: "/accounting/bank_transfer-control",
    name: "BankTransfersControl",
    component: BankTransfersControl,
    meta: {
      screen_code: "02-017",
    },
  },
  {
    path: "/accounting/bank_transfer-control/:id",
    name: "BankTransfersControlEdit",
    component: BankTransfersControl,
    meta: {
      screen_code: "02-017",
    },
  },
  // Close Delegate Petty Cash
  {
    path: "/accounting/delegate_petty",
    name: "DelegatePettyCashEntity",
    component: DelegatePettyCashEntity,
    meta: {
      screen_code: "02-019",
    },
  },

  {
    path: "/accounting/delegate_petty-control/:delegate_id",
    name: "DelegatePettyCashControl",
    component: DelegatePettyCashControl,
    meta: {
      screen_code: "02-019",
    },
  },
  // Close Maintenance Petty Cash
  {
    path: "/accounting/maintenance_petty",
    name: "MaintenancePettyCashEntity",
    component: MaintenancePettyCashEntity,
    meta: {
      screen_code: "02-018",
    },
  },
  {
    path: "/accounting/maintenance_petty-control/:id/:delegate_id",
    name: "MaintenancePettyCashControl",
    component: MaintenancePettyCashControl,
    meta: {
      screen_code: "02-018",
    },
  },

];
