<template>
  <v-container style="height: 100vh" fluid class="d-flex align-center light justify-center bgError">
    <div>
      <v-row align="center">
        <v-col cols="auto">
          <div class="text-h1 primary--text">404</div>
        </v-col>
        <v-col cols="auto">
          <h1 class="primary--text">{{ $t('Page not Found') }}</h1>
          <p class=" my-3">
            {{ $t("The Page you are looking for doesn't exist or an other error occurred") }}.
            <br />
              {{ $t("Go back, or head over to home page to choose a new direction") }}.
          </p>
          <v-btn
            color="primary"
            @click="$router.go(-1)"
            class="mt-2 text-decoration-underline"
            elevation="0" text
          >{{ $t('Back previous page') }}</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
  background-image: url("../../assets/img/png/layer_1.png");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  img {
    margin: auto;
    display: block;
    max-height: 200px;
  }
  .text-h1{
    font-size: 10rem !important;
  }
}
</style>

<script>
export default {
  name: "Error404",
  computed: {},
};
</script>
