<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :showCreate="form.maintenance_petty_status == 1"
            :valid="valid && tableRows.length > 0 && form.total_amount >= totalsCalculations.amount"
            :deleteMethod="deleteMethod" :editForm="pageData.isEdit">
            <template v-slot:centerSide>
               <div class="font-weight-bold subtitle-1 primary--text"
                  v-if="form.total_amount && form.maintenance_petty_status == 2 && formCollapse">
                  {{ $t('total petty cash') }} : {{ form.total_amount | float }}</div>
            </template>
         </ControlHeader>
         <!-- End Of Header -->
         <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
            <AnimatedLoading :height="100" :hideText="false" :isLoading="pageData.isLoading" />
         </v-col>

         <div v-if="!pageData.isLoading">
            <!-- content -->
            <!-- Form -->
            <v-form ref="form" v-model="valid" class="pa-3">
               <v-card class="backgroundW shadow pa-5 pt-7">
                  <v-fade-transition>
                     <div class="collapseForm" v-if="tableRows.length > 0 || form.maintenance_petty_status == 2">
                        <div class="d-flex">
                           <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                              <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                              <v-icon v-else>mdi-chevron-down</v-icon>
                           </v-btn>
                        </div>
                     </div>
                  </v-fade-transition>
                  <v-expand-transition>
                     <v-row align="center" v-show="!formCollapse">
                        <v-col cols="12" md="12">
                           <v-row>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('action date') }} :
                                    <span class="gray6--text"> {{ form.action_date || '-' | capitalize }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('daily') }} :
                                    <span class="gray6--text"> {{ form.daily_type_name || '-' | capitalize }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('delegate') }} :
                                    <span class="gray6--text"> {{ form.delegate_name || '-' | capitalize }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('total petty cash') }} :
                                    <span class="gray6--text"> {{ form.total_amount || 0 | float }}</span>
                                 </div>
                              </v-col>
                              <v-col cols="6" md="4">
                                 <div class="subtitle-1 font-weight-bold">
                                    {{ $t('remaining') }} :
                                    <span class="gray6--text"> {{ form.remaining_amount || 0 | float }}</span>
                                 </div>
                              </v-col>

                              <v-col cols="12" class="d-flex justify-end" v-if="form.maintenance_petty_status == 1">
                                 <v-btn :disabled="(totalsCalculations.remaining_amount == 0)" class="me-2 white--text"
                                    depressed color="success" :min-width="120"
                                    @click="() => { detailDialog = true; custody_settlement = true }" :height="37">
                                    {{ $t('custody settlement') }}
                                 </v-btn>
                                 <v-btn outlined class="" depressed color="blue2" :min-width="120"
                                    @click="detailDialog = true" :height="37">
                                    {{ $t('add line') }}
                                 </v-btn>
                              </v-col>
                           </v-row>
                        </v-col>
                     </v-row>
                  </v-expand-transition>
               </v-card>
            </v-form>

            <!-- Details Table -->
            <div class="pa-2" v-if="tableRows.length">
               <DynamicTable :isLoading="pageData.isLoading" :data="tableRows || []" :footerData="[totalsCalculations]"
                  :header="tableHeader" :tableHeight="$store.state.Settings.windowSize.y - 230">
                  <template v-slot:td="{ row, header, index }">
                     <div v-if="header.key == 'actions'">
                        <v-btn @click="editDetail(index, row)" x-small icon fab>
                           <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                        </v-btn>
                        <v-btn @click="removeItem(index, row)" x-small icon fab>
                           <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                        </v-btn>
                     </div>
                  </template>
               </DynamicTable>
            </div>
            <!-- Detail Form -->
            <MPettyCashDetail :detailAction="detailAction" :account_trees="account_trees" :treasuries="treasuries"
               :clearances="clearances" :dialog="detailDialog" :tableRows="tableRows" :detail="detail"
               :updateDetail="updateDetail" :isDetailEdit="isDetailEdit"
               :remaining_amount="totalsCalculations.remaining_amount" :custody_settlement="custody_settlement" />
            <!-- Detail of Form -->
         </div>
      </v-container>


   </section>
</template>



<script>

import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import MPettyCashDetail from "./MPettyCashDetail.vue";
export default {
   name: "MaintenancePettyCash",
   data: () => ({
      pageData: {
         screen_code: "02-018",
         url: null,
         controlRoute: "/accounting/maintenance_petty-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      formCollapse: false,
      custody_settlement: false,
      viewForm: false,
      valid: false,
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      getContainerLoading: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      daily_types: [],
      account_trees: [],
      cost_centers: [],
      delegates: [],
      clearances: [],
      containers: [],
      covenant_types: [],
      treasuries: [],
      form: {
         action_date: null,
         maintenance_petty_status: 0,
         daily_type_id: null,
         daily_type_name: null,
         delegate_id: null,
         delegate_name: null,
         total_amount: 0,
         remaining_amount: 0,
         arabic_description: null,
         items: [],
      },
      detail: {
         account_tree_id: null,
         treasury_id: null,
         clearance_id: null,
         receipt_number: null,
         arabic_description: null,
         amount: null,
      }
   }),
   components: {
      ControlHeader,
      DynamicTable,
      AnimatedLoading,
      MPettyCashDetail
   },
   computed: {
      totalsCalculations() {
         if (this.tableRows.length > 0) {
            const totalAmount = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.amount);
            }, 0);
            return {
               clearance_id: this.$i18n.t('total'),
               amount: totalAmount,
               remaining_amount: this.form.total_amount - totalAmount
            }
         } else {
            return {
               clearance_id: this.$i18n.t('total'),
               amount: 0,
               remaining_amount: 0,
            }
         }
      },
   },
   watch: {
      'form.maintenance_petty_status'() {
         if (this.form.maintenance_petty_status == 2) {
            this.tableHeader = [
               { text: "clearance", key: "clearance_id", type: 'text', classes: "" },
               { text: "account name", key: "account_tree_name", type: 'text', classes: "" },
               { text: "treasury", key: "treasury_name", type: 'text', classes: "" },
               { text: "receipt number", key: "receipt_number", type: 'text', classes: "" },
               { text: "amount", key: "amount", type: 'float', classes: "" },
               { text: "description", key: "arabic_description", type: 'text', classes: "" },
            ];
         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            row.account_tree_name = row.account_tree_id ? this.$global.FilterArrayOfObjectElement(this.account_trees, 'id', row.account_tree_id, 'name') : null;
            row.treasury_name = row.treasury_id ? this.$global.FilterArrayOfObjectElement(this.treasuries, 'id', row.treasury_id, 'name') : null;
         });
      }
   },
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "clearance", key: "clearance_id", type: 'text', classes: "" },
            { text: "account name", key: "account_tree_name", type: 'text', classes: "" },
            { text: "treasury", key: "treasury_name", type: 'text', classes: "" },
            { text: "receipt number", key: "receipt_number", type: 'text', classes: "" },
            { text: "amount", key: "amount", type: 'float', classes: "" },
            { text: "description", key: "arabic_description", type: 'text', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
         ];

      },
      getData() {
         if (this.$route.params.id && this.$route.params.delegate_id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`maintenance_petty/${this.$route.params.id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.account_trees = response.data.account_trees || [];
                     this.daily_types = response.data.daily_types || [];
                     this.delegates = response.data.delegates || [];
                     this.clearances = response.data.clearances || [];
                     this.treasuries = response.data.treasurys || [];
                     this.form = response.data.master
                     this.tableRows = response.data.details;
                  }
               })
            } else {
               this.$router.push(this.pageData.url)
            }
         } else {
            this.$router.push(this.pageData.url)
         }


      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form._method = 'PUT';
            this.$api.POST_METHOD(`maintenance_petty/${this.form.maintenance_petty_m_id}`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.printReport(response.data)
                  this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               }
            })

         }
      },
      detailAction(detail, isEdit, cancel) {
         console.log(detail, isEdit, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.custody_settlement = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.detail = {
               account_tree_id: null,
               treasury_id: null,
               clearance_id: null,
               receipt_number: null,
               arabic_description: null,
               amount: null,
            }
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.detailDialog = false;
            this.custody_settlement = false;
            this.isDetailEdit = false;
            this.tableRows.push(detail);
            this.detail = {
               account_tree_id: null,
               treasury_id: null,
               clearance_id: null,
               receipt_number: null,
               arabic_description: null,
               amount: null,
            }
         }

      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.custody_settlement = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.detail = {
            account_tree_id: null,
            treasury_id: null,
            clearance_id: null,
            receipt_number: null,
            arabic_description: null,
            amount: null,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`maintenance_petty/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
      printReport(id) {
         this.$api.PrintPOST('export_payment_voucher', id, 'print').then(() => { })
      }
   },
};
</script>
