<template>
   <section>
      <v-container fluid v-if="!printView">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :showCreate="!pageData.isEdit" :save="save" :valid="valid"
            :deleteMethod="deleteMethod" :editForm="false" :actionBtnClick="notice" actionBtnText="invoice notice"
            :showDelete="pageData.isEdit" :actionBtnValid="pageData.isEdit" actionBtnColor="secondary" :actionBtn="false">
            <template v-slot:centerSide>
               <div class="font-weight-bold subtitle-1 primary--text" v-if="totalsCalculations.final_total">
                  {{ $t('total invoice') | capitalize }} : {{ totalsCalculations.final_total || 0 | float }}
                  {{ $t('sar') }}
               </div>
            </template>
            <template v-slot:leftSide>
               <PrintInvoice v-if="pageData.isEdit" endpoint="export_clearance_draft" :screenCode="pageData.screen_code"
                  :id="master.id" />
            </template>
         </ControlHeader>
         <!-- End Of Header -->
         <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
            <AnimatedLoading :height="100" :hideText="false" :isLoading="pageData.isLoading" />
         </v-col>

         <div v-if="!pageData.isLoading">
            <!-- content -->
            <!-- Form -->
            <v-form ref="form" v-model="valid" class="pa-3">
               <v-card class="backgroundW shadow pa-5 pt-7">
                  <v-fade-transition>
                     <div class="collapseForm" v-if="tableRows.length > 0">
                        <div class="d-flex">
                           <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                              <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                              <v-icon v-else>mdi-chevron-down</v-icon>
                           </v-btn>
                        </div>
                     </div>
                  </v-fade-transition>
                  <v-expand-transition>
                     <v-row align="center" v-show="!formCollapse">
                        <v-col cols="12" md="12">
                           <v-row>
                              <GenericInput v-if="!master.id" type="select" :lookups="clearances"
                                 :value="form.clearance_id" @input="form.clearance_id = $event" label="clearance"
                                 :multi="false" :required="true" selected_label="id" selected_prop="id"
                                 :isLoading="getClearanceDetailsLoading" :cols="[12, 6, 6]">
                              </GenericInput>
                              <ControlSummery :summery="summery" colClass="py-2" :shadow="false"
                                 :loading="getClearanceDetailsLoading" v-if="master.id" />
                           </v-row>
                        </v-col>
                     </v-row>
                  </v-expand-transition>
               </v-card>
            </v-form>

            <!-- Details Table -->

            <div class="pa-2" v-if="master.id">
               <DynamicTable :isLoading="pageData.isLoading || getClearanceDetailsLoading" :data="tableRows || []"
                  :footerData="[totalsCalculations]" :header="tableHeader"
                  :tableHeight="$store.state.Settings.windowSize.y - 230">
               </DynamicTable>
            </div>
            <div class="pa-2" v-if="master.id">
               <DynamicTable :isLoading="pageData.isLoading || getClearanceDetailsLoading" :data="expensesRows || []"
                  :footerData="[totalsCalculations]" :header="expensesHeader"
                  :tableHeight="$store.state.Settings.windowSize.y - 230">
               </DynamicTable>
            </div>

         </div>
      </v-container>
      <v-col cols="12" v-if="printView">
         <ClearanceInvoicePrintView :master="master" :totals="totals" :prices="prices" :transport_fees="transport_fees"
            :details="tableRows" :areas="areas" :clearance_d="clearance_d" :isEdit="pageData.isEdit" printType="draft"
            :count_invoices="count_invoices" :expenses="expensesRows" :isLoading="pageData.isLoading" />
      </v-col>
   </section>
</template>



<script>

import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import GenericInput from "@/components/ui/GenericInput.vue";
import ControlSummery from "@/components/ui/ControlSummery.vue";
import PrintInvoice from "@/components/ui/PrintInvoice.vue";
import ClearanceInvoicePrintView from "../ClearanceInvoice/ClearanceInvoicePrintView";
export default {
   name: "ClearanceDraftInvoiceControl",
   data: () => ({
      pageData: {
         screen_code: "03-023",
         url: null,
         controlRoute: "sales/clearance_draft_invoice-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      printView: true,
      formCollapse: false,
      viewForm: false,
      valid: false,
      count_invoices: 0,
      getClearanceDetailsLoading: false,
      transport_fees: 0,
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      tableHeader: [],
      expensesHeader: [],
      tableRows: [],
      oldData: {},
      expensesRows: [],
      tableOption: {},
      clearances: [],
      summery: [],
      clearance_d: [],
      prices: [],
      areas: [],
      form: {
         action_date: null,
         customer_name: null,
         customer_id: 0,
         clearance_id: 0,
         items: [],
      },
      master: Object,
      totals: Object,
   }),
   components: {
      ControlHeader,
      DynamicTable,
      AnimatedLoading,
      GenericInput,
      PrintInvoice,
      ControlSummery,
      ClearanceInvoicePrintView
   },
   computed: {
      totalsCalculations() {
         if (this.tableRows) {
            const amount_without_vat = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.amount_without_vat);
            }, 0);
            const vat_amount = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.vat_amount);
            }, 0);
            const amount_with_vat = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.amount_with_vat);
            }, 0);
            const amount = this.expensesRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.amount); // expenses table
            }, 0);
            const final_total = amount + amount_with_vat;
            return {
               index: this.$i18n.t('total'),
               amount_without_vat: amount_without_vat,
               vat_amount: vat_amount,
               amount_with_vat: amount_with_vat,
               amount: amount, // expenses table
               final_total: final_total,
            }
         }
         else {
            return {
               index: this.$i18n.t('total'),
               amount_without_vat: 0,
               vat_amount: 0,
               amount_with_vat: 0,
               amount: 0, // expenses table
               final_total: 0,
            }
         }
      }
   },
   watch: {

   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "#", key: "index", type: 'index', classes: "" },
            { text: "amount", key: "amount_without_vat", type: 'float', classes: "" },
            { text: "vat", key: "vat_amount", type: 'float', classes: "" },
            { text: "percentage", key: "vat_per", type: 'percentage', classes: "" },
            { text: "total", key: "amount_with_vat", type: 'float', classes: "" },
            { text: "description", key: "name", type: 'text', classes: "" },
         ];
         this.expensesHeader = [
            { text: "#", key: "index", type: 'index', classes: "" },
            { text: "expense code", key: "expense_code_name", type: 'text', classes: "" },
            { text: "amount", key: "amount", type: 'float', classes: "" },
            { text: "description", key: "description", type: 'text', classes: "" },
         ];

      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 2) && this.$route.query.clearance_id) {
            this.pageData.isLoading = true;
            this.getClearanceDetailsLoading = false;
            this.$api.GET_METHOD(`clearance_draft/create?clearance_id=${this.$route.query.clearance_id}`).then((response) => {
               this.getClearanceDetailsLoading = false;
               this.pageData.isLoading = false;
               if (response?.check) {
                  this.master = response.data.clearance_m
                  this.clearance_d = response.data.clearance_d;
                  this.prices = response.data.prices || [];
                  this.transport_fees = response.data.transport_fees || 0;
                  this.summery = [
                     {
                        label: 'clearance',
                        value: response.data.clearance_m.id,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'customer',
                        value: response.data.clearance_m.customer_name,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'vat register number',
                        value: response.data.clearance_m.vat_register_number,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'port',
                        value: response.data.clearance_m.port_name,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'policy number',
                        value: response.data.clearance_m.shipping_policy_no,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'date of declaration',
                        value: response.data.clearance_m.action_date,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'containers count',
                        value: response.data.clearance_m.container_count,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'number of small containers',
                        value: response.data.clearance_m.container_small,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'number of large containers',
                        value: response.data.clearance_m.container_big,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'status',
                        value: response.data.clearance_m.status_name,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                  ];
                  this.tableRows = response.data.amounts;
                  this.count_invoices = response.data.count_invoices || 0;
                  this.expensesRows = response.data.deleget_amounts;

                  this.areas = response.data.areas || [];
                  this.totals = response.data.totals;
               }
            })
         }
         else if (this.$global.CheckAction(this.pageData.screen_code, 1) && this.$route.query.clearance_invoice_id) {
            this.pageData.isLoading = true;
            this.getClearanceDetailsLoading = false;
            this.$api.GET_METHOD(`clearance_draft/${this.$route.query.clearance_invoice_id}`).then((response) => {
               this.getClearanceDetailsLoading = false;
               this.pageData.isEdit = true;
               this.pageData.isLoading = false;
               if (response?.check) {
                  this.master = response.data.master
                  this.summery = [
                     {
                        label: 'invoice number',
                        value: response.data.master.id,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'clearance',
                        value: response.data.master.id,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'customer',
                        value: response.data.master.customer_name,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'port',
                        value: response.data.master.port_name,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'invoice date',
                        value: response.data.master.invoice_date,
                        type: 'text',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'vat',
                        value: response.data.master.vat_per,
                        type: 'float',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'expenses',
                        value: response.data.master.total_delegate,
                        type: 'float',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                     {
                        label: 'total cost',
                        value: response.data.master.total_cost,
                        type: 'float',
                        class: '',
                        cols: 12, sm: 6, md: 6, lg: 4, xl: 3,
                     },
                  ];
                  this.tableRows = response.data.details;
                  this.prices = response.data.prices || [];
                  this.totals = response.data.totals;
                  this.transport_fees = response.data.transport_fees || 0;
                  this.expensesRows = response.data.delegate_details;
               }
            })
         }
         else {
            this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
         }

      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            // this.form._method = 'PUT';
            this.$api.POST_METHOD(`clearance_draft`, { clearance_id: this.master.id }).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  const src = `src="${this.$api.serverUrl}export_clearance_draft/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
                  this.$api.PrintInvoice(src, response.data);
                  this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               }
            })

         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`clearance_draft/${this.master.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
   },
};
</script>



<style lang="scss" scoped></style>