<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showDeleted="false" :createBtn="false"
        :getSearch="getSearch">
        <template v-slot:rightSide>
          <v-col cols="12" md="auto">
            <v-btn depressed color="success" width="120" :height="37" @click="clearanceDialog = true">
              {{ $t('create invoice') }}
            </v-btn>
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->


      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden">
              <v-col cols="12" class="pa-0 px-10">
                <v-tabs optional :slider-size="0" active-class="blue12">
                  <v-tab class="font-weight-bold" :class="{ 'blue12 primary--text': pageData.isTrashed }" :value="1"
                    @click="$router.push(pageData.url + `?active=1&rows=10&page=1&word=`)">
                    <strong>{{ $t('invoices') }}</strong>
                  </v-tab>
                  <v-tab class="font-weight-bold" :class="{ 'red12 error--text': !pageData.isTrashed }" :value="2"
                    @click="$router.push(pageData.url + `?active=0&rows=10&page=1&word=`)">
                    <strong>{{ $t('deleted invoices') }}</strong>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
              <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" cardClasses="shadow-none"
                :data="pageData.rows" :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit"
                :pageData="pageData" :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod">
                <template v-slot:td="{ row, header }" :tableHeight="$store.state.Settings.windowSize.y - 285">
                  <v-hover v-if="header.key == 'journal_code'" v-slot="{ hover }" close-delay="50" open-delay="50">
                    <div style="width: 100%;height: 100%;">
                      <v-btn color="secondary" min-width="80" text v-if="!hover" small>
                        {{ row[header.key] || '-' }}
                      </v-btn>
                      <v-btn @click="openJournal(row)" v-if="hover" class="mx-1" x-small icon>
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn @click="printJournal(row)" class="mx-1"
                        :loading="pageData.printLoading.loading && pageData.printLoading.id == row.m_id" v-if="hover"
                        x-small icon>
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </div>
                  </v-hover>
                  <div v-if="header.key == 'actions'" class="d-flex align-center">
                    <v-btn @click="print(row)" v-if="$global.CheckAction(pageData.screen_code, 5)" x-small icon fab>
                      <img src="@/assets/img/svg_icons/fi_printer.svg" height="20" />
                    </v-btn>
                    <v-btn @click="edit(row)" x-small icon fab>
                      <img src="@/assets/img/svg_icons/fi_eye.svg" height="20" />
                    </v-btn>
                    <div v-if="pageData.isTrashed == 1">
                      <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <img class="mx-2 mt-1 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                            @click="dialogDeleteItem = row, deleteDialog = true"
                            src="@/assets/img/svg_icons/delete_icon.svg" alt="clearance" />
                        </template>
                        <span>{{ $t('delete') }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
              </DynamicTable>
            </v-card>
          </v-col>

        </v-col>
      </v-row>


      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>


      <v-dialog v-model="clearanceDialog" overlay-opacity="0.75" persistent :max-width="500">
        <v-card relative class="pa-7 py-10">
          <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="clearanceDialog = false">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
          <v-row class="mt-2">
            <v-col cols="12" class="font-weight-bold text-h6 gray1--text">
              {{ $t('create clearance invoice') | capitalize }}
            </v-col>

            <GenericInput type="autocomplete" :lookups="clearances" :value="clearance_id" @input="clearance_id = $event"
              :keyupValue="searchClearance" :required="true" noDataText="Enter the transaction number"
              label="clearance number" selected_label="clearance_label" selected_prop="id"
              :isLoading="clearancesIsLoading" :cols="[12, 12, 12]" />

            <GenericInput type="date" :value="invoice_date" @input="invoice_date = $event" label="invoice date"
              :required="true" :isLoading="clearancesIsLoading" :cols="[12, 12, 12]">
            </GenericInput>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn class="mx-2" text depressed color="red4" @click="() => { clearanceDialog = false; }" :height="37"
                :width="120">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn class="mx-2 white--text" @click="createInvoice" :disabled="!clearance_id || !invoice_date" depressed
                color="success" :loading="clearancesIsLoading" :height="37" :width="120">
                {{ $t('create invoice') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <DeleteConfirmation :dialog="deleteDialog" :item="`${$i18n.t('invoice')} ${dialogDeleteItem.id}`"
        :backValueMethod="deleteMethod">
      </DeleteConfirmation>
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
export default {
  name: "ClearanceInvoiceEntity",

  data: () => ({
    pageData: {
      screen_code: "04-012",
      url: null,
      controlRoute: "sales/clearance_invoice-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      status: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
    },
    tab: 1,
    deleteDialog: false,
    dialogDeleteItem: Object,
    clearances: [],
    clearancesIsLoading: false,
    clearanceDialog: false,
    clearance_id: null,
    invoice_date: null,
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    GenericInput,
    DeleteConfirmation,
    // PrintInvoice
  },
  computed: {
    searchClearance() {
      let asyncSearch = null;

      return (val) => {
        clearTimeout(asyncSearch)

        if (val && !this.clearance_id) {
          asyncSearch = setTimeout(() => {
            this.clearancesIsLoading = true
            this.$api.GET_METHOD(`get_aval_clearances_short?word=${val}`)
              .then((response) => {
                response.data.forEach(clearance => {
                  clearance.clearance_label = `${clearance.clearance_id} - ${clearance.customer_name}`
                });
                this.clearances.push(...response.data)
              })
              .finally(() => (this.clearancesIsLoading = false))
          }, 600)
        }
      }
    },
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      this.getData();
    },
    clearanceDialog() {
      if (this.clearanceDialog == true) {
        this.invoice_date = this.$global.GetCurrentDate()
      }
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "invoice date", key: "invoice_date", type: 'text', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "transaction no", key: "clearance_id", type: 'text', classes: "" },
        { text: "port", key: "port_name", type: 'text', classes: "" },
        { text: "total cost", key: "total_amount", type: 'float', classes: "" },
        { text: "journal", key: "journal_code", type: 'slot', classes: "", width: 80 },
        { text: "added by", key: "added_by", type: 'text', classes: "" },
        { text: "actions", key: "actions", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: true,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      this.pageData.rows = [];
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `clearance_invoice${this.pageData.queryParam}&status=${this.tab}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(status) {
      if (status) {
        this.$api.POST_METHOD(`clearance_invoice/${this.dialogDeleteItem.id}`, { _method: "delete" }).then((response) => {
          if (response.check) {
            this.getData();
            this.deleteDialog = false
          }
        });
      } else {
        this.deleteDialog = false
      }

    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`clearance_invoice_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/clearance_invoice/${row.m_id}`)
    },
    createInvoice() {
      this.$router.push(`/${this.pageData.controlRoute}?clearance_id=${this.clearance_id}&invoice_date=${this.invoice_date}`)
    },
    edit(row) {
      this.$router.push(`/${this.pageData.controlRoute}?clearance_invoice_id=${row.id}`);
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.m_id, '_blank')
    },
    printJournal(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.m_id;
      const src = `export_journal_data/${row.daily_journal_m_id}?type=pdf`;
      this.$api.DOWNLOAD_METHOD(src, `${this.pageData.entityName}_${row.daily_journal_m_id}.pdf`).then(() => {
        this.pageData.printLoading.loading = false;
      })
    },
    print(row) {
      this.$api.PrintInvoiceURL(`${this.$api.serverUrl}export_clearance_invoice/${row.id}?type=print&clientID=${this.$store.state.clientID}`, row.id)
    }

  },
};
</script>
