<template>
  <v-btn @click="print" v-if="$global.CheckAction(screenCode, 5)" :loading="loading" small icon fab>
    <img src="@/assets/img/svg_icons/fi_printer.svg" height="20" />
  </v-btn>
</template>


<script>
export default {
  name: "PrintInvoice",
  props: {
    printMethod: { type: Function },
    endpoint: { default: null },
    btnText: { default: null },
    id: { default: null },
    type: { default: 'image' },
    screenCode: { default: null }
  },
  data: () => ({
    loading: false
  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    print() {
      const src = `src="${this.$api.serverUrl}${this.endpoint}/${this.id}?type=${this.type}&clientID=${this.$store.state.clientID}"`;
      this.$api.PrintInvoice(src, this.id);
    }
  },
};
</script>



<style scoped lang="scss">

</style>
