<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="650">
      <v-card relative :key="selectedRow.id">
         <v-card class="backgroundW shadow pa-5 pt-7">
            <v-row>
               <v-col cols="12" class="text-h6 font-weight-bold">
                  {{ `${$i18n.t('paid with')}` }}
               </v-col>


               <GenericInput type="radiogroup" :value="paid_by" @input="paid_by = $event" label=""
                  :lookups="[{ name: $i18n.t('customer'), id: 1 }, { name: $i18n.t('company'), id: 2 }]"
                  selected_label="name" selected_prop="id" :multi="false" :required="true" :isLoading="false"
                  :cols="[12, 12, 12]">
               </GenericInput>
               <GenericInput type="date" :value="customs_floor_date" @input="customs_floor_date = $event"
                  label="date of the customs floor" :multi="false" :required="true" :isLoading="false"
                  :cols="[12, 12, 12]">
               </GenericInput>
               <GenericInput type="file" :value="final_note_file" @input="final_note_file = $event" label="final statement"
                  :multi="false" :required="false" :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>

               <GenericInput type="textarea" :value="notes" @input="notes = $event" label="notes" :multi="false"
                  :required="false" :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>

               <v-col cols="12" class="d-flex justify-end pt-0">
                  <v-btn text depressed color="error" :min-width="120" @click="closeDialog(false)" :height="37">
                     <v-icon left>mdi-close</v-icon>
                     {{ $t('close') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="changeStatus" depressed color="success" :loading="isLoading"
                     :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-card>
   </v-dialog>
</template>

<script>

import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "PaidClearanceDialog",
   props: {
      closeDialog: { type: Function },
      dialog: { default: false },
      id: { default: 0 },
      selectedRow: { default: Object },
   },
   computed: {

   },
   components: {
      GenericInput
   },
   watch: {
      dialog() {
         if (this.dialog) {
            this.paid_by = this.selectedRow.paid_by || 1
            this.customs_floor_date = this.selectedRow.customs_floor_date  || this.$global.GetCurrentDate()
            this.final_note_file = this.selectedRow.final_note_file
         } else {
            this.isLoading = false;
            this.paid_by = 1;
            this.customs_floor_date = this.$global.GetCurrentDate();
            this.final_note_file = null;
            this.notes = null;
         }

      }
   },
   data: () => ({
      isLoading: false,
      paid_by: 1,
      customs_floor_date: null,
      final_note_file: null,
      notes: null,
   }),
   methods: {
      changeStatus() {
         this.isLoading = true;
         const requestBody = {
            paid_by: this.paid_by,
            notes: this.notes,
            customs_floor_date: this.customs_floor_date,
            final_note_file: this.final_note_file
         }
         this.$api.POST_METHOD(`update_clearance_paymant/${this.id}`, requestBody).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.selectedRow.withdrawal_note = this.withdrawal_note
               this.customs_floor_date = null;
               this.final_note_file = null;
               this.paid_by = 1;
               this.notes = null;
               this.closeDialog(true);
            }
         })

      },

   },
   mounted() {

   },
};
</script>
