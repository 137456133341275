<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" content-class="overflow-hidden" overlay-opacity="0.75" persistent :max-width="900">
         <v-card relative :key="modalKey" class="pa-7  shadow-none overflow-hidden">
            <v-btn absolute class="ma-2 mt-n6" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="closeDialog">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>

            <DynamicTable :isLoading="isLoading" :data="details" :header="tableHeader">
               <template v-slot:td="{ row, header }" :tableHeight="$store.state.Settings.windowSize.y - 285">
                  <v-btn @click="showPetty(row)" v-if="header.key === 'm_id'" small text color="blue1"
                     class="text-decoration-underline">
                     {{ row.m_id }}
                  </v-btn>
                  <div v-if="header.key === 'from_type'">
                     {{ row.from_type == 'multi_voucher' ? $t('multi voucher') : $t('Petty cash') }}
                  </div>

               </template>
            </DynamicTable>

         </v-card>
      </v-dialog>
   </v-row>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
export default {
   name: "ExpensesDetailsDialog",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      isLoading: { default: false },
      details: { default: [] },
   },
   computed: {

   },
   components: {
      DynamicTable
   },
   watch: {

   },
   data: () => ({
      tableHeader: []
   }),
   methods: {
      closeDialog() {
         this.backValueMethod(false)
      },
      showPetty(row) {
         // this.$router.push(`/accounting/delegate_petty-control/${row.m_id}?show=true`)
         if (row.from_type == 'multi_voucher'){
            window.open(`/accounting/multi_voucher-control/${row.m_id}?show=true`, '_blank')
         }else{
            window.open(`/accounting/delegate_petty-control/${row.m_id}?show=true`, '_blank')
         }
      },

   },
   mounted() {
      this.tableHeader = [
         { text: "#", key: "m_id", type: 'slot', classes: "" },
         { text: "date", key: "add_date", type: 'date', classes: "" },
         { text: "amount", key: "amount", type: 'float', classes: "" },
         { text: "source", key: "from_type", type: 'slot', classes: "" },
      ];
   },
};
</script>
