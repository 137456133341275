<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showSearch="false" :createBtn="false" :showDeleted="false"
        actionBtnText="research" :actionBtn="!showFilter" :actionBtnValid="true" actionBtnColor="transparent"
        :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true" actionBtnClass="blue4--text">
        <template v-slot:leftSide>
          <v-col cols="12" sm="auto" v-if="currentClearance" class="d-flex align-center justify-end">
            <v-btn color="primary" width="90" :disabled="(firstClearance == currentClearance)"
              @click="changeClearance(firstClearance)" depressed class="mx-1 blue12 blue1--text" :height="37">
              <v-icon v-if="$vuetify.rtl" left>mdi-skip-next</v-icon>
              <v-icon v-if="!$vuetify.rtl" left>mdi-skip-previous</v-icon>
              {{ $t('first') }}

            </v-btn>
            <v-btn color="primary" width="90" :disabled="(prevClearance == currentClearance || prevClearance == 0)"
              @click="changeClearance(prevClearance)" depressed class="mx-1 blue12 blue1--text" :height="37">
              <v-icon v-if="$vuetify.rtl" left>mdi-arrow-right-bold-outline</v-icon>
              <v-icon v-if="!$vuetify.rtl" left>mdi-arrow-left-bold-outline</v-icon>
              {{ $t('prev') }}
            </v-btn>
            <div class="mx-3 font-weight-bold">{{ $t('clearance number') + ': ' + currentClearance }}</div>
            <v-btn color="primary" width="90" :disabled="(nextClearance == currentClearance || nextClearance == 0)"
              @click="changeClearance(nextClearance)" depressed class="mx-1 blue12 blue1--text" :height="37">
              {{ $t('next') }}
              <v-icon v-if="!$vuetify.rtl" right>mdi-arrow-right-bold-outline</v-icon>
              <v-icon v-if="$vuetify.rtl" right>mdi-arrow-left-bold-outline</v-icon>
            </v-btn>
            <v-btn color="primary" width="90" :disabled="(lastClearance == currentClearance || lastClearance == 0)"
              @click="changeClearance(lastClearance)" depressed class="mx-1 blue12 blue1--text" :height="37">
              {{ $t('last') }}
              <v-icon v-if="!$vuetify.rtl" right>mdi-skip-next</v-icon>
              <v-icon v-if="$vuetify.rtl" right>mdi-skip-previous</v-icon>
            </v-btn>



          </v-col>
        </template>
        <template v-slot:rightSide>
          <v-col cols="12" sm="auto">
            <ExportBtn :exportData="tableRows" v-if="tableRows.length" :header="pageData.tableHeader"
              :footerData="footerData" :groupHeader="[]" :attachmentFileName="pageData.entityName"
              :printTitle="pageData.entityName">
              <template v-slot:td="{ row, header }">
                <div v-if="header.key == 'journal_code'"> {{ row[header.key] || '-' }}</div>
                <div v-if="header.key == 'is_invoice'">
                  <v-chip small :color="row[header.key] ? 'success' : 'error'">
                    {{ row[header.key] ? $t('yes') + ' ' + $t('invoice_number') + ': ' + row.invoice_number : $t('no') }}
                  </v-chip>
                </div>
              </template>
            </ExportBtn>
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->

      <!-- Filter -->
      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="">
          <v-card class="shadow pa-7">
            <v-row align="center" class="" justify="end">
              <GenericInput type="date" :isPickerOpened="() => pageData.to_date = null" :value="pageData.from_date"
                :clearable="true" :maxDate="pageData.to_date || today" @input="pageData.from_date = $event" label="from"
                :required="false" :hide-details="true" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
              </GenericInput>

              <GenericInput type="date" :value="pageData.to_date" :clearable="true" :minDate="pageData.from_date"
                :maxDate="today" @input="pageData.to_date = $event" label="to" :required="false" :hide-details="true"
                :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]"></GenericInput>

              <GenericInput type="text" :value="pageData.clearance_id" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.clearance_id = $event" label="transaction no" :required="false" :hide-details="true"
                :cols="[12, 3, 3]" />

              <GenericInput type="text" :value="pageData.container_number" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.container_number = $event" label="container number" :required="false"
                :hide-details="true" :cols="[12, 3, 3]" />

              <GenericInput type="text" :value="pageData.shipping_policy_no" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.shipping_policy_no = $event" label="shipping policy no" :required="false"
                :hide-details="true" :cols="[12, 3, 3]" />


              <GenericInput type="select" :lookups="customers" :value="pageData.customer_id"
                @input="pageData.customer_id = $event" label="choose customer" :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 'auto', 3]"></GenericInput>

              <GenericInput type="select" :lookups="delegates" :value="pageData.delegate_id"
                @input="pageData.delegate_id = $event" label="delegate" :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 'auto', 3]"></GenericInput>

              <GenericInput type="select" :lookups="expense_codes" :value="pageData.expense_code_id"
                @input="pageData.expense_code_id = $event" label="expense code" :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 'auto', 3]"></GenericInput>

              <v-col cols="3" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined
                  :loading="(pageData.isLoading || pageData.detailIsLoading)" @click="getReports"
                  class="mx-1 white--text">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- End Of Filter -->

      <!-- Master -->
      <v-row justify="center" v-if="showMaster">
        <v-col cols="12" class="pa-0">
          <ControlSummery :summery="master" :loading="false" />
        </v-col>
      </v-row>
      <!-- End OF Master -->

      <!-- Details -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="tableRows" :footerData="footerData"
            :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 320 : $store.state.Settings.windowSize.y - 220"
            :header="pageData.tableHeader" :fixedHeader="true" :option="pageData.options" :pageData="pageData"
            :showGroupHeader="false">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'invoice_id'">
                <v-btn :text="row[header.key]" small @click="openInvoice(row)" depressed
                  :color="row[header.key] ? 'blue2' : 'error'" :class="{ 'text-decoration_underline': row.invoice_id }">
                  {{ row[header.key] ? $t('invoice number') + ': ' + row.invoice_id : $t('no') }}
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <!-- End OF Details -->
    </v-container>
  </section>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
// // import PrintBtn from "@/components/ui/PrintBtn.vue";
import ControlSummery from "@/components/ui/ControlSummery.vue";
import ExportBtn from '@/components/ui/ExportBtn.vue';
export default {
  name: "ReimbursesExpensesEntity",

  data: () => ({
    pageData: {
      screen_code: "03-019",
      url: null,
      controlRoute: "transport/expenses_clearance",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      from_date: null,
      to_date: null,
      customer_id: null,
      delegate_id: null,
      account_tree: null,
      expense_code_id: null,
      clearance_id: null,
      container_number: null,
      shipping_policy_no: null,
      type: 1,
      first: [],
      printLoading: false
    },
    firstClearance: 0,
    lastClearance: 0,
    currentClearance: 0,
    nextClearance: 0,
    prevClearance: 0,
    showMaster: false,
    customers: [],
    delegates: [],
    expense_codes: [],
    showFilter: true,
    today: null,
    clearances: [],
    footerData: [{
      invoice_id: 'total',
      amount: 0,
    }],
  }),
  watch: {
    'pageData.rows'() {
      this.footerData[0].invoice_id = this.$i18n.t('total');
      this.footerData[0].amount = this.totalAmount;
    },
    'pageData.customer_id'() {
      if (this.pageData.customer_id || this.$router.currentRoute.query.customer_id) {
        this.getReports();
      }
    },
    'pageData.delegate_id'() {
      if (this.pageData.delegate_id || this.$router.currentRoute.query.delegate_id) {
        this.getReports();
      }
    },
    $route() {
      this.getReports();
    },
  },
  components: {
    GenericInput,
    EntityHeader,
    DynamicTable,
    // PrintBtn,
    ExportBtn,
    ControlSummery,
  },
  computed: {
    totalAmount() {
      return this.pageData.rows.reduce((oldValue, newValue) => {
        return +oldValue + +newValue.amount || 0;
      }, 0);
    },
    selectedCustomer() {
      if (this.pageData.customer_id)
        return this.$global.FilterArrayOfObjectElement(this.customers || [], 'id', this.pageData.customer_id, 'name') || null
      else
        return null
    },
    selectedDelegate() {
      if (this.pageData.customer_id)
        return this.$global.FilterArrayOfObjectElement(this.delegates || [], 'id', this.pageData.delegate_id, 'name') || null
      else
        return null
    },
    tableRows() {
      if (this.currentClearance) {
        return this.pageData.rows.filter(row => row.clearance_id == this.currentClearance)
      } else {
        return [];
      }
    },
    master() {
      if (this.tableRows.length) {
        const masterData = this.tableRows[0]
        return [
          { label: 'customer', value: masterData.customer_name, class: '', cols: null, sm: null, md: null, lg: 4, xl: null },
          { label: 'shipping policy number', value: masterData.shipping_policy_no, class: '', cols: null, sm: null, md: null, lg: 4, xl: null },
          { label: 'port', value: masterData.port_name, class: '', cols: null, sm: null, md: null, lg: 4, xl: null },
          { label: 'action date', value: masterData.clearance_date, class: '', cols: null, sm: null, md: null, lg: 4, xl: null },
          { label: 'delegate', value: masterData.delegate_name, class: '', cols: null, sm: null, md: null, lg: 4, xl: null },
        ]
      } else {
        return []
      }
    }
  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    console.log(this.$router.currentRoute.query);
    this.pageData.from_date = this.$router.currentRoute.query.from_date || this.$global.GetFirstDayOfMonth();
    this.pageData.to_date = this.$router.currentRoute.query.to_date || this.$global.GetCurrentDate();
    this.getData();
    this.pageData.customer_id = Number(this.$router.currentRoute.query.customer_id) || null
    this.pageData.delegate_id = Number(this.$router.currentRoute.query.delegate_id) || null
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;

      this.pageData.tableHeader = [
        { text: "#", key: "index", type: 'index', classes: "" },
        { text: "expense code", key: "expense_code_name", type: 'text', classes: "" },
        { text: "amount", key: "amount", type: 'float', classes: "" },
        { text: "action number", key: "receipt_number", type: 'text', classes: "" },
        { text: "is invoice", key: "invoice_id", type: 'slot', classes: "" },
      ];

      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`reimburses_expenses_report`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.customers = response.data.customers || [];
              this.delegates = response.data.delegates || [];
              this.expense_codes = response.data.expense_codes || [];
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getReports() {
      this.showMaster = false;
      this.pageData.isLoading = true;
      const body = {
        from: this.pageData.from_date,
        to: this.pageData.to_date,
        customer_id: this.pageData.customer_id,
        delegate_id: this.pageData.delegate_id,
        expense_code_id: this.pageData.expense_code_id,
        clearance_id: this.pageData.clearance_id,
        container_number: this.pageData.container_number,
        shipping_policy_no: this.pageData.shipping_policy_no,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`reimburses_expenses_report`, body, false)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.rows = response.data.report;
            this.clearances = response.data.ids;
            if (response.data.ids.length) {
              this.currentClearance = response.data.ids.length ? response.data.ids.at(0) : response.data.ids.at(0);
              this.prevClearance = 0;
              this.firstClearance = response.data.ids.at(0);
              this.lastClearance = response.data.ids.at(-1);
              this.nextClearance = response.data.ids.length > 1 ? response.data.ids.at(1) : response.data.ids.at(0);
              this.showMaster = true
              this.showFilter = false;
            }
            else {
              this.firstClearance = 0;
              this.prevClearance = 0;
              this.currentClearance = 0;
              this.nextClearance = 0;
              this.lastClearance = 0;
            }
          }
        });

    },
    changeClearance(id) {
      this.currentClearance = id;
      const getIndex = this.clearances.findIndex(clearance => clearance === id)
      this.nextClearance = this.clearances.length > getIndex + 1 ? this.clearances.at(getIndex + 1) : 0;
      this.prevClearance = getIndex > 0 ? this.clearances.at(getIndex - 1) : 0;
    },
    openInvoice(row) {
      if (row.invoice_id) {
        window.open('/sales/clearance_invoice-control?clearance_invoice_id=' + row.invoice_id, '_blank')
      }
    },
    print(type) {
      this.pageData.printLoading = true;
      const body = {
        from: this.pageData.from_date,
        to: this.pageData.to_date,
        customer_id: this.pageData.customer_id,
        delegate_id: this.pageData.delegate_id,
        expense_code_id: this.pageData.expense_code_id,
        clearance_id: this.pageData.clearance_id,
        container_number: this.pageData.container_number,
        shipping_policy_no: this.pageData.shipping_policy_no,
        type: type
      }
      this.$api
        .DOWNLOAD_METHOD_BODY(`reimburses_expenses_report`, body, `reimburses_expenses_report.${type}`)
        .then((response) => {
          this.pageData.printLoading = false;
          if (response.check) {
            // this.pageData.rows = response.data.report;
          }
        });
    },
  },
};
</script>
