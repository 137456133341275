<template>
   <v-col cols="12" class="accent--text" :class="{ 'white': !isLoading }">
      <v-col cols="12" v-if="isLoading" class="mt-16">
         <AnimatedLoading :height="100" :hideText="false" :isLoading="isLoading" />
      </v-col>
      <v-col cols="12" v-else class="mt-2" dir="rtl" id="printInvoice">
         <!-- invoice header -->
         <v-row align="center" justify="center">
            <v-col cols="12" class="d-flex justify-end pt-0 px-0">
               <div class="d-flex flex-wrap justify-center align-center hideInPrint">
                  <v-btn v-if="isEdit" @click="print" class="cursor_pointer" small icon fab>
                     <img src="@/assets/img/svg_icons/fi_printer.svg" height="20" />
                  </v-btn>

                  <v-btn color="error" v-if="isEdit" :loading="isSaveLoading" outlined :width="100" small class="mx-2 my-1"
                     @click="deleteMethod(true)" depressed>{{ $t('delete') }}</v-btn>

                  <v-btn color="primary" v-if="(!isEdit && transport_fees)" :min-width="100" small class="mx-2 my-1"
                     @click="transportFeesDialog = true" depressed>{{ $t('apply transport fees') }}</v-btn>

                  <v-btn color="success" v-if="!isEdit" :loading="isSaveLoading" :width="100" small class="mx-2 my-1"
                     @click="save" depressed>{{ $t('save') }}</v-btn>

                  <v-btn color="success"
                     v-if="detailsIsChanged && $route.query.clearance_invoice_id && printType === 'draft'"
                     :loading="editDraftInvoiceLoading" :min-width="100" small class="mx-2 my-1" @click="editDraftInvoice"
                     depressed>{{ $t('Save the modification') }}</v-btn>

                  <v-btn color="primary" v-if="!detailsIsChanged && isEdit && printType" :loading="draftToInvoiceLoading"
                     :min-width="100" small class="mx-2 my-1" @click="saveDraftToInvoice" depressed>
                     {{ $t('Transfer to a clearance invoice') }}</v-btn>

                  <v-btn color="accent" v-if="$route.query.clearance_invoice_id && printType === 'draft'" :min-width="100"
                     :loading="recalculateDataIsLoading" small class="mx-2 my-1" @click="recalculateData" depressed>{{
                        $t('Recalculate') }}</v-btn>
               </div>
            </v-col>
            <v-col cols="4" class="text-start">
               <div class="text-h6">
                  {{ invoice_defaults.company_name_ar }}
               </div>
               <div class="subtitle-1">
                  {{ invoice_defaults.slogan_ar }}
               </div>
               <div class="subtitle-1">
                  {{ invoice_defaults.vat_no }}
               </div>
            </v-col>

            <v-col cols="4">
               <img src="@/assets/img/png/logo.png" class="d-block ma-auto" :height="50" alt="buraq">

            </v-col>
            <v-col cols="4" dir="ltr">
               <div class="text-h6">
                  {{ invoice_defaults.company_name_en }}
               </div>
               <div class="subtitle-1">
                  {{ invoice_defaults.slogan_en }}
               </div>
               <div class="subtitle-1">
                  {{ invoice_defaults.vat_no }}
               </div>
            </v-col>

         </v-row>

         <!-- customer data -->
         <v-col cols="12" class="mt-4 section-border">
            <v-row align="center" justify="center">
               <v-col cols="8" class="column">
                  <div class="mx-2">
                     <div>السادة</div>
                     <div>Customer</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ master.customer_name }}
                  </div>
               </v-col>
               <v-col cols="4" class="column">
                  <div class="mx-2">
                     <div>رقم التسجيل الضريبي</div>
                     <div>Tax Reg. Number</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ master.vat_register_number }}
                  </div>
               </v-col>
               <v-col cols="12" class="column">
                  <div class="mx-2">
                     <div>عنوان العميل</div>
                     <div>Customer Address</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ master.address || '' }}
                  </div>
               </v-col>
            </v-row>
         </v-col>

         <!-- master data -->
         <v-col cols="12" class="mt-4 section-border">
            <v-row align="center">
               <v-col cols="3" class="column">
                  <div class="mx-2">
                     <div>رقم الفاتورة</div>
                     <div>Invoice Number</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ $route.query.clearance_invoice_id ? master.id : '' }}
                  </div>
               </v-col>
               <v-col cols="3" class="column">
                  <div class="mx-2">
                     <div>تاريخ</div>
                     <div>Date</div>
                  </div>
                  <div class="mx-2 text-center master-key">
                     {{ master.invoice_date || $route.query.invoice_date || ' ' }}
                  </div>
               </v-col>
               <v-col cols="3" class="column">
                  <div class="mx-2">
                     <div>البيان الجمركي</div>
                     <div>Bayan No</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ master.customs_declaration_number || ' ' }}
                  </div>
               </v-col>
               <v-col cols="3" class="column">
                  <div class="mx-2">
                     <div>رقم البوليصة</div>
                     <div>B/L No</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ master.shipping_policy_no || ' ' }}
                  </div>
               </v-col>
               <v-col cols="3" class="column">
                  <div class="mx-2">
                     <div>الفرع</div>
                     <div>Branch</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ master.port_name || ' ' }}
                  </div>
               </v-col>
               <v-col cols="3" class="column">
                  <div class="mx-2">
                     <div>رقم الملف</div>
                     <div>File No</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ $route.query.clearance_invoice_id ? master.clearance_id : master.id }}
                  </div>
               </v-col>
               <v-col cols="3" class="column">
                  <div class="mx-2">
                     <div>نوع البضاعة</div>
                     <div>Goods Type</div>
                  </div>
                  <div class="mx-2 master-key">
                     {{ master.goods_type || ' ' }}
                  </div>
               </v-col>
               <v-col cols="3" class="column">
                  <div class="mx-2">
                     <div>عدد الحاويات</div>
                     <div>No/Count</div>
                  </div>
                  <div class="mx-2 master-key">
                     <div v-if="master.container_big">{{ master.container_big }}*40</div>
                     <div v-if="master.container_small">{{ master.container_small }}*20</div>
                  </div>
               </v-col>
               <v-col cols="12" class="column warp" v-if="clearance_d.length">
                  <div class="mx-2">
                     <div>ارقام الحاويات</div>
                     <div>Containers No.</div>
                  </div>
                  <div class="master-key" v-for="(detail, index) in clearance_d" :key="index">
                     <div>
                        {{ detail.container_number }}
                        <span class="caption">({{ detail.container_size }})</span>
                        <span class="mx-1 gray9--text" v-if="clearance_d.length > index + 1">|</span>
                     </div>
                  </div>
               </v-col>

            </v-row>
         </v-col>


         <!-- detail data -->
         <v-col cols="12" class="mt-4 section-border">
            <!-- header -->
            <v-row align="center">
               <v-col cols="1" class="column column-header">
                  <div>رقم الأيصال</div>
                  <div>File No</div>
               </v-col>
               <v-col cols="2" class="column column-header">
                  <div>البيان</div>
                  <div>Details</div>
               </v-col>
               <v-col cols="2" class="column column-header">
                  <div>القيمة قبل الضريبة</div>
                  <div>Value excl.VAT</div>
               </v-col>
               <v-col cols="1" class="column column-header">
                  <div>الضريبة %</div>
                  <div>VAT %</div>
               </v-col>
               <v-col cols="2" class="column column-header">
                  <div>قيمة الضريبة</div>
                  <div>Amount VAT</div>
               </v-col>
               <v-col cols="2" class="column column-header">
                  <div>الأجمالي شامل الضريبة</div>
                  <div>Value including VAT</div>
               </v-col>
               <v-col cols="2" class="column column-header">
                  <div>ملاحظات</div>
                  <div>Notes</div>
               </v-col>
            </v-row>
            <v-row align="center" v-for="(detail, index) in expenses" :key="`expense_detail_${detail.d_id || index}}`">
               <v-col cols="1" class="column column-header">
                  {{ detail.d_id }}
               </v-col>
               <v-col cols="2" class="column column-body">
                  <div>{{ detail.expense_code_name }}</div>
               </v-col>
               <v-col cols="2" class="column column-body">
                  <span v-if="!detail.amount"> {{ detail.amount | float }}</span>
                  <span v-if="detail.amount" class="cursor_pointer primary--text text-decoration-underline"
                     @click="showExpensesDetails(detail.expense_code_id)">{{ detail.amount | float }}</span>
               </v-col>
               <v-col cols="1" class="column column-body">
                  {{ '-' }}
               </v-col>
               <v-col cols="2" class="column column-body">
                  {{ '-' }}
               </v-col>
               <v-col cols="2" class="column column-body">
                  {{ detail.amount | float }}
               </v-col>
               <v-col cols="2" class="column column-body">
                  {{ detail.description }}
               </v-col>
            </v-row>
            <v-row align="center" v-for="(detail, index) in details"
               :key="`detail_${detail.clearance_invoices_d_id || index}}`">
               <v-col cols="1" class="column column-header">
                  {{ '-' }}
               </v-col>
               <v-col cols="2" class="column column-body column-gray">
                  <div>{{ detail.name_ar }}</div>
                  <div>{{ detail.name_en }}</div>
               </v-col>
               <v-col cols="2" class="column column-body column-gray"
                  v-if="(!isEdit || printType === 'draft') && ($global.CheckAction('04-012', 3) || $global.CheckAction('03-023', 3))">
                  <FloatInput type="float" class="input_transparent" name="vat" label="" :value="detail.amount_without_vat"
                     @input="setValue(index, $event)" :isLoading="false" :cols="[12, 12, 12]" />
               </v-col>
               <v-col cols="2" class="column column-body column-gray" v-else>
                  {{ detail.amount_without_vat | float }}
               </v-col>
               <v-col cols="1" class="column column-body column-gray">
                  {{ detail.vat_per }} %
               </v-col>
               <v-col cols="2" class="column column-body column-gray">
                  {{ +detail.amount_with_vat - +detail.amount_without_vat | float }}
               </v-col>
               <v-col cols="2" class="column column-body column-gray">
                  {{ detail.amount_with_vat | float }}
               </v-col>
               <v-col cols="2" class="column column-body column-gray">
                  {{ detail.description }}
               </v-col>
            </v-row>
         </v-col>


         <!-- summery -->
         <v-row class="mt-3">
            <v-col cols="6">
               <v-sheet color="#f9f9f9" class="py-3 px-5" :height="totals.total_with_vat ? 120 : 90">
                  <div class="subtitle-2 d-flex justify-space-between">
                     <div>ملاحظات :</div>
                     <div>:Notes </div>
                  </div>
                  <div>{{ master.description }}</div>
               </v-sheet>
            </v-col>
            <v-col cols="6">
               <v-sheet color="#f9f9f9" class="py-3 px-5">
                  <v-row class="subtitle-2 font-weight-bold d-flex justify-space-between">
                     <v-col cols="4" class="pa-1">غير شامل الضريبة :</v-col>
                     <v-col cols="4" class="pa-1 text-center">
                        {{ !isEdit || printType === 'draft' ?
                           (calculations.total_without_vat) :
                           (totals.total_without_vat) | float }} </v-col>
                     <v-col cols="4" class="pa-1 text-end">: Value Excluding Vat</v-col>
                  </v-row>
                  <v-row class="subtitle-2 font-weight-bold d-flex justify-space-between">
                     <v-col cols="4" class="pa-1">قيمة الضريبة :</v-col>
                     <v-col cols="4" class="pa-1 text-center">
                        {{ !isEdit || printType === 'draft' ?
                           (calculations.total_vat) :
                           (totals.total_vat) | float }}
                     </v-col>
                     <v-col cols="4" class="pa-1 text-end">: Vat Value</v-col>
                  </v-row>
                  <v-row class="subtitle-2 font-weight-bold d-flex justify-space-between blue12">
                     <v-col cols="4" class="pa-1">القيمة شامل الضريبة :</v-col>
                     <v-col cols="4" class="pa-1 text-center">
                        {{ !isEdit || printType === 'draft' ?
                           (calculations.total_with_vat) :
                           (totals.total_with_vat) | float }}
                     </v-col>
                     <v-col cols="4" class="pa-1 text-end">: Value Including Vat</v-col>
                  </v-row>
                  <v-row class="body-2 font-weight-bold d-flex justify-space-between blue12">
                     <v-col cols="12" class="pa-1 text-center" v-if="totals.total_with_vat || calculations.total_with_vat">
                        <!-- {{ totals.total_with_vat || calculations.total_with_vat | tafqeet }} -->
                        {{ !isEdit || printType === 'draft' ?
                           (calculations.total_with_vat).toFixed(2) :
                           (totals.total_with_vat).toFixed(2) | tafqeet }}
                     </v-col>
                  </v-row>
               </v-sheet>
            </v-col>
            <v-col cols="6">
               <v-sheet class="py-0 px-5" :height="30">
                  <div class="subtitle-1 d-flex justify-space-between">
                     <div>المستلم :</div>
                  </div>
                  <div>{{ "" }}</div>
               </v-sheet>
            </v-col>
            <v-col cols="6">
               <v-sheet class="py-0 px-5" :height="30">
                  <div class="subtitle-1 d-flex justify-space-between">
                     <div>تاريخ الأستلام :</div>
                  </div>
                  <div>{{ "" }}</div>
               </v-sheet>
            </v-col>
            <v-col cols="6">
               <v-sheet class="py-0 px-5" :height="30">
                  <div class="subtitle-1 d-flex justify-space-between">
                     <div>المحاسب :</div>
                  </div>
                  <div>{{ "" }}</div>
               </v-sheet>
            </v-col>
            <v-col cols="6">
               <v-sheet class="py-0 px-5" :height="30">
                  <div class="subtitle-1 d-flex justify-space-between">
                     <div>المرجع :</div>
                  </div>
                  <div>{{ "" }}</div>
               </v-sheet>
            </v-col>
            <v-col cols="12">
               <v-sheet color="#f9f9f9" class="py-0 px-5">
                  <v-row align="center">
                     <v-col cols="6" class="py-0">
                        <v-row class="subtitle-2 font-weight-bold d-flex align-center justify-space-between">
                           <v-col cols="auto" class="pa-1"> بنك الأهلي :</v-col>
                           <v-col cols="auto" class="pa-1 body-2 text-center"> IBAN EG-12334567891323582</v-col>
                           <v-col cols="auto" class="pa-1 text-end">: NCB Bank</v-col>
                        </v-row>
                        <v-row class="subtitle-2 font-weight-bold d-flex align-center justify-space-between">
                           <v-col cols="auto" class="pa-1"> بنك الرياض :</v-col>
                           <v-col cols="auto" class="pa-1 body-2 text-center"> IBAN KSA-1233456789132358</v-col>
                           <v-col cols="auto" class="pa-1 text-end">: Riyad Bank</v-col>
                        </v-row>
                        <v-row class="subtitle-2 font-weight-bold d-flex align-center justify-space-between">
                           <v-col cols="auto" class="pa-1"> بنك الراجحي :</v-col>
                           <v-col cols="auto" class="pa-1 body-2 text-center"> IBAN KSA-1233456789132358</v-col>
                           <v-col cols="auto" class="pa-1 text-end">: Rajhi Bank</v-col>
                        </v-row>
                     </v-col>
                     <v-col cols="6" dir="ltr">
                        <img src="@/assets/img/svg/qrcode.svg" :height="85" alt="">
                     </v-col>
                  </v-row>
               </v-sheet>
            </v-col>
         </v-row>
         <!-- footer -->
         <v-row>
            <v-col cols="12">
               <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="subtitle-2 text-center font-weight-bold">
               <v-row justify="center">
                  <v-col cols="auto">
                     Email : customer@buraqalmad.com
                  </v-col>
                  <v-col cols="auto">
                     Website : buraqalmad.business.site
                  </v-col>
                  <v-col cols="auto">
                     Jeddah 012 6272666
                  </v-col>
                  <v-col cols="auto">
                     Riyadh 011 4762222
                  </v-col>
               </v-row>
            </v-col>
         </v-row>
      </v-col>

      <ExpensesDetailsDialog :dialog="expensesDetailsDialog" :isLoading="expensesDetailsLoading" :details="expensesDetails"
         :backValueMethod="(status) => { expensesDetailsDialog = status }" />

      <ApplyTransportFeesDialog :dialog="transportFeesDialog" :areas="areas" :prices="prices" :master="master"
         :setContainersPrice="setContainersPrice" :backValueMethod="(status) => { transportFeesDialog = status }"
         :clearance_d="clearance_d" />
   </v-col>
</template>



<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import ApplyTransportFeesDialog from './ApplyTransportFeesDialog';
import ExpensesDetailsDialog from './ExpensesDetailsDialog';
import FloatInput from '@/components/inputs/FloatInput.vue';


export default {
   name: "ClearanceInvoicePrintView",
   props: {
      detailAction: { type: Function },
      isLoading: { default: false },
      isEdit: { default: false },
      transport_fees: { default: 0 },
      count_invoices: { default: 0 },
      master: { default: Object },
      totals: { default: Object },
      details: { default: [] },
      expenses: { default: [] },
      clearance_d: { default: [] },
      areas: { default: [] },
      prices: { default: [] },
      printType: { default: String },
   },
   data: () => ({
      invoice_defaults: Object,
      isSaveLoading: false,
      transportFeesDialog: false,
      printLoading: true,
      expensesDetailsDialog: false,
      expensesDetailsLoading: false,
      editDraftInvoiceLoading: false,
      recalculateDataIsLoading: false,
      expensesDetails: false,
      draftToInvoiceLoading: false,
      detailsIsChanged: false,
      clearance_transport_fees: [],
      endPoint: ''
   }),
   components: {
      AnimatedLoading,
      ApplyTransportFeesDialog,
      ExpensesDetailsDialog,
      FloatInput
   },
   computed: {
      calculations() {
         let total_with_vat = [...this.expenses, ...this.details].reduce((oldValue, newValue) => {
            if (newValue.amount_with_vat >= 0) // clearance detail
               return +oldValue + +newValue.amount_with_vat;
            else // expense detail
               return +oldValue + +newValue.amount;
         }, 0);
         let total_without_vat = [...this.expenses, ...this.details].reduce((oldValue, newValue) => {
            if (newValue.amount_without_vat >= 0) // clearance detail
               return +oldValue + (+newValue.amount_without_vat);
            else // expense detail
               return +oldValue + +newValue.amount;
         }, 0);
         let total_vat = total_with_vat - total_without_vat;
         return {
            total_with_vat: total_with_vat,
            total_without_vat: total_without_vat,
            total_vat: total_vat,
         }
      },
      // computedDetailsIsChanged() {
      //    // return Object.keys(this.oldForm).every(oldForm => this.oldForm[oldForm] == this.form[oldForm])
      // }
   },
   watch: {
      // details(oldValve,newValve){
      //    console.log(oldValve,newValve);
      // }

      // details: {
      //    handler(newVal, oldVal) {
      //       // This will be called whenever any property within `person` changes.
      //       console.log(newVal[0].amount_without_vat, oldVal[0].amount_without_vat);
      //    },
      //    deep: true // Set the deep flag to true
      // }

   },
   mounted() {
      this.invoice_defaults = {
         company_name_ar: 'شركة البراك للخدمات اللوجستية',
         company_name_en: 'buraq AL-MAD LOGISTICS CI., LTD',
         slogan_ar: 'تخليص جمركي - نقليات - شحن جوي و بحري',
         slogan_en: 'Customs Clearance - Transportation -  Freight',
         vat_no: 30005523300003
      }

      this.endPoint = this.printType === 'draft' ? 'clearance_draft' : 'clearance_invoice';
   },
   methods: {
      save() {
         let request_body = { clearance_id: this.master.id, invoice_date: this.$route.query.invoice_date, clearance_transport_fees: this.clearance_transport_fees, extra_amounts: this.details }
         if (this.count_invoices) {
            request_body.extra_amounts = this.details || [];
         }
         console.log('calculations', this.calculations);
         console.log('details', this.details);
         this.isSaveLoading = true;
         this.$api.POST_METHOD(this.endPoint, request_body).then((response) => {
            this.isSaveLoading = false;
            if (response.check) {
               const src = `src="${this.$api.serverUrl}export_clearance_invoice/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
               this.$api.PrintInvoice(src, response.data);

               if (this.endPoint === 'clearance_draft') {
                  this.$router.push({ name: 'ClearanceDraftInvoiceEntity' })
               } else {
                  this.$router.push({ name: 'ClearanceInvoiceEntity' })
               }

            }
         })
      },
      saveDraftToInvoice() {
         if (this.printType === 'draft') {
            let request_body = { clearance_id: this.master.id, invoice_date: this.$route.query.invoice_date, clearance_transport_fees: this.clearance_transport_fees, extra_amounts: this.details }
            this.draftToInvoiceLoading = true;
            this.$api.POST_METHOD(`convert_draft_to_invoice/${this.$route.query.clearance_invoice_id}`, request_body).then((response) => {
               this.draftToInvoiceLoading = false;
               if (response.check) {
                  console.log('convert_draft_to_invoice', response);
                  this.$router.push({ name: 'ClearanceDraftInvoiceEntity' })
               }
            })
         }
      },
      recalculateData() {
         this.recalculateDataIsLoading = true;
         this.$api.GET_METHOD(`clearance_draft/${this.$route.query.clearance_invoice_id}`).then((response) => {
            this.recalculateDataIsLoading = false;
            if (response?.check) {
               console.log('response', response);
               this.expenses = response.data.deleget_amounts;
               this.detailsIsChanged = true
            }
         })
      },
      editDraftInvoice() {
         if (this.printType === 'draft') {
            let request_body = { clearance_id: this.master.id, invoice_date: this.$route.query.invoice_date, clearance_transport_fees: this.clearance_transport_fees, extra_amounts: this.details }
            this.editDraftInvoiceLoading = true;
            request_body._method = 'PUT';
            this.$api.POST_METHOD(`clearance_draft/${this.$route.query.clearance_invoice_id}`, request_body).then((response) => {
               this.editDraftInvoiceLoading = false;
               if (response.check) {
                  console.log('clearance_draft', response);
                  // this.$router.push({ name: 'ClearanceDraftInvoiceEntity' })
                  this.detailsIsChanged = false
               }
            })
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.isSaveLoading = true;
            this.$api.POST_METHOD(`${this.endPoint}/${this.master.id}`, { _method: 'delete' }).then(() => {
               this.isSaveLoading = false;
               if (this.endPoint === 'clearance_draft') {
                  this.$router.push({ name: 'ClearanceDraftInvoiceEntity' })
               } else {
                  this.$router.push({ name: 'ClearanceInvoiceEntity' })
               }
            })
         }
      },
      setContainersPrice(clearance_transport_fees = []) {
         this.transportFeesDialog = false;
         this.clearance_transport_fees = clearance_transport_fees || [];
         const totalTransportFees = clearance_transport_fees.reduce((oldValue, newValue) => +oldValue + +newValue.price, 0)
         this.setValue(4, totalTransportFees)
      },
      print() {
         this.$api.PrintInvoiceURL(`${this.$api.serverUrl}export_clearance_invoice/${this.$route.query.clearance_invoice_id}?type=print&clientID=${this.$store.state.clientID}`, this.$route.query.clearance_invoice_id)
      },
      showExpensesDetails(expense_code_id) {
         this.expensesDetailsLoading = true;
         this.expensesDetailsDialog = true;
         this.$api.GET_METHOD(`get_invoice_deleget_data?clearance_id=${this.master.clearance_id || this.master.id || ''}&expense_code_id=${expense_code_id}`).then((response) => {
            this.expensesDetailsLoading = false;
            if (response.check) {
               this.expensesDetails = response.data.items || [];
            }
         })
      },
      setValue(rowIndex, value) {
         let details = [...this.details]
         this.detailsIsChanged = value
         details[rowIndex].amount_without_vat = value;
         details[rowIndex].vat_per = details[rowIndex].vat_per || 15;
         details[rowIndex].vat_amount = (value * details[rowIndex].vat_per) / 100;
         details[rowIndex].amount_with_vat = details[rowIndex].amount_without_vat + details[rowIndex].vat_amount;
         this.details = [...details]
      },
   },
};
</script>



<style lang="scss" scoped>
.section-border {
   // border : 2px solid #d2d1d4 !important;
   border-bottom: 4px solid #23a4c3;
   border-right: 4px solid #23a4c3;

   .column {
      border: 3px solid #d2d1d4;
      border-bottom: 0px solid #d2d1d4;
      border-right: 0px solid #d2d1d4;
      padding: 0.15rem;
      display: flex;
      align-items: center;
      font-size: 0.875rem !important;
      font-weight: 500;
      height: 43px;
      position: relative;

      &.warp {
         flex-wrap: wrap;
         height: auto;
      }

      .master-key {
         font-weight: 700;
      }

      &.column-header {
         background-color: #e3f8fe;
         color: #00637b;
         font-weight: bold;
         text-align: center !important;
         vertical-align: middle;
         display: grid;
         height: 50px;
      }

      &.column-body {
         color: #000;
         text-align: center;
         font-weight: bold;
         vertical-align: middle;
         display: grid;
         height: 50px;
      }

      &.column-gray {
         background-color: #e8e8ea;
      }
   }
}
</style>