<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData"
        :createBtnText="$i18n.t('add') + ' ' + this.$store.getters.activeScreen.sub_title" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'daily'">
                <v-btn @click="openJournal(row)" small  text class="blue1--text">
                  {{ $t('journal') }}
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
export default {
  name: "BankTransfersEntity",

  data: () => ({
    pageData: {
      screen_code: "02-017",
      url: null,
      controlRoute: "accounting/bank_transfer-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      status: 1,
      pagination: {},
      queryParam: '?'
    },
    tab: 1,
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
  },
  computed: {

  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "code", key: "m_id", type: 'text', classes: "" },
        { text: "finance year", key: "finance_year_name", type: 'text', classes: "" },
        { text: "branch", key: "branch_name", type: 'text', classes: "" },
        { text: "date", key: "action_date", type: 'date', classes: "" },
        { text: "amount", key: "total_amount", type: 'date', classes: "" },
        { text: "description", key: "arabic_description", type: 'date', classes: "" },
        { text: "daily type", key: "daily_type_name", type: 'date', classes: "" },
        // { text: "actions", key: "id", type: 'actions', classes: "" },
        { text: "daily journal", key: "daily", type: 'slot', classes: "" },
        { text: "actions", key: "actions", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: true,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `bank_transfer${this.pageData.queryParam}&status=${this.tab}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`bank_transfer/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`bank_transfer_toggle_active/${row.m_id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/bank_transfer/${row.m_id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id);
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.daily_journal_m_id, '_blank')
    },
  },
};
</script>
