<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save"
            :valid="(valid && tableRows.length > 0 && Math.abs(totalsCalculations.difference).toFixed(2) == 0)"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
            <AnimatedLoading :height="100" :hideText="false" :isLoading="pageData.isLoading" />
         </v-col>

         <div v-if="!pageData.isLoading">
            <!-- content -->
            <!-- Form -->
            <v-form ref="form" v-model="valid" class="pa-3">
               <v-card class="backgroundW shadow pa-5 pt-7">
                  <v-fade-transition>
                     <div class="collapseForm" v-if="tableRows.length > 0 || form.multi_voucher_status == 2">
                        <div class="d-flex">
                           <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                              <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                              <v-icon v-else>mdi-chevron-down</v-icon>
                           </v-btn>
                        </div>
                     </div>
                  </v-fade-transition>
                  <v-expand-transition>
                     <v-row v-show="!formCollapse">
                        <v-col cols="6">
                           <v-row align="center">
                              <GenericInput type="text" :value="form.id" v-if="pageData.isEdit" :disabled="true"
                                 @input="form.id = $event" label="the bond number" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>

                              <!-- <GenericInput type="select" :lookups="transaction_types" :value="form.conversion_type_id"
                                 @input="form.conversion_type_id = $event" label="transaction type" :multi="false"
                                 :required="true" selected_label="name" :disabled="false" selected_prop="id"
                                 :isLoading="false" :cols="[12, 6, 6]"></GenericInput> -->

                              <GenericInput type="date" :value="form.action_date" @input="form.action_date = $event"
                                 label="movement date" :required="true" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 12, 12]">
                              </GenericInput>

                              <!-- <GenericInput type="select" :lookups="currencies" :value="form.currency_id"
                                 @input="form.currency_id = $event" label="currency" :multi="false" :required="true"
                                 selected_label="name" :disabled="false" selected_prop="id" :isLoading="false"
                                 :cols="[12, 6, 6]"></GenericInput> -->
                           </v-row>
                        </v-col>

                        <v-col cols="6">
                           <v-row>
                              <GenericInput type="textarea" :value="form.description" @input="form.description = $event"
                                 label="description" :required="false" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 12, 12]">
                              </GenericInput>
                              <v-col cols="12">
                                 <v-btn outlined class="d-block mi-start-auto" depressed color="blue2" :min-width="120"
                                    @click="detailDialog = true" :height="37">
                                    {{ $t('add line') }}
                                 </v-btn>
                              </v-col>
                           </v-row>
                        </v-col>

                     </v-row>
                  </v-expand-transition>
               </v-card>
            </v-form>

            <!-- Details Table -->
            <div class="pa-2">
               <DynamicTable :isLoading="pageData.isLoading" :data="tableRows || []" :footerData="[totalsCalculations]"
                  :header="tableHeader" :tableHeight="$store.state.Settings.windowSize.y - 230">
                  <template v-slot:td="{ row, header, index }">
                     <div v-if="header.key == 'actions'">
                        <v-btn @click="editDetail(index, row)" v-if="!row.invoice_id" x-small icon fab>
                           <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                        </v-btn>
                        <v-btn @click="removeItem(index, row)" v-if="!row.invoice_id" x-small icon fab>
                           <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                        </v-btn>
                        <div v-if="pageData.isEdit && header.key == 'download'">
                           <v-btn icon :loading="printLoading" v-if="row.attach_ext" color="success"
                              @click="downloadAttachment(row)">
                              <v-icon>mdi-download</v-icon>
                           </v-btn>

                           <v-btn icon color="primary" @click="openAttachmentDialog(row, index)">
                              <v-icon v-if="!row.attach_ext">mdi-paperclip-plus</v-icon>
                              <v-icon v-if="row.attach_ext">mdi-paperclip</v-icon>
                           </v-btn>
                        </div>
                     </div>
                  </template>
               </DynamicTable>
            </div>
            <!-- Detail Form -->
            <MultiVoucherDetail :detailAction="detailAction" :tableRows="tableRows" :voucher_types="voucher_types"
               :taxes="taxes" :vat_accounts="vat_accounts" :vat_types="vat_types" :dis_types="dis_types"
               :account_trees="account_trees" :cost_centers="cost_centers" :customers="customers" :suppliers="suppliers"
               :clearances="clearances" :sales_agents="sales_agents" :purchase_agents="purchase_agents"
               :delegates="delegates" :expense_codes="expense_codes" :pay_types="pay_types" :treasuries="treasuries"
               :banks="banks" :dialog="detailDialog" :detail="detail" :updateDetail="updateDetail"
               :isDetailEdit="isDetailEdit" :totalsCalculations="totalsCalculations" />
            <!-- Detail of Form -->

            <!-- Attachment -->
            <AttachmentDialog v-if="pageData.isEdit" :dialog="attachmentDialog" entityName="multi voucher" dataKey="d_id"
               :data="selectedRow" uploadResource="add_multi_voucher_attach" downloadResource="get_multi_voucher_attach"
               :returnMethod="(reload) => { attachmentDialog = false; reload ? getData() : null }" />
            <!-- Attachment -->
         </div>
      </v-container>


   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import MultiVoucherDetail from "./MultiVoucherDetail.vue";
import AttachmentDialog from "@/components/modals/AttachmentDialog.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "MultiVoucherControl",
   data: () => ({
      pageData: {
         screen_code: "02-013",
         url: null,
         controlRoute: "/inventory/multi_voucher-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      attachmentDialog: false,
      formCollapse: false,
      viewForm: false,
      valid: false,
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      getContainerLoading: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      selectedRow: null,
      // lockups
      vat_types: [],
      vat_accounts: [],
      taxes: [],
      transaction_types: [],
      currencies: [],
      cost_centers: [],
      account_trees: [],
      customers: [],
      banks: [],
      suppliers: [],
      sales_agents: [],
      purchase_agents: [],
      treasuries: [],
      dis_types: [],
      pay_types: [],
      voucher_types: [],
      delegates: [],
      expense_codes: [],
      clearances: [],
      form: {
         voucher_number: null,
         description: null,
         // currency_id: null,
         conversion_type_id: null,
         action_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
         items: [],
      },
      detail: {
         voucher_type: 1,
         amount: 0,
         description: null,
         cost_centers: [],
         tax_id: null,
         dis_type: 8,
         dis_treasury_id: null,
         dis_bank_id: null,
         dis_customer_id: null,
         dis_supplier_id: null,
         dis_account_tree_id: null,
         dis_delegate_id: null,
         dis_expense_code_id: null,
         clearance_id: null,
         vat_percentage: 0,
         taxable: 0,
         receipt_number: null,
      }
   }),
   components: {
      ControlHeader,
      DynamicTable,
      AnimatedLoading,
      MultiVoucherDetail,
      AttachmentDialog,
      GenericInput
   },
   computed: {
      totalsCalculations() {
         if (this.tableRows.length > 0) {
            const credit = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.credit);
            }, 0);
            const debit = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.debit);
            }, 0);
            const vat = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.vat);
            }, 0);
            const without_vat = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.without_vat);
            }, 0);
            const total = credit + debit;
            const difference = debit - credit;
            return {
               voucher_type_title: this.$i18n.t('total'),
               credit: credit,
               debit: debit,
               difference: difference,
               vat: vat,
               without_vat: without_vat,
               total: total,
            }
         } else {
            return {
               voucher_type_title: this.$i18n.t('total'),
               credit: 0,
               debit: 0,
               difference: 0,
               vat: 0,
               without_vat: 0,
               total: 0,
            }
         }
      },
   },
   watch: {
      tableRows() {
         this.tableRows.forEach(row => {
            row.voucher_type_title = row.voucher_type ? this.$global.FilterArrayOfObjectElement(this.voucher_types, 'id', +row.voucher_type, 'name') : null;
            row.dis_type_title = row.dis_type ? this.$global.FilterArrayOfObjectElement(this.dis_types, 'id', +row.dis_type, 'name') : null;
            row.tax_title = row.tax_id ? this.$global.FilterArrayOfObjectElement(this.taxes, 'id', +row.tax_id, 'name') : null;
            row.vat_percentage = row.tax_id ? this.$global.FilterArrayOfObjectElement(this.taxes, 'id', +row.tax_id, 'tax_percentage') : null;
            row.debit = +row.voucher_type == 1 ? +row.amount : 0;
            row.credit = +row.voucher_type == 2 ? +row.amount : 0;
            row.vat = +row.amount - (+row.amount / ((+row.vat_percentage / 100) + 1));
            row.without_vat = +row.amount - row.vat;
            if (typeof row.cost_centers == 'string' && row.cost_centers) {
               row.cost_centers = row.cost_centers.split(',').map(i => Number(i))
            }
            row.selected_cost_centers = null;
            if (row.cost_centers.length) {
               row.cost_centers.forEach(cost_center => {
                  row.selected_cost_centers = `${row.selected_cost_centers ? row.selected_cost_centers + '-' : ''}  ${this.$global.FilterArrayOfObjectElement(this.cost_centers, 'id', +cost_center, 'name')}`;
               });
            }
            switch (+row.dis_type) {
               case 1:
                  row.dis_type_name = row.dis_treasury_id ? this.$global.FilterArrayOfObjectElement(this.treasuries, 'id', +row.dis_treasury_id, 'name') : null;
                  break;
               case 2:
                  row.dis_type_name = row.dis_bank_id ? this.$global.FilterArrayOfObjectElement(this.banks, 'id', +row.dis_bank_id, 'name') : null;
                  break;
               case 3:
                  row.dis_type_name = row.dis_customer_id ? this.$global.FilterArrayOfObjectElement(this.customers, 'id', +row.dis_customer_id, 'name') : null;
                  break;
               case 4:
                  row.dis_type_name = row.dis_supplier_id ? this.$global.FilterArrayOfObjectElement(this.suppliers, 'id', +row.dis_supplier_id, 'name') : null;
                  break;
               case 5:
                  row.dis_type_name = row.dis_account_tree_id ? this.$global.FilterArrayOfObjectElement(this.account_trees, 'id', +row.dis_account_tree_id, 'name') : null;
                  break;
               case 6:
                  row.dis_type_name = row.dis_delegate_id ? this.$global.FilterArrayOfObjectElement(this.delegates, 'id', +row.dis_delegate_id, 'name') : null;
                  break;
               case 7:
                  row.dis_type_name = row.dis_delegate_id ? this.$global.FilterArrayOfObjectElement(this.delegates, 'id', +row.dis_delegate_id, 'name') : null;
                  break;
               case 8:
                  row.dis_type_name = row.dis_expense_code_id ? this.$global.FilterArrayOfObjectElement(this.expense_codes, 'id', +row.dis_expense_code_id, 'name') : null;
                  break;
               default:
                  break;
            }

         });
      },
      '$store.state.Settings.key_clicked'() {
         switch (this.$store.state.Settings.key_clicked) {
            case 'F3':
               this.detailDialog = true;
               break;
            case 'F10':
               if (this.valid && !this.detailDialog) {
                  this.save()
               }
               break;
            default:
               break;
         }
      },
   },
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
         this.tableHeader = [
            { text: "voucher type", key: "voucher_type_title", type: 'text', classes: "" },
            { text: "entity type", key: "dis_type_title", type: 'text', classes: "" },
            { text: "the entity", key: "dis_type_name", type: 'text', classes: "" },
            { text: "cost center", key: "selected_cost_centers", type: 'text', classes: "" },
            { text: "receipt number", key: "receipt_number", type: 'text', classes: "" },
            { text: "clearance number", key: "clearance_id", type: 'text', classes: "" },
            { text: "description", key: "description", type: 'text', classes: "" },
            { text: "debit", key: "debit", type: 'float', classes: "" },
            { text: "credit", key: "credit", type: 'float', classes: "" },
            { text: "vat type", key: "tax_title", type: 'text', classes: "" },
            { text: "vat", key: "vat", type: 'float', classes: "" },
            { text: "without vat", key: "without_vat", type: 'float', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
         ];
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`multi_voucher/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.form = response.data.master;
                     response.data.details.forEach(detail => {
                        detail.taxable = detail.tax_id ? 1 : 0
                     });
                     this.tableRows = response.data.details;
                     this.dis_types = [
                        { id: 1, name: this.$i18n.t('treasury') },
                        { id: 2, name: this.$i18n.t('bank') },
                        { id: 3, name: this.$i18n.t('customer') },
                        // { id: 4, name: this.$i18n.t('supplier') },
                        { id: 5, name: this.$i18n.t('account') },
                        { id: 6, name: this.$i18n.t('delegate covenant') },
                        { id: 7, name: this.$i18n.t('maintenance covenant - workshop') },
                        { id: 8, name: this.$i18n.t('refundable expenses') },
                     ];
                     this.voucher_types = [
                        { id: 1, name: this.$i18n.t('debit') },
                        { id: 2, name: this.$i18n.t('credit') }
                     ];

                     this.account_trees = response.data.account_trees || [];
                     this.cost_centers = response.data.cost_centers || [];
                     this.banks = response.data.bank || [];
                     this.customers = response.data.customers || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.suppliers = response.data.purchases_vendors || [];
                     this.purchase_agents = response.data.purchases_agents || [];
                     this.treasuries = response.data.treasurys || [];
                     this.taxes = response.data.taxes || [];
                     this.currencies = response.data.currencys || [];
                     this.transaction_types = response.data.types || [];
                     this.expense_codes = response.data.expense_codes || [];
                     this.delegates = response.data.delegates || [];
                     response.data.clearances.forEach(clearance => {
                        clearance.selected_label = `${clearance.id} - ${clearance.customer_name}`
                     });
                     this.clearances = response.data.clearances || [];
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.pageData.isEdit = false;
               this.$api.GET_METHOD(`multi_voucher/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.dis_types = [
                        { id: 1, name: this.$i18n.t('treasury') },
                        { id: 2, name: this.$i18n.t('bank') },
                        { id: 3, name: this.$i18n.t('customer') },
                        // { id: 4, name: this.$i18n.t('supplier') },
                        { id: 5, name: this.$i18n.t('account') },
                        { id: 6, name: this.$i18n.t('delegate covenant') },
                        { id: 7, name: this.$i18n.t('maintenance covenant - workshop') },
                        { id: 8, name: this.$i18n.t('refundable expenses') },
                     ];
                     this.voucher_types = [
                        { id: 1, name: this.$i18n.t('debit') },
                        { id: 2, name: this.$i18n.t('credit') }
                     ];
                     this.account_trees = response.data.account_trees || [];
                     this.cost_centers = response.data.cost_centers || [];
                     this.banks = response.data.bank || [];
                     this.customers = response.data.customers || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.suppliers = response.data.purchases_vendors || [];
                     this.purchase_agents = response.data.purchases_agents || [];
                     this.treasuries = response.data.treasurys || [];
                     this.taxes = response.data.taxes || [];
                     this.currencies = response.data.currencys || [];
                     this.form.currency_id = response.data.currencys.at(0).id
                     this.transaction_types = response.data.types || [];
                     this.expense_codes = response.data.expense_codes || [];
                     this.delegates = response.data.delegates || [];
                     response.data.clearances.forEach(clearance => {
                        clearance.selected_label = `${clearance.id} - ${clearance.customer_name}`
                     });
                     this.clearances = response.data.clearances || [];
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {
         this.form.items = this.tableRows;

         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`multi_voucher/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.printReport(response.data)
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`multi_voucher`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.printReport(response.data)
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }

      },
      detailAction(detail, isEdit, cancel) {
         console.log(detail, isEdit, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.resetDetail();
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.tableRows.push(detail);
            this.resetDetail();
         }
      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.resetDetail();
      },
      resetDetail() {
         this.detail = {
            voucher_type: 1,
            amount: 0,
            description: null,
            cost_centers: [],
            tax_id: null,
            dis_type: 8,
            dis_treasury_id: null,
            dis_bank_id: null,
            dis_customer_id: null,
            dis_supplier_id: null,
            dis_account_tree_id: null,
            dis_delegate_id: null,
            dis_expense_code_id: null,
            vat_percentage: 0,
            clearance_id: null,
            taxable: 0,
            receipt_number: null,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      openAttachmentDialog(row) {
         this.selectedRow = row
         this.attachmentDialog = true;
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`multi_voucher/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
      printReport(id) {
         this.$api.PrintPOST('export_multi_voucher', id, 'print').then(() => { })
      }
   },
};
</script>