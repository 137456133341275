<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="700">
      <v-card relative :key="modalKey">

         <v-form ref="detail" v-model="valid" class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row justify="space-between">
                  <v-col cols="12" class="pt-0">
                     <div class="subtitle-1 font-weight-bold d-flex align-center">
                        <v-icon right v-if="!isDetailEdit">mdi-plus</v-icon>
                        <img v-else src="@/assets/img/svg_icons/pen.svg" height="18" />
                        <span class="mx-2">
                           {{ isDetailEdit ? $t('edit line') : $t('add line') }}
                        </span>
                     </div>
                  </v-col>




                  <!-- <v-col cols="4" class="">
                     <div class="subtitle-2 gray1--text">
                        {{ $t('the price of internal transport') | capitalize }} :
                     </div>
                  </v-col>
                  <GenericInput type="float" :value="detail.internal" @input="detail.internal = $event"
                     label="the price of internal transport" :required="true" :isLoading="false"
                     :cols="[6, 6, 6]">
                  </GenericInput>
                  <v-col cols="4" class="">
                     <div class="subtitle-2 gray1--text">
                        {{ $t('the price of external transport') | capitalize }} :
                     </div>
                  </v-col>
                  <GenericInput type="float" :value="detail.external" @input="detail.external = $event"
                     label="the price of external transport" :required="true" :isLoading="false"
                     :cols="[6, 6, 6]">
                  </GenericInput> -->

                  <GenericInput type="select" :lookups="areas" :value="detail.from_area_id"
                     :disableItem="{ key: 'id', value: detail.to_area_id }" @input="detail.from_area_id = $event"
                     label="from city" :multi="false" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="areas" :value="detail.to_area_id"
                     :disableItem="{ key: 'id', value: detail.from_area_id }" @input="detail.to_area_id = $event"
                     label="to city" :multi="false" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="float" :value="detail.price" @input="detail.price = $event" label="package price"
                     :required="false" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>


                  <GenericInput type="float" :value="detail.container_20" @input="detail.container_20 = $event"
                     label="container size 20" :required="false" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>
                  <GenericInput type="float" :value="detail.container_40" @input="detail.container_40 = $event"
                     label="container size 40" :required="false" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>

                  <v-col cols="7" v-if="duplicatePrice">
                     <v-alert type="error" dense outlined>
                        {{ $t('the price for this route has already been entered') }}
                     </v-alert>
                  </v-col>
                  <v-col cols="5" class="d-flex justify-end">
                     <v-btn text depressed color="error" class="mx-1" :min-width="120" @click="closeDialog" :height="37">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn outlined style="background-color:  #e6ecff ;" class="mx-1" :loading="isLoading"
                        :disabled="!(valid && !duplicatePrice)" depressed color="blue2" :min-width="120" @click="save" :height="37">
                        <span v-if="isDetailEdit">
                           <v-icon left>mdi-pencil</v-icon>
                           {{ $t('edit line') }}
                        </span>
                        <span v-else>
                           <v-icon left>mdi-plus</v-icon>
                           {{ $t('add line') }}
                        </span>
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>

      </v-card>
   </v-dialog>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "PortPricingDetail",
   props: {
      detailAction: { type: Function },
      dialog: { default: false },
      updateDetail: { type: Function },
      detail: { default: Object },
      port: { default: Object },
      isDetailEdit: { default: false },
      detailIndex: { default: null },
      areas: { default: [] },
      tableRows: { default: [] },
   },
   computed: {
      duplicatePrice() {
         if (this.isDetailEdit)
            return this.tableRows.some((row, index) => row.from_area_id == this.detail.from_area_id && row.to_area_id == this.detail.to_area_id && index !== this.detailIndex)
         else
            return this.tableRows.some((row) => row.from_area_id == this.detail.from_area_id && row.to_area_id == this.detail.to_area_id)
      }
   },
   components: {
      GenericInput
   },
   watch: {
   },
   data: () => ({
      isLoading: false,
      valid: false,
      modalKey: 0,
   }),
   methods: {
      closeDialog() {
         this.detailAction(null, false, true)
      },
      save() {
         console.log(this.isDetailEdit);
         this.detail.port_id = this.port.port_id;
         this.detail.from_area_name = this.areas.find(area => area.id == this.detail.from_area_id)?.name || '';
         this.detail.to_area_name = this.areas.find(area => area.id == this.detail.to_area_id)?.name || '';
         this.detail.customer_id = +this.$route.params.id;
         this.detailAction(this.detail, this.isDetailEdit, false);
         // this.isLoading = true;
         // this.$api.POST_METHOD(`toggle_customer_transport_prices`, this.detail).then((response) => {
         //    this.isLoading = false;
         //    if (response.check) {
         //       this.detailAction(this.detail, this.isDetailEdit, false);
         //    }
         // })
      },

   },
   mounted() {

   },
};
</script>
