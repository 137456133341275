<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <GenericInput type="text" :value="form.car_name_ar" @input="form.car_name_ar = $event"
                     label="car number in Arabic" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.car_name_en" @input="form.car_name_en = $event"
                     label="car number in English" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.car_code" @input="form.car_code = $event" label="car code"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="drivers" :value="form.default_driver"
                     @input="form.default_driver = $event" label="default driver" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="date" :value="form.form_expiry_date" @input="form.form_expiry_date = $event"
                     label="form expiration date" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="file" :value="form.form_expiry_image" @input="form.form_expiry_image = $event"
                     label="form expiration image" :required="false" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="date" :value="form.card_expiry_date" @input="form.card_expiry_date = $event"
                     label="card expiration date" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="file" :value="form.card_expiry_image" @input="form.card_expiry_image = $event"
                     label="card expiration image" :required="false" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
   name: "CarControl",

   data: () => ({
      pageData: {
         screen_code: "03-008",
         url: null,
         controlRoute: "/transport/car-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      areas: [],
      drivers: [],
      form: {
         car_name_en: null,
         car_name_ar: null,
         default_driver: null,
         car_code: null,
         form_expiry_date: null,
         form_expiry_image: null,
         card_expiry_date: null,
         card_expiry_image: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader
   },
   watch: {

   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`car/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.drivers = response.data.driver;
                     this.areas = response.data.areas;
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`car/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.drivers = response.data.driver;
                     this.areas = response.data.areas;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`car/${this.form.car_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`car`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`car/${this.form.car_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
