<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :showCreate="false" :showDelete="false" :pageData="pageData"  />
         <!-- End Of Header -->


         <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
            <AnimatedLoading :height="100" :hideText="false" :isLoading="pageData.isLoading" />
         </v-col>

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="!pageData.isLoading" >
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-col cols="12" class="pa-0 px-10">
                  <v-tabs active-class="blue12" v-model="toggle_ports" color="primary">
                     <v-tab class="font-weight-bold" v-for="(port, index) in prices" :value="index" :key="index">
                        <strong>
                           {{ port.port_name | capitalize }}
                        </strong>
                     </v-tab>

                  </v-tabs>
               </v-col>
               <v-col cols="12" class="pa-0">
                  <v-divider></v-divider>
               </v-col>

               <v-sheet class="pa-5">


                  <v-tabs-items v-model="toggle_ports">
                     <v-tab-item eager :value="index" class="pb-4" v-for="(port, index) in prices" :key="index">
                        <CustomerPortPricing :port="port" :areas="areas" :trans_prices="trans_prices" :prices="prices"
                           :index="index" :backAction="getData" />
                     </v-tab-item>
                  </v-tabs-items>


               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->



      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import CustomerPortPricing from "./CustomerPortPricing.vue";
// import DynamicTable from "@/components/DynamicTable.vue";

export default {
   name: "CustomersControl",

   data: () => ({
      pageData: {
         screen_code: "04-003",
         url: null,
         controlRoute: "/sales/customer-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      toggle_ports: 0,
      areas: [],
      prices: [],
      trans_prices: [],
      account_trees: [],

   }),
   watch: {

   },
   components: {
    ControlHeader,
    CustomerPortPricing,
    AnimatedLoading
},
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$i18n.t('customer prices') + ' / ' + (this.$route.query?.customer_name || '');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`customer/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.areas = response.data.areas || [];
                     this.prices = response.data.prices || [];
                     this.trans_prices = response.data.trans_prices || [];
                     this.account_trees = response.data.account_tree || [];
                     this.form.customer_attachments = response.data.customer_attachments || [];
                     this.form.customer_contacts = response.data.customer_contacts || [];
                     this.viewForm = true;
                     this.tab = 0;
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            this.$router.push('/')
         }

      },


   },

};
</script>
