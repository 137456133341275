<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <v-card elevation="0" class="ma-0 pa-0 px-5 transparent rounded-lg">
            <v-row justify="space-between" align="center">
               <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
                  <div class="subtitle-2 primary--text  font-weight-medium">
                     {{ pageData.main }} > {{ pageData.category }} >
                  </div>
                  <div class="text-h6 primary--text mx-2 text-capitalize">
                     <strong>{{ pageData.entityName }}</strong>
                  </div>
               </v-col>
               <v-col cols="12" sm="auto" class="pa-0">
                  <v-row justify="end" align="center">
                     <v-col cols="12" sm="auto">
                        <v-btn @click="save" :loading="pageData.isLoading" :disabled="!valid"
                           class="my-2 mx-2 rounded-lg white--text" :height="37" color="success">{{ $t('save settings')
}}</v-btn>
                     </v-col>
                  </v-row>
               </v-col>
            </v-row>
         </v-card>
         <!-- End Of Header -->
         <v-row justify="center" v-if="pageData.isLoading">
            <v-col cols="auto" class="my-15">
               <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
                  indeterminate></v-progress-circular>
               <p class="text-center">{{ $t("Loading data") }}</p>
            </v-col>
         </v-row>
         <!-- Form -->
         <v-form ref="form" v-model="valid" lazy-validation class="pa-5" v-if="pageData.isLoading == false">
            <v-card class="backgroundW shadow pa-0" :key="settingKey">
               <v-col cols="12">
                  <v-tabs active-class=" " color="primary" v-model="tab">
                     <v-tabs-slider></v-tabs-slider>
                     <v-tab v-for="item in settings" :key="item" class="font-weight-bold"
                        @click="activeTab = item.title">{{ $t(item.title) }}</v-tab>
                  </v-tabs>
               </v-col>

               <span v-for="(settingTab, index) in settings" :key="settingTab.id">
                  <v-row class="pa-5" v-if="activeTab == settingTab.title">
                     <GenericInput v-for="(dynamicInput, inputIndex) in settingTab.settings" :key="dynamicInput.id"
                        :name="dynamicInput.id" :type="dynamicInput.type" :value="dynamicInput.value"
                        @input="form[index].form[inputIndex][dynamicInput.id].newValue = $event"
                        :label="dynamicInput.label" :lookups="lockups[dynamicInput.lookups_id]"
                        :required="dynamicInput.required == 0 ? false : true"
                        :disabled="dynamicInput.disabled == 0 ? false : true"
                        :multi="dynamicInput.multi == 0 ? false : true"
                        :cols="dynamicInput.cols.split(',').map(i => Number(i))" selected_label="name"
                        selected_prop="id" :isLoading="pageData.editIsLoading" />
                  </v-row>
               </span>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
import GenericInput from '../../../components/ui/GenericInput.vue';
export default {
   name: "GeneralSettings",

   data: () => ({
      pageData: {
         screen_code: "01-007",
         url: null,
         controlRoute: "/main/screen_sub-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      settingKey: 1,
      deleteDialog: false,
      valid: false,
      lockups: {},
      settings: [],
      form: [],
      tab: null,
      activeTab: null,
   }),
   components: {
      GenericInput,
   },
   computed: {
      ...mapState(["favourites"]),
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      ...mapActions(["addFavourites"]),
      addToFav() {
         const fav = {
            screen_code: this.pageData.screen_code,
            name: this.$i18n.t('main setting'),
            url: this.pageData.controlRoute,
         };
         this.addFavourites(fav).finally(() => { });
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$store.state.activeScreen.sub_title
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`setting?sub_id=${this.$route.params.id}`).then((response) => {
                  if (response.check) {
                     this.lockups = response.data.loockups;
                     this.settings = response.data.settings;
                     this.activeTab = response.data.settings[0]['title'];
                     // loop in tabs (category)
                     response.data.settings.forEach(category => {
                        let createCategoryInForm = {
                           category_id: category.id,
                           form: []
                        }
                        category.settings.forEach(input => {
                           let createInputInCategoryForm = {}
                           createInputInCategoryForm.inputID = input.id;
                           createInputInCategoryForm.oldValue = input.value ? input.value : null;
                           createInputInCategoryForm.newValue = null;
                           createInputInCategoryForm.category_id = category.id;
                           createCategoryInForm.form.push({ [input.id]: createInputInCategoryForm });
                        });
                        this.form.push(createCategoryInForm);
                     });
                     this.pageData.editIsLoading = false;
                     this.pageData.isLoading = false;
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            this.$router.push('/')
         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            console.log('Form : ', this.form);
            var changes = []
            this.form.forEach(categories => {
               categories.form.forEach(input => {
                  let inputID = Number(...Object.keys(input));

                  if (input[inputID].newValue !== null && input[inputID].newValue !== input[inputID].oldValue) {
                     //  change in values
                     if (input[inputID].type == "multiselect") {
                        changes.push({
                           id: inputID,
                           category_id: input[inputID].category_id,
                           new_value: input[inputID].newValue.toString(),
                           old_value: input[inputID].oldValue,
                           type: input[inputID].type,
                        })
                     } else {
                        changes.push({
                           id: inputID,
                           category_id: input[inputID].category_id,
                           new_value: input[inputID].newValue,
                           old_value: input[inputID].oldValue,
                           type: input[inputID].type,
                        })
                     }
                  } else {
                     // no changes in values
                  }
               });
            });
            console.log("Changes in form", changes);
            let request = {
               _method: "PUT",
               items: changes
            }
            if (changes.length > 0) {
               this.$api.POST_METHOD(`setting/${this.$route.params.id}`, request).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.pageData.isLoading = false;
                     this.settingKey = this.settingKey + 1
                     this.tab = null;
                     this.lockups = Object;
                     this.settings = [];
                     this.form = [];
                     this.getData();
                  }
               })
            }
            else {
               this.pageData.isLoading = false;
               this.$store.state.snackbarTitle = 'no changes';
               this.$store.state.snackbarType = "success";
               this.$store.state.showSnackbar = true;
            }
         }
      },
   },
};
</script>
