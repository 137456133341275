import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import ComponentsRegister from "@/plugins/ComponentsRegister";
import VueFullScreen from "@/plugins/VueFullScreen";
import vueEditor from "@/plugins/vueEditor";
import axios from "@/plugins/custom-axios";
import vue2Dropzone from "@/plugins/vue2Dropzone";
import i18n from "@/plugins/i18n";
import VueCryptojs from "@/plugins/cryptojs";
import VueLazyload from "@/plugins/lazyload";
import "@/plugins/apexcharts";
import VueSweetalert2 from "@/plugins/sweetalert";
import otp from "./plugins/otp";
import draggable from "@/plugins/vuedraggable";
import apiPlugin from "@/plugins/api";
import global from "@/plugins/global";
import "./registerServiceWorker";
import filters from "./filters/filters";
Vue.prototype.$http = axios;
Vue.use(global);
Vue.use(apiPlugin);
Vue.filter(filters);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  ComponentsRegister,
  VueFullScreen,
  vueEditor,
  vue2Dropzone,
  i18n,
  VueCryptojs,
  VueLazyload,
  VueSweetalert2,
  draggable,
  otp,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
