<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod" :printValueMethod="printMethod">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'daily'">
                <v-btn @click="openJournal(row)" small icon class="blue1--text">
                  <img src="@/assets/img/svg_icons/eye-2.svg" height="18" />
                </v-btn>
                <v-btn @click="printJournalMethod(row)" small icon class="success--text">
                  <img src="@/assets/img/svg_icons/fi_printer.svg" height="18" />
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
export default {
  name: "PaymentVoucherEntity",

  data: () => ({
    pageData: {
      screen_code: "02-008",
      url: null,
      controlRoute: "accounting/payment_voucher-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      printLoading: {
        loading: false,
        id: null,
        key: 'm_id'
      },
    },
  }),
  components: {
    EntityHeader,
    DynamicTable,
    Pagination,
  },
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "code", key: "m_id", type: 'text', classes: "" },
        { text: "finance year", key: "finance_year_name", type: 'text', classes: "" },
        { text: "branch", key: "branch_name", type: 'text', classes: "" },
        { text: "date", key: "action_date", type: 'date', classes: "" },
        { text: "amount", key: "total_amount", type: 'date', classes: "" },
        { text: "description", key: "arabic_description", type: 'date', classes: "" },
        { text: "daily type", key: "daily_type_name", type: 'date', classes: "" },
        // { text: "actions", key: "id", type: 'actions', classes: "" },
        { text: "daily journal", key: "daily", type: 'slot', classes: "" },
        { text: "actions", key: "actions", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `payment_voucher?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted payment_voucher")
                  : this.$store.state.activeScreen.sub_title;
              response.data.items.data.forEach(row => {
                switch (Number(row.to_type)) {
                  case 1:
                    row.entity = this.$i18n.t('account')
                    break;

                  case 2:
                    row.entity = this.$i18n.t('treasury')
                    break;

                  case 3:
                    row.entity = this.$i18n.t('transfer')
                    break;
                  case 4:
                    row.entity = this.$i18n.t('cheq')
                    break;

                  default:
                    break;
                }
              });
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      this.$api
        .POST_METHOD(`payment_voucher/${id}`, { _method: "delete" })
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    restoreMethod(row) {
      this.$api
        .POST_METHOD(`payment_voucher_toggle_active/${row.m_id}`, null)
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    setClickRow() {
      // this.$router.push(`/main/payment_voucher/${row.m_id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id);
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.daily_journal_m_id, '_blank')
    },
    printMethod(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.m_id;
      this.$api.PrintPOST('export_payment_voucher', row.m_id , 'print').then(() => {
        this.pageData.printLoading.loading = false;
      })
    },
    printJournalMethod(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.daily_journal_m_id;
      const src = `export_journal_data/${row.daily_journal_m_id}?type=pdf`;
      this.$api.DOWNLOAD_METHOD(src, `${this.$i18n.t('journal')}_${row.daily_journal_m_id}.pdf`).then(() => {
        this.pageData.printLoading.loading = false;
      })
    },
  },
};
</script>
