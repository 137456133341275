<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="500">
      <v-card relative :key="selectedRow.id">
         <v-card class="backgroundW shadow pa-5 pt-7">
            <v-row>
               <v-col cols="12" class="text-h6 font-weight-bold">
                  {{ $t('clearance status') | capitalize }}
                  {{ selectedRow.id || '' }}
               </v-col>
               <GenericInput type="select" :lookups="status" :value="new_status" @input="new_status = $event"
                  label="status" :multi="false" :required="true" selected_label="name" selected_prop="id"
                  :cols="[12, 12, 12]" />

               <v-col cols="12" class="d-flex justify-end pt-0">
                  <v-btn text depressed color="error" :min-width="120" @click="closeDialog(false)" :height="37">
                     <v-icon left>mdi-close</v-icon>
                     {{ $t('close') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="changeStatus" depressed color="success" :loading="isLoading"
                     :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-card>
   </v-dialog>
</template>

<script>

import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "ClearanceStatusDialog",
   props: {
      closeDialog: { type: Function },
      dialog: { default: false },
      id: { default: 0 },
      selectedRow: { default: Object },
   },
   computed: {
      status() {
         switch (this.selectedRow.clearance_status_id) {
            case 1:
               return [];
            case 2:
               return [
                  {
                     id: 1,
                     name: this.$i18n.t('Waiting For Arrival'),
                  },
                  {
                     id: 2,
                     name: this.$i18n.t('Clearances Required'),
                  },
               ];
            case 3:
               return [
                  {
                     id: 1,
                     name: this.$i18n.t('Waiting For Arrival'),
                  },
                  {
                     id: 2,
                     name: this.$i18n.t('Clearances Required'),
                  },
                  {
                     id: 3,
                     name: this.$i18n.t('Clearances Preview'),
                  },
               ];
            case 4:
               return [
                  {
                     id: 1,
                     name: this.$i18n.t('Waiting For Arrival'),
                  },
                  {
                     id: 2,
                     name: this.$i18n.t('Clearances Required'),
                  },
                  {
                     id: 3,
                     name: this.$i18n.t('Clearances Preview'),
                  },
                  {
                     id: 4,
                     name: this.$i18n.t('Collect Customs Fees'),
                  },
               ];
            case 5:
               return [
                  {
                     id: 1,
                     name: this.$i18n.t('Waiting For Arrival'),
                  },
                  {
                     id: 2,
                     name: this.$i18n.t('Clearances Required'),
                  },
                  {
                     id: 3,
                     name: this.$i18n.t('Clearances Preview'),
                  },
                  {
                     id: 4,
                     name: this.$i18n.t('Collect Customs Fees'),
                  },
                  {
                     id: 5,
                     name: this.$i18n.t('Waiting For Clearing'),
                  },
               ];
            case 6:
               return [
                  {
                     id: 1,
                     name: this.$i18n.t('Waiting For Arrival'),
                  },
                  {
                     id: 2,
                     name: this.$i18n.t('Clearances Required'),
                  },
                  {
                     id: 3,
                     name: this.$i18n.t('Clearances Preview'),
                  },
                  {
                     id: 4,
                     name: this.$i18n.t('Collect Customs Fees'),
                  },
                  {
                     id: 5,
                     name: this.$i18n.t('Waiting For Clearing'),
                  },
               ];
            default:
               return [];
         }
      }
   },
   components: {
      GenericInput
   },
   watch: {
      dialog() {
         if (this.dialog) {
            this.arrival_notes = this.selectedRow.id || null
            this.new_status = this.selectedRow.clearance_status_id;
            this.today = this.$global.GetCurrentDate();
         } else {
            this.isLoading = false;
            this.status_id = null;
         }

      }
   },
   data: () => ({
      isLoading: false,
      new_status: null,
   }),
   methods: {
      changeStatus() {
         this.isLoading = true;
         const requestBody = {
            clearance_status_id: this.new_status,
            items_update: 0,
         }
         this.$api.POST_METHOD(`update_clearance_item/${this.id}`, requestBody).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.status_id = null;
               this.closeDialog(this.id);
            }
         })

      },

   },
   mounted() {

   },
};
</script>
