<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && tableRows.length > 0"
            :deleteMethod="deleteMethod" :editForm="pageData.isEdit">
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
                  <v-row align="center" v-show="!formCollapse">
                     <v-col cols="6" md="4">
                        <v-row>
                           <GenericInput type="date" :value="form.action_date" @input="form.action_date = $event"
                              label="date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                           </GenericInput>

                           <GenericInput type="select" :lookups="daily_types" :value="form.daily_type_id"
                              @input="form.daily_type_id = $event" label="daily" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 12, 12]">
                           </GenericInput>
                        </v-row>
                     </v-col>
                     <GenericInput type="textarea" solo :value="form.arabic_description" :rows="3"
                        @input="form.arabic_description = $event" label="description" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 7]"></GenericInput>
                  </v-row>
               </v-expand-transition>
            </v-card>
            <!-- Detail Form -->
         </v-form>
         <v-expand-transition>
            <v-form ref="newItem" v-model="validItem" class="pa-2" v-show="!formCollapse">
               <v-card class="backgroundW shadow pa-5 pt-7">
                  <v-row align="center">

                     <v-col cols="12" md="6" class="py-0">
                        <!-- from -->
                        <div class="body-2 primary--text mb-2">
                           {{ $t('transfer from') }}
                        </div>
                        <v-col cols="12">
                           <v-row>
                              <GenericInput type="select"
                                 :lookups="[{ id: 1, name: $i18n.t('bank') }, { id: 2, name: $i18n.t('customer') }]"
                                 :value="newItem.transfer_from" @input="newItem.transfer_from = $event"
                                 label="transfer from" :multi="false" :required="true" selected_label="name"
                                 selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>

                              <GenericInput type="select" v-if="newItem.transfer_from == 1" :lookups="banks"
                                 :disableItem="{ key: 'id', value: newItem.to_bank_id }" :value="newItem.from_bank_id"
                                 @input="newItem.from_bank_id = $event" label="bank" :multi="false" :required="true"
                                 selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 6, 6]">
                              </GenericInput>

                              <GenericInput type="select" v-if="newItem.transfer_from == 2" :lookups="customers"
                                 :disableItem="{ key: 'id', value: newItem.to_customer_id }"
                                 :value="newItem.from_customer_id" @input="newItem.from_customer_id = $event"
                                 label="customer" :multi="false" :required="true" selected_label="name"
                                 selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>

                           </v-row>
                        </v-col>

                        <!-- to -->
                        <div class="body-2 primary--text mb-2">
                           {{ $t('transfer to') }}
                        </div>
                        <v-col cols="12">
                           <v-row>
                              <GenericInput type="select"
                                 :lookups="[{ id: 1, name: $i18n.t('bank') }, { id: 2, name: $i18n.t('customer') }]"
                                 :value="newItem.transfer_to" @input="newItem.transfer_to = $event" label="transfer to"
                                 :multi="false" :required="true" selected_label="name" selected_prop="id"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>

                              <GenericInput type="select" v-if="newItem.transfer_to == 1" :lookups="banks"
                                 :disableItem="{ key: 'id', value: newItem.from_bank_id }" :value="newItem.to_bank_id"
                                 @input="newItem.to_bank_id = $event" label="bank" :multi="false" :required="true"
                                 selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 6, 6]">
                              </GenericInput>


                              <GenericInput type="select" v-if="newItem.transfer_to == 2" :lookups="customers"
                                 :disableItem="{ key: 'id', value: newItem.from_customer_id }"
                                 :value="newItem.to_customer_id" @input="newItem.to_customer_id = $event"
                                 label="customer" :multi="false" :required="true" selected_label="name"
                                 selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>


                           </v-row>
                        </v-col>

                        <div class="body-2 primary--text mb-2">
                           {{ $t('price') }}
                        </div>
                        <v-col cols="12">
                           <v-row>
                              <GenericInput type="float" solo :value="newItem.amount" :rows="5"
                                 @input="newItem.amount = $event" label="amount" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>

                              <GenericInput type="select" :lookups="clearances" :value="newItem.clearance_id"
                                 @input="newItem.clearance_id = $event" label="clearance" :multi="false"
                                 :required="false" selected_label="id" selected_prop="id"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                              </GenericInput>
                           </v-row>
                        </v-col>


                     </v-col>


                     <!-- text area -->
                     <v-col cols="6">
                        <v-row>
                           <GenericInput type="textarea" solo :value="newItem.arabic_description" :rows="5"
                              @input="newItem.arabic_description = $event" label="description" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                           <v-col cols="12">
                              <v-btn outlined style="background-color:  #e6ecff ;" class="d-block mi-start-auto"
                                 :disabled="!(validItem && newItem.amount)" depressed color="blue2" :min-width="120"
                                 @click="addItem" :height="37">
                                 {{ $t('add line') }}
                              </v-btn>
                           </v-col>
                        </v-row>
                     </v-col>


                  </v-row>
               </v-card>
            </v-form>
         </v-expand-transition>
         <!-- Details Table -->
         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="tableRows || []" :footerData="[totalsCalculations]"
               :header="tableHeader" :deleteValueMethod="removeItem"
               :tableHeight="$store.state.Settings.windowSize.y - 230" />
         </div>

         <!-- End of Form -->
      </v-container>


   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
export default {
   name: "BankTransfersControl",
   data: () => ({
      pageData: {
         screen_code: "02-017",
         url: null,
         controlRoute: "/accounting/bank_transfer-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      formCollapse: false,
      approveDialog: false,
      viewForm: false,
      summery: [],
      valid: false,
      validItem: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      customers: [],
      daily_types: [],
      clearances: [],
      banks: [],

      form: {
         action_date: null,
         daily_type_id: null,
         arabic_description: null,
         items: [],
      },
      newItem: {
         transfer_from: 1,
         from_bank_id: null,
         from_customer_id: null,
         transfer_to: 2,
         to_bank_id: null,
         to_customer_id: null,
         arabic_description: null,
         amount: null,
         clearance_id: null
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
      DynamicTable,
   },
   computed: {
      totalsCalculations() {
         if (this.tableRows) {
            const totalAmount = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.amount);
            }, 0);
            return {
               transfer_from_title: this.$i18n.t('total'),
               amount: totalAmount,
            }
         } else {
            return {
               transfer_from_title: this.$i18n.t('total'),
               amount: 0,
            }
         }
      },
   },
   watch: {
      "newItem.transfer_from"() {
         if (!this.pageData.isEdit) {
            this.newItem.from_bank_id = null;
            this.newItem.from_customer_id = null;
         }
      },
      "newItem.transfer_to"() {
         if (!this.pageData.isEdit) {
            this.newItem.to_bank_id = null;
            this.newItem.to_customer_id = null;
         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            if (row.transfer_from == 1) {
               row.transfer_from_title = row.from_bank_id ? this.$global.FilterArrayOfObjectElement(this.banks, 'id', row.from_bank_id, 'name') : null;
            }
            if (row.transfer_from == 2) {
               row.transfer_from_title = row.from_customer_id ? this.$global.FilterArrayOfObjectElement(this.customers, 'id', row.from_customer_id, 'name') : null;
            }
            if (row.transfer_to == 1) {
               row.transfer_to_title = row.to_bank_id ? this.$global.FilterArrayOfObjectElement(this.banks, 'id', row.to_bank_id, 'name') : null;
            }
            if (row.transfer_to == 2) {
               row.transfer_to_title = row.to_customer_id ? this.$global.FilterArrayOfObjectElement(this.customers, 'id', row.to_customer_id, 'name') : null;
            }
         });
      }
   },
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "transfer from", key: "transfer_from_title", type: 'text', classes: "" },
            { text: "transfer to", key: "transfer_to_title", type: 'text', classes: "" },
            { text: "amount", key: "amount", type: 'float', classes: "" },
            { text: "description", key: "arabic_description", type: 'text', classes: "" },
            { text: "clearance", key: "clearance_id", type: 'text', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },
         ];


      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`bank_transfer/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.master;
                     this.tableRows = response.data.details;
                     this.customers = response.data.customers || [];
                     this.banks = response.data.banks || [];
                     this.daily_types = response.data.daily_types || [];
                     this.clearances = response.data.clearances || [];
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`bank_transfer/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.customers = response.data.customers || [];
                     this.banks = response.data.banks || [];
                     this.daily_types = response.data.daily_types || [];
                     this.clearances = response.data.clearances || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`bank_transfer/${this.form.bank_transfer_m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`bank_transfer`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      addItem() {
         this.tableRows.push(this.newItem);
         this.newItem = {
            transfer_from: 1,
            from_bank_id: null,
            from_customer_id: null,
            transfer_to: 2,
            to_bank_id: null,
            to_customer_id: null,
            arabic_description: null,
            amount: null,
            clearance_id: null
         }
      },
      removeItem(item, index) {
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`bank_transfer/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
   },
};
</script>
