<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="500">
      <v-card relative :key="selectedRow.id">
         <v-card class="backgroundW shadow pa-5 pt-7">
            <v-row>
               <v-col cols="12" class="pt-0">
                  <div class="subtitle-1 font-weight-bold d-flex align-center">
                     <span class="mx-2">
                        {{ $t('preview done') }}
                        <span class="gray5--text" v-if="selectedRow.id">( {{ selectedRow.id }} )</span>
                     </span>
                  </div>
               </v-col>

               <GenericInput type="textarea" :value="notes" @input="notes = $event" label="notes" :multi="false"
                  :required="false" :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>

               <v-col cols="12" class="d-flex justify-end pt-0">
                  <v-btn text depressed color="error" :min-width="120" @click="closeDialog(false)" :height="37">
                     <v-icon left>mdi-close</v-icon>
                     {{ $t('close') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="changeStatus" depressed color="success" :loading="isLoading"
                     :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-card>
   </v-dialog>
</template>

<script>

import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "PreviewDoneDialog",
   props: {
      closeDialog: { type: Function },
      dialog: { default: false },
      id: { default: 0 },
      selectedRow: { default: Object },
   },
   computed: {

   },
   components: {
      GenericInput
   },
   watch: {
      dialog() {
         if (this.dialog) {
            this.withdrawal_note = this.selectedRow.withdrawal_note
         } else {
            this.isLoading = false;
            this.withdrawal_note = null;
            this.notes = null;
         }

      }
   },
   data: () => ({
      isLoading: false,
      withdrawal_note: null,
      notes: null,
   }),
   methods: {
      changeStatus() {
         this.isLoading = true;
         const requestBody = {
            notes: this.notes
         }
         this.$api.POST_METHOD(`update_preview_done/${this.id}`, requestBody).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.notes = null;
               this.closeDialog(true);
            }
         })

      },

   },
   mounted() {

   },
};
</script>
