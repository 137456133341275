import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";

export default {
  install(Vue) {
    Vue.prototype.$global = {
      ///////// Input Required Rules /////////
      requiredRule: [(v) => !!v || i18n.t("Field is required")],
      notRequiredRule: [],
      requiredNumberRule: [
        (v) =>
          parseFloat(String(v).replace(/,/g, "")) > 0 ||
          i18n.t("please enter valid number"),
      ],
      ///////// Input Email Required Rules /////////
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.$t("Please enter email address"),
      ],
      requiredNumberAcceptZeroRule: [
        (v) => v.toString().length >= 1 || i18n.t("please enter valid number"),
      ],
      ///////// Input Mobile Required Rules /////////
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      ///////// Input Password Required Rules /////////
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],
      percentageRule: [
        (v) =>
          (v.toString().length >= 1 && v <= 100) ||
          i18n.t("percentage not valid"),
      ],
      // username
      usernameRule: [(v) => !!(v || "").match(/^[a-z][0-9a-z_]{2,23}[0-9a-z]$/) || i18n.t("not valid")],
      /////////  Array /////////
      FilterPermissions(Screen_Code) {
        if(store.state.permissions.length > 0){
          var find = store.state.permissions.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.filter(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(ARRAY, SearchIn, filterBy , ReturnElement = null) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
          return filter.length > 0 ? filter[0][ReturnElement] : null;     
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      SelectFromArray() {
        let original_array = arguments.length ? arguments[0] : [];
        let required_object =
          arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = required_object.reduce(
            (prevKey, nextKey) =>
              object[arguments[nextKey]]
                ? {
                    ...prevKey,
                    [arguments[nextKey]]: object[arguments[nextKey]],
                  }
                : { ...prevKey },
            {}
          );
          filtered_array.push(new_object);
        });
        return filtered_array;
      },
      SelectAsFromArray(original_array = [], required_object = Object) {
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = Object;
          Object.keys(required_object).forEach((key) => {
            if (object[required_object[key]])
              new_object[key] = object[required_object[key]];
          });
          filtered_array.push({ ...new_object });
        });
        return filtered_array || [];
      },
      SelectFromObject() {
        let original_object = arguments.length ? arguments[0] : Object;
        let required_keys =
          arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_object = required_keys.reduce(
          (prevKey, nextKey) =>
            original_object[arguments[nextKey]]
              ? {
                  ...prevKey,
                  [arguments[nextKey]]: original_object[arguments[nextKey]],
                }
              : { ...prevKey },
          {}
        );
        return filtered_object;
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      PaginateArray(ARRAY, PAGE_SIZE, PAGE_NUMBER) {
        return ARRAY.slice((PAGE_NUMBER - 1) * PAGE_SIZE, PAGE_NUMBER * PAGE_SIZE);
      },
      // Dates
      GetCurrentTime() {
        var time = new Date();      
        return   time.getHours() + ":" + time.getMinutes();
      },
      GetCurrentDate() {
        var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
          return [year, month, day].join('-');
        },

      GetFirstDayOfMonth() {
        var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
          const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
          return firstDay.toISOString().split('T')[0];
      },
      SubtractDates(FIRST_DATE, SECOND_DATE) {
        const dateOne = new Date(FIRST_DATE); 
        const dateTwo = new Date(SECOND_DATE);
        return dateTwo.getDate() - dateOne.getDate();
      },
      SubtractFromDate(DATE, VALUE) {
        const date = new Date(DATE); 
        date.setDate(date.getDate() - Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      AddToDate(DATE, VALUE) {
        const date = new Date(DATE); 
        date.setDate(date.getDate() + Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },
      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "SAR",
        });

        return formatter.format(NUMBER);
      },

      setDiscount(DISCOUNT , TOTAL_PRICE) {
        let discount_amount = 0;
        let percent_value = 0;
        if(DISCOUNT.type == 1){
          discount_amount =  TOTAL_PRICE - DISCOUNT.value;
          percent_value =  ( DISCOUNT.value / TOTAL_PRICE) *100 ;
        }
        if(DISCOUNT.type == 2){
          discount_amount = TOTAL_PRICE - (TOTAL_PRICE * (DISCOUNT.value/100));
          percent_value = DISCOUNT.value;
        }
       const discountCalc = {
          type: DISCOUNT.type,
          value: DISCOUNT.value,
          discount_amount: discount_amount,
          percent_value: percent_value,
          total_price: TOTAL_PRICE,
        }
       return discountCalc
      },

      PRICE_CALCULATION(ARRAY,DISCOUNT_OBJ){
        const total_price = ARRAY.reduce((oldValue, newValue) => {
          return Number(+oldValue) +( Number(+newValue.price) * Number(+newValue.quantity))}, 0);
        const DISCOUNT = this.setDiscount(DISCOUNT_OBJ,total_price);
        ARRAY.forEach(item => {
          item.total_price_without_vat = item.price * item.quantity;
          item.total_vat = item.total_price_without_vat * (item.tax_percent / 100);
          item.total_with_vat = item.total_price_without_vat  + item.total_vat;
          item.total_discount = DISCOUNT.discount_amount ? (item.total_price_without_vat * (DISCOUNT.percent_value/100)) : 0; // <=
          item.total_price_with_discount = item.total_price_without_vat -  item.total_discount;
          item.total_vat_after_discount =  item.total_price_with_discount * (item.tax_percent / 100);
          item.total_price_after_discount_with_vat =   item.total_price_with_discount + item.total_vat_after_discount;
        });
         // Reduces
          const total_discount = ARRAY.reduce((oldValue, newValue) => {
           return Number(+oldValue) + Number(+newValue.total_discount);}, 0);
          const total_price_with_discount = ARRAY.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.total_price_with_discount);}, 0);
          const total_vat_after_discount = ARRAY.reduce((oldValue, newValue) => {
           return Number(+oldValue) + Number(+newValue.total_vat_after_discount);}, 0);
          const total_price_after_discount_with_vat = ARRAY.reduce((oldValue, newValue) => {
           return Number(+oldValue) + Number(+newValue.total_price_after_discount_with_vat);}, 0);
          const totalVat = ARRAY.reduce((oldValue, newValue) => {
           return Number(+oldValue) + Number(+newValue.total_vat);
          }, 0);
          const totalQuantity = ARRAY.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.quantity);
          }, 0);
          return {
            discount : DISCOUNT,
            total_price_with_discount : total_price_with_discount, // without vat
            total_quantity : totalQuantity,
            total_discount : total_discount,
            total_vat : totalVat,
            total_vat_after_discount : total_vat_after_discount,
            total_price_after_discount_with_vat : total_price_after_discount_with_vat,
            new_array : ARRAY
          }
      },
      FILE_TYPE(FILE) {
        // Base64
        // console.log(FILE);
        if (String(FILE).substring(0, 5) == "data:") {
          console.log(
            "FILE TYPE =>",
            FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          );
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              return "notFound";
          }
        }
        // Normal
        else {
          switch (FILE.type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              var n = FILE.lastIndexOf(".");
              var result = FILE.substring(n + 1);
              return result;
          }
        }
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt =   Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );
        
        return textEncrypt

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();

      },
      DECRYPT(KEY, ENCODED) {
        // 1
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(ENCODED).toString(
          Vue.prototype.$CryptoJS.enc.Utf8
        );
        return textDecrypt
        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(key);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var decrypted = Vue.prototype.$CryptoJS.AES.decrypt(encryptedString, x,{iv:iv});
        // return decrypted.toString();

      },

      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },
      RemovesNullParams(PARAMS) {
        return PARAMS.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, '')
      },
    };
  },
};
