<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit">
            <template v-slot:centerSide>
               <div class="font-weight-bold subtitle-1 primary--text" v-if="totalsCalculations.total">
                  {{ $t('total invoice') | capitalize }} : {{ totalsCalculations.total || 0 | float }}
                  {{ $t('sar') }}
               </div>
            </template>
         </ControlHeader>
         <!-- End Of Header -->
         <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
            <AnimatedLoading :height="100" :hideText="false" :isLoading="pageData.isLoading" />
         </v-col>

         <div v-if="!pageData.isLoading">
            <!-- content -->
            <!-- Form -->
            <v-form ref="form" v-model="valid" class="pa-3">
               <v-card class="backgroundW shadow pa-5 pt-7">
                  <v-fade-transition>
                     <div class="collapseForm" v-if="tableRows.length > 0 || form.delegate_petty_status == 2">
                        <div class="d-flex">
                           <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                              <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                              <v-icon v-else>mdi-chevron-down</v-icon>
                           </v-btn>
                        </div>
                     </div>
                  </v-fade-transition>
                  <v-expand-transition>
                     <v-row align="center" v-show="!formCollapse">
                        <v-col cols="12" md="12">
                           <v-row>
                              <v-col cols="12" md="6">
                                 <v-row>
                                    <GenericInput type="date" :value="form.action_date"
                                       @input="form.action_date = $event" label="action date" :required="true"
                                       :isLoading="getClearanceDetailsLoading" :cols="[12, 12, 12]">
                                    </GenericInput>
                                    <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                                       @input="form.customer_id = $event" label="customer" :multi="false"
                                       :required="true" selected_label="name" selected_prop="id"
                                       :isLoading="getClearanceDetailsLoading" :cols="[12, 12, 12]">
                                    </GenericInput>

                                    <GenericInput type="select" :lookups="clearances" :value="form.clearance_id"
                                       @input="form.clearance_id = $event" label="clearance" :multi="false"
                                       :required="true" selected_label="id" selected_prop="id"
                                       :isLoading="getClearanceDetailsLoading" :cols="[12, 12, 12]">
                                    </GenericInput>


                                 </v-row>
                              </v-col>


                              <GenericInput type="textarea" rows="5" :value="form.description"
                                 @input="form.description = $event" label="description" :required="false"
                                 :isLoading="getClearanceDetailsLoading" :cols="[12, 6, 6]">
                              </GenericInput>
                              <v-col cols="12">
                                 <v-btn outlined class="d-block mi-start-auto" :disabled="!(containers)" depressed
                                    color="blue2" :min-width="120" @click="detailDialog = true" :height="37">
                                    {{ $t('add line') }}
                                 </v-btn>
                              </v-col>


                           </v-row>
                        </v-col>
                     </v-row>
                  </v-expand-transition>
               </v-card>
            </v-form>

            <!-- Details Table -->
            <div class="pa-2">
               <DynamicTable :isLoading="pageData.isLoading || getClearanceDetailsLoading" :data="tableRows || []"
                  :footerData="[totalsCalculations]" :header="tableHeader"
                  :tableHeight="$store.state.Settings.windowSize.y - 230">
                  <template v-slot:td="{ row, header, index }">
                     <div v-if="header.key == 'actions'">
                        <v-btn @click="editDetail(index, row)" x-small icon fab>
                           <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                        </v-btn>
                        <v-btn @click="removeItem(index, row)" x-small icon fab>
                           <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                        </v-btn>
                     </div>
                  </template>
               </DynamicTable>
            </div>
            <!-- Detail Form -->
            <StorageDetail :containers="containers" :detailAction="detailAction" :dialog="detailDialog"
               :tableRows="tableRows" :detail="detail" :updateDetail="updateDetail" :isDetailEdit="isDetailEdit" />
            <!-- Detail of Form -->
         </div>
      </v-container>


   </section>
</template>



<script>

import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import StorageDetail from "./StorageDetail.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "StorageControl",
   data: () => ({
      pageData: {
         screen_code: "02-019",
         url: null,
         controlRoute: "/sales/storage/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      formCollapse: false,
      viewForm: false,
      valid: false,
      getContainerLoading: false,
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      customers: [],
      clearances: [],
      containers: [],

      form: {
         action_date: null,
         customer_name: null,
         customer_id: 0,
         clearance_id: 0,
         items: [],
      },
      detail: {
         container_no: null,
         total_days: null,
         from_date: null,
         to_date: null,
      }
   }),
   components: {
      ControlHeader,
      DynamicTable,
      AnimatedLoading,
      StorageDetail,
      GenericInput
   },
   computed: {
      totalsCalculations() {
         if (this.tableRows) {
            const total_days = this.tableRows.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_days);
            }, 0);

            return {
               container_name: this.$i18n.t('total days'),
               total_days: total_days,
            }
         }
         else {
            return {
               container_name: this.$i18n.t('total days'),
               total_days: 0,
            }
         }
      }
   },
   watch: {
      'form.clearance_id'() {
         if (this.form.clearance_id) {
            this.getClearanceContainers();
            this.containers = [];
            this.tableRows = [];
         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            row.size_name = row.size_id ? this.$global.FilterArrayOfObjectElement(this.sizes, 'id', row.size_id, 'name') : null;
            row.from_area_name = row.from_area_id ? this.$global.FilterArrayOfObjectElement(this.areas, 'id', row.from_area_id, 'name') : null;
            row.container_name = row.container_no ? this.$global.FilterArrayOfObjectElement(this.containers, 'id', row.container_no, 'container_no') : null;
         });
      }
   },
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         // this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
         this.pageData.entityName = this.$i18n.t('storage') || null;

         this.tableHeader = [
            { text: "container number", key: "container_name", type: 'text', classes: "" },
            { text: "from date", key: "from_date", type: 'date', classes: "" },
            { text: "to date", key: "to_date", type: 'date', classes: "" },
            { text: "total days", key: "total_days", type: 'text', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
         ];

      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`trip/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.clearances = response.data.clearances || [
                        {
                           "id": 62,
                           "date": "2022-04-19",
                           "old_clearance": "221257",
                           "customer_id": "287",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "12",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "381368",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-04-19 14:23:15",
                           "updated_at": "2022-04-19 14:23:15"
                        },
                        {
                           "id": 64,
                           "date": "2022-10-17",
                           "old_clearance": "223415",
                           "customer_id": "1206",
                           "status": "2",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "4",
                           "requested": "10,11",
                           "shipping_policy_no": "SZPA19084600",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "8",
                           "LCL": "0",
                           "created_user": "10",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-10-17 16:41:39",
                           "updated_at": "2022-10-24 13:21:57"
                        },
                        {
                           "id": 105,
                           "date": "2022-11-13",
                           "old_clearance": "223675",
                           "customer_id": "1346",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "03839B",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 13:51:35",
                           "updated_at": "2022-11-13 13:51:35"
                        },
                        {
                           "id": 107,
                           "date": "2022-11-13",
                           "old_clearance": "223677",
                           "customer_id": "155",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "14",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "262352",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "2",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 14:51:33",
                           "updated_at": "2022-11-13 14:51:33"
                        },
                        {
                           "id": 109,
                           "date": "2022-11-13",
                           "old_clearance": "223682",
                           "customer_id": "989",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "937830",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "10",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:05:11",
                           "updated_at": "2022-11-13 15:05:11"
                        },
                        {
                           "id": 110,
                           "date": "2022-11-13",
                           "old_clearance": "223683",
                           "customer_id": "989",
                           "status": "2",
                           "status_2": 1,
                           "delegate_id": "14",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "709300",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "6",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "16",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:12:03",
                           "updated_at": "2022-11-14 13:40:58"
                        },
                        {
                           "id": 111,
                           "date": "2022-11-13",
                           "old_clearance": "223685",
                           "customer_id": "428",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "14",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "272000",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "5",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:15:38",
                           "updated_at": "2022-11-13 15:15:38"
                        },
                        {
                           "id": 114,
                           "date": "2022-11-13",
                           "old_clearance": "223691",
                           "customer_id": "709",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "701850",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "5",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:30:02",
                           "updated_at": "2022-11-13 15:30:02"
                        },
                        {
                           "id": 115,
                           "date": "2022-11-13",
                           "old_clearance": "223692",
                           "customer_id": "709",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "14",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "216180",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "6",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:33:13",
                           "updated_at": "2022-11-13 15:33:13"
                        },
                        {
                           "id": 119,
                           "date": "2022-11-14",
                           "old_clearance": "223734",
                           "customer_id": "27",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "11",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "317771",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-14 10:04:56",
                           "updated_at": "2022-11-14 10:04:56"
                        },
                        {
                           "id": 120,
                           "date": "2022-11-14",
                           "old_clearance": "223735",
                           "customer_id": "1209",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "855282",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-14 10:16:29",
                           "updated_at": "2022-11-14 10:16:29"
                        }
                     ];
                     this.customers = response.data.customers;
                     this.containers = response.data.containers;
                     this.form = response.data.master;
                     this.tableRows = response.data.details || [];
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`trip/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.clearances = response.data.clearances || [
                        {
                           "id": 62,
                           "date": "2022-04-19",
                           "old_clearance": "221257",
                           "customer_id": "287",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "12",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "381368",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-04-19 14:23:15",
                           "updated_at": "2022-04-19 14:23:15"
                        },
                        {
                           "id": 64,
                           "date": "2022-10-17",
                           "old_clearance": "223415",
                           "customer_id": "1206",
                           "status": "2",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "4",
                           "requested": "10,11",
                           "shipping_policy_no": "SZPA19084600",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "8",
                           "LCL": "0",
                           "created_user": "10",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-10-17 16:41:39",
                           "updated_at": "2022-10-24 13:21:57"
                        },
                        {
                           "id": 105,
                           "date": "2022-11-13",
                           "old_clearance": "223675",
                           "customer_id": "1346",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "03839B",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 13:51:35",
                           "updated_at": "2022-11-13 13:51:35"
                        },
                        {
                           "id": 107,
                           "date": "2022-11-13",
                           "old_clearance": "223677",
                           "customer_id": "155",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "14",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "262352",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "2",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 14:51:33",
                           "updated_at": "2022-11-13 14:51:33"
                        },
                        {
                           "id": 109,
                           "date": "2022-11-13",
                           "old_clearance": "223682",
                           "customer_id": "989",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "937830",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "10",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:05:11",
                           "updated_at": "2022-11-13 15:05:11"
                        },
                        {
                           "id": 110,
                           "date": "2022-11-13",
                           "old_clearance": "223683",
                           "customer_id": "989",
                           "status": "2",
                           "status_2": 1,
                           "delegate_id": "14",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "709300",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "6",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "16",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:12:03",
                           "updated_at": "2022-11-14 13:40:58"
                        },
                        {
                           "id": 111,
                           "date": "2022-11-13",
                           "old_clearance": "223685",
                           "customer_id": "428",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "14",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "272000",
                           "shipping_policy_type": "2",
                           "total_containers_qty": "5",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:15:38",
                           "updated_at": "2022-11-13 15:15:38"
                        },
                        {
                           "id": 114,
                           "date": "2022-11-13",
                           "old_clearance": "223691",
                           "customer_id": "709",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "701850",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "5",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:30:02",
                           "updated_at": "2022-11-13 15:30:02"
                        },
                        {
                           "id": 115,
                           "date": "2022-11-13",
                           "old_clearance": "223692",
                           "customer_id": "709",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "14",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "216180",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "6",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-13 15:33:13",
                           "updated_at": "2022-11-13 15:33:13"
                        },
                        {
                           "id": 119,
                           "date": "2022-11-14",
                           "old_clearance": "223734",
                           "customer_id": "27",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "11",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "317771",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-14 10:04:56",
                           "updated_at": "2022-11-14 10:04:56"
                        },
                        {
                           "id": 120,
                           "date": "2022-11-14",
                           "old_clearance": "223735",
                           "customer_id": "1209",
                           "status": "1",
                           "status_2": 1,
                           "delegate_id": "13",
                           "port": "1",
                           "billed": "0",
                           "requested": "0",
                           "shipping_policy_no": "855282",
                           "shipping_policy_type": "1",
                           "total_containers_qty": "1",
                           "branch_id": "1",
                           "fin_year": "2022",
                           "classifications": "0",
                           "LCL": "0",
                           "created_user": "12",
                           "updated_user": null,
                           "deleted_user": null,
                           "deleted_at": null,
                           "created_at": "2022-11-14 10:16:29",
                           "updated_at": "2022-11-14 10:16:29"
                        }
                     ];
                     this.customers = response.data.customers;
                     this.containers = response.data.containers;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form._method = 'PUT';
            this.$api.POST_METHOD(`delegate_petty/${this.form.delegate_petty_m_id}`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               }
            })

         }
      },
      detailAction(detail, isEdit, cancel) {
         console.log(detail, isEdit, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.detail = {
               container_no: null,
               total_days: null,
               from_date: null,
               to_date: null,
            }
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.tableRows.push(detail);
            this.detail = {
               container_no: null,
               total_days: null,
               from_date: null,
               to_date: null,
            }
         }

      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.detail = {
            container_no: null,
            total_days: null,
            from_date: null,
            to_date: null,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`delegate_petty/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },

      getClearanceContainers() {
         this.getContainerLoading = true;
         this.$api.GET_METHOD(`get_clearance_containers/${this.form.clearance_id}`).then((response) => {
            this.getContainerLoading = false;
            if (response.check) {
               this.containers = response.data;
            }
         })
      },
   },
};
</script>
