<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-7">
               <v-row align="center">
                  <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
                  <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
                     <GenericInput type="select" :lookups="account_trees" :value="form.account_tree_id"
                     @input="form.account_tree_id = $event" label="linked account" :multi="false"
                     :required="true" selected_label="name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>


                  <GenericInput type="percentage" :value="form.tax_percentage" @input="form.tax_percentage = $event" label="tax percentage"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12,6, 4]"></GenericInput>

                     <GenericInput solo type="textarea" :value="form.details" @input="form.details = $event" label="description"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12,6, 8]"></GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "TaxControl",
   data: () => ({
      pageData: {
           screen_code: "02-012",
         url: null,
         controlRoute: "/sales/tax-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      account_trees: [],
      form: {
         name_en: null,
         name_ar: null,
         tax_percentage: 0,
         details: null,
         account_tree_id: null,
      },
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`tax/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.account_trees = response.data.account_tree || [];
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`tax/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.account_trees = response.data.account_tree || [];
                     this.form.account_tree_id = response.data.default_account
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {
         this.form.tax_percentage = this.form.tax_percentage || 0
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`tax/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`tax`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`tax/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
   },
};
</script>