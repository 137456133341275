<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="700">
      <v-card relative>
         <v-card class="backgroundW shadow pa-5 pt-7">
            <v-row>
               <v-col cols="12" class="pt-0">
                  <div class="subtitle-1 font-weight-bold d-flex align-center">
                     <span class="mx-2">
                        {{ $t('clearance has a problem') }}
                        <span class="gray5--text">( {{ id }} )</span>
                     </span>
                  </div>
               </v-col>
               <v-col cols="12" class="d-flex justify-center" v-if="isLoading">
                  <AnimatedLoading :height="100" :hideText="true" :isLoading="isLoading" />
               </v-col>
               <v-col cols="12" v-else-if="last_comment.clearances_comment_id">
                  <v-card outlined class="shadow-none light  justify-space-between  mt-2">
                     <v-card-title class="subtitle-2  font-weight-bold">
                        <!-- <div>
                              {{ last_comment[userKey] }}
                              <span class="mx-1 font-weight-medium gray8--text"><small>
                                    {{ last_comment[infoKey] }}
                                 </small></span>
                           </div> -->
                        <v-card-text class="subtitle-2 gray6--text pa-0">{{ last_comment['note'] }}
                        </v-card-text>
                     </v-card-title>
                     <div class="caption px-5 mt-n5 text-end">{{ last_comment['add_date'] }}</div>
                  </v-card>
               </v-col>
               <v-col cols="12" v-else>
                  <v-card outlined class="shadow-none light  justify-space-between  mt-2">
                     <v-card-title class="subtitle-2  font-weight-bold">
                        <v-card-text class="subtitle-2 text-center font-weight-bold gray6--text pa-0">{{ $t('no comment')
                        }}</v-card-text>
                     </v-card-title>
                  </v-card>
               </v-col>

               <v-col cols="12" class="pt-0">
                  <v-btn text depressed color="error" class="d-block mi-start-auto" :min-width="120" @click="closeDialog"
                     :height="37">
                     <v-icon left>mdi-close</v-icon>
                     {{ $t('close') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-card>
   </v-dialog>
</template>

<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';

export default {
   name: "LastProblemCommentDialog",
   props: {
      closeDialog: { type: Function },
      dialog: { default: false },
      id: { default: 0 },
      selectedRow: { default: Object },
   },
   computed: {

   },
   components: {
      AnimatedLoading
   },
   watch: {
      dialog() {
         if (this.dialog)
            this.getLastProblem()
         else
            this.last_comment = Object
      }
   },
   data: () => ({
      isLoading: false,
      last_comment: Object,
   }),
   methods: {
      getLastProblem() {
         this.isLoading = true
         if (this.selectedRow.has_problem)
            this.$api.GET_METHOD(`get_clearance_problem_comment/${this.id}`)
               .then((response) => {
                  if (response.check) {
                     this.isLoading = false
                     this.last_comment = response.data.item || Object
                  }
               })
         else
            this.closeDialog()
      }

   },
   mounted() {

   },
};
</script>
