<template>
    <v-dialog v-model="openDialog" persistent width="650">
        <v-card class="pa-3" rounded="rounded">
            <v-card-title class="px-3 red6--text font-weight-bold d-flex flex-wrap" >
                <span>{{ $t('The total invoice value is not equal to the invoice value with a difference') }}</span>
                <span class="mx-1">{{ selectedData | float }}</span> 
            </v-card-title>

            <v-card-actions class="px-4 mt-3 justify-end">
                <v-btn class="red6 white--text" text
                    :loading="loading" @click="confirm" width="49%" height="42">
                    {{ $t('confirm') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class=" gray4--text" text @click="closeDialog" width="49%" height="42" :loading="loading" outlined>
                    {{ $t('back') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "WarningDialog",
    props: {
        openDialog: { default: false },
        save: { type: Function },
        closeDialog: { type: Function },
        confirmAction: { type: Function },
        selectedData: { default: Number },
    },
    data: () => ({
        loading: false,
    }),

    methods: {
        confirm() {
            this.confirmAction(true)
            this.closeDialog()
        },
    },
    computed: {},
}


</script>

<style lang="scss" scoped></style>
