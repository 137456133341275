<template >
  <!-- List Level 2 link -->
  <span>
    <v-list-item v-for="(cats, y) in menuList.cats" :key="y" class="py-0">
      <v-menu
        :left="$vuetify.rtl"
        :value="cats.menuStatus"
        transition="scale-transition"
        offset-x
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            @mouseover="disableHover(y)"
            :color="attrs['aria-expanded'] == 'true' ? 'primary' : 'gray7'"
            text
            @click="dd(cats)"
            v-bind="attrs"
            v-on="on"
          >
            {{ cats.title }}
            <v-icon small v-if="$vuetify.rtl">mdi-chevron-left</v-icon>
            <v-icon small v-else>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <!-- List Level 3 link -->
        <v-list class="shadow">
          <v-list-item
            style="width: 100%;"
            dense
            active-class="primary white--text"
            v-for="(sub, z) in cats.subs"
            :key="z"
            link
            :to="sub.url"
            @click="close"
          >
            <v-list-item-title>{{ sub.sub_title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
  </span>
</template>


<script>
export default {
  name: "AppBarRoutesCatMenu",
  computed: {

  },
  data: () => ({
    hideMenu: false
  }),
  props: {
    menuList: { default: [] },
    menuIndex: { default: 0 },
    backValueMethod: { type: Function },
    hover: { type: Function },
  },
  mounted() {

  },
  watch: {
    menuList() {

    }
  },

  methods: {
    close() {
      this.hideMenu = false
      this.backValueMethod(true, this.menuIndex)
    },
    disableHover(y) {

      console.log(y)
      // console.log(this.menuList)
      this.hover(false)
    },
    dd(y) {
      console.log(y)
    }
  },
};
</script>



<style lang="scss" scoped>
.v-menu__content {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  //  top: 4rem !important;
  margin: 0 1.5rem;
  padding-top: 0.3rem !important;
}
.v-application--is-rtl .v-menu__content {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  //  top: 4rem !important;
  margin: 0 -1.5rem;
  padding-top: 0.3rem !important;
}
</style>