import { render, staticRenderFns } from "./AppBarRoutesMenu.vue?vue&type=template&id=ad97989a&scoped=true"
import script from "./AppBarRoutesMenu.vue?vue&type=script&lang=js"
export * from "./AppBarRoutesMenu.vue?vue&type=script&lang=js"
import style0 from "./AppBarRoutesMenu.vue?vue&type=style&index=0&id=ad97989a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad97989a",
  null
  
)

export default component.exports