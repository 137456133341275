<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showSearch="false" :createBtn="false" :showDeleted="false"
        actionBtnText="research" :actionBtn="!showFilter" :actionBtnValid="true" actionBtnColor="transparent"
        :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true" actionBtnClass="blue4--text">
        <template v-slot:rightSide>
          <v-col cols="12" sm="auto">
            <ExportBtn :exportData="driver_income_report" :maxPDFRows="36" v-if="driver_income_report.length"
              :footerData="[calculations]" :header="pageData.tableHeader" :showGroupHeader="true"
              :groupHeader="groupHeader" :attachmentFileName="pageData.entityName" :printSubTitle="printTitle">
              <template v-slot:after_table="{ isLastPage }">
                <div v-if="isLastPage" style="display : flex ; width :100%; text-align: center; margin-top:20px">
                  <div style="width :20%; font-size: 12px;">
                    {{ $t('driver signature') }}
                  </div>
                  <div style="width :20%; font-size: 12px;">
                    {{ $t('cashier') }}
                  </div>
                  <div style="width :20%; font-size: 12px;">
                    {{ $t('accounts') }}
                  </div>
                  <div style="width :20%; font-size: 12px;">
                    {{ $t('CEO') }}
                  </div>
                  <div style="width :20%; font-size: 12px;">
                    {{ $t('general manager') }}
                  </div>
                </div>
              </template>
            </ExportBtn>
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->

      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" class="">
              <GenericInput type="date" :isPickerOpened="() => to_date = null" :value="from_date"
                :maxDate="to_date || today" @input="from_date = $event" label="from" :required="false"
                :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="date" :value="to_date" :minDate="from_date" :maxDate="today" @input="to_date = $event"
                label="to" :required="false" :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="select" solo :clearable="true" :lookups="customers" :value="customer_id"
                @input="customer_id = $event" label="customer" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.reportIsLoading)"
                :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="select" solo :clearable="true" :lookups="drivers" :value="driver_id"
                @input="driver_id = $event" label="driver" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.reportIsLoading)"
                :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="select" solo :clearable="true" :lookups="branches" :value="branch_id"
                @input="branch_id = $event" label="branch" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.reportIsLoading)"
                :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="select" solo :clearable="true" :lookups="cars" :value="car_id" @input="car_id = $event"
                label="car" :multi="false" :required="false" selected_label="name" selected_prop="id" :hide-details="true"
                :isLoading="(pageData.isLoading || pageData.reportIsLoading)" :cols="[12, 6, 3]">
              </GenericInput>
              <v-col cols="6" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="primary" outlined
                  :disabled="!(driver_id || car_id || customer_id || from_date || to_date)"
                  :loading="(pageData.isLoading || pageData.reportIsLoading)" @click="getReport"
                  class="mx-1 white--text">{{
                    $t("Search")
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--  -->
      <v-row v-if="driver_income_report.length > 0">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="driver_income_report" :printSubTitle="printTitle"
            :footerData="[calculations]" :maxPDFRows="36"
            :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 260 : $store.state.Settings.windowSize.y - 150"
            :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData" :showGroupHeader="true"
            :groupHeader="groupHeader" :customize_header_footer="true" :printStaticData="printStaticData">
            <template v-slot:after_table="{ isLastPage }">
              <div v-if="isLastPage" style="display : flex ; width :100%; text-align: center; margin-top:20px">
                <div style="width :20%; font-size: 12px;">
                  {{ $t('driver signature') }}
                </div>
                <div style="width :20%; font-size: 12px;">
                  {{ $t('cashier') }}
                </div>
                <div style="width :20%; font-size: 12px;">
                  {{ $t('accounts') }}
                </div>
                <div style="width :20%; font-size: 12px;">
                  {{ $t('CEO') }}
                </div>
                <div style="width :20%; font-size: 12px;">
                  {{ $t('general manager') }}
                </div>
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import ExportBtn from "@/components/ui/ExportBtn.vue";
export default {
  name: "TripExpensesReport",

  data: () => ({
    pageData: {
      screen_code: "03-021",
      url: null,
      controlRoute: "transport/trip_expenses_report-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      reportIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    groupHeader: [],
    printStaticData: Object,
    showFilter: true,
    from_date: null,
    to_date: null,
    today: null,
    driver_income_report: [],
    drivers: [],
    branches: [],
    branch_id: null,
    cars: [],
    driver_id: null,
    car_id: null,
    customer_id: null,
    customers: [],
  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput,
    ExportBtn
  },
  watch: {
    driver_id() {
      this.driver_income_report = [];
      this.getReport();
    },
    customer_id() {
      this.driver_income_report = [];
      this.getReport();
    },
    car_id() {
      this.driver_income_report = [];
      this.getReport();
    },
  },
  computed: {
    printTitle() {
      let title = '';
      if (this.from_date) {
        title = this.from_date
      }
      if (this.to_date) {
        title = title + (this.from_date ? ' - ' : '') + this.to_date
      }
      if (this.branch_id) {
        const branch = this.branches.find((branch) => branch.id == +this.branch_id)
        title = title + ' / ' + this.$i18n.t('Branch') + ' : ' + branch.name
      }
      if (this.customer_id) {
        const customer = this.customers.find((customer) => customer.id == +this.customer_id)
        title = title + ' / ' + this.$i18n.t('customer') + ' : ' + customer.name
      }
      if (this.car_id) {
        const car = this.cars.find((car) => car.id == +this.car_id)
        title = title + ' / ' + this.$i18n.t('car') + ' : ' + car.name
      }
      return title
    },
    calculations() {
      let driver_price = this.driver_income_report.reduce((oldValue, newValue) => {
        return +oldValue + +newValue.driver_price;
      }, 0);
      return {
        id: this.$i18n.t('total'),
        driver_price: driver_price || 0,
      }
    },
  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.to_date = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.getData();

  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "trip date", key: "trip_date", type: 'text', classes: "" },
        { text: "driver", key: "driver_name", type: 'text', classes: "" },
        { text: "car number", key: "car_name", type: 'text', classes: "" },
        { text: "name", key: "customer_name", type: 'text', classes: "" },
        { text: "phone", key: "customer_mobile", type: 'text', classes: "" },
        { text: "number", key: "container_number", type: 'text', classes: "" },
        { text: "size", key: "container_size", type: 'text', classes: "" },
        { text: "from", key: "from_place", type: 'text', classes: "" },
        { text: "to", key: "to_place", type: 'text', classes: "" },
        { text: "driver trip", key: "driver_price", type: 'float', classes: "" },
        // { text: "trip price", key: "trip_price", type: 'float', classes: "" },
        // { text: "total price", key: "total_price", type: 'float', classes: "" },
        // { text: "notes", key: "note", type: 'text', classes: "" },
      ];
      this.groupHeader = [
        { text: " ", colspan: 1, classes: "" },
        { text: "car", colspan: 2, classes: "" },
        { text: "customer", colspan: 2, classes: "" },
        { text: "container", colspan: 2, classes: "" },
        { text: "direction", colspan: 2, classes: "" },
        { text: "trip expenses", colspan: 3, classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`trip_expenses_report`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.customers = response.data.customers || [];
              this.drivers = response.data.drivers || [];
              this.cars = response.data.cars || [];
              this.branches = response.data.branchs || [];
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getReport() {
      this.pageData.reportIsLoading = true;
      const body = {
        driver_id: this.driver_id,
        branch_id: this.branch_id,
        customer_id: this.customer_id,
        car_id: this.car_id,
        from_date: this.from_date,
        to_date: this.to_date,
        type: 'json'
      }
      this.$api.POST_METHOD(`trip_expenses_report`, body, false).then((response) => {
        this.pageData.reportIsLoading = false;
        if (response.check) {
          response.data.report.forEach(row => {
            if (row.from_area_name) {
              row.from_place = row.from_area_name
            }
            if (row.from_port_name) {
              row.from_place = row.from_port_name
            }
            if (row.from_wearhouse_name) {
              row.from_place = row.from_wearhouse_name
            }
            if (row.to_area_name) {
              row.to_place = row.to_area_name
            }
            if (row.to_port_name) {
              row.to_place = row.to_port_name
            }
            if (row.to_wearhouse_name) {
              row.to_place = row.to_wearhouse_name
            }
          });
          this.driver_income_report = response.data.report || [];
          this.printStaticData = response.data.print;
        }
      });
    },
    print(type) {
      this.pageData.printLoading = true;
      const body = {
        driver_id: this.driver_id,
        customer_id: this.customer_id,
        branch_id: this.branch_id,
        car_id: this.car_id,
        from_date: this.from_date,
        to_date: this.to_date,
        type: type
      }
      this.$api.DOWNLOAD_METHOD_BODY(`trip_expenses_report`, body, `trip_expenses_report.${type}`)
        .then((response) => {
          this.pageData.printLoading = false;
          if (response.check) {
            // this.pageData.rows = response.data.report;
          }
        });
    },

  },
};
</script>
