<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="pageData.rows"
            :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" :printValueMethod="printClassification">
            <template v-slot:td="{ row, header }">

              <div v-if="header.key == 'daily'">
                <v-btn @click="openJournal(row)" small text class="blue1--text">
                  {{ $t('view journal') }}
                </v-btn>
              </div>

              <div v-if="header.key == 'comments'">
                <v-btn @click="viewComments(row.id)" small
                  :loading="commentsLoading && row.id === pageData.rowIsLoading.id" icon class="success--text">
                  <v-icon>mdi-comment-outline</v-icon>
                </v-btn>
              </div>

              <div class="d-flex justify-center" style="width : 120px" v-if="header.key == 'status'">
                <v-select @change="changeStatus(row)" v-model="row.clearance_status_id" :items="status"
                  :label="$i18n.t('')" dense hide-details item-text="name" item-value="id" outlined>
                  <template v-slot:append>
                    <v-fade-transition leave-absolute>
                      <v-progress-circular v-if="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id"
                        size="23" color="primary" indeterminate>
                      </v-progress-circular>
                      <v-icon v-else size="25">mdi-menu-down</v-icon>
                    </v-fade-transition>
                  </template>
                </v-select>
              </div>

              <div v-if="header.key == 'is_upload'" :key="counterRowIsLoading">
                <v-btn v-if="!row.is_upload" @click="makeUpload(row)" :disabled="!row.is_custom" :loading="row.isLoading"
                  color="primary" class="white--text">
                  {{ $t('Entered') }}
                </v-btn>

                <span v-else class="green1--text font-weight-bold"> {{ $t('Entered') }}</span>
              </div>

              <div v-if="header.key == 'classification_status'">
                <v-btn v-if="!row.is_custom" @click="edit(row)" color="black" class="white--text">
                  {{ $t('classification') }}
                </v-btn>

                <span v-else class="black--text font-weight-bold">
                  {{ !row.is_upload ? $t('It has been classified') : $t('Entered') }}</span>
              </div>

            </template>
          </DynamicTable>
        </v-col>
      </v-row>


      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="commentsDialog" overlay-opacity="0.75" persistent :max-width="750">
      <v-card relative class="pa-7 py-10">
        <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="commentsDialog = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-row justify="center" class="" v-if="pageData.rowIsLoading.id !== null">



          <v-col cols="12">
            <v-card class=" shadow-none">
              <div class="text-h6 font-weight-bold mx-2">
                {{ $t('notes') | capitalize }}
              </div>
              <template v-if="notes.length > 0">
                <v-card class="shadow-none light d-flex align-center justify-space-between  mt-2"
                  v-for="(note, index) in notes" :key="index">
                  <v-card-title class="subtitle-2  font-weight-bold">
                    <div>
                      {{ note.user_full_name }} <span class="mx-1 font-weight-medium gray8--text"><small>{{
                        note.add_date | dateEn
                      }}</small></span>
                    </div>
                    <v-card-text class="subtitle-2 gray6--text pa-0">{{ note.comment_det }} </v-card-text>
                  </v-card-title>
                </v-card>
              </template>
              <div v-else class="text-center text-h6 gray7--text">
                {{ $t('no note found') | capitalize }}
              </div>

            </v-card>
          </v-col>

          <GenericInput type="textarea" :value="comment" @input="comment = $event" label="notes" :multi="false"
            :required="false" selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
          </GenericInput>


          <v-col cols="12" class="d-flex justify-end">
            <v-btn class="mx-2" text depressed color="red4" @click="commentsDialog = false" :height="37" :width="120">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn class="mx-2 white--text" :disabled="!(comment)" :loading="commentsLoading" @click="addComments"
              depressed color="success" :height="37" :width="120">
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
  name: "classificationEntity",

  data: () => ({
    pageData: {
      screen_code: "04-007",
      url: null,
      controlRoute: "sales/classification-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      status: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        id: null,
        key: 'id',
        loading: false,
      },
      rowIsLoading: {
        loading: false,
        id: null,
        index: null,
        key: 'id'
      },
    },
    tab: 1,
    comment: null,
    notes: [],
    commentsDialog: false,
    commentsLoading: false,
    makeUploadLoading: false,
    counterRowIsLoading: 0,
    status: [],
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    GenericInput
  },
  computed: {

  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "clearance date", key: "action_date", type: 'date', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "transaction no", key: "clearances_m_id", type: 'text', classes: "" },
        { text: "invoice type", key: "invoice_type", type: 'text', classes: "" },
        { text: "containers QTY", key: "container_count", type: 'text', classes: "" },
        { text: "shipping fee in Dollars", key: "shipping_amount", type: 'float', classes: "" },
        { text: "supplier invoice", key: "supplier_invoice_code", type: 'text', classes: "" },
        { text: "custom fees", key: "total_custom_fees", type: 'float', classes: "" },
        { text: "notes", key: "notes", type: 'text', classes: "" },
        // { text: "status", key: "status", type: 'slot', classes: "" },
        { text: "notes", key: "comments", type: 'slot', classes: "" },
        { text: "", key: "is_upload", type: 'slot', classes: "" },
        { text: "status", key: "classification_status", type: 'slot', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: true,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `classification${this.pageData.queryParam}&status=${this.tab}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.status = response.data.status || [];
              this.pageData.rows = response.data.items.data;
              this.pageData.rows.forEach(row => row.isLoading = false)
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`classification/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`classification_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/classification/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.daily_journal_id, '_blank')
    },
    printMethod(row) {
      this.pageData.rowIsLoading.loading = true;
      this.pageData.rowIsLoading.id = row.daily_journal_id;
      const src = `export_journal_data/${row.daily_journal_id}?type=pdf`;
      this.$api.DOWNLOAD_METHOD(src, `${this.$i18n.t('journal')}_${row.daily_journal_id}.pdf`).then(() => {
        this.pageData.rowIsLoading.loading = false;
      })
    },
    classification_customs(row) {
      // this.pageData.isLoading = true;
      this.pageData.rowIsLoading.loading = true;
      this.pageData.rowIsLoading.id = row.id;
      this.$api.POST_METHOD(`update_classification_customs/${row.id}`, { customs_declaration_date: this.customs_declaration_date, customs_declaration_number: this.customs_declaration_number }).then((response) => {
        if (response.check) {
          this.pageData.rowIsLoading.loading = false;
          this.pageData.rowIsLoading.id = null;
          // this.pageData.isLoading = false;
          // this.getData();
        }
      })
    },
    makeUpload(row) {
      this.counterRowIsLoading++
      row.isLoading = true;
      this.$api.POST_METHOD(`upload_classification/${row.id}`).then((response) => {
        row.isLoading = false;
        this.counterRowIsLoading++
        if (response.check) {
          row.is_upload = 1
        }
      })
    },
    viewComments(id) {
      this.commentsLoading = true
      this.pageData.rowIsLoading.id = id;
      this.$api.GET_METHOD(`classification_get_comment/${id}`).then((response) => {
        this.commentsLoading = false
        if (response.check) {
          this.notes = response.data.items;
          this.commentsDialog = true;
        }
      })
    },
    addComments(row) {
      // this.pageData.isLoading = true;
      this.commentsLoading = true;
      this.$api.POST_METHOD(`classification_add_comment/${row.id || this.pageData.rowIsLoading.id}`, { comment: this.comment }).then((response) => {
        if (response.check) {
          this.commentsLoading = false;
          this.comment = null;
          this.pageData.rowIsLoading.id = null;
          this.commentsDialog = false;
        }
      })
    },
    printClassification(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api.PrintPOST('export_classification', row.id, 'print').then(() => {
        this.pageData.printLoading.id = null;
        this.pageData.printLoading.loading = false;
      })
      // const src = `export_classification/${row.id}?type=pdf`;
      // this.$api.DOWNLOAD_METHOD(src, `${this.$i18n.t('classification')}_${row.id}.pdf`).then(() => {
      //   this.pageData.printLoading.id = null;
      //   this.pageData.printLoading.loading = false;
      // })
    }
  },
};
</script>
