<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="1200">
      <v-card relative :key="modalKey">

         <v-form ref="detail" v-model="valid" class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row justify="space-around">
                  <v-col cols="6" class="pt-0">
                     <div class="subtitle-1 font-weight-bold d-flex align-center">
                        <v-icon right v-if="!isDetailEdit">mdi-plus</v-icon>
                        <img v-else src="@/assets/img/svg_icons/pen.svg" height="18" />
                        <span class="mx-2">
                           {{ isDetailEdit ? $t('edit line') : $t('add line') }}
                        </span>
                     </div>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                     <div class="caption text-end font-weight-bold">
                        <span class="text--disabled">{{ $t('difference') | capitalize }} :</span>
                        <span dir="ltr" class="success--text" v-if="totalsCalculations.difference >= 0">{{
                           totalsCalculations.difference | float
                        }}</span>
                        <span dir="ltr" class="error--text" v-else>({{ totalsCalculations.difference | float }})</span>
                     </div>
                     <div class="caption text-end font-weight-bold">
                        <span class="text--disabled">{{ $t('total') | capitalize }} :</span>
                        <span class="primary--text">{{ totalsCalculations.total | float }}</span>
                     </div>
                  </v-col>

                  <v-col cols="6">
                     <v-row>
                        <GenericInput type="radiogroup" :lookups="voucher_types" :value="detail.voucher_type"
                           @input="detail.voucher_type = $event" label="voucher type" :multi="false" :required="true"
                           selected_label="name" :disabled="false" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                        </GenericInput>


                        <GenericInput type="float" :value="detail.amount" @input="detail.amount = $event" label="amount"
                           :disabled="false" :required="true" :isLoading="false" :cols="[6, 6, 6]">
                        </GenericInput>

                        <GenericInput type="select" :lookups="cost_centers" :value="detail.cost_centers"
                           @input="detail.cost_centers = $event" label="cost center" :multi="true" :required="false"
                           selected_label="name" :disabled="false" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                        </GenericInput>

                        <GenericInput type="number" solo :value="detail.receipt_number" :rows="5"
                           @input="detail.receipt_number = $event" label="receipt number" :required="false"
                           :isLoading="false" :cols="[6, 6, 6]">
                        </GenericInput>

                        <GenericInput type="checkbox" :value="detail.taxable" @input="detail.taxable = $event"
                           label="taxable" :multi="false" :required="false" :disabled="false" :isLoading="false"
                           :cols="[6, 6, 6]">
                        </GenericInput>

                        <GenericInput type="select" v-if="detail.taxable" :lookups="taxes" :value="detail.tax_id"
                           @input="detail.tax_id = $event" label="vat account" :multi="false" :required="true"
                           selected_label="name" :disabled="false" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                        </GenericInput>

                        <v-col cols="6" v-if="detail.taxable">
                           <div class="text--disabled font-weight-bold subtitle-2">{{ $t('vat') | capitalize }} :
                              <span class="primary--text">{{ compute_vat | float }}</span>
                           </div>
                        </v-col>

                     </v-row>
                  </v-col>


                  <v-col cols="6">
                     <v-row>

                        <GenericInput type="select" :lookups="dis_types" :value="detail.dis_type"
                           @input="detail.dis_type = $event" label="the entity" :multi="false" :required="true"
                           selected_label="name" :disabled="false" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                        </GenericInput>


                        <GenericInput type="select" :lookups="destination" :value="detail[destination_key]"
                           @input="detail[destination_key] = $event" :label="destination_label" :multi="false"
                           :required="true" selected_label="name" :disabled="!(detail.dis_type)" selected_prop="id"
                           :isLoading="false" :cols="[6, 6, 6]">
                           <template v-slot:append>
                              <v-icon v-if="detail.dis_type == 3" color="success"
                                 @click="addCustomerDialog = true">mdi-plus</v-icon>
                           </template>
                        </GenericInput>


                        <GenericInput type="select" :lookups="dis_types" :value="source_type" v-if="!isDetailEdit"
                           @input="source_type = $event" label="source" :multi="false" :required="false"
                           selected_label="name" :disabled="false" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                        </GenericInput>

                        <GenericInput type="select" :lookups="sources" :value="source_value" v-if="!isDetailEdit"
                           @input="source_value = $event" :label="source_type_label" :multi="false"
                           :required="(source_type)" selected_label="name" :disabled="!(source_type)" selected_prop="id"
                           :isLoading="false" :cols="[6, 6, 6]">
                           <template v-slot:append>
                              <v-icon v-if="source_type == 3" color="success"
                                 @click="addCustomerDialog = true">mdi-plus</v-icon>
                           </template>
                        </GenericInput>

                        <GenericInput type="select" :lookups="clearances"
                           v-if="detail.dis_type == 8 || detail.dis_type == 5" :value="detail.clearance_id"
                           @input="detail.clearance_id = $event" label="clearance number" :multi="false"
                           :required="(detail.dis_type == 8)" selected_label="selected_label" :disabled="false"
                           selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                        </GenericInput>


                        <GenericInput type="textarea" :value="detail.description" @input="detail.description = $event"
                           label="description" :required="false" :disabled="false" :isLoading="false" :cols="[12, 12, 12]">
                        </GenericInput>

                     </v-row>
                  </v-col>


                  <v-col cols="12" class="d-flex justify-end">
                     <v-btn text depressed color="error" class="mx-1" :min-width="120" @click="closeDialog" :height="37">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn outlined style="background-color:  #e6ecff ;" class="mx-1" :disabled="!(valid)" depressed
                        color="blue2" :min-width="120" @click="save" :height="37">
                        <span v-if="isDetailEdit">
                           <v-icon left>mdi-pencil</v-icon>
                           {{ $t('edit line') }}
                        </span>
                        <span v-else>
                           <v-icon left>mdi-plus</v-icon>
                           {{ $t('add line') }}
                        </span>
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>

         <AddLockupsDialog entityName="customer" endPoint="customer" :enablePhone="true" :dialog="addCustomerDialog"
            :closeMethod="() => { addCustomerDialog = false }" :saveMethod="saveCustomer" />


      </v-card>
   </v-dialog>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
import AddLockupsDialog from "@/components/modals/AddLockupsDialog.vue";
export default {
   name: "MultiVoucherDetail",
   props: {
      detailAction: { type: Function },
      updateDetail: { type: Function },
      saveCustomer: { type: Function },
      dialog: { default: false },
      detail: { default: Object },
      totalsCalculations: { default: Object },
      isDetailEdit: { default: false },
      tableRows: { default: [] },
      voucher_types: { default: [] },
      dis_types: { default: [] },
      account_trees: { default: [] },
      cost_centers: { default: [] },
      customers: { default: [] },
      suppliers: { default: [] },
      sales_agents: { default: [] },
      purchase_agents: { default: [] },
      pay_types: { default: [] },
      treasuries: { default: [] },
      banks: { default: [] },
      clearances: { default: [] },
      delegates: { default: [] },
      expense_codes: { default: [] },
      vat_types: { default: [] },
      vat_accounts: { default: [] },
      taxes: { default: [] },
   },
   computed: {
      compute_vat() {
         return +this.detail.amount - (+this.detail.amount / ((+this.detail.vat_percentage / 100) + 1));
      },
   },
   components: {
      GenericInput,
      AddLockupsDialog
   },
   watch: {
      'detail.dis_type'() {
         this.setDisType();
      },
      source_type() {
         switch (this.source_type) {
            case 1:
               this.sources = this.treasuries;
               this.source_type_label = this.$i18n.t('select') + ' ' + this.$i18n.t('treasury');
               this.source_key = 'dis_treasury_id';
               break;
            case 2:
               this.sources = this.banks;
               this.source_type_label = this.$i18n.t('select') + ' ' + this.$i18n.t('bank');
               this.source_key = 'dis_bank_id';
               break;
            case 3:
               this.sources = this.customers;
               this.source_type_label = this.$i18n.t('select') + ' ' + this.$i18n.t('customer');
               this.source_key = 'dis_customer_id';
               break;
            case 4:
               this.sources = this.suppliers;
               this.source_type_label = this.$i18n.t('select') + ' ' + this.$i18n.t('supplier');
               this.source_key = 'dis_supplier_id';
               break;
            case 5:
               this.sources = this.account_trees;
               this.source_type_label = this.$i18n.t('select') + ' ' + this.$i18n.t('account');
               this.source_key = 'dis_account_tree_id';
               break;
            case 6:
               this.sources = this.$global.FilterArrayOfObject(this.delegates, 'covenant_type_id', 1);
               this.source_type_label = this.$i18n.t('select') + ' ' + this.$i18n.t('delegate covenant');
               this.source_key = 'dis_delegate_id';
               break;
            case 7:
               this.sources = this.$global.FilterArrayOfObject(this.delegates, 'covenant_type_id', 2);
               this.source_type_label = this.$i18n.t('select') + ' ' + this.$i18n.t('maintenance covenant - workshop');
               this.source_key = 'dis_delegate_id';
               break;
            case 8:
               this.sources = this.expense_codes;
               this.source_type_label = this.$i18n.t('select') + ' ' + this.$i18n.t('expense code');
               this.source_key = 'dis_expense_code_id';
               break;
            default:
               this.sources = [];
               this.source_type_label = 'select source first';
               this.source_key = null;
               this.source_value = null;
               break;
         }
      },
      'detail.voucher_type'() {
         if (this.detail.voucher_type == 1) {
            this.detail.credit = 0
         }
         if (this.detail.voucher_type == 2) {
            this.detail.debit = 0
         }
      },
      'detail.taxable'() {
         if (this.detail.taxable == 0) {
            this.detail.vat_percentage = 0
            this.detail.tax_id = null
         }
      },
      'detail.tax_id'() {
         if (this.detail.tax_id) {
            this.detail.vat_percentage = this.$global.FilterArrayOfObjectElement(this.taxes, 'id', +this.detail.tax_id, 'tax_percentage')
         }
      },
      '$store.state.Settings.key_clicked'() {
         switch (this.$store.state.Settings.key_clicked) {
            case 'F10':
               if (this.valid && this.dialog) {
                  this.save()
               }
               break;
            case 'Escape':
               this.closeDialog()
               break;
            default:
               break;
         }
      },
      dialog() {
         this.destination = this.expense_codes || [];
         this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('refundable expenses');
         this.destination_key = 'dis_expense_code_id';
         if (this.dialog) {
            this.sources = [];
            this.source_type_label = 'select source first';
            this.source_key = null;
            this.source_value = null;
            this.source_type = null;
            this.setDisType();
         }
      }
   },
   data: () => ({
      isLoading: false,
      valid: false,
      addCustomerDialog: false,
      destination: [],
      destination_key: null,
      destination_label: 'select destination first',
      sources: [],
      source_type: null,
      source_key: null,
      source_value: null,
      source_type_label: 'select source first',
      modalKey: 0,
   }),
   methods: {
      closeDialog() {
         this.detailAction(null, false, true)
      },
      setDisType() {
         switch (this.detail.dis_type) {
            case 1:
               this.destination = this.treasuries;
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('treasury');
               this.destination_key = 'dis_treasury_id';
               this.detail.dis_bank_id = null;
               this.detail.dis_customer_id = null;
               this.detail.dis_supplier_id = null;
               this.detail.dis_account_tree_id = null;
               this.detail.dis_delegate_id = null;
               this.detail.dis_expense_code_id = null;
               this.detail.clearance_id = null;
               break;
            case 2:
               this.destination = this.banks;
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('bank');
               this.destination_key = 'dis_bank_id';
               this.detail.dis_treasury_id = null;
               this.detail.dis_customer_id = null;
               this.detail.dis_supplier_id = null;
               this.detail.dis_account_tree_id = null;
               this.detail.dis_delegate_id = null;
               this.detail.dis_expense_code_id = null;
               this.detail.clearance_id = null;
               break;
            case 3:
               this.destination = this.customers;
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('customer');
               this.destination_key = 'dis_customer_id';
               this.detail.dis_treasury_id = null;
               this.detail.dis_bank_id = null;
               this.detail.dis_supplier_id = null;
               this.detail.dis_account_tree_id = null;
               this.detail.dis_delegate_id = null;
               this.detail.dis_expense_code_id = null;
               this.detail.clearance_id = null;
               break;
            case 4:
               this.destination = this.suppliers;
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('supplier');
               this.destination_key = 'dis_supplier_id';
               this.detail.dis_treasury_id = null;
               this.detail.dis_bank_id = null;
               this.detail.dis_customer_id = null;
               this.detail.dis_account_tree_id = null;
               this.detail.dis_delegate_id = null;
               this.detail.dis_expense_code_id = null;
               this.detail.clearance_id = null;
               break;
            case 5:
               this.destination = this.account_trees;
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('account');
               this.destination_key = 'dis_account_tree_id';
               this.detail.dis_treasury_id = null;
               this.detail.dis_bank_id = null;
               this.detail.dis_customer_id = null;
               this.detail.dis_supplier_id = null;
               this.detail.dis_delegate_id = null;
               this.detail.dis_expense_code_id = null;
               this.detail.clearance_id = null;
               break;
            case 6:
               this.destination = this.$global.FilterArrayOfObject(this.delegates, 'covenant_type_id', 1);
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('delegate covenant');
               this.destination_key = 'dis_delegate_id';
               this.detail.dis_treasury_id = null;
               this.detail.dis_bank_id = null;
               this.detail.dis_customer_id = null;
               this.detail.dis_supplier_id = null;
               this.detail.dis_expense_code_id = null;
               this.detail.clearance_id = null;
               this.detail.clearance_id = null;
               break;
            case 7:
               this.destination = this.$global.FilterArrayOfObject(this.delegates, 'covenant_type_id', 2);
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('maintenance covenant - workshop');
               this.destination_key = 'dis_delegate_id';
               this.detail.dis_treasury_id = null;
               this.detail.dis_bank_id = null;
               this.detail.dis_customer_id = null;
               this.detail.dis_supplier_id = null;
               this.detail.dis_expense_code_id = null;
               this.detail.clearance_id = null;
               break;
            case 8:
               this.destination = this.expense_codes;
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('expense code');
               this.destination_key = 'dis_expense_code_id';
               this.detail.dis_treasury_id = null;
               this.detail.dis_bank_id = null;
               this.detail.dis_customer_id = null;
               this.detail.dis_supplier_id = null;
               this.detail.dis_delegate_id = null;
               break;
            default:
               this.destination = this.expense_codes;
               this.destination_label = this.$i18n.t('select') + ' ' + this.$i18n.t('expense code');
               this.destination_key = 'dis_expense_code_id';
               this.detail.dis_treasury_id = null;
               this.detail.dis_bank_id = null;
               this.detail.dis_customer_id = null;
               this.detail.dis_supplier_id = null;
               this.detail.dis_account_tree_id = null;
               this.detail.dis_delegate_id = null;
               break;
         }
      },
      save() {
         this.detail.vat = this.compute_vat;
         console.log(this.isDetailEdit);
         this.modalKey = this.modalKey + 1
         let source_detail = { ...this.detail };
         this.detailAction(this.detail, this.isDetailEdit, false);
         if (this.source_type && this.source_key && this.source_value && !this.isDetailEdit) {
            // reverse values
            source_detail.voucher_type = this.detail.voucher_type == 2 ? 1 : 2;
            source_detail.debit = this.detail.credit
            source_detail.credit = this.detail.debit
            source_detail.tax_id = null,
               source_detail.taxable = 0,
               source_detail[this.source_key] = this.source_value;
            source_detail.dis_type = this.source_type;
            source_detail.clearance_id = this.source_type == 8 ? this.detail.clearance_id : null;
            this.detailAction(source_detail, this.isDetailEdit, false);
         }
      },
   },
   mounted() {
   },
};
</script>