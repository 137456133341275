<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showSearch="false" :createBtn="false" :showDeleted="false"
        actionBtnText="research" :actionBtn="!showFilter" :actionBtnValid="true" actionBtnColor="transparent"
        :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true" actionBtnClass="blue4--text">


        <template v-slot:rightSide>
          <v-col cols="12" sm="auto">
             <!-- <PrintBtn classes="my-2 gray4 white--text" :backValueMethod="print" :width="120" :height="37" 
              :loading="pageData.printLoading || pageData.printLoading" :image="false"
              :disabled="!(pageData.rows.length > 0)" /> -->
              <ExportBtn :exportData="pageData.rows" v-if="pageData.rows.length" :header="pageData.tableHeader"
                :footerData="footerData" :groupHeader="groupHeader" 
                :attachmentFileName="pageData.entityName"
                :printTitle="pageData.entityName">
                <template v-slot:td="{ row, header }">
                  <div v-if="header.key == 'journal_code'"> {{ row[header.key] || '-' }}</div>
                </template>
              </ExportBtn>
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->


      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="">
          <v-card class="shadow pa-7">
            <v-row align="center" class="" justify="space-between">
              <GenericInput type="date" :isPickerOpened="() => pageData.to_date = null" :value="pageData.from_date"
                :clearable="true" :maxDate="pageData.to_date || today" @input="pageData.from_date = $event" label="from"
                :required="false" :hide-details="true" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
              </GenericInput>

              <GenericInput type="date" :value="pageData.to_date" :clearable="true" :minDate="pageData.from_date"
                :maxDate="today" @input="pageData.to_date = $event" label="to" :required="false" :hide-details="true"
                :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]"></GenericInput>

              <GenericInput type="select" :lookups="customers" :value="pageData.customer_id"
                @input="pageData.customer_id = $event" label="choose customer" :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 'auto', 3]"></GenericInput>

              <GenericInput type="select" :lookups="delegates" :value="pageData.delegate_id"
                @input="pageData.delegate_id = $event" label="delegate" :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 'auto', 3]"></GenericInput>

              <GenericInput type="select" :lookups="expense_codes" :value="pageData.expense_code_id"
                @input="pageData.expense_code_id = $event" label="expense code" :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 'auto', 3]"></GenericInput>

                <GenericInput type="select" :lookups="ports" :value="pageData.port_id" @input="pageData.port_id = $event"
                :clearable="true" label="port" :multi="false" :required="false" selected_label="name" selected_prop="id"
                :hide-details="true" :cols="[12, 'auto', 3]" />

              <v-col cols="6" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined 
                  :loading="(pageData.isLoading || pageData.detailIsLoading)" @click="getReports"
                  class="mx-1 white--text">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="pageData.rows"
            :footerData="footerData"
            :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 320 : $store.state.Settings.windowSize.y - 220"
            :header="pageData.tableHeader" :fixedHeader="true" :option="pageData.options" :pageData="pageData"
            :showGroupHeader="false">
            <template v-slot:td="{ row, header, index }">
              <div v-if="header.key == 'transaction_type' && index">
                <v-btn depressed x-small color="blue12" v-if="+row.type !== 3 && +row.type !== 5" class="caption"
                  :to="row.transaction_type_route">
                  {{ row.transaction_type | capitalize }}
                </v-btn>
                <div small class="caption" color="transparent" v-else>
                  {{ row.transaction_type | capitalize }}
                </div>
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
// // import PrintBtn from "@/components/ui/PrintBtn.vue";
import ExportBtn from '@/components/ui/ExportBtn.vue';
export default {
  name: "ReimbursesExpensesEntity",

  data: () => ({
    pageData: {
      screen_code: "04-021",
      url: null,
      controlRoute: "sales/reimburses_expenses-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      from_date: null,
      to_date: null,
      customer_id: null,
      delegate_id: null,
      account_tree: null,
      expense_code_id: null,
      port_id: null,
      type: 1,
      first: [],
      printLoading: false
    },
    customers: [],
    delegates: [],
    ports: [],
    expense_codes: [],
    showFilter: true,
    today: null,
    footerData: [{
      invoice_id: 'total',
      amount: 0,
    }],
  }),
  watch: {
    'pageData.rows'() {
      this.footerData[0].invoice_id = this.$i18n.t('total');
      this.footerData[0].amount = this.totalAmount;
    },
    'pageData.customer_id'() {
      if (this.pageData.customer_id || this.$router.currentRoute.query.customer_id) {
        this.getReports();
      }
    },
    'pageData.delegate_id'() {
      if (this.pageData.delegate_id || this.$router.currentRoute.query.delegate_id) {
        this.getReports();
      }
    },
  },
  components: {
    GenericInput,
    EntityHeader,
    DynamicTable,
    // PrintBtn,
    ExportBtn
  },
  computed: {
    totalAmount() {
      return this.pageData.rows.reduce((oldValue, newValue) => {
        return +oldValue + +newValue.amount || 0;
      }, 0);
    },
    selectedCustomer() {
      if (this.pageData.customer_id)
        return this.$global.FilterArrayOfObjectElement(this.customers || [], 'id', this.pageData.customer_id, 'name') || null
      else
        return null
    },
    selectedDelegate() {
      if (this.pageData.customer_id)
        return this.$global.FilterArrayOfObjectElement(this.delegates || [], 'id', this.pageData.delegate_id, 'name') || null
      else
        return null
    }
  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    console.log(this.$router.currentRoute.query);
    this.pageData.from_date = this.$router.currentRoute.query.from_date || this.$global.GetFirstDayOfMonth();
    this.pageData.to_date = this.$router.currentRoute.query.to_date || this.$global.GetCurrentDate();
    this.getData();
    this.pageData.customer_id = Number(this.$router.currentRoute.query.customer_id) || null
    this.pageData.delegate_id = Number(this.$router.currentRoute.query.delegate_id) || null
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;

      this.pageData.tableHeader = [
        { text: "invoice", key: "invoice_id", type: 'text', classes: "" },
        { text: "clearance number", key: "clearance_id", type: 'text', classes: "" },
        { text: "expense code", key: "expense_code_name", type: 'text', classes: "" },
        { text: "the statement", key: "description", type: 'text', classes: "" },
        { text: "receipt number", key: "receipt_number", type: 'text', classes: "" },
        { text: "amount", key: "amount", type: 'float', classes: "" },
        { text: "date", key: "add_date", type: 'date', classes: "", width: '120' },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`reimburses_expenses_report`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.customers = response.data.customers || [];
              this.delegates = response.data.delegates || [];
              this.expense_codes = response.data.expense_codes || [];
              this.ports = response.data.ports || [];
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getReports() {
      this.pageData.isLoading = true;
      const body = {
        from: this.pageData.from_date,
        to: this.pageData.to_date,
        customer_id: this.pageData.customer_id,
        delegate_id: this.pageData.delegate_id,
        expense_code_id: this.pageData.expense_code_id,
        port_id: this.pageData.port_id,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`reimburses_expenses_report`, body, false)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.rows = response.data.report;
            this.showFilter = false
          }
        });
    },

    print(type) {
      this.pageData.printLoading = true;
      const body = {
        from: this.pageData.from_date,
        to: this.pageData.to_date,
        customer_id: this.pageData.customer_id,
        delegate_id: this.pageData.delegate_id,
        expense_code_id: this.pageData.expense_code_id,
        port_id: this.pageData.port_id,
        type: type
      }
      this.$api
        .DOWNLOAD_METHOD_BODY(`reimburses_expenses_report`, body, `reimburses_expenses_report.${type}`)
        .then((response) => {
          this.pageData.printLoading = false;
          if (response.check) {
            // this.pageData.rows = response.data.report;
          }
        });
    },
  },
};
</script>
