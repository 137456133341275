<template>

  <v-sheet elevation="0" class="transparent">


    <!-- level one -->
    <v-menu bottom transition="scale-transition" offset-y close-on-content :z-index="9999999"
      :close-on-content-click="false" :open-on-hover="false" v-for="(menu, index) in menus" :key="menu.id"
      v-model="menu.menuStatus" eager>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class=" rounded-pill" style="margin: 0 1px;" :color="attrs['aria-expanded'] == 'true' ? 'primary' : ''" text v-bind="attrs"
          v-on="on" @mouseover="hover = true" v-lazy-container="{ selector: 'img'  }">
          <img v-if="menu.image" height="18" class="mx-1 d-lg-block d-none" :data-src="$api.serverUrl + menu.image" :alt="menu.title" />
         
          {{ menu.title }}
          <v-icon small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <template v-slot:default>
        <v-list dense class="shadow">
          <AppBarRoutesCatMenu :backValueMethod="close" :hover="disableHover" :menuIndex="index" :menuList="menu" />
        </v-list>
      </template>

    </v-menu>
  </v-sheet>
</template>


<script>
import AppBarRoutesCatMenu from "./AppBarRoutesCatMenu.vue"
export default {
  name: "AppBarRoutesMenu",
  components: {
    AppBarRoutesCatMenu
  },
  computed: {

  },
  watch: {
    menus() {
      console.log('menus', this.menus)
    }
  },
  data: () => ({
    menus: [],
    hover: true,
    hideMenu: false,
  }),
  mounted() {
    this.menus = this.$store.state.screens
  },

  methods: {
    close(status, menuIndex) {
      this.hover = true;
      this.hideMenu = false;
      this.menus[menuIndex].menuStatus = false;
      // console.log('menus', this.menus)
    },
    disableHover(status) {
      this.hover = status;
    },
    over(active) {
      // console.log("over", active);
      this.$store.state.active_menu = active;
    },
    leave() {
      // console.log("leave");
      this.$store.state.active_menu = 0;
    },
  },
};
</script>



<style lang="scss" scoped>
.v-menu__content {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  //top: 3.5rem !important;
  padding-top: 0.3rem !important;

  button:focus {
    background: transparent !important;
  }
}
</style>