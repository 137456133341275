<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
      <!-- End Of Header -->

      <!-- Form -->
      <v-form ref="form" v-model="valid" class="pa-3">
        <v-card class="backgroundW shadow pa-5 pt-7">
          <v-row align="center">
            <GenericInput type="select" :lookups="finance_years" :value="form.finance_year_id"
              @input="form.finance_year_id = $event" label="choose the year" :multi="false" :required="true"
              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
            </GenericInput>

            <v-col cols="12" class="py-0 pb-3">
              <div class="mb-3 font-weight-bold">{{ $t("months") | capitalize }}</div>
              <v-btn-toggle v-model="form.months" multiple group color="primary" @change="checkClosing">
                <v-row>
                  <v-col cols="auto" v-for="(month, index) in months" :key="index">
                    <v-btn v-if="form.months[index - 1] == month.id - 1 || month.id == 1" :value="month.id" class="mx-2"
                      block :color="
                        $global.FilterArray(form.months, month.id).length == 1
                          ? 'blue4'
                          : 'primary'
                      " depressed outlined>
                      {{ month.name }}
                      <v-icon :color="
                      $global.FilterArray(form.months, month.id).length == 1 ? 'blue4' : 'primary'"
                        :disabled="!($global.FilterArray(form.months, month.id).length == 1)" right>mdi-check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" class="py-0 pb-3">
              <v-btn color="success" class="white--text d-block mi-start-auto" depressed :disabled="form.months == 0">{{
                  $t("closing of the fiscal year")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
      <!-- End of Form -->
    </v-container>
  </section>
</template>

<script>
import ControlHeader from "../../../components/ui/ControlHeader.vue";
import GenericInput from "../../../components/ui/GenericInput.vue";
export default {
  name: "FinancialPeriodsControl",

  data: () => ({
    pageData: {
      screen_code: "02-009",
      url: null,
      controlRoute: "/accounting/financial_period-control/",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isEdit: false,
      editIsLoading: false,
    },
    valid: false,
    finance_years: [],
    months: [],

    form: {
      finance_year_id: null,
      months: [],
    },
  }),
  components: {
    GenericInput,
    ControlHeader,
  },
  computed: {
  },
  mounted() {
    this.pageMainData();
    this.months = [
      { id: 1, name: this.$i18n.t("January"), closed: 1 },
      { id: 2, name: this.$i18n.t("February"), closed: 1 },
      { id: 3, name: this.$i18n.t("March"), closed: 1 },
      { id: 4, name: this.$i18n.t("April"), closed: 1 },
      { id: 5, name: this.$i18n.t("May"), closed: 1 },
      { id: 6, name: this.$i18n.t("June"), closed: 1 },
      { id: 7, name: this.$i18n.t("July"), closed: 1 },
      { id: 8, name: this.$i18n.t("August"), closed: 1 },
      { id: 9, name: this.$i18n.t("September"), closed: 1 },
      { id: 10, name: this.$i18n.t("October"), closed: 1 },
      { id: 11, name: this.$i18n.t("November"), closed: 1 },
      { id: 12, name: this.$i18n.t("December"), closed: 1 },
    ];
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.entityName = this.$route.params.id
        ? this.$i18n.t("edit") + " " + this.$i18n.t("financial periods")
        : this.$i18n.t("add financial periods");
    },
    getData() {
      if (this.$route.params.id) {
        if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
          this.pageData.isEdit = true;
          this.pageData.editIsLoading = true;
          this.pageData.isLoading = true;
          this.$api
            .GET_METHOD(`financial_period/${this.$route.params.id}`)
            .then((response) => {
              if (response.check) {
                this.pageData.editIsLoading = false;
                this.pageData.isLoading = false;
                console.log("response", response);
                this.finance_years = response.data.finance_years;
                response.data.financial_period.months = response.data.financial_period.months.split(',').map(i => Number(i));
                this.form = response.data.financial_period;
                this.$refs.form.validate();
              }
            });
        } else {
          this.$router.push("/");
        }
      } else {
        if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`financial_period/create`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.finance_years = response.data.finance_years;
            }
          });
        } else {
          this.$router.push("/");
        }
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        delete this.form.users;
        delete this.form.disable_date;
        delete this.form.disable_user;
        delete this.form.finance_year_order;
        delete this.form.financial_period_active;
        delete this.form.finance_year_active;
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          this.form._method = "PUT";
          this.$api
            .POST_METHOD(`financial_period/${this.form.financial_period_id}`, this.form)
            .then((response) => {
              this.pageData.isLoading = false;
              if (response.check) {
                this.$router.push(this.pageData.url);
              }
            });
        } else {
          this.$api.POST_METHOD(`financial_period`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url);
            }
          });
        }
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true;
        this.$api
          .POST_METHOD(`financial_period/${this.form.id}`, { _method: "delete" })
          .then(() => {
            this.$router.push(this.pageData.url);
            this.pageData.isLoading = false;
          });
      }
    },
    checkClosing() {
      console.log(this.form.months);
      this.form.months.forEach((id, index) => {
        let month = this.$global.FilterArrayOfObject(this.months, "id", id)[0];
        let nextMonth = this.$global.FilterArrayOfObject(this.months, "id", id + 1)[0];
        if (month.closed == 0) {
          let title =
            this.$i18n.t("You cant choose") +
            " " +
            month.name +
            " " +
            this.$i18n.t("for not closed") +
            " " +
            nextMonth.name;
          let body =
            this.$i18n.t("please close") +
            " " +
            nextMonth.name +
            " " +
            this.$i18n.t("first, then you can close") +
            " " +
            month.name +
            " " +
            this.$i18n.t(
              "the system for establishing the financial period runs in order of months"
            );
          this.$global.ShowAlert("info", title, false, body, 4000);
          if (index >= 0) {
            return this.form.months.splice(index, 1);
          }
        }
      });
    },
  },
};
</script>
