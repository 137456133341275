<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
                  <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
                  <GenericInput type="mobile" :value="form.mobile" @input="form.mobile = $event" label="mobile"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
                  <!-- <GenericInput type="radiogroup"
                     :lookups="[{ id: 1, name: $i18n.t('with customers') }, { id: 2, name: $i18n.t('with suppliers') }]"
                     :value="form.type" @input="form.type = $event" :labelInline="true" label="connection"
                     :multi="false" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]" color="#ffc20e">
                  </GenericInput> -->
                  <v-col cols="12" md="3" sm="6">
                     <GenericInput type="checkbox" :value="form.customer_id"  @input="form.customer_id = $event"
                        label="with customers" :disabled="false" :required="false" :isLoading="pageData.editIsLoading"
                        :cols="['auto', 'auto', 'auto']"></GenericInput>
                     <GenericInput type="checkbox" :value="form.supplier_id"  @input="form.supplier_id = $event"
                        label="with suppliers" :disabled="false" :required="false" :isLoading="pageData.editIsLoading"
                        :cols="['auto', 'auto', 'auto']"></GenericInput>
                  </v-col>

                  <GenericInput type="select" :lookups="branches" :value="form.branchs" @input="form.branchs = $event"
                     label="connected branches" :multi="true" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "../../../components/ui/ControlHeader.vue";
import GenericInput from '../../../components/ui/GenericInput.vue';
export default {
   name: "DelegatesControl",

   data: () => ({
      pageData: {
         screen_code: "01-015",
         url: null,
         controlRoute: "/main/delegate-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      branches: [],
      form: {
         name_en: null,
         name_ar: null,
         mobile: null,
         customer_id: null,
         supplier_id: null,
         type: 1,
         branchs: []
      },
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('delegate') : this.$i18n.t('add delegate');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`delegate/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.form.branchs = this.form.branchs.split(',').map(i => Number(i));
                     this.branches = response.data.branchs;
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`delegate/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.branches = response.data.branchs;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`delegate/${this.form.delegate_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`delegate`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`delegate/${this.form.delegate_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
