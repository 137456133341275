<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" content-class="overflow-x-hidden" persistent :max-width="750">
      <v-card relative :key="selectedRow.id">
         <v-card class="backgroundW shadow pa-5 pt-7">
            <v-row>
               <v-col cols="12" class="pt-0">
                  <div class="subtitle-1 font-weight-bold d-flex align-center">
                     <span class="mx-2">
                        {{ $t('attach declaration') }}
                        <span class="gray5--text" v-if="selectedRow.id">( {{ selectedRow.id }} )</span>
                     </span>
                  </div>
               </v-col>
               <v-col cols="12" class="d-flex justify-center" v-if="getAttachmentsIsLoading">
                  <AnimatedLoading :height="100" :hideText="true" :isLoading="getAttachmentsIsLoading" />
               </v-col>
               <v-row align="center" v-if="!getAttachmentsIsLoading">
                  <v-col cols="6" class="pb-0">
                     <GenericInput type="date" :value="update_declaration.customs_declaration_date"
                        @input="update_declaration.customs_declaration_date = $event" label="declaration date"
                        :multi="false" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                     </GenericInput>
                     <GenericInput type="date" :value="update_declaration.unloading_date"
                        @input="update_declaration.unloading_date = $event" label="unloading date"
                        :multi="false" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                     </GenericInput>
                     <GenericInput type="number" :value="update_declaration.customs_declaration_number"
                        @input="update_declaration.customs_declaration_number = $event" label="customs declaration number"
                        :multi="true" :required="false" :isLoading="false" :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="6" class="py-0">
                     <GenericInput type="textarea" :value="update_declaration.notes" rows="4"
                        @input="update_declaration.notes = $event" label="notes" :multi="false" :required="false"
                        :isLoading="false" :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12" class="py-0">
                     <GenericInput type="file" :value="update_declaration.customs_declaration_file"
                     @input="update_declaration.customs_declaration_file = $event" label="attachment" :multi="false"
                     :required="true" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>
                  </v-col>

               </v-row>


               <v-col cols="12" class="d-flex justify-end ">

                  <v-btn text depressed color="error" class="mx-1" :min-width="120" @click="()=>closeDialog(false)" :height="37">
                     <v-icon left>mdi-close</v-icon>
                     {{ $t('close') }}
                  </v-btn>
                  <v-btn class="white--text mx-1" depressed color="gray3" v-if="update_declaration.ext" :min-width="120"
                     @click="downloadAttachment" :loading="attachDownloadLoading" :height="37">
                     <v-icon left>mdi-download</v-icon>
                     {{ $t('download attachment') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" @click="updateDeclaration" depressed color="success" :loading="isLoading || getAttachmentsIsLoading"
                     :height="37" :width="120">
                     {{ $t('save') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-card>
   </v-dialog>
</template>

<script>

import GenericInput from "@/components/ui/GenericInput.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
export default {
   name: "AttachDeclarationDialog",
   props: {
      closeDialog: { type: Function },
      dialog: { default: false },
      id: { default: 0 },
      selectedRow: { default: Object },
   },
   computed: {

   },
   components: {
    GenericInput,
    AnimatedLoading
},
   watch: {
      dialog() {
         if (this.dialog) {
            this.update_declaration.customs_declaration_date = this.$global.GetCurrentDate();
            this.update_declaration.unloading_date = this.$global.GetCurrentDate();
            this.today = this.$global.GetCurrentDate();
            if(this.selectedRow.clearance_status_id !== 3){
               this.getAttachments();
            }
        
         } else {
            this.update_declaration = {
               customs_declaration_date: this.$global.GetCurrentDate(),
               unloading_date: this.$global.GetCurrentDate(),
               customs_declaration_number: null,
               customs_declaration_file: null,
               notes: null,
            };
            this.attach = Object;
         }
      }
   },
   data: () => ({
      isLoading: false,
      getAttachmentsIsLoading: false,
      attachDownloadLoading: false,
      today: null,
      update_declaration: {
         unloading_date: null,
         customs_declaration_date: null,
         customs_declaration_number: null,
         customs_declaration_file: null,
         notes: null,
      },
      attach: Object
   }),
   methods: {
      updateDeclaration() {
         this.isLoading = true;
         let requestBody = {...this.update_declaration}
         if (String(requestBody.customs_declaration_file).substring(0, 5) !== "data:") {
            delete requestBody.customs_declaration_file
         }

         this.$api.POST_METHOD(`update_clearance_customs_declaration/${this.id}`,requestBody).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.update_declaration = {
                  customs_declaration_date: this.$global.GetCurrentDate(),
                  unloading_date: this.$global.GetCurrentDate(),
                  customs_declaration_number: null,
                  customs_declaration_file: null,
                  notes: null,
               };
               this.attach = Object
               this.closeDialog(true);
            }
         })

      },
      getAttachments() {
         this.getAttachmentsIsLoading = true;
         this.$api.GET_METHOD(`get_clearance_customs_declaration/${this.id}`)
            .then((response) => {
               this.getAttachmentsIsLoading = false;
               if (response.check) {
                  console.log(response);
                  response.data.item.unloading_date =response.data.item.unloading_date || null
                  this.update_declaration = response.data.item || Object
               }
            })
      },
      downloadAttachment() {
         this.attachDownloadLoading = true
         var src = `download_clearance_customs_declaration/${this.update_declaration.id}`;
         var download_file_name = `${this.$i18n.t('declaration')}_${this.update_declaration.customs_declaration_number || ''}.${this.update_declaration.ext}`
         this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            this.attachDownloadLoading = false;
         })
      },

   },
   mounted() {
      this.update_declaration.customs_declaration_date = this.$global.GetCurrentDate()
      this.update_declaration.unloading_date = this.$global.GetCurrentDate()
      this.today = this.$global.GetCurrentDate();
   },
};
</script>
