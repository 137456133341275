<template>

   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="700">
      <v-card relative :key="modalKey">

         <v-form ref="detail" v-model="valid" class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <v-col cols="12" class="pt-0">
                     <div class="subtitle-1 font-weight-bold d-flex align-center">
                        <v-icon right v-if="!isDetailEdit && !custody_settlement">mdi-plus</v-icon>
                        <img v-if="isDetailEdit" src="@/assets/img/svg_icons/pen.svg" height="18" />
                        <span class="mx-2" v-if="custody_settlement">
                           {{  $t('custody settlement') }} <span dir="ltr" :class="remaining_amount >= 0 ? 'success--text' :'error--text'"> {{ remaining_amount | float }}</span>
                        </span>
                        <span v-else class="mx-2">
                           {{ isDetailEdit ? $t('edit line') : $t('add line') }}
                        </span>
                     </div>
                  </v-col>
                  <GenericInput type="float" solo :value="detail.amount" v-if="!custody_settlement" :rows="5" @input="detail.amount = $event"
                     label="amount" :required="true" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="number" solo :value="detail.receipt_number" :rows="5"
                     @input="detail.receipt_number = $event" label="receipt number" :required="true" :isLoading="false"
                     :cols="[12, 6, 6]">
                  </GenericInput>


                  <GenericInput type="select" :lookups="account_trees"  v-if="!custody_settlement" :value="detail.account_tree_id"
                     @input="detail.account_tree_id = $event" label="sub-accounting menu" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>


                  <GenericInput type="select" :lookups="clearances" v-if="!custody_settlement" :value="detail.clearance_id"
                     @input="detail.clearance_id = $event" label="clearance" :multi="false" :required="false"
                     selected_label="id" selected_prop="id" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="treasuries"  v-if="custody_settlement" :value="detail.treasury_id"
                     @input="detail.treasury_id = $event" label="treasury" :multi="false" :required="false"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 6, 6]">
                  </GenericInput>


                  <GenericInput type="textarea" solo :value="detail.arabic_description" :rows="2"
                     @input="detail.arabic_description = $event" label="description" :required="false"
                     :isLoading="false" :cols="[12, 12, 12]"></GenericInput>
                  <v-col cols="12" class="d-flex justify-end">
                     <v-btn text depressed color="error" class="mx-1" :min-width="120" @click="closeDialog"
                        :height="37">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn outlined style="background-color:  #e6ecff ;" class="mx-1" :disabled="!(valid)" depressed
                        color="blue2" :min-width="120" @click="save" :height="37">
                        <span v-if="isDetailEdit">
                           <v-icon left>mdi-pencil</v-icon>
                           {{ $t('edit line') }}
                        </span>
                        <span v-else-if="custody_settlement">
                           {{ $t('custody settlement') }}
                        </span>
                        <span v-else>
                           <v-icon left>mdi-plus</v-icon>
                           {{ $t('add line') }}
                        </span>
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>

      </v-card>
   </v-dialog>

</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "MPettyCashDetail",
   props: {
      detailAction: { type: Function },
      dialog: { default: false },
      updateDetail: { type: Function },
      detail: { default: Object },
      isDetailEdit: { default: false },
      account_trees: { default: [] },
      clearances: { default: [] },
      treasuries: { default: [] },
      tableRows: { default: [] },
      remaining_amount: { default: 0 },
      custody_settlement: { default: false },
   },
   computed: {

   },
   components: {
      GenericInput
   },
   watch: {
   },
   data: () => ({
      isLoading: false,
      valid: false,
      modalKey: 0,
   }),
   methods: {
      closeDialog() {
         this.detailAction(null, false, true)
      },
      save() {
         console.log(this.isDetailEdit);
         if(this.custody_settlement){
            this.detail.amount = this.remaining_amount;
            this.detail.custody_settlement = true;
         }
         this.detailAction(this.detail, this.isDetailEdit, false);
      },

   },
   mounted() {

   },
};
</script>
