// Store Module Routes:-
import DelegateEntity from "@/views/SalesModule/Delegate/DelegateEntity";
import DelegateControl from "@/views/SalesModule/Delegate/DelegateControl";
import CovenantTypesEntity from "@/views/SalesModule/CovenantTypes/CovenantTypesEntity.vue";
import CovenantTypesControl from "@/views/SalesModule/CovenantTypes/CovenantTypesControl.vue";
import CustomersEntity from "@/views/SalesModule/Customers/CustomersEntity.vue";
import CustomersControl from "@/views/SalesModule/Customers/CustomersControl.vue";
import CustomerPortControl from "@/views/SalesModule/Customers/CustomerPortControl.vue";
import CustomsCodeEntity from "@/views/SalesModule/CustomsCode/CustomsCodeEntity.vue";
import ClearanceEntity from "@/views/SalesModule/Clearance/ClearanceEntity.vue";
import ClearanceControl from "@/views/SalesModule/Clearance/ClearanceControl.vue";
import ClassificationsEntity from "@/views/SalesModule/Classifications/ClassificationsEntity.vue";
import ClassificationsControl from "@/views/SalesModule/Classifications/ClassificationsControl.vue";
import ClearanceInvoiceEntity from "@/views/SalesModule/ClearanceInvoice/ClearanceInvoiceEntity.vue";
import ClearanceInvoiceControl from "@/views/SalesModule/ClearanceInvoice/ClearanceInvoiceControl.vue";
import StorageEntity from "@/views/SalesModule/Storage/StorageEntity.vue";
import StorageControl from "@/views/SalesModule/Storage/StorageControl.vue";
import ItemsEntity from "@/views/SalesModule/Items/ItemsEntity.vue";
import CustomItemsEntity from "@/views/SalesModule/CustomItems/CustomItemsEntity.vue";
import UnitsEntity from "@/views/SalesModule/Units/UnitsEntity.vue";
import ClearanceArrivalEntity from "@/views/SalesModule/ClearanceArrival/ClearanceArrivalEntity.vue";
import ClearanceDeclarationEntity from "@/views/SalesModule/ClearanceDeclaration/ClearanceDeclarationEntity.vue";
import ClearancePayEntity from "@/views/SalesModule/ClearancePay/ClearancePayEntity.vue";
import CustomerStatementEntity from "@/views/SalesModule/CustomerStatement/CustomerStatementEntity.vue";
import CustomersBalancesEntity from "@/views/SalesModule/CustomersBalances/CustomersBalancesEntity.vue";
import DebtAgeEntity from "@/views/SalesModule/DebtAge/DebtAgeEntity.vue";
import SupplierStatement from "@/views/SalesModule/SupplierStatement/SupplierStatementEntity.vue";
import ClearanceProblemsEntity from "@/views/SalesModule/ClearanceProblems/ClearanceProblemsEntity.vue";
import WaitingForClearingEntity from "@/views/SalesModule/WaitingForClearing/WaitingForClearingEntity.vue";
import ClearanceFinishedEntity from "@/views/SalesModule/ClearanceFinished/ClearanceFinishedEntity.vue";
import CollectCustomsFeesEntity from "@/views/SalesModule/CollectCustomsFees/CollectCustomsFeesEntity.vue";
import ReimbursesExpensesEntity from "@/views/SalesModule/ReimbursesExpenses/ReimbursesExpensesEntity.vue";

import ClearanceDraftInvoiceEntity from "@/views/SalesModule/ClearanceDraftInvoice/ClearanceDraftInvoiceEntity.vue";
import ClearanceDraftInvoiceControl from "@/views/SalesModule/ClearanceDraftInvoice/ClearanceDraftInvoiceControl.vue";

export const SalesModuleChildrens = [
  // Delegates
  {
    path: "/sales/delegate",
    name: "DelegateEntity",
    component: DelegateEntity,
    meta: {
      screen_code: "04-001",
    },
  },
  {
    path: "/sales/delegate-control",
    name: "DelegateControlADD",
    component: DelegateControl,
    meta: {
      screen_code: "04-001",
    },
  },
  {
    path: "/sales/delegate-control/:id",
    name: "DelegateControlEdit",
    component: DelegateControl,
    meta: {
      screen_code: "04-001",
    },
  },
  //  Covenant type
  {
    path: "/sales/covenant_type",
    name: "CovenantTypesEntity",
    component: CovenantTypesEntity,
    meta: {
      screen_code: "04-002",
    },
  },
  {
    path: "/sales/covenant_type-control",
    name: "CovenantTypesControlADD",
    component: CovenantTypesControl,
    meta: {
      screen_code: "04-002",
    },
  },
  {
    path: "/sales/covenant_type-control/:id",
    name: "CovenantTypesControlEdit",
    component: CovenantTypesControl,
    meta: {
      screen_code: "04-002",
    },
  },
  // Customers
  {
    path: "/sales/customer",
    name: "CustomersEntity",
    component: CustomersEntity,
    meta: {
      screen_code: "04-003",
    },
  },
  {
    path: "/sales/customer-control",
    name: "CustomersControlADD",
    component: CustomersControl,
    meta: {
      screen_code: "04-003",
    },
  },
  {
    path: "/sales/customer-control/:id",
    name: "CustomersControlEdit",
    component: CustomersControl,
    meta: {
      screen_code: "04-003",
    },
  },
  {
    path: "/sales/customer_ports-control/:id",
    name: "CustomerPortControl",
    component: CustomerPortControl,
    meta: {
      screen_code: "04-003",
    },
  },
  // Customs Code
  {
    path: "/sales/customs_code",
    name: "CustomsCodeEntity",
    component: CustomsCodeEntity,
    meta: {
      screen_code: "04-003",
    },
  },
  // Clearance 
  {
    path: "/sales/clearance",
    name: "ClearanceEntity",
    component: ClearanceEntity,
    meta: {
      screen_code: "04-004",
    },
  },
  {
    path: "/sales/clearance-control/",
    name: "ClearanceControl",
    component: ClearanceControl,
    meta: {
      screen_code: "04-004",
    },
  },
  {
    path: "/sales/clearance-control/:id",
    name: "ClearanceControlEdit",
    component: ClearanceControl,
    meta: {
      screen_code: "04-004",
    },
  },
  // Custom Item
  {
    path: "/sales/customs_item",
    name: "CustomItemsEntity",
    component: CustomItemsEntity,
    meta: {
      screen_code: "04-005",
    },
  },
  //  Items
  {
    path: "/sales/items",
    name: "ItemsEntity",
    component: ItemsEntity,
    meta: {
      screen_code: "04-006",
    },
  },
  // Classifications
  {
    path: "/sales/classification",
    name: "ClassificationsEntity",
    component: ClassificationsEntity,
    meta: {
      screen_code: "04-007",
    },
  },
  {
    path: "/sales/classification-control/",
    name: "ClassificationsControl",
    component: ClassificationsControl,
    meta: {
      screen_code: "04-007",
    },
  },
  {
    path: "/sales/classification-control/:id",
    name: "ClassificationsControlEdit",
    component: ClassificationsControl,
    meta: {
      screen_code: "04-007",
    },
  },
  // Units
  {
    path: "/sales/units",
    name: "UnitsEntity",
    component: UnitsEntity,
    meta: {
      screen_code: "04-008",
    },
  },
  // Clearance Arrival
  {
    path: "/sales/clearance_arrival",
    name: "ClearanceArrivalEntity",
    component: ClearanceArrivalEntity,
    meta: {
      screen_code: "04-009",
    },
  },
  // Clearance Declaration
  {
    path: "/sales/clearance_customs",
    name: "ClearanceDeclarationEntity",
    component: ClearanceDeclarationEntity,
    meta: {
      screen_code: "04-010",
    },
  },
  // Clearance Pay
  {
    path: "/sales/clearance_pay",
    name: "ClearancePayEntity",
    component: ClearancePayEntity,
    meta: {
      screen_code: "04-011",
    },
  },
  // Clearance Invoice
  {
    path: "/sales/clearance_invoice",
    name: "ClearanceInvoiceEntity",
    component: ClearanceInvoiceEntity,
    meta: {
      screen_code: "04-012",
    },
  },
  {
    path: "/sales/clearance_invoice-control/",
    name: "ClearanceInvoiceControl",
    component: ClearanceInvoiceControl,
    meta: {
      screen_code: "04-012",
    },
  },
  {
    path: "/sales/clearance_invoice-control/:id",
    name: "ClearanceInvoiceControlEdit",
    component: ClearanceInvoiceControl,
    meta: {
      screen_code: "04-012",
    },
  },
  // Draft Clearance invoice
  {
    path: "/sales/clearance_draft_invoice",
    name: "ClearanceDraftInvoiceEntity",
    component: ClearanceDraftInvoiceEntity,
    meta: {
      screen_code: "03-023",
    },
  },
  {
    path: "/sales/clearance_draft_invoice-control/",
    name: "DraftClearanceInvoiceControl",
    component: ClearanceDraftInvoiceControl,
    meta: {
      screen_code: "03-023",
    },
  },
  {
    path: "/sales/clearance_draft_invoice-control/:id",
    name: "ClearanceDraftInvoiceControlEdit",
    component: ClearanceDraftInvoiceControl,
    meta: {
      screen_code: "03-023",
    },
  },

  // Storage
  {
    path: "/sales/storage",
    name: "StorageEntity",
    component: StorageEntity,
    meta: {
      screen_code: "04-007",
    },
  },
  {
    path: "/sales/storage-control/",
    name: "StorageControlControl",
    component: StorageControl,
    meta: {
      screen_code: "04-007",
    },
  },
  {
    path: "/sales/storage-control/:id",
    name: "StorageControlControlEdit",
    component: StorageControl,
    meta: {
      screen_code: "04-007",
    },
  },
  // Customer Statement
  {
    path: "/sales/customer_statement",
    name: "CustomerStatementEntity",
    component: CustomerStatementEntity,
    meta: {
      screen_code: "04-013",
    },
  },
  {
    // Customer Balances
    path: "/sales/customers_balances",
    name: "CustomersBalancesEntity",
    component: CustomersBalancesEntity,
    meta: {
      screen_code: "04-014",
    },
  },
  // Debt age
  {
    path: "/sales/debt_age",
    name: "DebtAgeEntity",
    component: DebtAgeEntity,
    meta: {
      screen_code: "04-015",
    },
  },
  // Supplier Statement
  {
    path: "/sales/supplier_statement",
    name: "SupplierStatement",
    component: SupplierStatement,
    meta: {
      screen_code: "04-016",
    },
  },
  // Problems 
  {
    path: "/sales/clearance_problems",
    name: "ClearanceProblems",
    component: ClearanceProblemsEntity,
    meta: {
      screen_code: "04-017",
    },
  },
  // WaitingForClearingEntity 
  {
    path: "/sales/Waiting_for_clearing",
    name: "WaitingForClearingEntity",
    component: WaitingForClearingEntity,
    meta: {
      screen_code: "04-018",
    },
  },
  // Clearance Finished 
  {
    path: "/sales/clearance_finished",
    name: "ClearanceFinishedEntity",
    component: ClearanceFinishedEntity,
    meta: {
      screen_code: "04-019",
    },
  },
  // Collect Customs Fees  
  {
    path: "/sales/collect_customs_fees",
    name: "CollectCustomsFeesEntity",
    component: CollectCustomsFeesEntity,
    meta: {
      screen_code: "04-020",
    },
  },
  // Collect Customs Fees  
  {
    path: "/sales/reimburses_expenses",
    name: "ReimbursesExpensesEntity",
    component: ReimbursesExpensesEntity,
    meta: {
      screen_code: "04-021",
    },
  },
];
