<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" createBtnText="add" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="pageData.rows"
            :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod">

            <template v-slot:actions="{ row }">
              <v-btn class="" :class="{ 'd-none': !$global.CheckAction(pageData.screen_code, 5) }" :icon="true"
                @click="printVoucher(row)" depressed text x-small color="blue5"
                :loading="pageData.printLoading.loading && row.id === pageData.printLoading.id">
                <v-icon color="black" size="21">
                  mdi-printer-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:td="{ row, header }">
              <v-hover v-if="header.key == 'journal_code'" v-slot="{ hover }" close-delay="50" open-delay="50">
                <div style="width: 100%;height: 100%;">
                  <v-btn color="secondary" min-width="80" text v-if="!hover" small>
                    {{ row[header.key] || '-' }}
                  </v-btn>
                  <v-btn @click="openJournal(row)" v-if="hover" class="mx-1" x-small icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn @click="printJournal(row)" class="mx-1"
                    :loading="pageData.printLoading.loading && pageData.printLoading.id == row.m_id" v-if="hover" x-small
                    icon>
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </div>
              </v-hover>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
    <DailyJournalPrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />

    <MultiVoucherPrint :print="printMultiVoucher" :printAction="() => { printMultiVoucher = false; printData = [] }"
      :printStaticData="printStaticData" :printData="printData" />
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import MultiVoucherPrint from "./MultiVoucherPrint.vue";
import DailyJournalPrint from "../DailyJournal/DailyJournalPrint.vue";
export default {
  name: "MultiVoucherEntity",
  data: () => ({
    pageData: {
      screen_code: "02-013",
      url: null,
      controlRoute: "accounting/multi_voucher-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 20,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
    },
    printData: [],
    printStaticData: Object,
    print: false,
    printMultiVoucher: false,
  }),
  components: { MultiVoucherPrint, EntityHeader, DynamicTable, Pagination, DailyJournalPrint },
  computed: {

  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 20;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        // { text: "#", key: "id", type: 'text', classes: "" },
        { text: "the bond number", key: "id", type: 'text', classes: "" },
        { text: "journal", key: "journal_code", type: 'slot', classes: "", width: 80 },
        // { text: "currency", key: "currency_name", type: 'text', classes: "" },
        { text: "description", key: "description", type: 'text', classes: "" },
        { text: "movement date", key: "action_date", type: 'text', classes: "" },
        { text: "created_at", key: "add_date", type: 'date', width: 120, classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: false,
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`multi_voucher${this.pageData.queryParam}`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`multi_voucher/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`multi_voucher_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    edit(row) {
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.daily_journal_m_id, '_blank')
    },
    // printJournal(row) {
    //   this.pageData.printLoading.loading = true;
    //   this.pageData.printLoading.id = row.m_id;
    //   this.$api
    //     .GET_METHOD(`daily_journal/${row.daily_journal_m_id}`).then((response) => {
    //       this.pageData.printLoading.loading = false;
    //       if (response.check) {
    //         this.printData = response.data.details;
    //         this.printStaticData = response.data.master;
    //         this.print = true;
    //         setTimeout(() => {
    //           this.printData = [];
    //           this.printStaticData = Object;
    //           this.print = false;
    //           this.pageData.printLoading.loading = false;
    //         }, 1000);
    //       }
    //     });
    // },
    printJournal(row) {
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = row.m_id;
         const src = `export_journal_data/${row.daily_journal_m_id}?type=pdf`;
         this.$api.DOWNLOAD_METHOD(src, `${this.pageData.entityName}_${row.daily_journal_m_id}.pdf`).then(() => {
            this.pageData.printLoading.loading = false;
         })
      },
    printVoucher(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api.GET_METHOD(`multi_voucher/${row.id}`).then((response) => {
        this.pageData.printLoading.loading = false;
        if (response.check) {
          this.printData = response.data.details;
          this.printStaticData = response.data;
          this.printMultiVoucher = true;
          setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.printMultiVoucher = false;
            this.pageData.printLoading.loading = false;
          }, 1000);
        }
      });
    },
  },
};
</script>
