<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="(valid && noPriceFound == false)"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW rounded-lg shadow pa-5">
               <v-row align="center">

                  <GenericInput type="radiogroup" :lookups="trips_types" selected_label="name" selected_prop="id"
                     label="trip type" :value="trip_type" @input="trip_type = $event" :isLoading="false"
                     :cols="[12, 'auto', 'auto']"></GenericInput>

                  <!-- if trip internal (((1)))  -->
                  <v-sheet v-if="trip_type == 1" class="col-12">
                     <v-row align="center">
                        <!-- Customer -->
                        <v-col v-if="form.customer_name" cols="12" md="6" lg="4" class="subtitle-1 font-weight-bold">
                           <v-row>
                              <v-col cols="auto">
                                 <span class="primary--text">{{ $t('customer name') }} : </span>
                              </v-col>
                              <v-col cols="auto">
                                 {{ form.customer_name }}
                                 <v-progress-circular :size="20" :width="3" v-if="pageData.isLoading && pageData.isEdit"
                                    style="margin: auto" class="d-block mx-2" color="blue4" indeterminate />
                              </v-col>
                           </v-row>
                        </v-col>
                        <!-- Trip Way -->
                        <GenericInput type="select" :lookups="trips_ways" :value="trip_road" @input="trip_road = $event"
                           label="trip way" :multi="false" :required="true" selected_label="name" selected_prop="id"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <!-- From -->
                        <GenericInput v-if="trip_road == 1 || trip_road == 2" type="select" :lookups="ports"
                           :value="form.from_port_id" @input="form.from_port_id = $event" label="from port" :multi="false"
                           :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 4]" :onChange="onLocationChange('from_port_id')">
                        </GenericInput>
                        <GenericInput v-if="trip_road == 3 || trip_road == 5" type="select" :lookups="warehouses"
                           :value="form.from_wearhouse_id" @input="form.from_wearhouse_id = $event" label="from terminal"
                           :multi="false" :required="true" selected_label="name" selected_prop="id"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"
                           :onChange="onLocationChange('from_wearhouse_id')">
                        </GenericInput>
                        <!-- choose agent -->
                        <GenericInput v-if="trip_road == 5" type="select" :lookups="agents" :value="form.to_agent_id"
                           @input="form.to_agent_id = $event" label="choose agent" :multi="false" :required="true"
                           selected_label="agent_name" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 4]" :onChange="onLocationChange('to_agent_id')">
                        </GenericInput>
                        <GenericInput v-if="trip_road == 4" type="select" :lookups="areas" :value="form.from_area_id"
                           @input="form.from_area_id = $event" label="from place" :multi="false"
                           :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 4]" :onChange="onLocationChange('from_area_id')">
                        </GenericInput>

                        <!-- To -->
                        <GenericInput v-if="trip_road == 1 || trip_road == 4" type="select" :lookups="warehouses"
                           :value="form.to_wearhouse_id" @input="form.to_wearhouse_id = $event; to_wearhouse_id = $event"
                           label="to terminal" :multi="false" :required="true" selected_label="name" selected_prop="id"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"
                           :onChange="onLocationChange('to_wearhouse_id')">
                        </GenericInput>
                        <GenericInput v-if="trip_road == 2 || trip_road == 3" type="select" :lookups="areas"
                           :value="form.to_area_id" @input="form.to_area_id = $event; to_area_id = $event" label='to place'
                           :multi="false" :required="true" selected_label="name" selected_prop="id"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"
                           :onChange="onLocationChange('to_area_id')">
                        </GenericInput>
                        <!-- No Prices -->
                        <v-col cols="12" md="6" lg="12" v-if="noPriceFound == true">
                           <v-sheet color="blue12" :height="39" v-if="!getPriceLoading"
                              class="body-1 rounded d-flex align-center px-3">
                              <v-icon class="mx-1">mdi-information-outline</v-icon>
                              {{ $t('no price found to this trip location') }}
                           </v-sheet>
                        </v-col>
                        <!-- transaction_number -->
                        <v-col cols="12" md="6" lg="4" v-if="trip_road" class="pa-0 cursor_pointer position-relative"
                           @click="dialog = true">
                           <div class="clearance_abs" @click="dialog = true" />
                           <GenericInput type="text" :disabled="true" :value="transaction_number"
                              @input="transaction_number = $event" :readonly="true"
                              :label="form.from_type == 1 ? 'please enter transaction number' : 'please select transaction number'"
                              :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                        </v-col>
                        <GenericInput type="select" v-if="trip_road && clearance_containers.is_lcl != 1" :lookups="clearance_containers"
                           :value="form.container_number" @input="form.container_number = $event" label="container"
                           :multi="false" :required="true" selected_label="container_number"
                           selected_prop="container_number" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <v-col cols="12" md="6" lg="4" v-if="trip_road == 3 && form.container_number" class="d-flex">
                           <h4 class="gray7--text text-center mx-2">{{ $t('container number') }} : </h4>
                           <h4 class="blue4--text text-center mx-2">{{ form.container_number }} </h4>
                        </v-col>
                        <v-col cols="12" md="6" lg="4" v-if="container_used_count" class="d-flex">
                           <h4 class="gray7--text text-center mx-2">{{ $t('container used times') }} :</h4>
                           <h4 class="blue4--text text-center mx-2">{{ container_used_count }} </h4>
                        </v-col>

                        <!-- <GenericInput type="number" v-if="trip_road == 1 || trip_road == 2"
                           :value="form.container_number" @input="form.container_number = $event"
                           label="container number" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]"></GenericInput> -->

                        <GenericInput type="checkbox" v-if="trip_road == 4" :value="form.company_bears_expenses"
                           @input="form.company_bears_expenses = $event" label="The company bears the cost"
                           :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />
                     </v-row>
                  </v-sheet>
                  <!-- if trip external (((2))) , Customer Name , vat register number   -->
                  <v-sheet v-if="trip_type == 2" class="col-12">
                     <v-row align="center">
                        <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                           @input="form.customer_id = $event" label="customer" :multi="false" :required="true"
                           selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>
                        <GenericInput type="text" v-if="form.customer_id == 1" :value="form.customer_name"
                           @input="form.customer_name = $event" label="customer name" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>
                        <GenericInput type="number" v-if="form.customer_id == 1" :value="form.customer_vat_number"
                           @input="form.customer_vat_number = $event" label="vat register number" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>
                        <GenericInput type="select" :lookups="areas" :disableItem="{ key: 'id', value: form.to_area_id }"
                           :value="form.from_area_id" @input="form.from_area_id = $event; from_area_id = $event"
                           label="from place" :multi="false" :required="true" selected_label="name" selected_prop="id"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>
                        <GenericInput type="select" :lookups="areas" :disableItem="{ key: 'id', value: form.from_area_id }"
                           :value="form.to_area_id" @input="form.to_area_id = $event; to_area_id = $event" label="to place"
                           :multi="false" :required="true" selected_label="name" selected_prop="id"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>
                        <!-- No Prices -->
                        <v-col cols="12" md="6" lg="6" v-if="noPriceFound == true">
                           <v-sheet color="blue12" :height="39" v-if="!getPriceLoading"
                              class="body-1 rounded d-flex align-center px-3">
                              <v-icon class="mx-1">mdi-information-outline</v-icon>
                              {{ $t('no price found to this trip location') }}
                           </v-sheet>
                        </v-col>
                     </v-row>
                  </v-sheet>

                  <GenericInput type="number" v-if="trip_type == 2" :value="form.container_number"
                     @input="form.container_number = $event" label="container number" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="radiogroup" :lookups="[{ id: 40 }, { id: 20 }]"
                     v-if="form.container_number || trip_type == 2" selected_label="id" selected_prop="id"
                     :disabled="trip_type == 1" :value="form.container_size" @input="form.container_size = $event"
                     label="container size" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />

                  <GenericInput type="checkbox" v-if="form.container_number || trip_type == 2" :value="form.is_empty"
                     @input="form.is_empty = $event" label="is container empty ?" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />
               </v-row>
            </v-card>

            <v-card class="backgroundW rounded-lg shadow pa-5  mt-3">
               <v-row align="center">
                  <v-col cols="12" xs="12" class="">
                     <h4>{{ $t('trip details') }} :
                        <span class="gray7--text body-2" v-if="roadName">( {{ roadName }})</span>
                     </h4>
                  </v-col>

                  <v-col cols="12" md="6" lg="auto" class="d-flex" v-if="form.driver_price_in && trip_type == 1">
                     <h4 class="subtitle-2 gray2--text text-center mx-2">{{ $t('driver price in') }} : </h4>
                     <h4 class="subtitle-2 blue4--text text-center mx-2" v-if="!getPriceLoading">
                        {{ form.driver_price_in | float }}
                        {{ $t("sar") }}</h4>
                     <v-progress-circular :size="20" :width="3" v-if="getPriceLoading" style="margin: auto"
                        class="d-block mx-2" color="blue4" indeterminate>
                     </v-progress-circular>
                  </v-col>

                  <v-col cols="12" md="6" lg="auto" class="d-flex" v-if="form.driver_price_out && trip_type == 2">
                     <h4 class="subtitle-2 gray2--text text-center mx-2">{{ $t('driver price out') }} : </h4>
                     <h4 class="subtitle-2 blue4--text text-center mx-2" v-if="!getPriceLoading">{{
                        form.driver_price_out | float
                     }} {{ $t("sar") }}</h4>
                     <v-progress-circular :size="20" :width="3" v-if="getPriceLoading" style="margin: auto"
                        class="d-block mx-2" color="blue4" indeterminate>
                     </v-progress-circular>
                  </v-col>

                  <v-col cols="12" md="6" lg="auto" class="d-flex" v-if="form.trip_price_in && trip_type == 1">
                     <h4 class="subtitle-2 gray2--text text-center mx-2">{{ $t('trip price in') }} : </h4>
                     <h4 class="subtitle-2 blue4--text text-center mx-2" v-if="!getPriceLoading">
                        {{ form.trip_price_in | float }} {{ $t("sar") }}</h4>
                     <v-progress-circular :size="20" :width="3" v-if="getPriceLoading" style="margin: auto"
                        class="d-block mx-2" color="blue4" indeterminate />
                  </v-col>
                  <v-col cols="12" md="6" lg="auto" class="d-flex" v-if="form.trip_price_out && trip_type == 2">
                     <h4 class="subtitle-2 gray2--text text-center mx-2">{{ $t('trip price out') }} : </h4>
                     <h4 class="subtitle-2 blue4--text text-center mx-2" v-if="!getPriceLoading">
                        {{ form.trip_price_out | float }} {{ $t("sar") }} </h4>
                     <v-progress-circular :size="20" :width="3" v-if="getPriceLoading" style="margin: auto"
                        class="d-block mx-2" color="blue4" indeterminate />
                  </v-col>

                  <v-col cols="12" md="6" lg="auto" class="d-flex"
                     v-if="form.trip_price_in || form.trip_price_out || form.driver_price_in || form.driver_price_out">
                     <h4 class="subtitle-2 gray2--text text-center mx-2">{{ $t('total price') }} : </h4>
                     <h4 class="subtitle-2 blue4--text text-center mx-2" v-if="!getPriceLoading">

                        <span v-if="trip_type == 1">{{ +form.trip_price_in + +form.driver_price_in | float }}</span>
                        <span v-if="trip_type == 2">{{ +form.trip_price_out + +form.driver_price_out | float }}</span>
                        {{ $t("sar") }}
                     </h4>
                     <v-progress-circular :size="20" :width="3" v-if="getPriceLoading" style="margin: auto"
                        class="d-block mx-2" color="blue4" indeterminate />
                  </v-col>
                  <v-col cols="4"
                     v-if="form.trip_price_in || form.trip_price_out || form.driver_price_in || form.driver_price_out"></v-col>
                  <GenericInput type="date" :value="form.trip_date" @input="form.trip_date = $event" label="trip date"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="cars" :value="form.car_id" @input="form.car_id = $event"
                     label="car" :multi="false" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="drivers" :value="form.driver_id" @input="form.driver_id = $event"
                     label="driver" :multi="false" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>


               </v-row>
            </v-card>

         </v-form>
         <!-- End of Form -->

         <v-dialog v-model="dialog" persistent width="450">
            <v-card class="pb-5">
               <v-card-title class="subtitle-1 light shadow">
                  <span v-if="form.from_type == 1">{{ $t('please enter transaction number') }}</span>
                  <span v-if="form.from_type == 2">{{ $t('please select transaction number') }}</span>
               </v-card-title>
               <v-divider></v-divider>
               <div class="pa-5">

                  <v-col cols="12" v-if="!form.customer_id && (trip_road == 3 || trip_road == 5)">
                     <v-alert type="info" dense>{{ $t('please choose customer to view available transaction') }}
                     </v-alert>
                  </v-col>
                  <v-col cols="12"
                     v-if="form.customer_id && !(transaction_numbers.length || avail_clearances.length) &&  (trip_road == 3 || trip_road == 5) && getContainerLoading == false">
                     <v-alert type="info" outlined dense>{{ $t('no available terminal transaction') }}
                     </v-alert>
                  </v-col>
                  <!-- <GenericInput type="number" v-if="trip_road == 1 || trip_road == 2" :value="form.transaction_number"
                     @input="form.transaction_number = $event" label="transaction no" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                  </GenericInput> -->
                  <GenericInput type="select" v-if="avail_clearances.length > 0" :lookups="avail_clearances"
                     :value="avail_clearance" @input="avail_clearance = $event" label="transaction no" :multi="false"
                     :required="true" selected_label="customer_clearance"
                     :selected_prop="form.trip_road == 3 || form.trip_road == 5  ? 'clearance_m_id' : 'id'" :isLoading="getContainerLoading"
                     :cols="[12, 12, 12]">
                  </GenericInput>
                  <v-col cols="12" v-if="getContainerLoading">
                     <v-progress-circular :size="50" :width="5" style="margin: auto" class="d-block" color="primary"
                        indeterminate>
                     </v-progress-circular>
                  </v-col>
               </div>
               <v-card-actions class="justify-center">
                  <v-btn color="error" text depressed @click="dialog = false">
                     {{ $t('cancel') }}
                  </v-btn>
                  <v-btn color="success" :loading="getContainerLoading || getAvailClearanceLoading"
                     :disabled="!(transaction_number || form.transaction_number)" depressed @click="getContainers">
                     {{ $t('ok') }}
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-dialog>
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
   name: "TripControl",

   data: () => ({
      pageData: {
         screen_code: "03-007",
         url: null,
         controlRoute: "/transport/trip-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      dialog: false,
      getPriceLoading: false,
      getContainerLoading: false,
      getAvailClearanceLoading: false,
      noPriceFound: null,
      drivers: [],
      trips_types: [],
      trips_ways: [],
      customers: [],
      ports: [],
      cars: [],
      transaction_numbers: [],
      areas: [],
      agents: [],
      from_ports: [],
      to_ports: [],
      from_warehouses: [],
      to_warehouses: [],
      warehouses: [],
      containers: [],
      avail_clearances: [],
      clearance_containers: [],
      // 
      trip_type: 1,
      trip_road: null,
      roadName: null,
      // 
      transaction_number: null,
      avail_clearance: null,
      container_number: null,
      to_wearhouse_id: null,
      to_agent_id: null,
      from_area_id: null,
      to_area_id: null,
      from_active_clearance: false,
      checkClearanceLoading: false,
      container_used_count: null,
      form: {
         trip_date: null,
         trip_type: 1,
         trip_road: null,
         from_type: null,
         to_type: null,
         customer_id: 2,
         customer_name: null,
         driver_id: null,
         from_area_id: null,
         to_area_id: null,
         from_port_id: null,
         to_port_id: null,
         from_wearhouse_id: null,
         to_wearhouse_id: null,
         transaction_number: null,
         car_id: null,
         container_number: null,
         is_empty: 0,
         container_size: null,
         customer_vat_number: null,
         driver_price_in: null,
         driver_price_out: null,
         trip_price_in: null,
         trip_price_out: null,
         trip_price: null,
         to_agent_id: null,
         company_bears_expenses: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader
   },
   watch: {
      'form.car_id'() {
         this.form.driver_id = null;
         this.form.driver_id = this.form.car_id ? this.$global.FilterArrayOfObjectElement(this.cars, 'id', this.form.car_id, 'default_driver') : null;
      },
      avail_clearance() {
         this.transaction_number = this.avail_clearance;
         console.log('transaction_number',this.transaction_number);
         if (this.avail_clearance) {
            if (this.trip_road == 3 || this.trip_road == 5) {
               console.log();
               this.clearance_containers = this.avail_clearances.filter((clearance) => clearance.clearance_m_id == this.avail_clearance)
            }
            else {

               this.getClearanceContainers()
            }
         }
      },
      trip_road() {
         this.form.from_port_id = this.pageData.isEdit == true ? this.form.from_port_id : null;
         this.form.to_port_id = this.pageData.isEdit == true ? this.form.to_port_id : null;
         this.form.from_wearhouse_id = this.pageData.isEdit == true ? this.form.from_wearhouse_id : this.pageData.isEdit == true ? this.form.from_wearhouse_id : null;
         this.form.to_wearhouse_id = this.pageData.isEdit == true ? this.form.to_wearhouse_id : null;
         this.form.trip_road = this.trip_road;
         this.form.container_number = this.pageData.isEdit == true ? this.container_number : null;
         this.transaction_number = this.pageData.isEdit == true || this.from_active_clearance == true ? this.transaction_number : null;
         this.form.transaction_number = this.pageData.isEdit == true ? this.form.transaction_number : null;
         switch (Number(this.trip_road)) {
            case 1:
               this.form.from_type = 1;
               this.form.to_type = 2;
               this.getAvailClearances();
               break;
            case 2:
               this.form.from_type = 1;
               this.form.to_type = 3;
               this.getAvailClearances();
               break;
            case 3:
               this.form.from_type = 2;
               this.form.to_type = 3;
               break;
            case 4:
               // this.form.from_type = 3;
               // this.form.to_type = 2;
               this.getAvailClearances();
               break;
            case 5:
            this.form.from_type = 2;
               this.form.to_type = 3;
               break;

            default:
               break;
         }
      },
      trip_type() {
         this.form.container_number = this.pageData.isEdit == true ? this.form.container_number : null;
         this.avail_clearance = this.pageData.isEdit == true ? this.avail_clearance : null;
         this.transaction_number = this.pageData.isEdit == true ? this.transaction_number : null;
         this.form.transaction_number = this.pageData.isEdit == true ? this.form.transaction_number : null;
         this.form.trip_type = this.trip_type;
         switch (Number(this.trip_type)) {
            case 1:
               this.form.customer_id = 2;
               break;
            case 2:
               this.form.customer_id = this.form.customer_id == 2 ? 0 : this.form.customer_id;
               break;
            default:
               break;
         }
      },
      dialog() {
         if (this.dialog == true && (this.trip_road == 3 || this.trip_road == 5) && this.form.customer_id) {
            // this.getAvailClearances()
            this.getWarehouseTransaction()
         }
      },
      transaction_number() {
         this.form.transaction_number = this.transaction_number.transaction_number || this.transaction_number;
         this.form.container_number = this.transaction_number.container_number || this.container_number;
         if (this.transaction_number) {
            let clearance = {}
            if (this.form.trip_road == 3 || this.form.trip_road == 5) {
               clearance = this.avail_clearances.find(clearance => clearance.clearance_m_id == this.transaction_number) || {}
            } else {
               clearance = this.avail_clearances.find(clearance => clearance.id == this.transaction_number) || {}
            }
            // this.form.customer_id = 2;
            this.form.customer_id = clearance.customer_id || this.form.customer_id;

            this.form.customer_name = clearance.customer_name || this.form.customer_name;
         }
         this.dialog = false;
         console.log(this.form.transaction_number);
      },
      to_wearhouse_id() {
         if (!this.getPriceLoading) {
            this.getPrices()
         }
      },
      to_agent_id() {
         if (!this.getPriceLoading) {
            this.getPrices()
         }
      },
      from_area_id() {
         if (!this.getPriceLoading) {
            this.getPrices()
         }
      },
      to_area_id() {
         if (!this.getPriceLoading) {
            this.getPrices()
         }
      },
      'form.container_number'() {
         if (this.form.container_number) {
            let selectedContainer = this.clearance_containers.find(container => container.container_number == this.form.container_number) || {}
            console.log('selectedContainer', selectedContainer);
            this.form.container_size = selectedContainer.container_size || this.form.container_size;
            this.form.clearance_m_id = selectedContainer.clearance_m_id || selectedContainer.clearances_m_id || 0
            this.form.clearance_d_id = selectedContainer.clearance_d_id || selectedContainer.clearances_d_id || 0
            this.form.warehouse_transaction_id = selectedContainer.id || 0
            this.checkClearanceTrip();
         }
      }
   },
   computed: {
      totalPrice() {
         if (this.form.driver_price_in || this.form.trip_price_in || this.form.driver_price_out || this.form.trip_price_out) {
            if (this.trip_type == 1 || this.form.trip_type == 1) {
               return Number(+this.form.driver_price_in) + Number(+this.form.trip_price_in)
            }
            else {
               return Number(this.form.driver_price_out) + Number(this.form.trip_price_out)
            }
         } else {
            return 0
         }
      },
   },
   mounted() {
      this.pageMainData();
      this.form.trip_date = this.$global.GetCurrentDate();
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         this.trips_ways = [
            { id: 1, name: this.$i18n.t('from port to terminal') },
            { id: 2, name: this.$i18n.t('from port to customer') },
            { id: 3, name: this.$i18n.t('from terminal to customer') },
            { id: 4, name: this.$i18n.t('from the client to the arena') },
            { id: 5, name: this.$i18n.t('from the yard to the agent') },
         ]
         this.trips_types = [{ name: this.$i18n.t('an internal customer'), id: 1 }, { name: this.$i18n.t('an external customer'), id: 2 }]
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`trip/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.avail_clearance = response.data.data.transaction_number || response.data.data.clearance_m_id;
                     this.transaction_number = response.data.data.transaction_number;
                     this.container_number = response.data.data.container_number;
                     this.form.transaction_number = response.data.data.transaction_number;
                     this.trip_type = response.data.data.trip_type;
                     this.trip_road = response.data.data.trip_road;
                     this.areas = response.data.areas;
                     this.agents = response.data.agents;
                     this.warehouses = response.data.wearhouses;
                     this.ports = response.data.ports;
                     response.data.customers.unshift({ id: 1, name: this.$i18n.t('customer not saved') })
                     this.customers = response.data.customers;
                     this.cars = response.data.cars;
                     this.drivers = response.data.drivers;
                     this.from_area_id = this.form.from_area_id;
                     this.to_area_id = this.form.to_area_id;
                     this.to_agent_id = this.form.to_agent_id;
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`trip/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.areas = response.data.areas;
                     this.warehouses = response.data.wearhouses;
                     this.ports = response.data.ports;
                     this.cars = response.data.cars;
                     this.agents = response.data.agents;
                     response.data.customers.unshift({ id: 1, name: this.$i18n.t('customer not saved') })
                     this.customers = response.data.customers;
                     this.drivers = response.data.drivers;
                     if (this.$route.params.clearance_id) {
                        this.from_active_clearance = true;
                        this.trips_ways = [
                           { id: 1, name: this.$i18n.t('from port to terminal') },
                           { id: 2, name: this.$i18n.t('from port to customer') },
                        ]
                        this.trips_types = [{ name: this.$i18n.t('internal'), id: 1 }]
                        this.trip_road = 1;
                        this.avail_clearance = this.$route.params.clearance_id;
                        this.transaction_number = this.$route.params.clearance_id;
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            if (this.form.trip_type == 1) {
               this.form.driver_price = this.form.driver_price_in;
               this.form.trip_price = this.form.trip_price_in;
               this.form.total_price = this.form.driver_price_in + this.form.trip_price_in;
               this.form.driver_price_out = 0;
               this.form.trip_price_out = 0;
            } else {
               this.form.driver_price = this.form.driver_price_out;
               this.form.trip_price = this.form.trip_price_out;
               this.form.total_price = this.form.driver_price_out + this.form.trip_price_out;
               this.form.driver_price_in = 0;
               this.form.trip_price_in = 0;
            }
            this.form.from_port_id = this.form.from_port_id || 0;
            this.form.to_port_id = this.form.to_port_id || 0;
            this.form.from_wearhouse_id = this.form.from_wearhouse_id || 0;
            this.form.to_wearhouse_id = this.form.to_wearhouse_id || 0;
            this.form.from_area_id = this.form.from_area_id || 0;
            this.form.to_area_id = this.form.to_area_id || 0;
            this.form.trip_type = this.form.trip_type || 0;
            this.form.trip_road = this.form.trip_road || 0;
            this.form.container_number = this.form.container_number || 0;
            this.form.transaction_number = this.form.transaction_number || 0;
            this.form.clearance_m_id = this.form.transaction_number || this.form.clearance_m_id || 0;
            this.form.trip_price = this.totalPrice || 0;
            !this.form.company_bears_expenses && (this.form.company_bears_expenses = 0);
            !this.form.clearance_d_id && (this.form.clearance_d_id = 0)
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`trip/${this.form.trip_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`trip`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      getWarehouseTransaction() {
         this.getContainerLoading = true;
         this.$api.GET_METHOD(`wearhouse_transaction?status=2&trip_id=${this.form.trip_type}`).then((response) => {
            this.getContainerLoading = false;
            if (response.check) {
               let clearances = []
               response.data.items.forEach(clearance => {
                  clearance.customer_clearance = clearance.clearance_m_id + ' - ' + clearance.customer_name
                  if (clearance.clearance_m_id && clearance.clearance_d_id) {
                     clearances.push(clearance)
                  }
               });
               this.transaction_numbers = clearances;
               this.avail_clearances = clearances;
            }
         })
      },
      checkClearanceTrip() {
         this.checkClearanceLoading = true;
         this.$api.GET_METHOD(`check_clearance_trip?clearance_d_id=${this.form.clearance_d_id}&clearance_m_id=${this.form.clearance_m_id}`).then((response) => {
            this.checkClearanceLoading = false;
            if (response.check) {
               this.container_used_count = response.data.count;
            }
         })
      },
      getAvailClearances() {
         this.getAvailClearanceLoading = true;
         this.$api.GET_METHOD(`get_aval_clearances`).then((response) => {
            this.getAvailClearanceLoading = false;
            if (response.check) {
               response.data.forEach(clearance => {
                  clearance.customer_clearance = clearance.id + ' - ' + clearance.customer_name
               });
               this.avail_clearances = response.data;
               this.transaction_numbers = response.data;
            }
         })
      },
      getClearanceContainers() {
         this.getContainerLoading = true;
         this.$api.GET_METHOD(`get_clearance_containers/${this.avail_clearance}`).then((response) => {
            this.getContainerLoading = false;
            if (response.check) {
               this.clearance_containers = response.data;
            }
         })
      },
      onLocationChange(type) {
         let from;
         let toArea;
         switch (type) {
            // set from area
            case "from_port_id":
               from = this.form.from_port_id ? this.$global.FilterArrayOfObject(this.ports, 'id', this.form.from_port_id)[0] : null
               this.from_area_id = from ? from.area_id : null;
               break;
            case "from_wearhouse_id":
               from = this.form.from_wearhouse_id ? this.$global.FilterArrayOfObject(this.warehouses, 'id', this.form.from_wearhouse_id)[0] : null
               this.from_area_id = from ? from.area_id : null;
               break;
            case "from_area_id":
               this.from_area_id = this.form.from_area_id;
               break;
            // set to area
            case "to_wearhouse_id":
               toArea = this.form.to_wearhouse_id ? this.$global.FilterArrayOfObject(this.warehouses, 'id', this.form.to_wearhouse_id)[0] : null
               this.to_area_id = toArea ? toArea.area_id : null;
               break;
            case "to_area_id":
               this.to_area_id = this.form.to_area_id;
               break;
            case "to_agent_id":
               this.to_area_id = this.agents.find(agent => agent.id == this.form.to_agent_id)?.area_id;
               console.log('to_area_id', this.to_area_id);
               break;
            default:
               break;
         }

      },
      getPrices() {
         console.log('getPrices', this.from_area_id, this.to_area_id);
         if ((this.from_area_id && this.to_area_id)) {
            this.getPriceLoading = true;
            this.$api.GET_METHOD(`area_price?from_area=${this.from_area_id}&to_area=${this.to_area_id}`).then((response) => {
               this.getPriceLoading = false;
               if (response.check && response.data.items.length > 0) {
                  this.form.driver_price_in = response.data.items[0].driver_price_in;
                  this.form.driver_price_out = response.data.items[0].driver_price_out;
                  this.form.trip_price_in = response.data.items[0].trip_price_in;
                  this.form.trip_price_out = response.data.items[0].trip_price_out;
                  this.form.trip_price_out = response.data.items[0].trip_price_out;
                  this.roadName = response.data.items[0].from_area_name + ' - ' + response.data.items[0].to_area_name
                  this.noPriceFound = false;
               } else {
                  this.form.driver_price_in = null;
                  this.form.driver_price_out = null;
                  this.form.trip_price_in = null;
                  this.form.trip_price_out = null;
                  this.roadName = null;
                  this.noPriceFound = true;
               }
            })
         }

      },
      getContainers() {
         // this.getContainerLoading = true
         // setTimeout(() => {
         //    this.containers = [{ id: 1, name: 'container 1' }, { id: 2, name: 'container 2' },];
         //    this.getContainerLoading = false;
         // }, 1000);
         this.dialog = false;
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`trip/${this.form.trip_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
<style lang="scss" scoped>
.clearance_abs {
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   z-index: 1;
}
</style>