<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showDeleted="false" :createBtn="false" :getSearch="getSearch"
        :showSearch="false" :leftSearch="true" actionBtnText="search in clearance" :actionBtn="!showFilter"
        :actionBtnValid="true" actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }"
        :actionBtnOutline="true" actionBtnClass="blue4--text">
        <template v-slot:rightSide>
          <v-col cols="12" sm="auto" v-if="Array.from(pageData.queryParam.values()).length > 6">
            <v-btn class="shadow-none" color="error" @click="clearFilter">{{ $t('reset search') }}
              <v-icon right>mdi-magnify-close</v-icon>
            </v-btn>
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->


      <!-- Filter -->
      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="">
          <v-card class="shadow pa-7">
            <v-row align="center" justify="space-between" class="">

              <GenericInput type="date" :isPickerOpened="() => pageData.to_date = null" :value="pageData.from_date"
                :clearable="true" :maxDate="pageData.to_date || today" @input="pageData.from_date = $event"
                label="from date" :required="false" :hide-details="true" :cols="[12, 4, 4]" />

              <GenericInput type="date" :value="pageData.to_date" :clearable="true" :minDate="pageData.from_date"
                :maxDate="today" @input="pageData.to_date = $event" label="to date" :required="false" :hide-details="true"
                :cols="[12, 4, 4]" />

              <GenericInput type="select" :lookups="customers" :value="pageData.customer_id" :clearable="true"
                @input="pageData.customer_id = $event" label="customer name" :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :cols="[12, 4, 4]" />

              <GenericInput type="select" :lookups="ports" :value="pageData.port_id" @input="pageData.port_id = $event"
                :clearable="true" label="port" :multi="false" :required="false" selected_label="name" selected_prop="id"
                :hide-details="true" :cols="[12, 4, 4]" />

              <GenericInput type="text" :value="pageData.clearance_id" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.clearance_id = $event" label="transaction no" :required="false" :hide-details="true"
                :cols="[12, 4, 4]" />

              <GenericInput type="text" :value="pageData.container_number" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.container_number = $event" label="container number" :required="false"
                :hide-details="true" :cols="[12, 4, 4]" />

              <GenericInput type="text" :value="pageData.shipping_policy_no" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.shipping_policy_no = $event" label="shipping policy no" :required="false"
                :hide-details="true" :cols="[12, 4, 4]" />

              <v-col cols="8" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined :loading="pageData.isLoading" @click="getSearch"
                  class="mx-1 white--text">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="pageData.rows"
            :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod"
            :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 380 : $store.state.Settings.windowSize.y - 200"
            :tableParams="true">
            <template v-slot:td="{ row, header, index }">
              <span class="d-flex justify-center" v-if="header.key == 'id'">
                <v-btn @click="rowClicked(row)" class="text-decoration-underline font-weight-bold" depressed
                  color="primary" text small>
                  {{ row.id || 0 }}
                </v-btn>
              </span>
              <div v-if="header.key == 'attachments'">
                <v-btn @click="attachmentsDialog = true; pageData.rowIsLoading.index = index; dialogKey = dialogKey + 1"
                  small fab icon :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id"
                  class="blue1--text">
                  <v-icon> mdi-attachment-plus</v-icon>
                </v-btn>
              </div>
              <div v-if="header.key == 'container_count'">
                <v-chip outlined small v-if="row.container_small">20*{{ row.container_small }}</v-chip> &nbsp;
                <v-chip outlined small v-if="row.container_big">40*{{ row.container_big }}</v-chip>
              </div>
              <div v-if="header.key == 'comments'">
                <v-btn @click=" viewComments(row.id)" small
                  :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id" icon
                  class="success--text">
                  <v-icon>mdi-comment-outline</v-icon>
                </v-btn>
              </div>
              <div v-if="header.key == 'has_problem'" class="font-weight-bold">
                <span @click="() => { lastCommentDialog = true; selectedRow = row }"
                  :class="{ 'error--text text-decoration-underline cursor_pointer': row.has_problem, 'success--text': !row.has_problem }">
                  {{ row.has_problem ? $t('yes') : $t('no') | capitalize }}
                </span>
              </div>
              <span class="d-flex justify-center" v-if="header.key == 'arrival_date'">
                <v-btn @click="() => { expectedDateDialog = true; selectedRow = row }" class="text-decoration-underline"
                  depressed color="primary" text small
                  :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                  {{ row.arrival_date || $t('add') }}
                </v-btn>
              </span>
              <span class="d-flex justify-center" v-if="header.key == 'withdrawal_note'">
                <v-btn @click="() => { withdrawalNoteDialog = true; selectedRow = row }" class="text-decoration-underline"
                  depressed color="primary" text small
                  :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                  {{ row.withdrawal_note || 0 }}
                </v-btn>
              </span>
              <span class="d-flex justify-center" v-if="header.key == 'inspection_number'">
                <v-btn @click="() => { inspectionNumberDialog = true; selectedRow = row }"
                  class="text-decoration-underline" depressed color="primary" text small
                  :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                  {{ row.inspection_number || 0 }}
                </v-btn>
              </span>
              <span v-if="header.key == 'port_withdraw'">
                <v-btn @click="() => { portsNumberDialog = true; pageData.rowIsLoading.id = row.id; selectedRow = row }"
                  text depressed color="primary" class="text-decoration-underline" small
                  :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                  {{ row.port_withdraw ? row.port_withdraw : $t('port numbers') }}
                </v-btn>
              </span>
              <span class="d-flex justify-center" style="width : 100px" v-if="header.key == 'change_status'">
                <v-btn @click="() => { confirmationDialog = true; selectedRow = row }" block depressed color="success"
                  small :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                  {{ $t('arrival') }}
                </v-btn>
              </span>

            </template>
          </DynamicTable>

        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>


    </v-container>
    <v-dialog v-model="attachmentsDialog" overlay-opacity="0.75" persistent :max-width="950">
      <v-card relative class="pa-7 py-10" :key="dialogKey">
        <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click=" attachmentsDialog = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-row justify="center" class="mt-2" v-if="pageData.rowIsLoading.index !== null">


          <GenericInput type="file" v-for="( attachment ) in  file_names " :key="attachment.id" :value="attachment.value"
            @input=" attachment.value = $event" :label="attachment.name" :multi="false" :required="false"
            selected_label="name" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
          </GenericInput>


          <v-col cols="12" class="d-flex justify-end">
            <v-btn class="mx-2" text depressed color="red4" @click=" attachmentsDialog = false" :height="37" :width="120">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn class="mx-2 white--text" :loading="false" @click="saveAttachment" depressed color="success"
              :height="37" :width="120">
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>


    <LastProblemCommentDialog :dialog="lastCommentDialog" :selectedRow="selectedRow"
      :closeDialog="() => { lastCommentDialog = false; selectedRow = Object }" :id="selectedRow.id" />

    <WithdrawalNoteDialog :dialog="withdrawalNoteDialog" :selectedRow="selectedRow"
      :closeDialog="() => { withdrawalNoteDialog = false; selectedRow = Object }" :id="selectedRow.id" />

    <ArrivalExpectedDateDialog :dialog="expectedDateDialog" :selectedRow="selectedRow"
      :closeDialog="() => { expectedDateDialog = false; selectedRow = Object }" :id="selectedRow.id" />

    <ArrivalConfirmationDialog :dialog="confirmationDialog" :selectedRow="selectedRow" :closeDialog="closeConformation"
      :id="selectedRow.id" />

    <ClearanceSummeryDialog :dialog="clearanceSummeryDialog" :selectedRow="selectedRow"
      :closeDialog="() => { clearanceSummeryDialog = false; selectedRow = Object }" :id="selectedRow.id" />

    <InspectionNumberDialog :dialog="inspectionNumberDialog" :selectedRow="selectedRow"
      :closeDialog="() => { inspectionNumberDialog = false; selectedRow = Object }" :id="selectedRow.id" />

    <PortsNumberDialog :dialog="portsNumberDialog" :selectedRow="selectedRow"
      :closeDialog="() => { portsNumberDialog = false; selectedRow = Object }" :id="selectedRow.id" />

    <ChatDialog :dialog="commentsDialog" :chat="comments" :isLoading="commentsLoading || pageData.rowIsLoading.loading"
      :saveMessage="addComment" :closeDialog="() => commentsDialog = false" />
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import LastProblemCommentDialog from "../ModuleComponents/LastProblemCommentDialog.vue";
import WithdrawalNoteDialog from "../ModuleComponents/WithdrawalNoteDialog.vue";
import PortsNumberDialog from "../ModuleComponents/PortsNumberDialog.vue";
import ArrivalConfirmationDialog from "../ModuleComponents/ArrivalConfirmationDialog.vue";
import InspectionNumberDialog from "../ModuleComponents/InspectionNumberDialog.vue";
import ChatDialog from "@/components/ui/ChatDialog.vue";
import ClearanceSummeryDialog from "../ModuleComponents/ClearanceSummeryDialog.vue";
import ArrivalExpectedDateDialog from "../ModuleComponents/ArrivalExpectedDateDialog.vue";
export default {
  name: "ClearanceArrivalEntity",

  data: () => ({
    pageData: {
      screen_code: "04-009",
      url: null,
      controlRoute: "sales/clearance-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      status: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
      rowIsLoading: {
        loading: false,
        id: null,
        index: null,
        key: 'id'
      },
      from_date: null,
      to_date: null,
      customer_id: null,
      port_id: null,
      clearance_id: null,
      shipping_policy_no: null,
      container_number: null,
    },
    today: null,
    dialogKey: 1,
    showFilter: false,
    customers: [],
    ports: [],
    arrival_notes: null,
    attachmentsDialog: false,
    selectedRow: Object,
    confirmationDialog: false,
    attachments: [],
    statusIsLoading: false,
    lastCommentDialog: false,
    inspectionNumberDialog: false,
    portsNumberDialog: false,
    withdrawalNoteDialog: false,
    expectedDateDialog: false,
    clearanceSummeryDialog: false,
    commentsDialog: false,
    commentsLoading: false,
    comments: [],
    tab: 1,
    status: [],
    file_names: []
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    GenericInput,
    LastProblemCommentDialog,
    WithdrawalNoteDialog,
    PortsNumberDialog,
    ArrivalConfirmationDialog,
    InspectionNumberDialog,
    ChatDialog,
    ClearanceSummeryDialog,
    ArrivalExpectedDateDialog
  },
  computed: {

  },
  watch: {
    $route() {
      this.pageData.queryParam = new URLSearchParams();
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      this.getData();
    }
  },
  mounted() {
    this.pageData.port_id = this.$store.state.userData.user.default_port || null;
    this.queryParams();
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
      this.pageData.queryParam.set("port_id", this.pageData.port_id || '');
      if (!(this.pageData.queryParam.has('sort_by') || this.pageData.queryParam.has('sort_value'))) {
        this.pageData.queryParam.set("sort_default", 1);
      } else {
        this.pageData.queryParam.delete("sort_default");
      }
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "transaction no", key: "id", type: 'slot', classes: "" },
        { text: "delivery date", key: "action_date", type: 'date', width: 95, classes: "" },
        { text: "customer", key: "customer_name", type: 'text', width: 120, classes: "" },
        { text: "delegate", key: "delegate_name", type: 'text', classes: "" },
        { text: "containers QTY", key: "container_count", type: 'slot', classes: "", not_sorted: true },
        { text: "shipping policy no", key: "shipping_policy_no", type: 'text', classes: "" },
        { text: "port", key: "port_name", type: 'text', classes: "" },
        { text: "first container number", key: "first_container_number", type: 'text', classes: "" },
        { text: "old transaction number", key: "old_sys_id", type: 'text', classes: "" },
        { text: "comments", key: "comments", type: 'slot', classes: "", not_sorted: true },
        { text: "status", key: "status_name", type: 'text', classes: "" },
        { text: "expected arrival", key: "arrival_date", type: 'slot', classes: "" },
        { text: "has a problem", key: "has_problem", type: 'slot', classes: "", not_sorted: true },
        { text: "port numbers", key: "port_withdraw", type: 'slot', classes: "" },
        { text: "delivery number", key: "withdrawal_note", type: 'slot', classes: "" },
        { text: "inspection no", key: "inspection_number", type: 'slot', classes: "" },
        { text: "transaction arrival", key: "change_status", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: true,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      console.log(this.pageData.queryParam.toString());
      this.setFilterParams();
      this.pageData.queryParam.set("word", typeof word == 'string' ? word : '')
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
    },
    setFilterParams() {
      this.pageData.queryParam.set("customer_id", this.pageData.customer_id || '');
      this.pageData.queryParam.set("port_id", this.pageData.port_id || '');
      this.pageData.queryParam.set("clearance_id", this.pageData.clearance_id || '');
      this.pageData.queryParam.set("shipping_policy_no", this.pageData.shipping_policy_no || '');
      this.pageData.queryParam.set("from_date", this.pageData.from_date || '');
      this.pageData.queryParam.set("to_date", this.pageData.to_date || '');
      this.pageData.queryParam.set("container_number", this.pageData.container_number || '');
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`clearance?${this.pageData.queryParam.toString()}&status=1`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              response.data.file_names.forEach(file => {
                file.value = null;
              });
              response.data.items.data.forEach(row => {
                row.attachments = response.data.file_names
                row.rowClass = row.has_problem ? 'red12' : ''
              });
              this.showFilter = false;
              this.file_names = response.data.file_names;
              this.customers = response.data.customers || [];
              this.ports = response.data.ports || [];
              this.attachments = [...response.data.file_names];
              this.status = response.data.status;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam.set("page", page || 1);
      this.pageData.queryParam.set("rows", limit || 15);
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`clearance/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`clearance_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/clearance/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.daily_journal_id, '_blank')
    },
    printMethod(row) {
      this.pageData.rowIsLoading.loading = true;
      this.pageData.rowIsLoading.id = row.daily_journal_id;
      const src = `export_journal_data/${row.daily_journal_id}?type=pdf`;
      this.$api.DOWNLOAD_METHOD(src, `${this.$i18n.t('journal')}_${row.daily_journal_id}.pdf`).then(() => {
        this.pageData.rowIsLoading.loading = false;
      })
    },
    saveAttachment() {
      // this.pageData.isLoading = true;
      this.pageData.rowIsLoading.loading = true;
      this.file_names.forEach((attachment) => {
        this.pageData.rowIsLoading.loading = true;
        if (attachment.value && attachment.id) {
          this.pageData.rowIsLoading.id = this.pageData.rows[this.pageData.rowIsLoading.index].id;
          const requestBody = {
            clearance_file_name_id: attachment.id,
            file: attachment.value,
          }
          this.$api.POST_METHOD(`clearance_add_file/${this.pageData.rows[this.pageData.rowIsLoading.index].id}`, requestBody).then((response) => {
            if (response.check) {
              this.pageData.rowIsLoading.loading = false;
              this.pageData.rowIsLoading.id = null;
              this.pageData.rowIsLoading.index = null;
              attachment.value = null;
            }
          })
        }

      });
      this.attachmentsDialog = false
    },
    closeConformation(status) {
      this.confirmationDialog = false;
      this.selectedRow = Object
      if (status == true) {
        this.getData()
      }
    },
    addComment(comment) {
      this.commentsLoading = true;
      this.$api.POST_METHOD(`clearance_add_comment/${this.pageData.rowIsLoading.id}`, { comment: comment }).then((response) => {
        this.commentsLoading = false;
        if (response.check) {
          this.viewComments(this.pageData.rowIsLoading.id);
        }
      });
    },
    viewComments(id) {
      this.pageData.rowIsLoading.loading = true
      this.pageData.rowIsLoading.id = id;
      this.$api.GET_METHOD(`clearance_get_comment/${id}`).then((response) => {
        this.pageData.rowIsLoading.loading = false
        if (response.check) {
          this.comments = response.data.items;
          this.commentsDialog = true;
        }
      })
    },
    rowClicked(row) {
      this.selectedRow = { ...row };
      this.clearanceSummeryDialog = true;
    },
    clearFilter(){
      this.pageData.customer_id = null;
      this.pageData.port_id = null;
      this.pageData.clearance_id = null;
      this.pageData.shipping_policy_no = null;
      this.pageData.from_date = null;
      this.pageData.to_date = null;
      this.pageData.search = '';
      this.$router.push(`${this.pageData.url}`)
    }
  },

};
</script>
