<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader
            :pageData="pageData"
            :form="form"
            :save="save"
            :valid="valid"
            :deleteMethod="deleteMethod"
         />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput
                     type="text"
                     :value="form.name"
                     @input="form.name = $event"
                     label="year"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  />
                  <v-col cols="6"></v-col>
                  <GenericInput
                     type="select"
                     :lookups="users"
                     :value="form.users"
                     @input="form.users = $event"
                     label="Choose who will see the branch"
                     :multi="true"
                     :required="true"
                     selected_label="user_full_name"
                     selected_prop="id"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  />
                  <v-col cols="12" md="4">
                     <v-col cols="12" v-if="form.users">
                        <v-btn depressed @click="form.users = null">{{ $t('delete all') }}</v-btn>
                     </v-col>
                     <v-chip
                        v-for="(select, index) in form.users"
                        :key="index"
                        class="ma-2"
                        label
                        close
                        @click:close="removeUser(select)"
                     >
                        <span
                           v-for="(all, index) in users.filter((all) => all.id == select)"
                           :key="index"
                        >{{ all.user_full_name }}</span>
                     </v-chip>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

import ControlHeader from "../../../components/ui/ControlHeader.vue";
import GenericInput from '../../../components/ui/GenericInput.vue';

export default {
   name: "FinancialYearsControl",

   data: () => ({
      pageData: {
         screen_code: "01-004",
         url: null,
         controlRoute: "/main/finance_year-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      users: [],
      form: {
         name: null,
         users: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {

      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('financial year') : this.$i18n.t('add financial year');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`finance_year/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.users = response.data.users;
                     this.form.users = this.form.users.split(',').map(i => Number(i));
                     this.$refs.form?.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`finance_year/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.users = response.data.users;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`finance_year/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`finance_year`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`finance_year/${this.form.finance_year_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      removeUser(item) {
         const index = this.form.users.indexOf(item)
         if (index >= 0) this.form.users.splice(index, 1)
      },

   },
};
</script>
