<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :showSearch="false" :leftSearch="true"
        actionBtnText="search in clearance" :actionBtn="!showFilter" :actionBtnValid="true" actionBtnColor="transparent"
        :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true" actionBtnClass="blue4--text">
        <template v-slot:rightSide>
          <v-col cols="12" sm="auto" v-if="Array.from(pageData.queryParam.values()).length > 6">
            <v-btn class="shadow-none" color="error" @click="clearFilter">{{ $t('reset search') }}
              <v-icon right>mdi-magnify-close</v-icon>
            </v-btn>
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->

      <!-- Filter -->
      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="">
          <v-card class="shadow pa-7">
            <v-row align="center" justify="space-between" class="">

              <GenericInput type="date" :isPickerOpened="() => pageData.to_date = null" :value="pageData.from_date"
                :clearable="true" :maxDate="pageData.to_date || today" @input="pageData.from_date = $event"
                label="from date" :required="false" :hide-details="true" :cols="[12, 4, 4]" />

              <GenericInput type="date" :value="pageData.to_date" :clearable="true" :minDate="pageData.from_date"
                :maxDate="today" @input="pageData.to_date = $event" label="to date" :required="false" :hide-details="true"
                :cols="[12, 4, 4]" />

              <GenericInput type="autocomplete" :lookups="customers" :value="pageData.customer_id" :clearable="true"
                @input="pageData.customer_id = $event" :isLoading="customersLoading" :keyupValue="search_customer"
                label="customer name" noDataText="Enter the customer's name" :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :cols="[12, 4, 4]" />

              <GenericInput type="select" :lookups="ports" :value="pageData.port_id" @input="pageData.port_id = $event"
                :clearable="true" label="port" :multi="false" :required="false" selected_label="name" selected_prop="id"
                :hide-details="true" :cols="[12, 4, 4]" />

              <GenericInput type="text" :value="pageData.clearance_id" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.clearance_id = $event" label="transaction no" :required="false" :hide-details="true"
                :cols="[12, 4, 4]" />

              <GenericInput type="text" :value="pageData.container_number" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.container_number = $event" label="container number" :required="false"
                :hide-details="true" :cols="[12, 4, 4]" />

              <GenericInput type="text" :value="pageData.shipping_policy_no" :keydownEnter="getSearch" :clearable="true"
                @input="pageData.shipping_policy_no = $event" label="shipping policy no" :required="false"
                :hide-details="true" :cols="[12, 4, 4]" />

              <v-col cols="8" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined :loading="pageData.isLoading" @click="getSearch"
                  class="mx-1 white--text">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden">
              <v-col cols="12" class="pa-0 px-0">
                <v-tabs optional :slider-size="0" active-class="blue12">
                  <v-tab class="font-weight-bold caption" :disabled="pageData.isLoading" :value="0"
                    @click="changeFilter(0)">
                    <strong>{{ $t('all') }}
                      <span v-if="status.length > 0">
                        ({{ status.reduce((oldValue, newValue) => Number(+oldValue) + Number(+newValue.counter), 0) }})
                      </span>
                    </strong>
                  </v-tab>
                  <v-tab class="font-weight-bold caption" v-for="(item) in status" :key="item.id"
                    :disabled="pageData.isLoading" :value="0" @click="changeFilter(item.id)">
                    <strong>{{ item.name | capitalize }} <span v-if="item.counter">({{ item.counter || 0
                    }})</span></strong>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
              <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="pageData.rows"
                cardClasses="shadow-none" :header="tableHeader" :option="pageData.options" :editValueMethod="edit"
                :pageData="pageData" :printValueMethod="printClearance"
                :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 430 : $store.state.Settings.windowSize.y - 280"
                :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" :tableParams="true">
                <template v-slot:td="{ row, header, index }">
                  <span class="d-flex justify-center" v-if="header.key == 'id'">
                    <v-btn @click="rowClicked(row)" class="text-decoration-underline font-weight-bold" depressed
                      color="primary" text small>
                      {{ row.id || 0 }}
                    </v-btn>
                  </span>
                  <div v-if="header.key == 'attachments'">
                    <v-btn @click="openDialog(row, index)" x-small fab icon
                      :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id" class="blue1--text">
                      <v-icon size="24"> mdi-attachment-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="header.key == 'status_name'">
                    <v-btn @click="() => { clearanceStatusDialog = true; selectedRow = row }" outlined
                      v-if="$store.state.userData.user.clearance_status && row.clearance_status_id !== 1" color="primary"
                      small depressed>
                      {{ row.status_name }}
                    </v-btn>
                    <div v-else>{{ row.status_name }}</div>
                  </div>
                  <div v-if="header.key == 'has_problem'" class="font-weight-bold">
                    <span @click="() => { lastCommentDialog = true; selectedRow = row }"
                      :class="{ 'error--text text-decoration-underline cursor_pointer': row.has_problem, 'success--text': !row.has_problem }">
                      {{ row.has_problem ? $t('yes') : $t('no') | capitalize }}
                    </span>
                  </div>
                  <span class="d-flex justify-center" v-if="header.key == 'arrival_date'">
                    <v-btn @click="() => { expectedDateDialog = true; selectedRow = row }"
                      class="text-decoration-underline" depressed color="primary" text small
                      :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                      {{ row.arrival_date || $t('add') }}
                    </v-btn>
                  </span>
                  <div v-if="header.key == 'comments'">
                    <v-btn @click="viewComments(row.id)" small
                      :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id" icon
                      class="success--text">
                      <v-icon>mdi-comment-outline</v-icon>
                    </v-btn>
                  </div>

                  <span class="d-flex justify-center" v-if="header.key == 'withdrawal_note'">
                    <v-btn @click="() => { withdrawalNoteDialog = true; selectedRow = row }"
                      class="text-decoration-underline" depressed color="primary" text small
                      :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                      {{ row.withdrawal_note || 0 }}
                    </v-btn>
                  </span>
                  <span class="d-flex justify-center" v-if="header.key == 'inspection_number'">
                    <v-btn @click="() => { inspectionNumberDialog = true; selectedRow = row }"
                      class="text-decoration-underline" depressed color="primary" text small
                      :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                      {{ row.inspection_number || 0 }}
                    </v-btn>
                  </span>
                  <span v-if="header.key == 'port_withdraw'">
                    <v-btn
                      @click="() => { portsNumberDialog = true; pageData.rowIsLoading.id = row.id; selectedRow = row }"
                      text depressed color="primary" class="text-decoration-underline" small
                      :loading="pageData.rowIsLoading.loading && row.id === pageData.rowIsLoading.id">
                      {{ row.port_withdraw ? row.port_withdraw : $t('port numbers') }}
                    </v-btn>
                  </span>
                  <div v-if="header.key == 'container_count'">
                    <v-chip outlined small v-if="row.container_small">20*{{ row.container_small }}</v-chip> &nbsp;
                    <v-chip outlined small v-if="row.container_big">40*{{ row.container_big }}</v-chip>
                  </div>
                  <span class="d-flex justify-center" v-if="header.key == 'invoices'">
                    <div v-for="(invoice, index) in row.invoices"
                      @click="$router.push(`/sales/clearance_invoice-control?clearance_invoice_id=${invoice}`)"
                      class="mx-1 cursor_pointer text-decoration-underline primary--text" :key="index">
                      {{ invoice }}
                    </div>
                  </span>
                </template>
              </DynamicTable>
            </v-card>
          </v-col>

        </v-col>
      </v-row>


      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>


    </v-container>
    <v-dialog v-model="attachmentsDialog" overlay-opacity="0.75" persistent :max-width="950">
      <v-card relative class="pa-7 py-10" :key="dialogKey">
        <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="attachmentsDialog = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-row justify="center" class="mt-2" v-if="pageData.rowIsLoading.index !== null">


          <GenericInput type="file" v-for="(attachment) in file_names" :key="attachment.id" :value="attachment.value"
            @input="attachment.value = $event" :label="attachment.name" :multi="false" :required="false"
            selected_label="name" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
          </GenericInput>

          <!-- <v-col cols="12" class="ma-0 d-flex" v-for="(attachment) in file_names" :key="attachment.id">
            <GenericInput type="file" :value="attachment.value" @input="attachment.value = $event"
              :label="attachment.name" :multi="false" :required="false" selected_label="name" selected_prop="id"
              :isLoading="false" :cols="[10, 10, 10]">
            </GenericInput>
            <v-col cols="auto">
              <v-icon>mdi mdi-download</v-icon>
            </v-col>
          </v-col> -->

          <v-col cols="12" class="d-flex justify-end">
            <v-btn class="mx-2" text depressed color="red4" @click="attachmentsDialog = false" :height="37" :width="120">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn class="mx-2 white--text" :loading="false" @click="saveAttachment" depressed color="success"
              :height="37" :width="120">
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <LastProblemCommentDialog :dialog="lastCommentDialog" :selectedRow="selectedRow"
      :closeDialog="() => { lastCommentDialog = false; selectedRow = Object }" :id="selectedRow.id" />
    <WithdrawalNoteDialog :dialog="withdrawalNoteDialog" :selectedRow="selectedRow"
      :closeDialog="() => { withdrawalNoteDialog = false; selectedRow = Object }" :id="selectedRow.id" />
    <InspectionNumberDialog :dialog="inspectionNumberDialog" :selectedRow="selectedRow"
      :closeDialog="() => { inspectionNumberDialog = false; selectedRow = Object }" :id="selectedRow.id" />
    <PortsNumberDialog :dialog="portsNumberDialog" :selectedRow="selectedRow"
      :closeDialog="() => { portsNumberDialog = false; selectedRow = Object }" :id="selectedRow.id" />
    <ArrivalExpectedDateDialog :dialog="expectedDateDialog" :selectedRow="selectedRow"
      :closeDialog="() => { expectedDateDialog = false; selectedRow = Object }" :id="selectedRow.id" />
    <ClearanceSummeryDialog :dialog="clearanceSummeryDialog" :selectedRow="selectedRow"
      :closeDialog="() => { clearanceSummeryDialog = false; selectedRow = Object }" :id="selectedRow.id" />

    <ChatDialog :dialog="commentsDialog" :chat="comments" :isLoading="commentsLoading || pageData.rowIsLoading.loading"
      :saveMessage="addComment" :closeDialog="() => commentsDialog = false" />

    <ClearanceStatusDialog :dialog="clearanceStatusDialog" :selectedRow="selectedRow" :closeDialog="changeStatus"
      :id="selectedRow.id" />

  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import LastProblemCommentDialog from "../ModuleComponents/LastProblemCommentDialog.vue";
import WithdrawalNoteDialog from "../ModuleComponents/WithdrawalNoteDialog.vue";
import PortsNumberDialog from "../ModuleComponents/PortsNumberDialog.vue";
import InspectionNumberDialog from "../ModuleComponents/InspectionNumberDialog.vue";
import ClearanceSummeryDialog from "../ModuleComponents/ClearanceSummeryDialog.vue";
import ChatDialog from "@/components/ui/ChatDialog.vue";
import ArrivalExpectedDateDialog from "../ModuleComponents/ArrivalExpectedDateDialog.vue";
import ClearanceStatusDialog from "../ModuleComponents/ClearanceStatusDialog.vue";
export default {
  name: "ClearanceEntity",

  data: () => ({
    pageData: {
      screen_code: "04-004",
      url: null,
      controlRoute: "sales/clearance-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      status: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
      printLoading: {
        id: null,
        key: 'id',
        loading: false,
      },
      rowIsLoading: {
        loading: false,
        print_loading: false,
        id: null,
        index: null,
        key: 'id'
      },
      from_date: null,
      to_date: null,
      customer_id: null,
      port_id: null,
      clearance_id: null,
      shipping_policy_no: null,
      container_number: null,
    },
    today: null,
    customers: [],
    ports: [],
    dialogKey: 1,
    showFilter: false,
    selectedRow: Object,
    attachmentsDialog: false,
    inspectionNumberDialog: false,
    attachments: [],
    statusIsLoading: false,
    lastCommentDialog: false,
    portsNumberDialog: false,
    withdrawalNoteDialog: false,
    expectedDateDialog: false,
    commentsDialog: false,
    clearanceSummeryDialog: false,
    commentsLoading: false,
    clearanceStatusDialog: false,
    customersLoading: false,
    asyncSearchCustomer: null,
    comments: [],
    tab: 0,
    status: [],
    file_names: []
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    GenericInput,
    LastProblemCommentDialog,
    WithdrawalNoteDialog,
    PortsNumberDialog,
    InspectionNumberDialog,
    ClearanceSummeryDialog,
    ChatDialog,
    ArrivalExpectedDateDialog,
    ClearanceStatusDialog,
  },
  computed: {
    tableHeader() {
      let custom_header = [...this.pageData.tableHeader]
      switch (+this.pageData.queryParam.get("status")) {
        case 1:
        case 2:
          custom_header.splice(10, 1);
          custom_header.splice(10, 1);
          custom_header.splice(10, 0,
            { text: "expected arrival", key: "arrival_date", type: 'slot', classes: "" },
          )
          return custom_header
        case 3:
        case 4:
          custom_header.splice(10, 1);
          custom_header.splice(10, 0,
            { text: "unloading date", key: "unloading_date", type: 'slot', classes: "" },
          )
          return custom_header
        default:
          return this.pageData.tableHeader
      }
    },
    isBase64() {
      return (str) => /^data:.*;base64,/.test(str);
    },
    search_customer() {
      return (val) => {
        console.log({ val });
        clearTimeout(this.asyncSearchCustomer)

        if (val) {
          this.asyncSearchCustomer = setTimeout(() => {
            this.customersLoading = true
            this.$api.GET_METHOD(`customer?word=${val}`)
              .then((response) => {
                this.customers.push(...response.data.items)
              })
              .finally(() => (this.customersLoading = false))
          }, 750)
        }
      }
    },
  },
  watch: {
    $route() {
      this.pageData.queryParam = new URLSearchParams();
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      this.getData();
    },
    hasProblemDialog() {
      if (!this.hasProblemDialog) {
        this.pageData.rowIsLoading.index = null;
        this.comment = null
      }
    },
  },
  mounted() {
    this.pageData.port_id = this.$store.state.userData.user.default_port || null;
    this.queryParams();
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.status = this.$store.state.activeScreen.queryParams['status'] || '';
      this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
      this.pageData.queryParam.set("port_id", this.pageData.port_id || '');
      if (!(this.pageData.queryParam.has('sort_by') || this.pageData.queryParam.has('sort_value'))) {
        this.pageData.queryParam.set("sort_default", 1);
      } else {
        this.pageData.queryParam.delete("sort_default");
      }
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "transaction no", key: "id", type: 'slot', classes: "", },
        { text: "delivery date", key: "action_date", type: 'date', width: 95, classes: "" },
        { text: "customer", key: "customer_name", type: 'text', width: 120, classes: "" },
        { text: "delegate", key: "delegate_name", type: 'text', classes: "" },
        { text: "containers QTY", key: "container_count", type: 'slot', classes: "", not_sorted: true },
        { text: "shipping policy no", key: "shipping_policy_no", type: 'text', classes: "" },
        { text: "port", key: "port_name", type: 'text', classes: "" },
        { text: "first container number", key: "first_container_number", type: 'text', classes: "" },
        { text: "comments", key: "comments", type: 'slot', classes: "", not_sorted: true },
        { text: "status", key: "status_name", type: 'slot', classes: "" },
        { text: "floor date", key: "customs_floor_date", type: 'date', classes: "", width: 85 },
        { text: "customs declaration number", key: "customs_declaration_number", type: 'text', classes: "" },
        { text: "has a problem", key: "has_problem", type: 'slot', classes: "", not_sorted: true },
        { text: "port numbers", key: "port_withdraw", type: 'slot', classes: "" },
        { text: "delivery number", key: "withdrawal_note", type: 'slot', classes: "" },
        { text: "inspection no", key: "inspection_number", type: 'slot', classes: "" },
        { text: "clearance invoice", key: "invoices", type: 'slot', classes: "" },
        { text: "attachments", key: "attachments", type: 'slot', classes: "", not_sorted: true },
        { text: "actions", key: "id", type: 'actions', classes: "", not_sorted: true },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: true,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      console.log(this.pageData.queryParam.toString());
      this.setFilterParams();
      this.pageData.queryParam.set("word", typeof word == 'string' ? word : '')
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
    },
    setFilterParams() {
      this.pageData.queryParam.set("customer_id", this.pageData.customer_id || '');
      this.pageData.queryParam.set("port_id", this.pageData.port_id || '');
      this.pageData.queryParam.set("clearance_id", this.pageData.clearance_id || '');
      this.pageData.queryParam.set("shipping_policy_no", this.pageData.shipping_policy_no || '');
      this.pageData.queryParam.set("from_date", this.pageData.from_date || '');
      this.pageData.queryParam.set("to_date", this.pageData.to_date || '');
      this.pageData.queryParam.set("container_number", this.pageData.container_number || '');
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`clearance?${this.pageData.queryParam.toString()}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              response.data.file_names.forEach(file => {
                file.value = null;
              });
              response.data.items.data.forEach(row => {
                row.attachments = response.data.file_names
                row.rowClass = row.has_problem ? 'red12' : '';
                row.invoices = row.invoices ? row.invoices.split(",").map((i) => +i) : [];
              });
              this.showFilter = false;
              // this.customers = response.data.customers || [];
              this.ports = response.data.ports || [];
              this.file_names = response.data.file_names;
              this.attachments = [...response.data.file_names];
              this.status = response.data.status;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    openDialog(row, index) {
      this.attachmentsDialog = true;
      this.pageData.rowIsLoading.index = index;
      this.dialogKey = this.dialogKey + 1

      if (this.attachmentsDialog) {
        this.$api.GET_METHOD(`get_clearance_attachment/${row.id}`)
          .then((response) => {
            if (response.check) {
              response.data.forEach((data) => {
                data.value = data.file_path
              });
              this.file_names = response.data
            }
          })
      }
    },
    changeFilter(id) {
      this.pageData.queryParam.set("status", id || '');
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
    },
    changePage(page, limit) {
      this.pageData.queryParam.set("page", page || 1);
      this.pageData.queryParam.set("rows", limit || 15);
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`clearance/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`clearance_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    saveAttachment() {
      // this.pageData.isLoading = true;
      // this.pageData.rowIsLoading.loading = true;
      this.file_names.forEach((attachment) => {
        if (attachment.value && attachment.id) {
          this.pageData.rowIsLoading.id = this.pageData.rows[this.pageData.rowIsLoading.index].id;
          const requestBody = {
            clearance_file_name_id: attachment.id,
            file: attachment.value,
          }

          if (this.isBase64(requestBody.file)) {
            this.pageData.rowIsLoading.loading = true;
            this.$api.POST_METHOD(`clearance_add_file/${this.pageData.rows[this.pageData.rowIsLoading.index].id}`, requestBody).then((response) => {
              if (response.check) {
                this.pageData.rowIsLoading.loading = false;
                this.pageData.rowIsLoading.id = null;
                this.pageData.rowIsLoading.index = null;
                attachment.value = null;
              }
            })
          }

        }

      });
      this.attachmentsDialog = false
    },
    printClearance(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api.PrintPOST('export_clearance', row.id, 'print').then(() => {
        this.pageData.printLoading.id = null;
        this.pageData.printLoading.loading = false;
      })
    },
    addComment(comment) {
      this.commentsLoading = true;
      this.$api.POST_METHOD(`clearance_add_comment/${this.pageData.rowIsLoading.id}`, { comment: comment }).then((response) => {
        this.commentsLoading = false;
        if (response.check) {
          this.viewComments(this.pageData.rowIsLoading.id);
        }
      });
    },
    viewComments(id) {
      this.pageData.rowIsLoading.loading = true
      this.pageData.rowIsLoading.id = id;
      this.$api.GET_METHOD(`clearance_get_comment/${id}`).then((response) => {
        this.pageData.rowIsLoading.loading = false
        if (response.check) {
          this.comments = response.data.items;
          this.commentsDialog = true;
        }
      })
    },
    rowClicked(row) {
      this.selectedRow = { ...row };
      this.clearanceSummeryDialog = true;
    },
    clearFilter() {
      this.pageData.customer_id = null;
      this.pageData.port_id = null;
      this.pageData.clearance_id = null;
      this.pageData.shipping_policy_no = null;
      this.pageData.from_date = null;
      this.pageData.to_date = null;
      this.pageData.search = '';
      this.$router.push(`${this.pageData.url}`)
    },
    changeStatus(id) {
      this.clearanceStatusDialog = false;
      this.selectedRow = Object;
      const getIndex = this.pageData.rows.findIndex((row) => row.id == id)
      this.pageData.rows.splice(getIndex, 1);
    }
  },

};
</script>
